import React, { useEffect, useState } from "react";

import { createUseStyles } from "react-jss";
import Header from "./shared/Header";
import { CircularProgress } from "@material-ui/core";
import { ViewAllButton } from "./shared/HeaderActions";

const useStyles = createUseStyles({
  loadingContainer: {
    width: "100%",
    height: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
});

const LoadingContent = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Header renderActions={() => <ViewAllButton />} />
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    </React.Fragment>
  );
};

export default LoadingContent;
