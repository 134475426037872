import isPlainObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import each from "lodash/each";
import compact from "lodash/compact";
import capitalize from "lodash/capitalize";
import pluralize from "pluralize";

export const formatAddress = ({
  address,
  city,
  state,
  postalCode,
  countryCode,
}) => {
  const addressArray = [address, city, state, postalCode, countryCode];

  return compact(addressArray).join(" ");
};

export const addHttp = (url) => {
  if (url && !/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const mapKeysToSnakeCase = (obj) => {
  const newObj = isArray(obj) ? [] : {};

  each(obj, (val, key) => {
    if (isPlainObject(val) || isArray(val)) {
      val = mapKeysToSnakeCase(val);
    }
    newObj[snakeCase(key)] = val;
  });

  return newObj;
};

export const mapKeysToCamelCase = (obj) => {
  const newObj = {};

  each(obj, (val, key) => {
    if (isPlainObject(val) || isArray(val)) {
      val = mapKeysToCamelCase(val);
    }
    newObj[camelCase(key)] = val;
  });

  return newObj;
};

export const removeSpaces = (str) => str && str.replace(/\s/g, "");
export const formatCount = (noun, count) =>
  `${count} ${pluralize(noun, count)}`;

export const buildFullName = (firstName, lastName) => {
  const applyCaps = (name) => name.replace(/\w+/g, (wrd) => capitalize(wrd));

  return applyCaps(`${firstName} ${lastName}`);
};

export const mapToInclusionHash = (arr) => {
  const res = {};

  each(arr, (el) => {
    res[el] = true;
  });

  return res;
};

export const prependHTTPProtocol = (urlString) => {
  if (typeof urlString === "string" && urlString.match(/^(http|https):\/\//)) {
    return urlString;
  } else {
    return `http://${urlString}`;
  }
};
