import React from "react";

import { styles } from "./utils";

const Input = ({ search, inputValue, setInputValue, loading, inputLoading }) => {
  const classes = styles({});

  const inLoadingState = (loading && search.length > 0) || inputLoading

  return (
    <React.Fragment>
      <i className={`fas fa-search ${classes.inputIcon}`} />
      <input
        value={inputValue}
        placeholder="Search by Event Title"
        onChange={(e) => setInputValue(e.target.value)}
        className="form-control"
        style={{ paddingLeft: 36 }}
      />
      {!inLoadingState && search && search.length > 0 && (
        <i
          onClick={() => setInputValue("", true)}
          className={`fas fa-times ${classes.inputClose}`}
        />
      )}
      {inLoadingState && (
        <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
      )}
    </React.Fragment>
  );
};

export default Input;
