import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import { EventsContext } from "../Wrapper";

const useStyles = createUseStyles({
  container: {
    padding: 20,
    textAlign: "center",
  },
  content: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#292E33",
  },
  action: {
    fontSize: "14px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#F35B5E",
    cursor: "pointer",
  },
});

function noResultText(status, isSearch) {
  if (isSearch) {
    return <span>We cannot find any events with that information.</span>;
  } else {
    switch (status) {
      case "all":
        return <span>You have no events.</span>;
      case "drafts":
        return <span>You have no draft events.</span>;
      default:
        return <span>You have no {status.replace(/_/g, " ")} events.</span>;
    }
  }
}

export default function NoResults() {
  const classes = useStyles();
  const { admin, boardMember } = useSelector(
    (state) => state.settings.attributes
  );

  const { status, search, setSearch } = useContext(EventsContext);
  const isSearch = search && search.length > 0;

  return (
    <div
      className="kt-portlet kt-portlet--height-fluid"
      style={{ height: "auto" }}
    >
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className={classes.container}>
          <div className={classes.content}>
            {noResultText(status, isSearch)}
          </div>
          {isSearch && (
            <div style={{ marginTop: 12 }}>
              <button
                onClick={() => setSearch("")}
                className="btn btn-sm btn-brand"
              >
                Show all Events
              </button>
            </div>
          )}
          {(admin || boardMember) && (
            <div style={{ marginTop: 12 }}>
              <a href={window.newChapterEventPath}>
                <button className="btn btn-sm btn-brand">Create Events</button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
