import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import AcceptedInvitationsText from "./AcceptedInvitationsText";

export default function StatusText({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const {
    invitationStatus,
    status,
    formattedSubmitDate
  } = event.attributes;

  let invitationText, showCount, eventText;

  switch (invitationStatus) {
    case "attended":
      invitationText = "You attended.";
      break;
    case "checked_in":
      invitationText = "You are checked-in.";
    case "no_show":
    case "expired":
    case "past":
      invitationText = "You did not attend.";
      break;
    case "accepted":
      invitationText = "You are attending.";
      showCount = true;
      break;
    case "declined":
      invitationText = "You are not attending.";
      showCount = true;
      break;
    case "pending":
    case "in_progress":
      invitationText = "Please RSVP.";
      showCount = true;
      break;
  }

  switch (status) {
    case "submitted":
      eventText = "Submitted on " + formattedSubmitDate;
      break;
    case "past":
      eventText = "You did not attend.";
      break;
    case "in_progress":
      eventText = "This event is in progress.";
      break;
  }

  return (
    <div
      style={{
        fontWeight: 500,
        color: "#357BA6",
        fontSize: 18,
        maxHeight: 25,
        overflow: "hidden",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: 10,
      }}
    >
      <span style={{ width: "100%", maxWidth: 315 }}>
        {invitationText || eventText}
        <AcceptedInvitationsText {...{ showCount, id }} />
      </span>
    </div>
  );
}
