import React from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import AvatarIcon from "../../../General/AvatarIcon";
import Like from "../../../General/Like";
import ProfileHover from "../../../Shared/ProfileHover/ProfileHover";

import moment from "moment";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#48465B",
    margin: "4px 0px 0px 8px",
  },
  userDetailsPrimary: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  userName: {
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  userBackground: {
    fontWeight: 400,
    fontSize: "1rem",
  },
  timestamp: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 400,
    color: "#48465B",
  },
  likeContainer: {
    marginLeft: "auto",
  },
});

const Header = ({ comment }) => {
  const { userId, id, liked, createdAt } = comment.attributes;

  const user = useSelector((state) => state.users.items[userId]);
  const settings = useSelector((state) => state.settings.attributes);

  const classes = useStyles();

  if (!user) {
    return null;
  }

  const { firstName, lastName, jobTitle, companyName } = user.attributes;

  return (
    <div className={classes.container}>
      <ProfileHover {...{ userId }}>
        <AvatarIcon avatarId={user.id} height={50} />
        <div className={classes.userDetails}>
          <div className={classes.userDetailsPrimary}>
            <div className={classes.userName}>
              {firstName} {lastName}
            </div>
            <div className={classes.timestamp}>
              <i className="la la-clock-o" />{" "}
              {moment(createdAt).fromNow(moment())} ago
            </div>
          </div>
          <div className={classes.userBackground}>
            {jobTitle}, {companyName}
          </div>
        </div>
      </ProfileHover>
      <div className={classes.likeContainer}>
        {settings.id !== user.id && <Like id={id} liked={liked} />}
      </div>
    </div>
  );
};

export default Header;
