import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { clearModal } from "../../../../actions/uiActions";
import { leaveConversation } from "../../../../actions/conversationActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    maxWidth: 400,
  },
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: 12,
  },
  contentContainer: {
    height: 100,
    fontSize: 15,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      4
    )}px ${theme.spacing(3)}px`,
  },
  actions: {
    flexDirection: "row",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
}));

const LeaveConversationModal = ({ conversationId }) => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.ui.modal.visible);

  const handleClose = () => {
    dispatch(clearModal());
  };

  const classes = useStyles();

  const handleLeave = () => {
    dispatch(leaveConversation(conversationId)).then(() => {
      dispatch(clearModal());
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper, root: classes.root }}
    >
      <DialogTitle classes={{ root: classes.title }}>
        Leave Chat
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        Are you sure you want to leave this chat? You will no longer have access
        to any previous messages.
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <button className="btn btn-outline-brand" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-brand" onClick={handleLeave}>
          Leave
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveConversationModal;
