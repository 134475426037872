import { GET } from "../util/apiHelpers";
import normalize from "json-api-normalizer";

export const RECEIVE_GROUPS = "RECEIVE_GROUPS";

export const receiveGroups = (groups) => ({
  type: RECEIVE_GROUPS,
  payload: groups,
});

export const fetchAdminAllGroups = () => (dispatch) => {
  return GET("/api/v1/group/admin_index").then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveGroups(normalized.group));
  });
};
