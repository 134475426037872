import React from "react";
import { Row, Col, Visible } from "react-grid-system";
import { Provider } from "react-redux";
import configureStore from "../../../configureStore";
import SideCards from "../../General/SideCards";

import Form from "./new/Form";

const store = configureStore();

const New = ({ vendorProductId, otherProductsInCategory }) => {
  return (
    <Provider store={store}>
      <Row>
        <Col lg={8} style={{ paddingRight: 0 }}>
          <Form
            vendorProductId={vendorProductId}
            otherProductsInCategory={otherProductsInCategory}
          />
        </Col>
        <Visible lg xl xxl>
          <Col md={12} lg={4}>
            <SideCards>
              <div className="kt-portlet">
                <div className="kt-portlet__body kt-portlet__body--fluid">
                  <div className="kt-section" style={{ margin: 0 }}>
                    <div className="kt-section__title" style={{ fontWeight: 500, fontSize: 18, color: "#48465B"}}>
                      Tips for a great review
                    </div>
                    <div className="kt-section__desc" style={{ fontWeight: 400, fontSize: 16, color: "#595D6E"}}>Be descriptive</div>
                    <div
                      className="kt-section__content"
                      style={{ marginBottom: "1.75em", fontWeight: 400, fontSize: 14, color: "#212121"}}
                    >
                      Instead of saying that you like the vendor, what specific things do you love? Use examples.
                    </div>
                    <div className="kt-section__desc" style={{ fontWeight: 400, fontSize: 16, color: "#595D6E"}}>Be objective</div>
                    <div
                      className="kt-section__content"
                      style={{ marginBottom: "1.75em", fontWeight: 400, fontSize: 14, color: "#212121"}}
                    >
                      Even if your review is more positive or negative, try to highlight both positive and negatives about the vendor.
                    </div>
                    <div className="kt-section__desc" style={{ fontWeight: 400, fontSize: 16, color: "#595D6E"}}>Be recent</div>
                    <div
                      className="kt-section__content"
                      style={{ marginBottom: "1.75em", fontWeight: 400, fontSize: 14, color: "#212121"}}
                    >
                      The best reviews are those written about recently used vendors.
                    </div>
                  </div>
                </div>
              </div>
            </SideCards>
          </Col>
        </Visible>
      </Row>
    </Provider>
  );
};

export default New;
