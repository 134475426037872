import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_CONVERSATIONS,
  REMOVE_CONVERSATION,
} from "../../actions/conversationActions";

const defaultState = {};

const itemsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CONVERSATIONS:
      return merge({}, state, action.payload);
    case REMOVE_CONVERSATION:
      const newState = cloneDeep(state);
      delete newState[action.payload];

      return newState;
    default:
      return state;
  }
};

export default itemsReducer;
