import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import moment from "moment";

import map from "lodash/map";
import orderBy from "lodash/orderBy";

import {
  ACCEPTED,
  makeGetFilteredEvents,
} from "../../selectors/eventSelectors";
import EventItem from "./EventItem";
import EventEmptyState from "./EventEmptyState";

const useStyles = createUseStyles({
  eventList: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(34,34,34,0.2)",
    height: "50px",
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "13px",
    letterSpacing: "0.07px",
    lineHeight: "24px",
    cursor: "pointer",
    "&:hover": {
      borderRadius: "0 0 5px 5px",
      backgroundColor: "#EAEEF3",
    },
  },
});

const AttendingEventsCard = ({ events }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const loaded = useSelector((state) => state.events.loaded);

  const sortedEvents = orderBy(events, ({ attributes }) =>
    moment(attributes.scheduledAt).diff(moment())
  );

  const displayCount = expanded ? events.length : 3;

  return (
    <div
      className="kt-portlet kt-portlet--height-fluid"
      style={{ height: "auto" }}
    >
      {loaded && (
        <div>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className={classes.eventList}>Events You Are Attending</div>
            {map(sortedEvents.slice(0, displayCount), (event) => (
              <EventItem event={event} key={event.id} />
            ))}
            {sortedEvents.length === 0 && <EventEmptyState />}
          </div>
          {!expanded && events.length > displayCount && (
            <div
              className={`event-item ${classes.expandButton}`}
              onClick={() => setExpanded(true)}
            >
              See More
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const makeMapStateToProps = () => {
  const getFilteredEvents = makeGetFilteredEvents();

  const mapStateToProps = (state) => {
    return {
      events: getFilteredEvents(state, { invitationStatus: ACCEPTED }),
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(AttendingEventsCard);
