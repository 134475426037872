import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";

import Skeleton from "react-loading-skeleton";
import useSharedStyles, { AVATAR_SIZE } from "./useSharedStyles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  skeletonWrapper: {
    "& > span": {
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
}));

const ConversationItemSkeleton = () => {
  const sharedClasses = useSharedStyles({ disableHover: true });
  const classes = useStyles();

  return (
    <div className={sharedClasses.container}>
      <div className={sharedClasses.avatarContainer}>
        <Skeleton circle={true} height={AVATAR_SIZE} width={AVATAR_SIZE} />
      </div>
      <div className={sharedClasses.textContainer}>
        <div
          className={classnames(
            sharedClasses.headerContainer,
            classes.skeletonWrapper
          )}
        >
          <Skeleton width={"100%"} height={20} />
        </div>
        <div
          className={classnames(
            sharedClasses.bodyContainer,
            classes.skeletonWrapper
          )}
        >
          <Skeleton width={"100%"} height={40} />
        </div>
      </div>
    </div>
  );
};

export default memo(ConversationItemSkeleton);
