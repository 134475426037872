import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { createUseStyles } from "react-jss";
import MuiAccordionUnstyled from "@material-ui/core/FormGroup";

import Options from "./form_group/Options";

const useStyles = createUseStyles({
  formControl: {
    maxHeight: ({ searchable }) => (searchable ? 250 : "fit-content"),
  },
});

const MuiFormGroup = withStyles({
  root: {
    overflowY: "scroll",
    flexWrap: "nowrap",
    width: "100%",
  },
})(MuiAccordionUnstyled);

export default function FormGroup({
  id,
  searchable,
  searchedOptions,
}) {
  const classes = useStyles({ searchable });

  return (
    <MuiFormGroup
      style={{
        maxHeight: ({ searchable }) => (searchable ? 250 : "fit-content"),
      }}
      className={classes.formControl}
    >
      <Options {...{ id, searchedOptions }} />
    </MuiFormGroup>
  );
}
