import React from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function WatchRecordingButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { link, webinar, status } = event.attributes;

  if (status !== "past") return null;
  if (!webinar) return null;

  return (
    <a
      href={link + "/conversation"}
      style={{ width: "100%", textAlign: "center" }}
    >
      <button
        style={{ width: "100%", maxWidth: 315 }}
        className="btn btn-sm btn-brand-secondary"
      >
        Watch Recording
      </button>
    </a>
  );
}
