import { makeStyles } from "@material-ui/core";
import { connect } from "formik";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import times from "lodash/times";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { openModal } from "../../../../actions/uiActions";
import { LOADING, LOADED } from "../../../../util/apiHelpers";
import { usePrevious } from "../../../../util/customHooks";
import { useConversationContext } from "../../ConversationsContext";
import DiscardModal from "../../partials/DiscardModal";
import { useIntersectionObserver } from "../../partials/hooks";

import ConversationItem from "./ConversationItem/ConversationItem";
import ConversationItemSkeleton from "./ConversationItem/ConversationItemSkeleton";
import { ITEM_HEIGHT } from "./ConversationItem/useSharedStyles";
import ConversationsListHeader from "./ConversationsListHeader";
import RenderIfVisible from "./RenderIfVisible";
import SearchBar from "./SearchBar";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRight: "1px solid #ebedf2",
    height: "100%",
  },
  itemsList: {
    overflow: "hidden",
    overflowY: "scroll",
    height: "calc(100% - 60px - 39px)",
  },
  emptyList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textAlign: "center",
  },
  emptyListText: {
    fontSize: 18,
    color: "#48465B",
    fontWeight: 400,
  },
}));

function ItemsList({
  conversations,
  selectedConversationId,
  handleSelection,
  selectedItemRef,
}) {
  return (
    <>
      {map(conversations, ({ id }, idx) => (
        <RenderIfVisible
          defaultHeight={ITEM_HEIGHT}
          key={id + idx}
          placeholder={<ConversationItemSkeleton />}
        >
          <ConversationItem
            id={id}
            isSelected={id === selectedConversationId}
            handleSelection={handleSelection(id)}
            ref={id === selectedConversationId ? selectedItemRef : null}
          />
        </RenderIfVisible>
      ))}
    </>
  );
}

function SkeletonList() {
  return (
    <>
      {times(4, (idx) => (
        <ConversationItemSkeleton key={`skeleton-item-${idx}`} />
      ))}
    </>
  );
}

function ScrollBottom({ fetchMore }) {
  const bottomRef = useRef();

  const observer = useIntersectionObserver(bottomRef);

  useEffect(() => {
    if (observer && observer.isIntersecting) fetchMore();
  }, [observer, fetchMore]);

  return <div ref={bottomRef} style={{ height: 1 }} />;
}

function ConversationsList({
  conversations,
  loadStatus,
  initiallyLoaded,
  fetchMore,
  fullyLoaded,
  formik,
  searchQuery,
  setSearchQuery,
}) {
  const classes = useStyles();
  const selectedItemRef = useRef();
  const dispatch = useDispatch();

  const {
    selectedConversationId,
    setSelectedConversationId,
    isCreating,
    setIsCreating,
    handleDrawerOpen,
  } = useConversationContext();

  const prevInitiallyLoaded = usePrevious(initiallyLoaded);

  const hasConversations = useSelector(
    (state) => !isEmpty(state.conversations.items)
  );
  // handle initial load without mounted selectedConversationId
  useEffect(() => {
    if (!prevInitiallyLoaded && initiallyLoaded && !selectedConversationId) {
      if (conversations.length) {
        setSelectedConversationId(conversations[0].id);
      }
      else {
        setIsCreating(true);
      }
    }
  }, [initiallyLoaded, prevInitiallyLoaded, conversations, setSelectedConversationId, selectedConversationId, setIsCreating]);

  useEffect(() => {
    const handleUnload = (event) => {
      if (
        formik.values.body.length > 0 ||
        formik.values.participantIds.length > 1
      ) {
        event.preventDefault();
        return event.returnValue = "Would you like to discard unsaved chat?";
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [formik.values.participantIds, formik.values.body]);

  const handleSelection = (id) => () => {
    const proceed = () => {
      setSelectedConversationId(id);
      setIsCreating(false);
      formik.resetForm();
      handleDrawerOpen(false);
    };

    Boolean(formik.values.body || formik.values.attachments.length)
      ? dispatch(openModal(<DiscardModal handleProceed={proceed} />))
      : proceed();
  };

  const handleOpenNewConversation = () => {
    const proceed = () => {
      setIsCreating(true);
      setSelectedConversationId(null);
      formik.resetForm();
      handleDrawerOpen(false);
    };

    Boolean(formik.values.body || formik.values.attachments.length)
      ? dispatch(openModal(<DiscardModal handleProceed={proceed} />))
      : proceed();
  };

  useEffect(() => {
    if (isCreating && selectedItemRef.current) {
      setTimeout(() => {
        selectedItemRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [isCreating]);

  const renderContent = () => {
    switch (loadStatus) {
      case LOADING:
        return (
          <div className={classes.itemsList}>
            {initiallyLoaded && (
              <ItemsList
                {...{
                  conversations,
                  selectedConversationId,
                  handleSelection,
                  selectedItemRef,
                }}
              />
            )}
            <SkeletonList />
          </div>
        );
      case LOADED:
        if (conversations.length) {
          return (
            <div className={classes.itemsList}>
              <ItemsList
                {...{
                  conversations,
                  selectedConversationId,
                  handleSelection,
                  selectedItemRef,
                }}
              />
              {!fullyLoaded && <ScrollBottom fetchMore={fetchMore} />}
            </div>
          );
        } if (searchQuery) {
          return (
            <div className={classes.emptyList}>
              <p className={classes.emptyListText}>
                We cannot find any chats with that information.
              </p>
              <p
                style={{ color: "#F35B5E", fontSize: 16, cursor: "pointer" }}
                onClick={() => setSearchQuery("")}
              >
                Show all chats
              </p>
            </div>
          );
        }
        return (
          <div className={classes.emptyList}>
            <p className={classes.emptyListText}>
              You currently have no chats.
            </p>
            {isCreating && (
            <p className={classes.emptyListText}>
              Search members on the right to start a chat.
            </p>
            )}
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={classes.container}>
      <ConversationsListHeader
        openNewConversation={handleOpenNewConversation}
      />
      {initiallyLoaded && hasConversations && (
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={!initiallyLoaded || loadStatus === LOADING}
        />
      )}
      {renderContent()}
    </div>
  );
}

export default connect(ConversationsList);
