import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import Truncate from "react-truncate";

import Tooltip from "@material-ui/core/Tooltip";

import { checkMobile, useStyles } from "./utils";
import { useScreenClass } from "react-grid-system";

function EditButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const settings = useSelector((state) => state.settings);
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const { status, editPath, creator, groupId } = event.attributes;
  const { admin, boardMemberGroups } = settings.attributes;

  if (
    !(admin || (creator === currentUserId) || boardMemberGroups.includes(groupId))
  )
    return null;
  if (["in_progress", "past"].includes(status)) return null;

  return (
    <Tooltip title="Edit" placement="top">
      <a
        style={{ marginLeft: 8 }}
        href={editPath}
        data-toggle="kt-tooltip"
        data-skin="secondary"
        data-placement="top"
        title=""
        data-original-title="Edit Event"
      >
        <i className="la la-edit"></i>
      </a>
    </Tooltip>
  );
}

function locationText({ webinar, groupName, locationName, formattedAddress }) {
  if (webinar) return groupName + " Webinar";

  return (
    groupName +
    ": " +
    ((locationName && locationName + " - ") || "") +
    formattedAddress
  );
}

export default function Details({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const classes = useStyles({});

  const { status, conversationPath, link, title, scheduledAt, subtitle } =
    event.attributes;

  const screenClass = useScreenClass();

  const [isMobile, setIsMobile] = useState(checkMobile(screenClass));

  useEffect(() => {
    setIsMobile(checkMobile(screenClass));
  }, [screenClass]);

  return (
    <div style={{ padding: 12, width: "100%" }}>
      <div title={title || "Event Title"} className={classes.titleContainer}>
        <a
          className={classes.titleLink}
          href={status === "past" ? conversationPath : link}
        >
          <Truncate lines={isMobile ? 2 : 1}>{title || "Event Title"}</Truncate>
        </a>
        <EditButton {...{ id }} />
      </div>
      <div className={classes.subtitle} title={subtitle}>
        <Truncate lines={3}>{subtitle}</Truncate>
      </div>
      <div className={classes.eventTime}>
        <div className="event-time">
          <time>
            {moment
              .utc(scheduledAt)
              .local()
              .format("ddd, MMM Do, YYYY, h:mm A")}
          </time>
          {status === "past" && " (PAST EVENT)"}
        </div>
      </div>
      <div className={classes.eventLocation}>
        {locationText({ ...event.attributes })}
      </div>
    </div>
  );
}
