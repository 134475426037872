import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    "@media (min-width: 992px)": {
      overflowY: "scroll",
      maxHeight: "calc(100vh - 60px)",
    },
  },
  subContainer: {
    paddingLeft: ({ alignment }) => (alignment === "right" ? 20 : 0),
    paddingRight: 0,
  },
});

const SideCards = ({children, alignment = 'right'}) => {
  const classes = useStyles({ alignment });

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        { children }
      </div>
    </div>
  );
};

export default SideCards;
