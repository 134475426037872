import { map, orderBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { getSubscribedGroups } from "../../../selectors/groupSelectors";
import { GET } from "../../../util/apiHelpers";
import {
  ConnectedTextField,
  ConnectedDropdown,
  ConnectedWysiwyg,
} from "../../General/FormInputs/ConnectedInputs";

const Subject = () => <ConnectedTextField id="subject" label="Subject" />;

const Body = () => (
  <ConnectedWysiwyg id="body" label="Body" uploadFolder="posts" hashtaggable />
);

const Group = () => {
  const subscribedGroups = useSelector(getSubscribedGroups);

  const options = useMemo(
    () =>
      orderBy(
        map(
          subscribedGroups,
          ({ id, attributes: { isNational, name, isInternational } }) => {
            const label = isNational || isInternational ? name : `${name} Only`;

            return { value: id, label };
          }
        ),
        [(group) => {
          if (group.label === 'National') return 0;
          if (group.label === 'International') return 1;
          return 2;
        }, 
        "label"],
        ["asc", "asc"]
      ),
    [subscribedGroups]
  );

  return (
    <ConnectedDropdown
      id="groupId"
      options={options}
      label="Who can view this topic?"
      styles={{ container: { maxWidth: 500 } }}
    />
  );
};

const VendorDropdown = () => {
  const [vendorOptions, setVendorOptions] = React.useState([]);

  useEffect(() => {
    GET("/public/api/v1/vendor_products").then((res) => {
      const options = (res?.data?.data ?? []).map((option) => ({
        value: option.id,
        label: option.name
      }));
      setVendorOptions(options);
    });
  }, []);

  return (
    <ConnectedDropdown 
      id="vendorProductId" 
      options={vendorOptions} 
      label="On Behalf of Vendor" 
      styles={{ container: { maxWidth: 500 } }}
      selectProps={{ isClearable: true }}
    />
  );
}

const FormFields = () => {
  const { admin } = useSelector((state) => state.settings.attributes);

  return (
    <div style={{ padding: 24 }}>
      <Subject />
      <Body />
      <Group />
      {admin && <VendorDropdown />}
    </div>
  );
}

export default FormFields;
