import React from "react";

import { styles } from "./utils";

import classnames from "classnames";

const Input = ({
  search,
  inputValue,
  setInputValue,
  loading,
  inputLoading,
  placeholder,
  inputClass = {},
}) => {
  const classes = styles({});

  const inLoadingState = (loading && search.length > 0) || inputLoading;

  return (
    <React.Fragment>
      <i className={`fas fa-search ${classes.inputIcon}`} />
      <input
        value={inputValue}
        placeholder={placeholder}
        onChange={(e) => setInputValue(e.target.value)}
        className={classnames("form-control", inputClass)}
        style={{ paddingLeft: 36 }}
      />
      {!inLoadingState && search && search.length > 0 && (
        <i
          onClick={() => setInputValue("")}
          className={`fas fa-times ${classes.inputClose}`}
        />
      )}
      {inLoadingState && (
        <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
      )}
    </React.Fragment>
  );
};

export default Input;
