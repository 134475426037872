import React from "react";
import Skeleton from "react-loading-skeleton";

import { useStyles } from "../item/utils";

export default function Details() {
  const classes = useStyles({});

  return (
    <div style={{ padding: 12, width: "100%" }}>
      <div className={classes.titleContainer}>
        <a className={classes.titleLink}>
          <Skeleton style={{ width: "90%" }} />
        </a>
      </div>
      <div className={classes.subtitle} >
        <Skeleton />
        <Skeleton />
        <Skeleton style={{ width: "80%" }} />
      </div>
      <div className={classes.eventTime}>
        <div className="event-time">
          <Skeleton style={{ width: 190 }} />
        </div>
      </div>
      <div className={classes.eventLocation}>
        <Skeleton style={{ width: 100 }} />
      </div>
    </div>
  );
}
