import React, { useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { deleteEvent } from "../../../../../actions/eventActions";
import { EventsContext } from '../../Wrapper';

export default function DeleteEventButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { status } = event.attributes;

  if (!["draft", "submitted"].includes(status)) return null;

  const dispatch = useDispatch();
  const { fetchAction } = useContext(EventsContext);

  const handleDelete = () => {
    if (window.confirm("Are you sure? This cannot be undone.")) {
      return dispatch(deleteEvent(id)).then(fetchAction);
    }
  };

  return (
    <button
      style={{ width: "100%", textAlign: "center" }}
      className="btn btn-sm btn-brand"
      onClick={handleDelete}
    >
      Delete {status === "draft" ? "Draft" : "Event"}
    </button>
  );
}
