import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import includes from "lodash/includes";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";

import { FilterContext } from "../../../Filter";

const useStyles = makeStyles(() => ({
  formControlLabel: {
    marginLeft: 0,
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#48465B",
  },
  checkbox: {
    padding: 4,
  },
}));

export default function Option({ id, label, value, color }) {
  const { context } = useContext(FilterContext);
  const { handleChangeState, state } = useContext(context);
  const classes = useStyles();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    
    if (queryParams[id] && includes(queryParams[id], label)) {
      handleChangeState(false, value, label, true, color);
    }
  }, []);

  return (
    <FormControlLabel
      key={value + "-checkbox"}
      className={classes.formControlLabel}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={(state[value + "|" + color] && state[value + "|" + color]["value"]) || false}
          onChange={handleChangeState}
          name={label}
          value={value}
          inputProps={{
            "data-color": color,
          }}
        />
      }
      label={label}
    />
  );
}
