import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import includes from "lodash/includes";
import pickBy from "lodash/pickBy";

import useSharedStyles from "../useSharedStyles";
import FieldGroupHeader from "../../General/FormCard/FieldGroupHeader";
import FormCard from "../../General/FormCard/FormCard";
import FormFooter from "../FormFooter";
import ChapterSection from "./ChapterSection";
import { updateTalkSettings } from "../../../actions/subscriptionActions";
import { openSnackbar } from "../../../actions/uiActions";
import { usePrevious } from "../../../util/customHooks";
import { GROUPLESS } from "../constants";
import GrouplessSection from "../GrouplessSection";

function TalkSettings({ subscriptions }) {
  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({});
  const prevSettings = usePrevious(subscriptions);

  const initializeNotificationSettings = () => {
    const initialSettings = {};
    map(
      subscriptions,
      ({ groupId, immediateNotifications }) =>
        (initialSettings[groupId] = {
          immediateNotifications,
          toUpdate: immediateNotifications,
          dirty: false,
        })
    );
    setNotificationSettings(initialSettings);
  };

  useEffect(() => {
    if (!isEqual(prevSettings, subscriptions)) {
      initializeNotificationSettings();
    }
  }, [subscriptions, prevSettings]);

  const updateSettings = (targetId) => {
    const [groupId, settingName] = targetId.split("_");
    const clonedSettings = cloneDeep(notificationSettings);
    const currentSettings = notificationSettings[groupId].toUpdate;

    clonedSettings[groupId].toUpdate = includes(currentSettings, settingName)
      ? without(currentSettings, settingName)
      : currentSettings.concat(settingName);

    clonedSettings[groupId].dirty =
      clonedSettings[groupId].toUpdate !==
      clonedSettings[groupId].immediateNotifications;

    setNotificationSettings(clonedSettings);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const dirtySettings = pickBy(notificationSettings, ({ dirty }) => dirty);
    const submitData = {};
    map(dirtySettings, ({ toUpdate }, groupId) => {
      submitData[groupId] = toUpdate;
    });

    return dispatch(updateTalkSettings({ data: submitData }))
      .then(() => {
        setIsSubmitting(false);
        return dispatch(openSnackbar("Settings successfully updated"));
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <FormCard overrideStyles={{ padding: 0 }}>
      <FieldGroupHeader
        title="On Talk"
        iconClass="la la-bell"
        iconStyles={{ fontSize: 22, marginRight: 12 }}
        overrideStyles={{ paddingLeft: 25 }}
        tooltipText="Receive alerts on your TALK notifications bell while on the website."
      />
      <div className={sharedClasses.headerRow}>
        <div className="col-lg-10" />
        <div className="col-lg-2">Yes</div>
      </div>
      <div className={sharedClasses.root}>
        <GrouplessSection
          settings={notificationSettings[GROUPLESS]}
          {...{ updateSettings }}
          type="Talk"
        />
        {map(
          subscriptions,
          ({ groupId, groupName }) =>
            groupId !== GROUPLESS && (
              <ChapterSection
                key={groupId + "-chapter-section"}
                settings={notificationSettings[groupId]}
                {...{ updateSettings, groupName, groupId }}
              />
            )
        )}
      </div>
      <FormFooter {...{ handleSubmit, isSubmitting }} />
    </FormCard>
  );
}

export default TalkSettings;
