import React from "react";
import { Provider } from "react-redux";

import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ScreenClassProvider } from "react-grid-system";
import momentConverter from "@date-io/moment";

import configureStore from "../../configureStore";
import muiTheme from "./muiTheme";

import Settings from "../General/Settings";
import Snackbar from "./Snackbar";
import Modal from "./ActionModal/ConnectedModal";
import FeatureFlags from "./FeatureFlags";

const store = configureStore();

const withGlobalProvidersPrerender = (Component) => (props) => (
  <Provider store={store}>
    <FeatureFlags>  
      <Settings>
        <ScreenClassProvider>
          <ThemeProvider theme={muiTheme}>
            <MuiPickersUtilsProvider utils={momentConverter}>
              <Component {...props} />
              <Snackbar />
              <Modal />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ScreenClassProvider>
      </Settings>
    </FeatureFlags>
  </Provider>
);

export default withGlobalProvidersPrerender;
