import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";

import { startEvent } from "../../../../../actions/eventActions";


export default function StartEventButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { status, startable } = event.attributes;

  if (!["upcoming"].includes(status)) return null;

  const dispatch = useDispatch();

  const handleStart = () => {
    if (!startable) return;
    return dispatch(startEvent(id));
  };

  const button = (
    <button
      style={{ maxWidth: "150px", width: "calc(33% - 6px)" }}
      className={"btn btn-sm btn-tertiary" + (startable ? "" : " disabled")}
      onClick={startable ? handleStart : null}
    >
      Start
    </button>
  );

  if (startable) {
    return button
  } else {
    return (
      <Tooltip
        title="You can download a csv file of all people who have RSVP’d at any point prior to the event. On the day of an event you will be able to start the event and check in guests from this section of the event card."
        placement="top"
      >
        {button}
      </Tooltip>
    );
  }
}
