import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import classnames from "classnames";
import includes from "lodash/includes";
import queryString from "query-string";
import React, {
  useState, useEffect, useMemo, useCallback
} from "react";
import {
  Col, Row, useScreenClass, Visible
} from "react-grid-system";
import { useLocation } from "react-router-dom";

import withGlobalProviders from "../General/withGlobalProviders";

import ConversationsContext, { useConversationContext } from './ConversationsContext';
import ConversationsLoader from "./ConversationsLoader";
import ConversationForm from "./form/ConversationForm";
import ConversationsList from "./index/ConversationsList/ConversationsList";
import NewConversation from "./new/NewConversation";
import Conversation from "./show/Conversation";

import ConversationSkeleton from "./show/skeleton/ConversationSkeleton";
import useConversationsSubscription from "./subscriptions/useConversationsSubscription";

const useStyles = makeStyles(() => ({
  container: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    height: "70vh", // todo(me): best way to ensure users scroll components instead of page? seems finicky
    minHeight: 400,
  },
}));

const mobileBreakpoints = { sm: true, xs: true };
const desktopBreakpoints = {
  md: true, lg: true, xl: true, xxl: true
};

function ConversationContent({ initiallyLoaded }) {
  const { isCreating, selectedConversationId } = useConversationContext();
  if (!initiallyLoaded) {
    return <ConversationSkeleton />;
  } 
  if (isCreating) {
    return <NewConversation />;
  } 
  if (selectedConversationId) {
    return <Conversation />;
  }
  return null;
}

function Conversations() {
  const classes = useStyles();

  const screenClass = useScreenClass();
  const isMobile = includes(Object.keys(mobileBreakpoints), screenClass);

  const [drawerOpen, setDrawerOpen] = useState(true);
  const location = useLocation();

  const priorityConversationId =
    queryString.parse(location.search)?.open_conversation_id || null;

  const [selectedConversationId, setSelectedConversationId] = useState(
    priorityConversationId
  );

  const [searchQuery, setSearchQuery] = useState("");

  const [isCreating, setIsCreating] = useState(false);

  useConversationsSubscription();

  useEffect(() => {
    setDrawerOpen(isMobile);
  }, [isMobile, setDrawerOpen]);

  const handleDrawerOpen = useCallback((shouldOpen, shouldToggle) => {
    setDrawerOpen(shouldToggle ? !drawerOpen : Boolean(shouldOpen));
  }, [drawerOpen]);

  const value = useMemo(() => ({
    selectedConversationId,
    setSelectedConversationId,
    isCreating,
    setIsCreating,
    handleDrawerOpen,
    isMobile,
  }), [handleDrawerOpen, isCreating, isMobile, selectedConversationId]);

  return (
    <ConversationsContext.Provider
      value={value}
    >
      <ConversationForm>
        <ConversationsLoader {...{ priorityConversationId, searchQuery }}>
          {({
            conversations,
            loadStatus,
            initiallyLoaded,
            fullyLoaded,
            fetchMore,
          }) => (
            <div className={classnames("kt-portlet", classes.container)}>
              <Row gutterWidth={0} style={{ height: "100%" }}>
                <Visible {...mobileBreakpoints}>
                  <Drawer
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                  >
                    <div
                      style={{ width: "380px", maxWidth: "calc(100vw * 0.75)" }}
                    >
                      <ConversationsList
                        {...{
                          conversations,
                          loadStatus,
                          fetchMore,
                          fullyLoaded,
                          initiallyLoaded,
                          searchQuery,
                          setSearchQuery,
                        }}
                      />
                    </div>
                  </Drawer>
                </Visible>
                <Visible {...desktopBreakpoints}>
                  <Col md={4} style={{ height: "100%" }}>
                    <ConversationsList
                      {...{
                        conversations,
                        loadStatus,
                        fetchMore,
                        fullyLoaded,
                        initiallyLoaded,
                        searchQuery,
                        setSearchQuery,
                      }}
                    />
                  </Col>
                </Visible>
                <Col md={8} xs={12} style={{ height: "100%" }}>
                  <ConversationContent {...{ initiallyLoaded }} />
                </Col>
              </Row>
            </div>
          )}
        </ConversationsLoader>
      </ConversationForm>
    </ConversationsContext.Provider>
  );
}

export default withGlobalProviders(Conversations);
