import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({});

export const ViewAllButton = ({}) => {
  const handleClick = () => (window.location.href = "/polls");

  return (
    <button
      onClick={handleClick}
      className={classnames("btn", "btn-sm", "btn-outline-brand-secondary")}
    >
      View All
    </button>
  );
};

export const NextButton = ({ handleNext }) => (
  <button
    onClick={handleNext}
    className={classnames("btn", "btn-sm", "btn-secondary")}
  >
    Next
  </button>
);

export const SkipButton = ({ handleNext }) => (
  <button
    onClick={handleNext}
    className={classnames("btn", "btn-sm", "btn-secondary")}
  >
    Skip
  </button>
);
