import React, { useCallback } from "react";
import Dropzone from "react-dropzone";

import { uploadFile } from "../../../util/uploadHelpers";

const FileInput = ({
  id,
  onChange,
  value,
  uploadFolder,
  acceptedTypes,
  maxFiles = 1,
  maxSize = 5000000, // in bytes
  onUploadSuccess,
  onUploadFailure,
  onUploadProgress,
  children,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      uploadFile({
        file,
        uploadFolder,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
      });
    };

    reader.readAsDataURL(file);
  }, []);

  return (
    <Dropzone
      onDrop={onDrop}
      accept={acceptedTypes}
      maxFiles={maxFiles}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps }) => (
        <React.Fragment>
          <div {...getRootProps()}>
            {children}
            <input
              id={id}
              onChange={onChange}
              value={value}
              {...getInputProps()}
            />
          </div>
        </React.Fragment>
      )}
    </Dropzone>
  );
};

export default FileInput;
