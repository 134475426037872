import React from "react";
import { useSelector } from "react-redux";

const Item = ({ categoryId, fontSize = 16, utmSource, utmContent }) => {
  const productCategory = useSelector(
    (state) => state.productCategories.items[categoryId]
  );

  const fathomParams =
    utmSource && utmContent
      ? `?utm_source=${utmSource}&utm_content=${utmContent}`
      : "";

  return (
    <li>
      <a
        href={productCategory.attributes.url + fathomParams}
        style={{ fontWeight: 400, fontSize: fontSize, color: "#367BA6" }}
      >
        {productCategory.attributes.name}
      </a>
    </li>
  );
};

export default Item;
