import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../../actions/settingActions";
import withGlobalProviders from "../General/withGlobalProviders";

import AddClassificationsModal from "./add_classifications/AddClassificationsModal";

function AddClassifications() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    settings.loaded &&
    !settings.attributes.classificationFieldsComplete && (
      <AddClassificationsModal />
    )
  );
};

export default withGlobalProviders(AddClassifications);
