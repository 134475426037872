import normalize from "json-api-normalizer";
import merge from "lodash/merge";
import queryString from "query-string";
import { batch } from "react-redux";

import { GET } from "../util/apiHelpers";

import { receiveComments } from "./commentActions";
import { receiveTopics } from "./topicActions";
import { receiveUsers } from "./userActions";

export const RECEIVE_ACTIVITIES = "RECEIVE_ACTIVITIES";

const receiveActivities = (activities) => ({
  type: RECEIVE_ACTIVITIES,
  payload: activities,
});

export const fetchActivities = (params = {}) => (dispatch) => {
  return GET(`/api/v1/activities?${queryString.stringify(params)}`).then(
    (res) => {
      const normalizedActivities = normalize(res.data.activities);
      const normalizedTopics = normalize(res.data.topics);
      const normalizedUsers = normalize(res.data.users);
      const normalizedVendorActivities = normalize(res.data.vendor_activities);

      const { topic, user, comment, vendorUser } = normalizedTopics;

      const mergedUsers = merge({}, user, normalizedUsers.user, vendorUser);

      return batch(() => {
        dispatch(receiveTopics(topic));
        dispatch(receiveComments(comment));
        dispatch(receiveUsers(mergedUsers));
        dispatch(
          receiveActivities({
            activity: normalizedActivities.activity,
            vendorActivity: normalizedVendorActivities.vendorActivity,
            allActivitiesLoaded: res.data.all_loaded,
          })
        );
      });
    }
  );
};

export const fetchVendorActivities = (params = {}) => dispatch =>
  GET('/api/v3/activities', params).then(res => {
    const topics = normalize(res.data.topics);

    const { topic, comment, user, vendorUser } = topics;

    const mergedUsers = merge({}, user, vendorUser);

    return batch(() => {
      dispatch(receiveActivities({ allActivitiesLoaded: res.data.all_loaded }));
      dispatch(receiveTopics(topic));
      dispatch(receiveComments(comment));
      dispatch(receiveUsers(mergedUsers));
    });
  });
