import React, { useState } from "react";
import { Visible } from "react-grid-system";

import Modal from "../../General/Modal";
import Tips from "./Tips";

const TipsModal = () => {
  const [open, setOpen] = useState(false);

  const handleToggleModal = () => {
    setOpen(!open);
  };

  return (
    <Visible xs sm md>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn btn-bold btn-label-brand btn-sm"
          style={{ width: 150 }}
          onClick={handleToggleModal}
        >
          Show Tips
        </button>
        <Modal open={open} handleClose={handleToggleModal}>
          <Tips />
        </Modal>
      </div>
    </Visible>
  );
};

export default TipsModal;
