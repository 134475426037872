import React, { useRef, useMemo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeGetFilteredUsers } from "../../../selectors/userSelectors";

import difference from "lodash/difference";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import maxBy from "lodash/maxBy";
import { buildFullName } from "../../../util/formatHelpers";
import $ from "jquery";
import {
  makeGetFilteredConversationParticipants,
  makeGetFilteredMessages,
} from "../../../selectors/conversationSelectors";
import { markConversationRead } from "../../../actions/conversationActions";
import { get } from "lodash-es";

export const useConversationUsers = (conversation, options = {}) => {
  const getConversationParticipants = useMemo(
    makeGetFilteredConversationParticipants,
    []
  );

  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const participants = useSelector(
    (state) =>
      getConversationParticipants(state, {
        conversationId: conversation.id,
        isActive: true,
      }),
      shallowEqual
  );

  const participantIds = map(
    participants,
    ({ attributes }) => attributes.userId
  );

  const getFilteredUsers = useMemo(makeGetFilteredUsers, []);

  const users = useSelector((state) =>
    getFilteredUsers(state, {
      ids: options.includeCurrent
        ? participantIds
        : difference(participantIds, [currentUserId]),
    })
  );

  const orderedUsers = useMemo(
    () =>
      orderBy(
        users,
        [
          ({ attributes }) =>
            buildFullName(attributes.firstName, attributes.lastName),
        ],
        ["asc"]
      ),
    [users]
  );

  return orderedUsers;
};

export const useScrollDetection = (scrollContainerRef) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let scrollingTimeoutId;

    const handleScroll = () => {
      clearTimeout(scrollingTimeoutId);
      setIsScrolling(true);

      scrollingTimeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 200);
    };

    const currentNode = scrollContainerRef.current;

    currentNode && currentNode.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(scrollingTimeoutId);
      currentNode && currentNode.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef.current, setIsScrolling]);

  return isScrolling;
};

export const useIntersectionObserver = (ref) => {
  const [entry, updateEntry] = useState({});

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry))
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (ref.current) currentObserver.observe(ref.current);

    return () => currentObserver && currentObserver.disconnect();
  }, [ref.current]);

  return entry;
};

export const useConversationReader = (conversationId) => {
  const dispatch = useDispatch();

  const unreadEventsCount = useSelector(
    (state) => state.conversations.events.totalUnreadCount
  );

  const getMessages = useMemo(() => makeGetFilteredMessages(), []);

  const messages = useSelector((state) =>
    getMessages(state, { conversationId })
  );

  const recentMessage = useMemo(
    () => maxBy(messages, (message) => message.attributes.createdAt, ["desc"]),
    [messages]
  );

  const recentMessageId = get(recentMessage, "id");

  useEffect(() => {
    conversationId &&
      recentMessageId &&
      dispatch(markConversationRead(conversationId));
  }, [recentMessageId, conversationId]);

  useEffect(() => {
    if (typeof unreadEventsCount === "number") {
      $("#unread-chat-events-counts-container").html(unreadEventsCount);
      $("#unread-chat-events-counts-container").css(
        "display",
        unreadEventsCount ? "" : "none"
      );
    }
  }, [unreadEventsCount]);
};

export const useConversationLabel = (conversation) => {
  const users = useConversationUsers(conversation);

  const participantString = useMemo(() => {
    const otherUsersList = map(users, ({ attributes }) =>
      buildFullName(attributes.firstName, attributes.lastName)
    ).join(", ");

    return users.length > 1 ? `${otherUsersList} and you` : otherUsersList;
  }, [users]);

  return conversation.attributes.name || participantString;
};
