import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { Visible } from "react-grid-system";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import Order from "../../General/Order";

import BoardMemberInterestButton from "./BoardMemberInterestButton";
import { useDirectoryContext } from "./DirectoryContext";
import { PROFILES_SORT_OPTIONS, defaultValue } from "./order/orderHelpers";
import Search from "./Search";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 60,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
  },
  secondPartHeader: { display: "flex" },
  "@media only screen and (max-width: 950px)": {
    header: {
      flexDirection: "column",
    },
    secondPartHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 12,
    },
  },
});

export default function Toolbar() {
  const { setOrder, state, toggleDrawer } = useDirectoryContext();

  const classes = useStyles();
  const { interests, boardMember } = useSelector(
    (state) => state.settings.attributes
  );
  const displayInterestButton = !interests.length && !boardMember;

  const [filterCount, setFilterCount] = useState(0);
  useEffect(() => {
    let count = 0;
    Object.keys(state).forEach((key) => {
      if (state[key]["value"]) count += 1;
    });
    setFilterCount(count);
  }, [state]);

  return (
    <div className={`kt-portlet kt-portlet__body ${classes.header}`}>
      <Search />
      <div className={classes.secondPartHeader}>
        <Visible xs sm md>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              fontFamily: "Poppins",
              lineHeight: "36px",
              color: "#F35B5E",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            Filters {filterCount > 0 && `[${filterCount}]`}
            <AddIcon fontSize={"small"} />
          </div>
        </Visible>
        <Order
          setOrder={setOrder}
          defaultValue={defaultValue}
          options={PROFILES_SORT_OPTIONS}
          isSearchable={false}
        />
        {displayInterestButton && <BoardMemberInterestButton />}
      </div>
    </div>
  );
}
