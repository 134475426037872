import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "0 20px",
  },
}))(MuiAccordionDetails);

export default function Content({ children }) {
  return (
    <AccordionDetails>{children}</AccordionDetails>
  );
}
