import React from "react";
import { Formik } from "formik";
import { object, string } from "yup";
import { ConnectedWysiwyg } from "../../../General/FormInputs/ConnectedInputs";
import { createComment } from "../../../../actions/commentActions";
import { mapKeysToSnakeCase } from "../../../../util/formatHelpers";

import classnames from "classnames";
import { useDispatch } from "react-redux";

const blankInitialValues = { body: "" };
const validationSchema = object().shape({
  body: string().required("Cannot be blank"),
});

const CommentForm = ({
  submitFunction,
  initialValues = blankInitialValues,
  actionText = "Add Comment",
  style = {},
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitFunction}
    >
      {(props) => (
        <div
          style={Object.assign(
            {},
            { display: "flex", flexDirection: "column" },
            style
          )}
        >
          <ConnectedWysiwyg
            id="body"
            uploadFolder="comments"
            customInitProps={{ height: 150 }}
            style={{ container: { marginBottom: "1rem" } }}
          />
          <button
            className={classnames("btn", "btn-secondary", "btn-sm")}
            onClick={props.handleSubmit}
            style={{ marginLeft: "auto" }}
          >
            {actionText}
          </button>
        </div>
      )}
    </Formik>
  );
};

export default CommentForm;
