import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import JoinWebinarButton from "./JoinWebinarButton";

export default function AcceptedEventInProgressActions({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const { invitationStatus, status, webinarLink } = event.attributes;

  if (invitationStatus !== "accepted") return null;
  if (status !== "in_progress") return null;
  if (!webinarLink) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "12px",
        alignItems: "space-between",
        width: "100%",
        maxWidth: 315,
        borderTop: "1px dashed #8c979a",
        margin: "0 auto",
      }}
    >
      <JoinWebinarButton {...{ id }} />
    </div>
  );
}
