import React from "react";
import { useSelector } from "react-redux";

import Topic from "./Topic";

const COMMENT = "Comment";
const TOPIC = "Topic";
export const VENDOR_ACTIVITY = "VendorActivity";

const ActivityItem = ({ activity }) => {
  const { subjectId, subjectType } = activity.attributes;
  let topic;

  if (subjectType === COMMENT) {
    const comment = useSelector((state) => state.comments.items[subjectId]);
    topic = useSelector(
      (state) => state.topics.items[comment.attributes.subjectId]
    );
  } else if (subjectType === TOPIC) {
    topic = useSelector((state) => state.topics.items[subjectId]);
  } else if (subjectType === VENDOR_ACTIVITY) {
    topic = {
      displayAvatar: true,
      attributes: {
        title: "Vendor Marketplace",
        path: "/products",
        ...activity.attributes,
      },
    };
  }

  if (!topic) return null;

  return (
    <div className="kt-portlet kt-portlet__body">
      <Topic {...topic} />
    </div>
  );
};

export default ActivityItem;
