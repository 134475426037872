import { createSelector } from "reselect";
import createDeepEqualSelector from "./createDeepEqualSelector";

import filter from "lodash/filter";
import every from "lodash/every";
import map from "lodash/map";
import groupBy from "lodash/groupBy";

// conversation event types
export const MESSAGE = "message";
export const ATTACHMENT = "attachment";
export const ADD_USER = "add_user";
export const REMOVE_USER = "remove_user";

export const getAllConversations = (state) => state.conversations.items;

export const getConversation = (state, { id }) => state.conversations.items[id];

export const getAllMessages = (state) => state.conversations.messages.items;

export const getAllAttachments = (state) =>
  state.conversations.attachments.items;

export const getAllConversationEvents = (state) =>
  state.conversations.events.items;

export const getMessage = (state, { id }) =>
  state.conversations.messages.items[id];

export const getAttachment = (state, { id }) =>
  state.conversations.attachments.items[id];

export const getAllConversationParticipants = (state) =>
  state.conversations.participants.items;

export const getConversationParticipant = (state, id) =>
  state.conversations.participants.items[id];

const buildMessageFilterFunctions = ({ conversationId }) => {
  const filterFunctions = [];

  if (conversationId) {
    filterFunctions.push(
      ({ attributes }) => attributes.conversationId === conversationId
    );
  }

  return filterFunctions;
};

export const makeGetFilteredMessages = () => {
  const getMessageFilters = (_state, filters) => filters;

  const getMemoizedMessageFilters = createDeepEqualSelector(
    [getMessageFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllMessages, getMemoizedMessageFilters],
    (messages, messageFilters) => {
      const filterFunctions = buildMessageFilterFunctions(messageFilters);

      return filter(messages, (message) =>
        every(filterFunctions, (filterFunction) => filterFunction(message))
      );
    }
  );
};

const buildConversationEventFilterFunctions = ({ conversationId }) => {
  const filterFunctions = [];

  if (conversationId) {
    filterFunctions.push(
      ({ attributes }) => attributes.conversationId === conversationId
    );
  }

  return filterFunctions;
};

export const makeGetFilteredConversationEvents = () => {
  const getEventFilters = (_state, filters) => filters;

  const getMemoizedEventFilters = createDeepEqualSelector(
    [getEventFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllConversationEvents, getMemoizedEventFilters],
    (events, eventFilters) => {
      const filterFunctions =
        buildConversationEventFilterFunctions(eventFilters);

      return filter(events, (event) =>
        every(filterFunctions, (filterFunction) => filterFunction(event))
      );
    }
  );
};

const buildConversationParticipantFilterFunctions = ({
  conversationId,
  isActive,
  includedUserIds,
  excludedUserIds,
}) => {
  const filterFunctions = [];

  if (conversationId) {
    filterFunctions.push(
      ({ attributes }) => attributes.conversationId === conversationId
    );
  }

  if (isActive) {
    filterFunctions.push(({ attributes }) => !attributes.removedAt);
  }

  if (includedUserIds) {
    const includedUsers = new Set(includedUserIds);
    filterFunctions.push(({ attributes }) =>
      includedUsers.has(attributes.userId)
    );
  }

  if (excludedUserIds) {
    const excludedUsers = new Set(excludedUserIds);

    filterFunctions.push(
      ({ attributes }) => !excludedUsers.has(attributes.userId)
    );
  }

  return filterFunctions;
};

export const makeGetFilteredConversationParticipants = () => {
  const getParticipantFilters = (_state, filters) => filters;

  const getMemoizedParticipantFilters = createDeepEqualSelector(
    [getParticipantFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllConversationParticipants, getMemoizedParticipantFilters],
    (participants, participantFilters) => {
      const filterFunctions =
        buildConversationParticipantFilterFunctions(participantFilters);

      return filter(participants, (participant) =>
        every(filterFunctions, (filterFunction) => filterFunction(participant))
      );
    }
  );
};

const buildConversationFilters = ({ minLastEventAt, includedIds }) => {
  const filterFunctions = [];

  if (minLastEventAt) {
    filterFunctions.push(
      ({ attributes }) => attributes.lastEventAt >= minLastEventAt
    );
  }

  if (includedIds) {
    const includedIdsSet = new Set(includedIds);
    filterFunctions.push(({ id }) => includedIdsSet.has(id));
  }

  return filterFunctions;
};

export const makeGetFilteredConversations = () => {
  const getConversationFilters = (_state, filters) => filters;

  const getMemoizedConversationFilters = createDeepEqualSelector(
    [getConversationFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllConversations, getMemoizedConversationFilters],
    (conversations, conversationFilters) => {
      const filterFunctions = buildConversationFilters(conversationFilters);

      return filter(conversations, (conversation) =>
        every(filterFunctions, (filterFunction) => filterFunction(conversation))
      );
    }
  );
};

export const makeGetConversationsWithRecentEvent = () => {
  return createSelector(
    [makeGetFilteredConversations(), getAllConversationEvents],
    (conversations, conversationEvents) => {
      // compose object with { [conversationId]: mostRecentEvent }
      const recentConversationEvents = {};
      let event;
      for (const eventId in conversationEvents) {
        event = conversationEvents[eventId];
        const { conversationId, createdAt } = event.attributes;

        if (
          !recentConversationEvents[conversationId] ||
          recentConversationEvents[conversationId].attributes.createdAt <
            createdAt
        ) {
          recentConversationEvents[conversationId] = event;
        }
      }

      return map(conversations, (conversation) => ({
        conversation,
        recentEvent: recentConversationEvents[conversation.id],
      }));
    }
  );
};

export const makeGetConversationsWithParticipants = () => {
  return createSelector(
    [makeGetFilteredConversations(), makeGetFilteredConversationParticipants()],
    (conversations, conversationParticipants) => {
      const groupedParticipants = groupBy(
        conversationParticipants,
        (participant) => participant.attributes.conversationId
      );

      return map(conversations, (conversation) => ({
        conversation,
        participants: groupedParticipants[conversation.id] || [],
      }));
    }
  );
};
