import { GET, POST, PATCH } from "../util/apiHelpers";
import normalize from "json-api-normalizer";
import queryString from "query-string";
import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";
import { receiveUsers } from "./userActions";

export const RECEIVE_TOPICS = "RECEIVE_TOPICS";

export const receiveTopics = (topics) => ({
  type: RECEIVE_TOPICS,
  payload: topics,
});

export const fetchTopics = (params) => (dispatch) => {
  const paramsStr = queryString.stringify(mapKeysToSnakeCase(params));

  return GET(`/api/v1/topics?${paramsStr}`).then((res) => {
    const normalized = normalize(res.data);
    
    dispatch(receiveTopics(normalized.topic));
    dispatch(receiveUsers(normalized.user));

    return {
      items: normalized.topic,
      ...mapKeysToCamelCase(res.data.meta || {}),
    };
  });
};

export const createTopic = (data) => (dispatch) => {
  return POST("/api/v1/topics", mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveTopics(normalized.topic));

    return normalized.topic[Object.keys(normalized.topic)[0]];
  });
};

export const updateTopic = (data) => (dispatch) =>
  PATCH(`/api/v1/topics/${data.id}`, mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveTopics(normalized.topic));
  });
