import React, { useEffect, useState } from "react";
import { useRef } from "react";

const ScrollableContent = ({ children }) => {
  const containerRef = useRef();

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let scrollingTimeoutId;

    const handleScroll = () => {
      clearTimeout(scrollingTimeoutId);
      setIsScrolling(true);

      scrollingTimeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 200);
    };

    const currentNode = containerRef.current;

    currentNode && currentNode.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(scrollingTimeoutId);
      currentNode && currentNode.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef.current, setIsScrolling]);

  return (
    <div ref={containerRef} style={{ overflowY: "scroll" }}>
      {children({ isScrolling })}
    </div>
  );
};

export default ScrollableContent;
