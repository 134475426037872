import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { fetchFeatureFlags } from "../../actions/featureFlagActions";


export default function FeatureFlags({ children }) {
  const dispatch = useDispatch();
  const { loaded } = useSelector(state => state.featureFlags);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchFeatureFlags());
    }
  }, []);

  return children;
}
