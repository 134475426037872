import React, { useState } from "react";

import withGlobalProviders from "../General/withGlobalProviders";
import Filters from "./index/Filters";
import PollsList from "./index/PollsList";

import merge from "lodash/merge";
import every from "lodash/every";

import { ALL, POSTED } from "./index/constants";

const Index = () => {
  const [filters, setFilters] = useState({
    groupId: ALL,
    status: ALL,
    sort: POSTED,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFilterSelection = (key, value) =>
    setFilters(merge({}, filters, { [key]: value }));

  const filtersSet = every(filters, (val) => Boolean(val));

  const resetFilters = () => {
    setFilters({ groupId: ALL, status: ALL, sort: POSTED });
    setSearch("");
  };

  return (
    <div className="row">
      <Filters
        currentFilters={filters}
        handleFilterSelection={handleFilterSelection}
        {...{ loading, search, setSearch }}
      />
      {filtersSet && (
        <PollsList
          {...{ filters, loading, setLoading, search, resetFilters }}
        />
      )}
    </div>
  );
};

export default withGlobalProviders(Index);
