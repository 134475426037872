import React from "react";
import { createUseStyles } from "react-jss";
import { Hidden, Visible } from "react-grid-system";
import ProductLogo from "./ProductLogo";
import Title from "./Title";
import PopoverLink from "../../General/PopoverLink";
import StarRatingDisplay from "./StarRatingDisplay";

const useStyles = createUseStyles({
  barRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "font-variant-numeric": "tabular-nums lining-nums",
    marginBottom: 8,
  },
  barContainer: {
    width: 150,
    height: 25,
    borderRadius: 4,
    margin: "0 10px 0 10px",
    border: "2px solid #F35A5D",
  },
  barValue: {
    backgroundColor: "#F35A5D",
    height: 21,
  },
  barStar: {
    textAlign: "right",
    width: 40,
  },
  barPercent: {
    textAlign: "left",
    width: 28,
  },
  arrow: {
    width: "50px",
    height: "50px",
    position: "absolute",
    marginLeft: "calc(100% - 60%)",
    marginTop: "-50px",
    overflow: "hidden",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "25px",
      height: "25px",
      background: "#fff",
      transform: "rotate(45deg)",
      top: "40px",
      left: "12.5px",
      boxShadow: "0px 0px 13px 0px rgba(82, 82, 82, 0.2)",
    },
  },
  card: {
    marginTop: 10,
    marginLeft: 0,
  },
  tooltipContainer: {
    padding: 0,
    height: 235,
    width: 275,
    fontFamily: "Poppins",
    fontSize: 14,
    color: "#48465B",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const reviewPercentage = function (typeCount, totalCount) {
  return (typeCount / parseFloat(totalCount)).toFixed(2) * 100 + "%";
};

const ReviewsPopoverContent = ({ product }) => {
  const classes = useStyles();
  const {
    pageUrl,
    overallRating,
    vendorProductReviewsCount,
    fiveStarReviewCount,
    fourStarReviewCount,
    threeStarReviewCount,
    twoStarReviewCount,
    oneStarReviewCount,
  } = product.attributes;

  const scoreBar = (count, stars) => {
    const percentString = reviewPercentage(count, vendorProductReviewsCount);

    return (
      <div className={classes.barRow}>
        <div className={classes.barStar}>{stars} star</div>
        <div className={classes.barContainer}>
          <div className={classes.barValue} style={{ width: percentString }}>
            &nbsp;
          </div>
        </div>
        <div className={classes.barPercent}>{percentString}</div>
      </div>
    );
  };

  return (
    <div>
      <div className={classes.arrow} />
      <div className={classes.tooltipContainer}>
        <div style={{ marginBottom: 8 }}>{overallRating} out of 5 Stars</div>
        {scoreBar(fiveStarReviewCount, 5)}
        {scoreBar(fourStarReviewCount, 4)}
        {scoreBar(threeStarReviewCount, 3)}
        {scoreBar(twoStarReviewCount, 2)}
        {scoreBar(oneStarReviewCount, 1)}
        <div className={classes.barRow}>
          <a
            style={{
              textDecoration: "underline",
              color: "#367BA6",
            }}
            href={`${pageUrl}/reviews`}
          >
            View All Reviews
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReviewsPopoverContent;
