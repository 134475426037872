import React from "react";
import { useSelector } from "react-redux";
import { filter, map, orderBy } from "lodash";
import Comment from "./Comment/Comment";
import { useMemo } from "react";

const CommentsList = ({ poll }) => {
  const pollComments = useSelector((state) =>
    filter(
      state.comments.items,
      ({ attributes: { subjectId, subjectType, ancestryDepth } }) =>
        !ancestryDepth && subjectId === poll.id && subjectType === "Poll"
    )
  );
  const orderedComments = useMemo(
    () =>
      orderBy(pollComments, ({ attributes }) => attributes.createdAt, "desc"),
    [pollComments]
  );

  return (
    <React.Fragment>
      <h6
        style={{
          color: "#F35B5E",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
          margin: "24px 0 36px 0",
          textAlign: "center",
        }}
      >
        {orderedComments.length ? "Comments" : "No comments, yet."}
      </h6>
      {map(orderedComments, ({ id }) => (
        <Comment id={id} key={id} />
      ))}
    </React.Fragment>
  );
};

export default CommentsList;
