import {
  SET_MODAL_VISIBLE,
  OPEN_MODAL,
  CLEAR_MODAL,
} from "../../actions/uiActions";

const _defaultState = {
  visible: false,
  content: {},
};

const modalReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_MODAL_VISIBLE:
      return Object.assign({}, state, { visible: action.payload });
    case OPEN_MODAL:
      return Object.assign({}, state, {
        content: action.payload,
        visible: true,
      });
    case CLEAR_MODAL:
      return _defaultState;
    default:
      return state;
  }
};

export default modalReducer;
