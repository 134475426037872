import React from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from 'react-router-dom';

import { useDirectoryContext } from "../DirectoryContext";

const useStyles = createUseStyles({
  container: {
    padding: 20,
    textAlign: "center",
  },
  content: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#292E33",
  },
  action: {
    fontSize: "14px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#F35B5E",
    cursor: "pointer",
  },
});

export default function NoResults() {
  const location = useLocation();
  const classes = useStyles();

  const { setSearch, resetFilters } = useDirectoryContext();
  
  const showAll = () => {
    setSearch("");
    resetFilters();
  };

  return (
    <div className={classes.container}>
      {location.search.length > 0 ? (<>
        <div className={classes.content}>
          We cannot find any members with that information.
        </div>
        <div>
          <span className={classes.action} onClick={showAll}>
            Show all Members
          </span>
        </div>
      </>) : (<>
        <div className={classes.content}>No Members</div>
      </>)}
    </div>
  );
}
