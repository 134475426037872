import { GET, PATCH } from "../util/apiHelpers";
import normalize from "json-api-normalizer";
import { batch } from "react-redux";
import { receiveGroups } from "./groupActions";

export const RECEIVE_SUBSCRIPTIONS = "RECEIVE_SUBSCRIPTIONS";

const receiveSubscriptions = (subscriptions) => ({
  type: RECEIVE_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const fetchSubscriptions = () => (dispatch) => {
  return GET("/api/v1/subscriptions").then((res) => {
    const normalizedSubscriptions = normalize(res.data.subscriptions);
    const normalizedGroups = normalize(res.data.groups);

    batch(() => {
      dispatch(receiveSubscriptions(normalizedSubscriptions.subscription));
      dispatch(receiveGroups(normalizedGroups.group));
    });
  });
};

export const updateEmailSettings = (data) => (dispatch) => {
  return PATCH("/api/v1/subscriptions/update_email_subscriptions", data).then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receiveSubscriptions(normalized.subscription));
    }
  );
};

export const updateTalkSettings = (data) => (dispatch) => {
  return PATCH("/api/v1/subscriptions/update_talk_subscriptions", data).then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receiveSubscriptions(normalized.subscription));
    }
  );
};
