import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import InfiniteScroll from "react-infinite-scroller";
import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment";
import Masonry from "react-masonry-css";
import classnames from "classnames";
import map from "lodash/map";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import times from "lodash/times";

import { fetchPolls } from "../../../actions/pollActions";
import { POSTED } from "./constants";
import Poll from "./Poll/Poll";
import FilteredEmptyState from "../../General/FilteredEmptyState";
import SkeletonPoll from "./Poll/SkeletonPoll";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > div": {
      width: "100%",
    },
  },
  masonryGrid: {
    display: "-webkit-box" /* Not needed if autoprefixing */,
    display: "-ms-flexbox" /* Not needed if autoprefixing */,
    display: "flex",
    marginLeft: "-20px" /* gutter size offset */,
    width: "auto",
  },
  masonryGridColumn: {
    paddingLeft: 20 /* gutter size */,
    backgroundClip: "padding-box",
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const PER_PAGE = 10;

const RenderWithGrid = ({ children }) => {
  const classes = useStyles();

  return (
    <Masonry
      breakpointCols={{
        default: 2,
        1100: 1,
      }}
      className={classes.masonryGrid}
      columnClassName={classes.masonryGridColumn}
    >
      {children}
    </Masonry>
  );
};

const PollsList = ({ filters, setLoading, search, resetFilters, loading }) => {
  const [items, setItems] = useState({});
  const dispatch = useDispatch();
  // used to force unmount and remount InfiniteScroll component so page is reset to 0 in its internal state
  const [scrollerKey, setScrollerKey] = useState(new Date().getTime());
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const classes = useStyles();

  const fetchItems = (page) => {
    setLoading(true);
    return dispatch(
      fetchPolls({ page, perPage: PER_PAGE, ...filters, search })
    );
  };

  const appendItems = (page) => {
    if (page > 0) {
      setIsInitialLoad(false);
    }
    fetchItems(page)
      .then(({ items: newItems, currentPage, totalPages }) => {
        setItems(merge({}, items, newItems));
        setHasMore(currentPage < totalPages);
      })
      .then(() => setLoading(false));
  };

  useDeepCompareEffect(() => {
    fetchItems(0)
      .then(({ items: newItems, currentPage, totalPages }) => {
        setScrollerKey(new Date().getTime());
        setItems(newItems);
        setHasMore(currentPage < totalPages);
      })
      .then(() => setLoading(false));
  }, [filters, search]);

  const orderedItems = useMemo(() => {
    const currMoment = moment();
    const closingIteratees = [
      ({ attributes }) =>
        moment(attributes.closesAt).isAfter(currMoment)
          ? moment(attributes.closesAt).diff(currMoment)
          : Infinity,
      ({ attributes }) =>
        moment(attributes.closesAt).isBefore(currMoment)
          ? currMoment.diff(moment(attributes.closesAt))
          : Infinity,
    ];
    const postedIteratees = [
      ({ attributes }) => currMoment.diff(moment(attributes.createdAt)),
      ({ id }) => id,
    ];

    const iteratees =
      filters.sort === POSTED
        ? postedIteratees
        : closingIteratees.concat(postedIteratees);

    const orders =
      filters.sort === POSTED ? ["asc", "desc"] : ["asc", "asc", "asc", "desc"];

    return orderBy(items, iteratees, orders);
  }, [items]);

  return (
    <div className={classnames("col-lg-12", classes.container)}>
      <InfiniteScroll
        pageStart={0}
        loadMore={appendItems}
        useWindow={true}
        hasMore={hasMore}
        key={scrollerKey}
        initialLoad={false}
        loader={
          isInitialLoad ? (
            <RenderWithGrid>
              {times(2, () => (
                <SkeletonPoll />
              ))}
            </RenderWithGrid>
          ) : (
            <div className={classes.loaderContainer} key={0}>
              <CircularProgress color="primary" />
            </div>
          )
        }
      >
        {orderedItems.length > 0 ? (
          <RenderWithGrid>
            {map(orderedItems, ({ id }) => (
              <Poll id={id} key={id} />
            ))}
          </RenderWithGrid>
        ) : (
          <FilteredEmptyState
            {...{ resetFilters, loading }}
            message="We cannot find anything with that information."
          />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default PollsList;
