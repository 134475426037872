import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useFormikContext } from "formik";
import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";

import { ConnectedDropdown } from "../../../General/FormInputs/ConnectedInputs";

import { fetchBoardMembersAndAdmins } from "../../../../actions/userActions";

const FromDropdown = () => {
  const { values } = useFormikContext();
  const { chapterId } = values;
  const dispatch = useDispatch();
  const selectBoardMembersAndAdmins = createSelector(
    (state) => state.users.items,
    (state) => state.groups.items,
    (users, chapters) => {
      const selectedChapter = chapters[chapterId];

      return filter(users, (user) => {
        if (selectedChapter) {
          return (
            user.attributes.admin ||
            (user.attributes.boardMember &&
              selectedChapter &&
              includes(
                user.attributes.chapters,
                selectedChapter.attributes.name
              ))
          );
        } else {
          return user.attributes.admin || user.attributes.boardMember;
        }
      });
    }
  );
  const boardMembersAndAdmins = useSelector(selectBoardMembersAndAdmins);

  const options = orderBy(
    map(boardMembersAndAdmins, (user) => ({
      value: user.id,
      label: `${user.attributes.firstName} ${user.attributes.lastName}`,
    })),
    "label",
    "asc"
  );

  useEffect(() => {
    if (chapterId) {
      dispatch(fetchBoardMembersAndAdmins(chapterId));
    }
  }, [chapterId]);

  return <ConnectedDropdown id="senderId" options={options} label="From" />;
};

export default memo(FromDropdown);
