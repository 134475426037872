import React, { useState } from "react";
import TopicsList from "./index/TopicsList";
import withGlobalProviders from "../General/withGlobalProviders";
import Filters from "./index/Filters";
import queryString from "query-string";

const Index = () => {
  const queryParams = queryString.parse(location.search);

  const [selectedGroupId, setSelectedGroupId] = useState(
    queryParams.group || null
  );
  const [groupName, setGroupName] = useState(null);
  const [query, setQuery] = useState(queryParams.search || "");
  const [loading, setLoading] = useState(true);
  const filterProps = {
    setSelectedGroupId,
    selectedGroupId,
    setQuery,
    query,
    groupName,
    setGroupName,
  };

  return (
    <div className="row">
      <Filters {...filterProps} loading={loading} />
      {selectedGroupId && (
        <TopicsList
          {...filterProps}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </div>
  );
};

export default withGlobalProviders(Index);
