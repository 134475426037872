
import map from "lodash/map";
import slice from "lodash/slice";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { makeGetCommentsByTopicId } from "../../../selectors/commentSelectors";
import CommentField from "../../General/CommentField";

import { VENDOR_ACTIVITY } from "./ActivityItem";
import Comment from "./Comment";
import Description from "./Description";
import Header from "./Header";
import VendorActivity from "./VendorActivity";

const useStyles = createUseStyles({
  container: {
    padding: "24px 24px 12px 24px",
  },
  commentDivider: {
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: "Poppins",
    fontSize: 13,
    color: "#212121",
    marginTop: 12,
  },
  description: {
    borderBottom: "1px solid #ECEDF2",
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "13px",
    letterSpacing: "0.07px",
    lineHeight: "24px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
});

const Topic = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(3);

  const {
    id,
    subject,
    forumName,
    title,
    path,
    userId,
    subjectType,
  } = props.attributes;

  const getCommentsByTopicId = useSelector((state) =>
    makeGetCommentsByTopicId(state)
  );
  const comments = getCommentsByTopicId(id);
  const settings = useSelector((state) => state.settings);

  if (subjectType === VENDOR_ACTIVITY) {
    return null;
  }

  return (
    <div>
      {settings.attributes.vendorUser ? (
        <Header title={title || subject} sideText={forumName} />
      ): (
        <a href={path}>
          <Header title={title || subject} sideText={forumName} />
        </a>
      )}
      <div className={classes.container}>
        <div className={classes.description}>
          {subjectType === VENDOR_ACTIVITY ? (
            <VendorActivity {...props} />
          ) : (
            <Description
              {...props}
              id={id}
              displayAvatar={true}
              displayActions={false}
              category="topic"
              topicId={id}
              editable={settings.id === userId || settings.attributes.admin}
            />
          )}
        </div>
        <CommentField topicId={id} />
        {comments.length > 0 && (
          <React.Fragment>
            <div className={classes.commentDivider}>Most Recent Comments</div>
            {map(slice(comments, 0, expanded), (comment) => (
              <Comment
                {...comment}
                key={comment.id}
                subjectType={subjectType}
              />
            ))}
            {expanded < comments.length && (
              <div
                className={`event-item ${classes.expandButton}`}
                onClick={() => setExpanded(expanded + 3)}
              >
                See more of this conversation
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Topic;
