import React from "react";
import { makeStyles } from "@material-ui/core";

import Skeleton from "react-loading-skeleton";
import MessagesIndexSkeleton from "./MessagesIndexSkeleton";

import { useStyles as useConversationStyles } from "../Conversation";
import { useStyles as useConversationHeaderStyles } from "../../partials/ConversationHeader";

import classnames from "classnames";
import FormContainer from "../../form/FormContainer";
import MessageBodyInput from "../../form/MessageBodyInput";
import FormFooter from "../../form/FormFooter";

const useStyles = makeStyles((theme) => ({
  skeletonWrapper: {
    "& > span": {
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
  },
}));

const ConversationSkeleton = () => {
  const conversationClasses = useConversationStyles();
  const conversationHeaderClasses = useConversationHeaderStyles();
  const classes = useStyles();

  return (
    <div className={conversationClasses.container}>
      <div
        className={classnames(
          conversationHeaderClasses.header,
          classes.skeletonWrapper
        )}
      >
        <Skeleton width={"100%"} height={30} />
      </div>
      <div className={conversationClasses.scrollableContent}>
        <MessagesIndexSkeleton />
      </div>
      <FormContainer>
        <MessageBodyInput />
        <FormFooter />
      </FormContainer>
    </div>
  );
};

export default ConversationSkeleton;
