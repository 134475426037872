import queryString from "query-string";
import normalize from "json-api-normalizer";
import { createUseStyles } from "react-jss";

import { GET } from "../../../util/apiHelpers";

export const searchUrls = {
  productCategory: (name) =>
    "/api/v1/product_categories/search?" + queryString.stringify({ name }),
  vendorProduct: (name) =>
    "/api/v1/vendor_products/search?" + queryString.stringify({ name }),
};

export const search = (type, name, done) =>
  GET(searchUrls[type](name)).then((res) => {
    const normalized = normalize(res.data);
    return done(Object.values(normalized[type] || {}));
  });

export const styles = createUseStyles({
  searchContainer: {
    position: "relative",
    display: "inline-block",
    margin: 0,
  },
  inputIcon: {
    position: "absolute",
    top: 10,
    left: 8,
  },
  inputClose: {
    position: "absolute",
    top: 10,
    right: 8,
    cursor: "pointer",
  },
  container: ({ show }) => ({
    display: show ? "block" : "none",
    position: "absolute",
    width: "100%",
    "& div.kt-portlet__body": {
      padding: "6px 0",
    },
  }),
  sectionTitle: {
    borderBottom: "1px solid #ECEDF2",
    padding: "6px 12px 6px 12px",
    color: "#48465B",
    fontSize: 16,
    fontWeight: 500,
  },
  unorderedList: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    "& li": {
      padding: "6px 24px",
    },
    "& li+li": {
      borderTop: "1px solid #ECEDF2",
    },
    "& li.no_results": {
      textAlign: "center",
      color: "#A3A5AE",
      fontSize: 14,
      borderBottom: "1px solid #ECEDF2",
    },
  },
  listItemLogo: {
    objectFit: "contain",
    height: 20,
    width: 20,
    marginRight: 10,
    marginTop: -4,
  },
});
