import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AddClassificationsFields from "./AddClassificationsFields";
import { makeStyles } from "@material-ui/core/styles";
import withGlobalProviders from "../../General/withGlobalProviders";

import { updateSettings } from "../../../actions/settingActions";
import { buildInitialValues, validationSchema } from "./fieldHelpers";
import classnames from "classnames";
import { openSnackbar } from "../../../actions/uiActions";

const useStyles = makeStyles(() => ({
  dialogTitleRoot: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  dialogActionsRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
}));

const AddClassificationsModal = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const [open, setOpen] = React.useState(true);

  const handleClose = () => setOpen(false);
  const initialValues = useMemo(() => buildInitialValues(settings.attributes));
  const classes = useStyles();

  const handleSubmit = useMemo(
    () => (values, context) =>
      dispatch(updateSettings(values))
        .then(() => {
          setOpen(false);
          context.setSubmitting(false);
        })
        .catch(() => {
          context.setSubmitting(false);
          dispatch(openSnackbar("An error occured during submission"));
        }),
    [dispatch]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick={true}
        >
          <DialogTitle
            id="form-dialog-title"
            classes={{ root: classes.dialogTitleRoot }}
            disableTypography={true}
          >
            Complete Your Profile
          </DialogTitle>
          <DialogContent>
            <AddClassificationsFields />
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActionsRoot }}>
            <button
              className={classnames("btn", "btn-secondary", {
                disabled: props.isSubmitting,
              })}
              onClick={props.handleSubmit}
              type="button"
            >
              Save And Continue To TALK
            </button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default withGlobalProviders(AddClassificationsModal);
