import React from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function CheckInButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { status, invitesPath } = event.attributes;

  let text = "";

  if (status === "in_progress") text = "Check In";
  else if (status === "upcoming" || status === "past") text = "RSVP List";
  else return null;

  return (
    <a
      href={invitesPath}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textAlign: "center" }}
    >
      <button style={{ width: "96px", height: "100%" }} className="btn btn-sm btn-secondary">
        {text}
      </button>
    </a>
  );
}
