import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";

import { fetchProductCategories } from "../../../actions/productCategoryActions";
import { fetchVendorProducts } from "../../../actions/vendorProductActions";
import Item from "../../Vendors/general/popularCategories/Item";
import VendorItem from "../../Vendors/general/popularVendors/Item";

import UserInfo from "./UserInfo";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
    minHeight: 50,
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
  },
  font: {
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
  },
  sectionTitle: {
    color: "#48465B",
    fontWeight: 500,
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
  },
  unorderedList: {
    padding: "8px 0",
    margin: 0,
    listStyleType: "none",
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
    "& li+li": {
      margin: "4px 0",
      fontSize: "1.28571rem",
      lineHeight: "2.28571rem",
    },
  },
});

const VendorList = ({ title, vendorIds }) => {
  const classes = useStyles();
  const vendorProducts = useSelector((state) => state.vendorProducts.items);
  const displayedVendors = filter(vendorProducts, (vendor) =>
    includes(vendorIds, vendor.id)
  );

  return displayedVendors.length > 0 ? (
    <React.Fragment>
      <span className={classes.sectionTitle}>{title}</span>
      <ul className={classes.unorderedList}>
        {map(displayedVendors, (vendorProduct) => (
          <VendorItem
            key={vendorProduct.id}
            vendorProduct={vendorProduct}
            utmSource="newsfeed"
            utmContent="vendor_products"
          />
        ))}
      </ul>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const RecentlySection = ({
  vendorActivity: { recentlyAdded, recentlyReviewed },
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (recentlyAdded.length + recentlyReviewed.length > 0) {
      dispatch(
        fetchVendorProducts(
          null,
          recentlyAdded.concat(recentlyReviewed).join(",")
        )
      );
    }
  }, [dispatch, recentlyAdded, recentlyReviewed]);

  return (
    <React.Fragment>
      {recentlyAdded.length > 0 && (
        <VendorList title="Recently Added Vendors" vendorIds={recentlyAdded} />
      )}
      {recentlyReviewed.length > 0 && (
        <VendorList
          title="Recently Reviewed Vendors"
          vendorIds={recentlyReviewed}
        />
      )}
    </React.Fragment>
  );
};

const VendorActivity = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { popular } = useSelector((state) => state.productCategories);
  const vendorActivity = useSelector(
    (state) => state.activities.vendorActivityItems[props.attributes.subjectId]
  );

  useEffect(() => {
    dispatch(fetchProductCategories());
  }, [dispatch, props.attributes.subjectId]);

  return (
    <div className={classes.container}>
      <UserInfo {...props} />
      <div
        style={{
          marginTop: 50,
          fontSize: "1.28571rem",
          lineHeight: "2.28571rem",
        }}
      >
        <p style={{ marginTop: 12 }}>
          There was a lot of activity in the Vendor Marketplace last week! All
          additional reviews and{" "}
          <a href="/vendors/suggest" style={{ textDecoration: "underline" }}>
            suggested vendors
          </a>{" "}
          are greatly appreciated.
        </p>
        {vendorActivity && vendorActivity.attributes ? (
          <RecentlySection vendorActivity={vendorActivity.attributes} />
        ) : (
          <React.Fragment />
        )}
        <span className={classes.sectionTitle}>Popular Categories</span>
        <ul className={classes.unorderedList}>
          {map(popular, (categoryId) => (
            <Item
              key={categoryId}
              categoryId={categoryId}
              fontSize={14}
              utmSource="newsfeed"
              utmContent="vendor_categories"
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VendorActivity;
