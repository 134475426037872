import { RECEIVE_CONVERSATION_PARTICIPANTS } from "../../actions/conversationActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
};

const participantsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CONVERSATION_PARTICIPANTS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default participantsReducer;
