import { object, string, number } from "../../../../util/yup";
import map from "lodash/map";

function arrayToOptions(array) {
  return map(array, (item) => ({ value: item, label: item }));
}

const lastUseOptionsArray = [
  "Current User",
  "less than 1 year ago",
  "1-2 years ago",
  "more than 2 years ago",
];

const useDurationOptionsArray = [
  "Less than 6 months",
  "6-12 months",
  "1-2 years",
  "more than 2 years",
];

const reviewerRolesOptionsArray = [
  "User",
  "Administrator",
  "Set up",
  "Selected",
];

export const lastUseOptions = arrayToOptions(lastUseOptionsArray);

export const useDurationOptions = arrayToOptions(useDurationOptionsArray);

export const reviewerRolesOptions = arrayToOptions(reviewerRolesOptionsArray);

export const initialValues = {
  lastUse: null,
  useDuration: null,
  reviewerRoles: [],
  vendorProductReviewsProducts: [],
  title: null,
  commentText: null,
  positiveText: null,
  negativeText: null,
  overallRating: 0,
  easeOfUseRating: 0,
  customerSupportRating: 0,
  productValueRating: 0,
  userVisible: true,
  vendorVisible: true,
};

// export const initialValues = {
//   lastUse: "Current User",
//   useDuration: "Less than 6 months",
//   reviewerRoles: ["User"],
//   vendorProductReviewsProducts: [],
//   title: "This is a review title",
//   commentText: null,
//   positiveText: "Here are the positives from my experience",
//   negativeText: "Unfortunately, there are some negatives, and here they are",
//   overallRating: 4,
//   easeOfUseRating: 5,
//   customerSupportRating: 2,
//   productValueRating: 5,
//   userVisible: true,
//   vendorVisible: true,
// };

export const validationSchema = object().shape({
  lastUse: string().required("This field is required.").nullable(),
  useDuration: string().required("This field is required.").nullable(),
  reviewerRoles: string().required("This field is required."),
  title: string()
    .required("This field is required.")
    .minWords(3)
    .max(75, "Cannot be more than 75 characters")
    .nullable(),
  commentText: string()
    .max(1000, "Cannot be more than 1000 characters")
    .nullable(),
  positiveText: string()
    .required("This field is required.")
    .max(1000, "Cannot be more than 1000 characters")
    .nullable(),
  negativeText: string()
    .required("This field is required.")
    .max(1000, "Cannot be more than 1000 characters")
    .nullable(),
  overallRating: number().min(1, "This field is required.").max(5),
  easeOfUseRating: number().min(0).max(5),
  customerSupportRating: number().min(0).max(5),
  productValueRating: number().min(0).max(5),
});

export const sectionFields = [
  ["lastUse", "useDuration", "reviewerRoles"],
  [
    "title",
    "commentText",
    "positiveText",
    "negativeText",
    "overallRating",
    "easeOfUseRating",
    "customerSupportRating",
    "productValueRating",
  ],
  [],
];

export const createHandleSubmit = ({
  handleSubmit,
  handleSuccess = () => {},
  handleErrors = () => {},
}) => {
  return (values, context) => {
    return handleSubmit(values).then(handleSuccess).catch(handleErrors);
  };
};
