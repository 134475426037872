import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import ChatContents from "../../../Shared/ProfileHover/ProfileHoverContents/ChatContents";

import { createConversation } from "../../../../actions/conversationActions";

const useStyles = makeStyles(() => ({
  chatContainer: {
    minWidth: 400,
    minHeight: 140,
    backgroundColor: "#FFF",
    border: "1px solid #357BA6",
    borderRadius: 4,
    padding: 12,
  },
  name: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
}));

const ChatboxCTA = ({ userId, firstName }) => {
  const [text, setText] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const createMessage = () =>
    dispatch(
      createConversation({ body: text, participant_ids: [userId] })
    ).then((res) => {
      const createdConversationId = Object.keys(res)[0];
      setConversationId(createdConversationId);
    });

  return (
    <div className={classes.chatContainer}>
      <div className={classes.name}>{`Chat with ${firstName}`}</div>
      <ChatContents
        {...{
          expanded: true,
          standAlone: true,
          text,
          setText,
          createMessage,
          conversationId,
        }}
      />
    </div>
  );
};

export default ChatboxCTA;
