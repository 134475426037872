import normalize from "json-api-normalizer";
import queryString from "query-string";

import { GET } from "../util/apiHelpers";
import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";

import { receiveUsers } from "./userActions";

export const RECEIVE_PROFILES = "RECEIVE_PROFILES";

const receiveProfiles = (profiles) => ({
  type: RECEIVE_PROFILES,
  payload: profiles,
});

export const fetchEventProfiles = (eventId) => (params) => {
  return fetchProfiles({ eventId, ...params })
}

export const fetchProfiles = (params) => (dispatch) => {
  const paramsStr = queryString.stringify(mapKeysToSnakeCase(params));

  return GET("/api/v1/profiles?" + paramsStr).then((res) => {
    const normalized = normalize(res.data);
    // todo(me): avoid this jank, move to users reducer, delete profiles reducer
    if (params.asUsers) {
      dispatch(receiveUsers(normalized.user));

      return {
        items: normalized.user,
        ...mapKeysToCamelCase(res.data.meta || {}),
      };
    } else {
      dispatch(receiveProfiles(normalized.profile));

      return {
        items: normalized.profile,
        ...mapKeysToCamelCase(res.data.meta || {}),
      };
    }
  });
};
