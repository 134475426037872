import React from "react";

import { createUseStyles } from "react-jss";
import { shallowEqual, useSelector } from "react-redux";

import Header from "../shared/Header";
import { ViewAllButton, NextButton, SkipButton } from "../shared/HeaderActions";

import PollResponseForm from "./PollResponseForm";
import PollResultsContent from "./PollResultsContent";

const useStyles = createUseStyles({
  container: {},
});

const PollContent = ({ id, handleNext }) => {
  const poll = useSelector((state) => state.polls.items[id], shallowEqual);

  const { takenByUser } = poll.attributes;

  const [NextCTA, TypedContent] = takenByUser
    ? [NextButton, PollResultsContent]
    : [SkipButton, PollResponseForm];

  return (
    <React.Fragment>
      <Header
        renderActions={() => (
          <React.Fragment>
            <NextCTA handleNext={handleNext} />
            <ViewAllButton />
          </React.Fragment>
        )}
      />
      <div style={{ padding: 12 }}>
        <TypedContent poll={poll} />
      </div>
    </React.Fragment>
  );
};

export default PollContent;
