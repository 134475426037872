import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-grid-system";

import {
  ConnectedTextarea,
  ConnectedTextField,
  ConnectedFiveStarRadio,
  ConnectedRadioInput,
} from "../../../General/FormInputs/ConnectedInputs";
import Radio from "../../../General/FormInputs/Radio";

const TitleField = () => (
  <ConnectedTextField id="title" label="Review Title" maxCharacters={75} />
);

const CommentTextField = () => (
  <ConnectedTextarea
    id="commentText"
    label="Overall Comments about this vendor (optional)"
    maxCharacters={1000}
  />
);

const PositiveTextField = () => (
  <ConnectedTextarea
    id="positiveText"
    label="What are some positives about this vendor?"
    maxCharacters={1000}
  />
);

const NegativeTextField = () => (
  <ConnectedTextarea
    id="negativeText"
    label="What are some negatives about this vendor?"
    maxCharacters={1000}
  />
);

const OverallRatingField = () => (
  <ConnectedFiveStarRadio id="overallRating" label="Overall" />
);

const EaseOfUseRatingField = () => (
  <ConnectedFiveStarRadio id="easeOfUseRating" label="Ease of use (optional)" />
);

const CustomerSupportRatingField = () => (
  <ConnectedFiveStarRadio
    id="customerSupportRating"
    label="Customer support (optional)"
  />
);

const ProductValueRatingField = () => (
  <ConnectedFiveStarRadio
    id="productValueRating"
    label="Value for money (optional)"
  />
);

const UserVisibleSwitch = () => {
  const id = "userVisible";
  const { setFieldValue } = useFormikContext();
  const options = [
    { value: true, label: "Your name, title, and company will be visible" },
    { value: false, label: "Your name title and company will not be visible" },
  ];

  const handleChange = (e) => {
    setFieldValue(id, e.target.value === "true");
  }

  return (
    <ConnectedRadioInput
      id={id}
      options={options}
      onChange={handleChange}
    />
  );
};

const VendorVisibleSwitch = () => {
  const id = "vendorVisible";
  const { setFieldValue } = useFormikContext();
  const options = [
    { value: true, label: "Your review can be seen by anyone (including the vendor)" },
    { value: false, label: "Your review can only be seen by other TALK Members" },
  ];

  const handleChange = (e) => {
    setFieldValue(id, e.target.value === "true");
  }

  return (
    <ConnectedRadioInput
      id={id}
      options={options}
      onChange={handleChange}
    />
  );
};

const BackgroundInfo = ({ otherProductsInCategory }) => (
  <div style={{ padding: 24 }}>
    <TitleField />
    <CommentTextField />
    <PositiveTextField />
    <NegativeTextField />
    <div className="form-group" style={{ margin: 0 }}>
      <label
        style={{
          fontSize: "1.2857142857142858rem",
          lineHeight: "2.2857142857142856rem",
          fontWeight: 400,
        }}
      >
        Ratings
      </label>
    </div>
    <Row style={{ paddingLeft: 24 }}>
      <Col xs={12} sm={6}>
        <OverallRatingField />
      </Col>
      <Col xs={12} sm={6}>
        <EaseOfUseRatingField />
      </Col>
      <Col xs={12} sm={6}>
        <CustomerSupportRatingField />
      </Col>
      <Col xs={12} sm={6}>
        <ProductValueRatingField />
      </Col>
    </Row>
    <div className="form-group" style={{ margin: 0 }}>
      <label
        style={{
          fontSize: "1.2857142857142858rem",
          lineHeight: "2.2857142857142856rem",
          fontWeight: 400,
        }}
      >
        Review Visibility
      </label>
    </div>
    <Row style={{ paddingLeft: 24 }}>
      <Col xs={12} sm={6} style={{ borderRight: "1px solid #e2e5ec", paddingRight: 0 }}>
        <UserVisibleSwitch />
      </Col>
      <Col xs={12} sm={6} style={{ padding: "0 0 0 30px" }}>
        <VendorVisibleSwitch />
      </Col>
    </Row>
  </div>
);

export default BackgroundInfo;
