
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";

import { createTopic } from "../../actions/topicActions";
import FormHeader from "../General/FormPage/FormHeader";
import FormPageLayout from "../General/FormPage/FormPageLayout";
import withGlobalProviders from "../General/withGlobalProviders";

import { initialValues, validationSchema } from "./create/fieldHelpers";
import Footer from "./create/Footer";
import FormFields from "./create/FormFields";

const CreateTopic = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values, context) =>
    dispatch(createTopic(values))
      .then((createdTopic) => {
        window.location.href = createdTopic.attributes.urlPath;
      })
      .catch(() => context.setSubmitting(false));

  return (
    <FormPageLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <React.Fragment>
            <FormHeader title="New Topic" />
            <FormFields />
            <Footer />
          </React.Fragment>
        )}
      </Formik>
    </FormPageLayout>
  );
};

export default withGlobalProviders(CreateTopic);
