import debounce from "lodash/debounce";
import React, { useState, useEffect, useCallback } from "react";

import { useDirectoryContext } from "./DirectoryContext";

import { styles } from "./search/utils";

const Search = ({ style }) => {
  const { search, loading, setSearch } = useDirectoryContext();

  const classes = styles({});
  const [inputValue, setInputValue] = useState(search);

  const performSearch = useCallback(
    debounce((search) => {
      setSearch(search);
    }, 500),
    []
  );

  const updateValue = (value) => {
    setInputValue(value);
    performSearch(value);
  }

  useEffect(() => {
    if (search !== inputValue) {
      setInputValue(search);
    }
  }, [search])

  return (
    <div
      className={`form-group ${classes.searchContainer}`}
      style={style || { width: "100%" }}
    >
      <i className={`fas fa-search ${classes.inputIcon}`} />
      <input
        value={inputValue}
        placeholder="Search by Name, Company, or Job Title"
        onChange={(e) => updateValue(e.target.value)}
        className="form-control"
        style={{ paddingLeft: 36 }}
      />
      {!loading && search && search.length > 0 && (
        <i
          onClick={() => updateValue("")}
          className={`fas fa-times ${classes.inputClose}`}
        />
      )}
      {loading && search && search.length > 0 && (
        <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
      )}
    </div>
  );
};

export default Search;
