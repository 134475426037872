import React, { useState } from "react";

import { createUseStyles } from "react-jss";

import map from "lodash/map";
import { connect } from "formik";
import { uniq, includes, difference, orderBy } from "lodash";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = createUseStyles({
  choicesList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  choiceItem: {
    border: "1px solid #357BA6",
    color: "#357BA6",
    borderRadius: 19,
    flexWrap: "wrap",
    maxWidth: "100%",
    minWidth: 320,
    width: "80%",
    minHeight: 40,
    fontSize: 14,
    marginBottom: 12,
    padding: "4px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  submittableChoiceItem: {
    justifyContent: "center",
    textAlign: "center",
    wordBreak: "break-word",
    "&:hover": {
      color: "white",
      backgroundColor: "#357BA6",
    },
  },
  selectableChoiceItem: {
    justifyContent: "flex-start",
  },
  selectableChoiceItemIconContainer: {
    marginLeft: 12,
    marginRight: 6,
  },
  selectableChoiceItemText: {
    justifyContent: "center",
    wordBreak: "break-word",
    maxWidth: "calc(100% - 40px)",
  },
});

const SubmittableChoice = connect(
  ({
    choice,
    fathomCode,
    formik: { setFieldValue, handleSubmit, isSubmitting },
  }) => {
    const classes = useStyles();

    const handleClick = () => {
      if (isSubmitting) {
        return;
      }

      window.fathom && window.fathom.trackGoal(fathomCode, 0);

      setFieldValue("selectedChoices", [choice.id]).then(handleSubmit);
    };

    return (
      <div
        className={`${classes.choiceItem} ${classes.submittableChoiceItem}`}
        onClick={handleClick}
      >
        {choice.text}
      </div>
    );
  }
);

const SelectableChoice = connect(
  ({ choice, formik: { setFieldValue, values, isSubmitting } }) => {
    const classes = useStyles();
    const isSelected = includes(values.selectedChoices, choice.id);

    const handleClick = () => {
      if (isSubmitting) {
        return;
      }

      setFieldValue(
        "selectedChoices",
        isSelected
          ? difference(values.selectedChoices, [choice.id])
          : uniq(values.selectedChoices.concat([choice.id]))
      );
    };

    return (
      <div
        className={`${classes.choiceItem} ${classes.selectableChoiceItem}`}
        onClick={handleClick}
      >
        <div className={classes.selectableChoiceItemIconContainer}>
          {isSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </div>
        <div className={classes.selectableChoiceItemText}>{choice.text}</div>
      </div>
    );
  }
);

const Choices = ({ poll, fathomCode }) => {
  const { choices, multiSelect } = poll.attributes;
  const classes = useStyles();

  const ItemClass = multiSelect ? SelectableChoice : SubmittableChoice;

  return (
    <div className={classes.choicesList}>
      {map(choices, (choice) =>
        React.createElement(ItemClass, { key: choice.id, choice })
      )}
    </div>
  );
};

export default Choices;
