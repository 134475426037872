import React from "react";
import { useSelector } from "react-redux";
import {
  getConversation,
  makeGetFilteredConversationEvents,
  makeGetFilteredMessages,
} from "../../../../../selectors/conversationSelectors";
import { getMessage } from "../../../../../selectors/conversationSelectors";

import ConversationItemBody from "./ConversationItemBody";
import ConversationItemUnreadLabel from "./ConversationItemUnreadLabel";
import ConversationItemTimestamp from "./ConversationItemTimestamp";
import useSharedStyles, { AVATAR_SIZE } from "./useSharedStyles";
import ConversationLabel from "../../../partials/ConversationLabel";
import { useMemo } from "react";
import maxBy from "lodash/maxBy";
import ConversationAvatar from "../../../partials/ConversationAvatar";
import { forwardRef } from "react";

const ConversationItem = ({ id, isSelected, handleSelection }, ref) => {
  const classes = useSharedStyles({ isSelected });

  const conversation = useSelector((state) => getConversation(state, { id }));

  const getEvents = useMemo(() => makeGetFilteredConversationEvents(), []);

  const events = useSelector((state) =>
    getEvents(state, { conversationId: id })
  );

  const recentEvent = useMemo(
    () => maxBy(events, (event) => event.attributes.createdAt),
    [events]
  );

  return (
    <div className={classes.container} onClick={handleSelection} ref={ref}>
      <div className={classes.avatarContainer}>
        <ConversationAvatar {...{ conversation, size: AVATAR_SIZE }} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>
            <ConversationLabel {...{ conversation }} />
          </div>
          <ConversationItemTimestamp {...{ conversation, recentEvent }} />
        </div>
        <div className={classes.bodyContainer}>
          <ConversationItemBody {...{ conversation, recentEvent }} />
          <ConversationItemUnreadLabel {...{ conversation, recentEvent }} />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ConversationItem);
