import React from "react";

import SubmitResponseForm from "../../shared/PollContent/SubmitResponseForm/SubmitResponseForm";
import Header from "../../shared/PollContent/Header";
import Question from "../../shared/PollContent/Question";
import Choices from "../../shared/PollContent/SubmitResponseForm/Choices";
import Footer from "../../shared/PollContent/Footer";
import SubmitButton from "../../shared/PollContent/SubmitResponseForm/SubmitButton";
import { POLL_ANSWER_INDEX } from "../../../../util/fathomEventCodes";

const PollResponseForm = ({ poll }) => {
  const { multiSelect } = poll.attributes;

  return (
    <SubmitResponseForm poll={poll}>
      <Header poll={poll} />
      <div style={{ paddingLeft: 12 }}>
        <Question poll={poll} />
        <Choices poll={poll} fathomCode={POLL_ANSWER_INDEX} />
        <Footer
          poll={poll}
          action={
            multiSelect && <SubmitButton fathomCode={POLL_ANSWER_INDEX} />
          }
        />
      </div>
    </SubmitResponseForm>
  );
};

export default PollResponseForm;
