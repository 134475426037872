import orderBy from "lodash/orderBy";
import includes from "lodash/includes";

// order options
export const ASC_NAME = "asc_name";
export const DESC_NAME = "desc_name";
export const MOST_REVIEWS = "most_reviews";
export const HIGHEST_RATED = "highest_rated";

export const PRODUCT_CATEGORIES_SORT_OPTIONS = [
  {
    value: ASC_NAME,
    label: "A to Z",
    defaultValue: true,
  },
  {
    value: DESC_NAME,
    label: "Z to A",
  },
  {
    value: MOST_REVIEWS,
    label: "Most Reviews",
  },
];

export const PRODUCT_CATEGORY_SORT_OPTIONS = [
  {
    value: MOST_REVIEWS,
    label: "Most Reviews",
    defaultValue: true,
  },
  {
    value: HIGHEST_RATED,
    label: "Highest Rated",
  },
  {
    value: ASC_NAME,
    label: "A to Z",
  },
  {
    value: DESC_NAME,
    label: "Z to A",
  },
];

export const orderItems = (ordering, items) => {
  const parsedOrdering = parseOrdering(ordering);

  return orderBy(items, ...parsedOrdering);
};

const parseOrdering = (ordering) => {
  let [orderIteratees, orderDirections] = [[], []];

  if (includes(ordering, DESC_NAME)) {
    orderIteratees.push("attributes.name");
    orderDirections.push("desc");
  } else if (includes(ordering, MOST_REVIEWS)) {
    orderIteratees.push(
      "attributes.vendorProductReviewsCount",
      "attributes.name"
    );
    orderDirections.push("desc", "asc");
  } else if (includes(ordering, HIGHEST_RATED)) {
    orderIteratees.push("attributes.overallRating", "attributes.name");
    orderDirections.push("desc", "asc");
  } else {
    orderIteratees.push("attributes.name");
    orderDirections.push("asc");
  }

  return [orderIteratees, orderDirections];
};

export const defaultValue = {
  value: ASC_NAME,
  label: "A to Z",
  defaultValue: true,
};
