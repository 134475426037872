import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#37474F",
    minWidth: 180,
    border: "1px solid #008DAE",
    textAlign: "center"
  },
}))(Tooltip);

export default HtmlTooltip;
