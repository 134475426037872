import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import orderBy from "lodash/orderBy";

import { ResponsiveBar } from "@nivo/bar";

const styles = {
  chartContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  leftAxisLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  leftAxisLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "13px",
    whiteSpace: "normal",
    fontWeight: 600,
    color: "#B0BEC5",
    fontFamily: "Poppins",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
  },
  svgContainer: {
    height: "100%",
  },
};

const FunnelBarChart = ({
  data,
  valueKey,
  height,
  axisWidth,
  svgWidth,
  classes,
}) => {
  const orderedData = orderBy(data, [valueKey], ["asc"]);
  const valueMax =
    orderedData.length && orderedData[orderedData.length - 1][valueKey];
  const renderMax = valueMax * 1.1;

  const formatValueLabel = (valueNum) => {
    let formatText = "" + valueNum;
    const isMax = valueNum === valueMax;
    !isMax && (formatText += ` (${Math.round((valueNum / valueMax) * 100)}%)`);

    const xAdjustment =
      (valueNum / renderMax) * 380 + (formatText.length / 2) * 7 + 4;
    return (
      <tspan x={xAdjustment || 0} style={{ fontSize: "14px" }}>
        {formatText}
      </tspan>
    );
  };

  const renderAxisLabels = () => {
    const axisLabels = orderedData.map(({ id }) => id).reverse();
    return axisLabels.map((label) => (
      <div
        className={classes.leftAxisLabel}
        style={{
          height: height / orderedData.length,
        }}
        key={label}
      >
        {label}
      </div>
    ));
  };

  return (
    <div
      style={{ height }}
      className={classes.chartContainer}
      data-cy="bar-chart"
    >
      <div
        style={{ width: axisWidth }}
        className={classes.leftAxisLabelContainer}
      >
        {renderAxisLabels()}
      </div>
      <div
        className={classes.svgContainer}
        style={{ width: svgWidth, maxWidth: 380, paddingRight: 12 }}
      >
        <ResponsiveBar
          data={orderedData}
          indexBy="id"
          maxValue={renderMax}
          keys={[valueKey]}
          colorBy={"index"}
          colors={orderedData.map(({ color }) => color)}
          borderColor={{ from: "color", modifiers: [["opacity", 1]] }}
          layout="horizontal"
          labelFormat={formatValueLabel}
          labelTextColor={"#37474F"}
          padding={0}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
};

FunnelBarChart.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
  // the key in data objects that holds their numerical value.
  // will vary by use case since the key itself is rendered in the chart
  valueKey: PropTypes.string.isRequired,
  // must be the px number -- used for calculation
  height: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  svgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FunnelBarChart);
