import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { useDispatch } from "react-redux";
import { fetchRandomNewPoll } from "../../../actions/pollActions";

import PollContent from "./PollContent/PollContent";
import EmptyContent from "./EmptyContent";
import LoadingContent from "./LoadingContent";

const START = "start";
const LOADING = "loading";
const READY = "ready";

const PollsCard = () => {
  const [status, setStatus] = useState(START);

  const [currentPollId, setCurrentPollId] = useState(null);
  const [excludedPollIds, setExcludedPollIds] = useState([]);

  const dispatch = useDispatch();

  const handleFetchRandomNewPoll = () => {
    setStatus(LOADING);
    dispatch(
      fetchRandomNewPoll({ excluded_poll_ids: excludedPollIds.join(",") })
    ).then((res) => {
      const newId = res ? Object.keys(res)[0] : null;

      setCurrentPollId(newId);
      setExcludedPollIds(excludedPollIds.concat(newId));
      setStatus(READY);
    });
  };

  useEffect(() => {
    if (!currentPollId && status === START) {
      handleFetchRandomNewPoll();
    }
  }, [dispatch, setCurrentPollId, setExcludedPollIds]);

  const handleNext = () => handleFetchRandomNewPoll();

  const renderContent = () => {
    if (status === LOADING || status === START) {
      return <LoadingContent />;
    } else if (status === READY && !currentPollId) {
      return <EmptyContent />;
    } else {
      return <PollContent id={currentPollId} handleNext={handleNext} />;
    }
  };

  return <div className={classnames("kt-portlet")}>{renderContent()}</div>;
};

export default PollsCard;
