import { createSelector } from "reselect";
import createDeepEqualSelector from "./createDeepEqualSelector";
import filter from "lodash/filter";
import every from "lodash/every";
import isArray from "lodash/isArray";

export const ACCEPTED = "accepted";
export const DECLINED = "declined";
export const PENDING = "pending";

const buildFilterFunctions = ({ invitationStatus }) => {
  const filterFunctions = [];

  if (invitationStatus) {
    filterFunctions.push((event) =>
      isArray(invitationStatus)
        ? includes(invitationStatus, event.attributes.invitationStatus)
        : invitationStatus === event.attributes.invitationStatus
    );
  }

  return filterFunctions;
};

export const getAllEvents = (state) => state.events.items;

export const makeGetFilteredEvents = () => {
  const getEventFilters = (_state, filters) => filters;

  const getFiltersSelector = createDeepEqualSelector(
    [getEventFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllEvents, getFiltersSelector],
    (events, eventFilters) => {
      const filterFunctions = buildFilterFunctions(eventFilters);

      return filter(events, (event) =>
        every(filterFunctions, (filterFunction) => filterFunction(event))
      );
    }
  );
};
