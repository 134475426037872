import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useDispatch } from "react-redux";

import { acceptInvitation, declineInvitation } from "../../../../../actions/eventInvitationActions";
import EventAcceptModal from "../../../EventAcceptModal";

function InvitationActions({ id, trackGoal }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { invitationStatus, status, inviteId } = event.attributes;

  const invitationPending = [
    "accepted",
    "declined",
    "in_progress",
    "pending",
  ].includes(invitationStatus);

  const eventUpcomingOrInProgress = ["in_progress", "upcoming"].includes(status);

  if (!invitationPending || !eventUpcomingOrInProgress) return null;

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    if (invitationStatus === "accepted") return;
    window.fathom && window.fathom.trackGoal(trackGoal, 0);
    dispatch(acceptInvitation(inviteId)).then(() => setOpen(true));
  };

  const handleDecline = (e) => {
    e.preventDefault();
    if (invitationStatus === "declined") return;
    window.fathom && window.fathom.trackGoal(trackGoal, 0);
    dispatch(declineInvitation(inviteId));
  };

  return (<>
    <EventAcceptModal
      open={open}
      handleClose={handleClose}
      {...event.attributes}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "12px",
        marginBottom: "12px",
        alignItems: "space-between",
      }}
    >
      <button
        style={{ maxWidth: "150px", width: "calc(50% - 6px)" }}
        className={`btn btn-sm btn${
          invitationStatus === "accepted" ? "" : "-outline"
        }-brand-secondary`}
        onClick={handleAccept}
        type="button"
      >
        <i
          className="flaticon2-check-mark"
          style={{ padding: 0 }}
        ></i>
      </button>
      <div style={{width: 12}} />
      <button
        style={{ maxWidth: "150px", width: "calc(50% - 6px)" }}
        className={`btn btn-sm btn${
          invitationStatus === "declined" ? "" : "-outline"
        }-brand-secondary`}
        onClick={handleDecline}
        type="button"
      >
        <i className="flaticon2-cross" style={{ padding: 0 }}></i>
      </button>
    </div>
    </>
  );
}

InvitationActions.propTypes = {
  id: PropTypes.string.isRequired,
  trackGoal: PropTypes.string.isRequired  
}

export default InvitationActions;
