import React from "react";

import Items from "./Items";

export default function ContentSection({ items }) {
  return (
    <div
      className="kt-portlet kt-portlet--height-fluid"
      style={{ height: "auto" }}
    >
      <div className="kt-portlet__body kt-portlet__body--fit">
        <Items {...{ profiles: items }} />
      </div>
    </div>
  );
}
