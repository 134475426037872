import React, { useState } from "react";

import AvatarEditor from "react-avatar-editor";
import merge from "lodash/merge";

import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  editingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: "#292E33",
    fontSize: 14,
  },
}));

const EditImageControls = ({
  width,
  height,
  borderRadius,
  editingImage,
  setEditor,
}) => {
  const [imageAttributes, setImageAttributes] = useState({
    image: editingImage,
    xPosition: 0.5,
    yPosition: 0.5,
    zoom: 1,
  });
  const classes = useStyles();
  const { image, xPosition, yPosition, zoom } = imageAttributes;

  const handleSlideChange = (e) => {
    setImageAttributes(
      merge({}, imageAttributes, {
        [e.target.id]: parseFloat(e.target.value),
      })
    );
  };

  const renderSlider = (label, controlName, min, max, step) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>{label}</span>
        <input
          id={controlName}
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={(e) => handleSlideChange(e)}
          value={imageAttributes[controlName]}
        />
      </div>
    );
  };

  const renderEditorControls = () => {
    return (
      <div>
        <div>
          Drag the image or use the slider to adjust how the image appears.
        </div>
        <div style={{ paddingTop: 20 }}>
          {renderSlider("Zoom", "zoom", 0, 2, 0.01)}
        </div>
      </div>
    );
  };

  const handlePositionChange = (coord) => {
    setImageAttributes(
      merge({}, imageAttributes, {
        xPosition: coord.x,
        yPosition: coord.y,
      })
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.editingContainer}>
        <AvatarEditor
          style={{ paddingTop: 20 }}
          ref={(editor) => setEditor(editor)}
          width={width}
          height={height}
          borderRadius={borderRadius}
          image={image}
          scale={zoom}
          position={{
            x: xPosition,
            y: yPosition,
          }}
          onPositionChange={(coord) => handlePositionChange(coord)}
        />
      </div>
      <div className={classes.editingContainer}>
        {renderEditorControls()}
      </div>
    </div>
  );
};

export default EditImageControls;
