import { GET } from "./apiHelpers";

export const fetchPresignedUrl = (uploadFolder) =>
  GET(`/api/v1/upload_url?folder=${uploadFolder}`).then((res) => res.data);

// upload to AWS
export const uploadFile = async ({
  file,
  uploadFolder,
  success = () => {},
  failure = () => {},
  progress = () => {},
}) => {
  const presignedUrl = await fetchPresignedUrl(uploadFolder);

  const xhr = new XMLHttpRequest();
  xhr.open("POST", presignedUrl.url);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    if (xhr.status < 200 || xhr.status >= 300) {
      failure("HTTP Error: " + xhr.status);
      return;
    }

    const parser = new DOMParser();
    const data = parser.parseFromString(xhr.response, "text/xml");
    const location =
      data.getElementsByTagName("Location")[0].childNodes[0].nodeValue;

    if (!location) {
      failure && failure("Invalid Response");
      return;
    }

    success(location);
  };

  xhr.onerror = function () {
    failure(
      "Image upload failed due to a XHR Transport error. Code: " + xhr.status
    );
  };

  const formData = new FormData();
  for (let key in presignedUrl.url_fields) {
    formData.append(key, presignedUrl.url_fields[key]);
  }
  formData.append("file", file);
  xhr.send(formData);
};
