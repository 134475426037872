import React, { useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Content = withStyles({
  root: {
    borderBottom: "0.5px solid #ECEDF2",
    borderTop: 0,
    boxShadow: "none",
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
    fontWeight: 400,
    "&$expanded": {
      margin: "0",
    },
    "&:before": {
      display: "none",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  expanded: {
    margin: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    minHeight: 56,
    padding: "0 25px",
    paddingRight: 35,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "0",
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

function Accordion({ summary, children, summaryOverride }) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => setExpanded(!expanded);
  const overrideClasses = summaryOverride ? { root: summaryOverride } : {};

  return (
    <Content square expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        classes={overrideClasses}
        expandIcon={<ExpandMoreIcon />}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Content>
  );
}

export default Accordion;
