import { object, string } from "yup";

export const initialValues = { 
  subject: "", 
  body: "", 
  groupId: null,
  vendorProductId: null
};

export const validationSchema = object().shape({
  subject: string().required("Cannot be blank"),
  body: string().required("Cannot be blank"),
  groupId: string().nullable().required("Cannot be blank"),
  vendorProductId: string().nullable(),
});
