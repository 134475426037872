import React from "react";
import { useMemo } from "react";
import AvatarIcon from "../../General/AvatarIcon";
import { useConversationUsers } from "./hooks";
import forEach from "lodash/forEach";
import { useState } from "react";

// todo(me): need to figure out how to handle opacity of name avatars against image avatars
const ConversationAvatar = ({ conversation, size }) => {
  const users = useConversationUsers(conversation);

  const [secondaryLoadError, setSecondaryLoadError] = useState(false);

  let [primaryUser, secondaryUser] = users;

  if (!primaryUser) {
    return <></>;
  }

  // dont impose transparent name avatar on image avatar
  if (
    primaryUser.attributes.avatarUrl &&
    ((secondaryUser && !secondaryUser.attributes.avatarUrl) ||
      secondaryLoadError)
  ) {
    [secondaryUser, primaryUser] = [primaryUser, secondaryUser];
  }

  const secondarySize = size / 1.5;

  return (
    <div style={{ height: size, width: size, position: "relative" }}>
      <AvatarIcon
        member={primaryUser}
        height={size}
        bolded={true}
        customStyles={{ border: "1px solid white" }}
      />
      {secondaryUser && (
        <div
          style={{
            position: "absolute",
            height: secondarySize,
            width: secondarySize,
            bottom: "-20%",
            right: "-20%",
          }}
        >
          <AvatarIcon
            member={secondaryUser}
            height={secondarySize}
            onImageLoadError={() => setSecondaryLoadError(true)}
            customStyles={{ border: "1px solid white" }}
          />
        </div>
      )}
    </div>
  );
};

export default ConversationAvatar;
