import React from "react";

import Header from "./partials/Header";
import Question from "../../../Polls/shared/PollContent/Question";
import Footer from "../../../Polls/shared/PollContent/Footer";
import Results from "../../../Polls/shared/PollContent/PollResults/Results";
import UndoButton from "../../../Polls/shared/PollContent/PollResults/UndoButton";

const PollResultsContent = ({ poll }) => (
  <React.Fragment>
    <Header poll={poll} />
    <div style={{ paddingLeft: 12 }}>
      <Question poll={poll} />
      <Results poll={poll} />
      <Footer poll={poll} action={<UndoButton poll={poll} />} />
    </div>
  </React.Fragment>
);

export default PollResultsContent;
