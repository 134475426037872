import axios from "axios";
import queryString from "query-string";
import { mapKeysToSnakeCase } from "./formatHelpers";

export const IDLE = "idle";
export const LOADING = "loading";
export const LOADED = "loaded";
export const ERROR = "error";

export const GET = (url, params) =>
  axios({
    method: "GET",
    url,
    params,
    paramsSerializer: function (params) {
      return queryString.stringify(mapKeysToSnakeCase(params));
    },
  });

export const POST = (url, data) =>
  axios({
    method: "POST",
    url,
    data,
  });

export const PATCH = (url, data) =>
  axios({
    method: "PATCH",
    url,
    data,
  });

export const DELETE = (url, data) =>
  axios({
    method: "DELETE",
    url,
    data,
  });
