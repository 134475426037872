import React from "react";
import { useSelector } from "react-redux";

const Item = ({
  vendorProduct,
  productId,
  fontSize = 16,
  utmSource,
  utmContent,
}) => {
  if (!vendorProduct) {
    vendorProduct = useSelector(
      (state) => state.vendorProducts.items[productId]
    );
  }

  const fathomParams =
    utmSource && utmContent
      ? `?utm_source=${utmSource}&utm_content=${utmContent}`
      : "";

  // Poppins, medium, 18, 367BA6
  return (
    <li>
      <img
        style={{ objectFit: "contain", height: 25, width: 25, marginRight: 10 }}
        src={vendorProduct.attributes.logoUrl}
      />
      <a
        href={vendorProduct.attributes.pageUrl + fathomParams}
        style={{ fontWeight: 400, fontSize: fontSize, color: "#367BA6" }}
      >
        {vendorProduct.attributes.name}
      </a>
    </li>
  );
};

export default Item;
