import { makeStyles } from "@material-ui/core";
import compact from "lodash/compact";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getCurrentUserId } from "../../../../../selectors/userSelectors";
import { buildFullName } from "../../../../../util/formatHelpers";
import { AvatarIcon } from "../../../../General/AvatarIcon";
import { useConversationUsers } from "../../../partials/hooks";

import RemoveParticipant from "./RemoveParticipant";

const AVATAR_SIZE = 40;

const calcContainerWidth = ({ canRemove, removing, theme }) => {
  let size = AVATAR_SIZE;
  if (canRemove) {
    size += 100 + theme.spacing(4);
    if (removing) {
      size += 100 - theme.spacing(2);
    }
  }
  return `calc(100% - ${size}px)`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ECEDF2",
    },
  },
  avatarContainer: {
    width: `${AVATAR_SIZE}px`,
    marginRight: theme.spacing(2),
  },
  textContainer: ({ canRemove, removing }) => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    maxWidth: calcContainerWidth({ canRemove, removing, theme }),
    "& > div": {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
  title: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#48465B",
    fontWeight: 400,
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {},
  actionsContainer: {
    width: "100%",
    float: "right",
  },
  email: {
    color: "blue",
    textDecoration: "underline",
    "&:hover": {
      color: "blue",
    }
  }
}));

function ParticipantItem({
  user, conversation, isGroup, isBoardGroup
}) {
  const {
    firstName, lastName, jobTitle, companyName, email
  } = user.attributes;
  const [removing, setRemoving] = useState(false);

  const users = useConversationUsers(conversation, { includeCurrent: true });

  /* only remove users in non board groups with 2 or more users */
  const canRemove = (!isBoardGroup && isGroup && users.length > 2);

  const classes = useStyles({ canRemove, removing, isBoardGroup });

  const currentUserId = useSelector(getCurrentUserId);

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        <AvatarIcon user={user} height={AVATAR_SIZE} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.title}>
          {buildFullName(firstName, lastName)}
        </div>
        <div className={classes.subtitle}>
          {compact([jobTitle, companyName]).join(" - ")}
        </div>
        {email && (
          <div className={classes.subtitle}>
            <a className={classes.email} href={`mailto:${email}`}>{email}</a>
          </div>
        )}
      </div>
      <div className={classes.actionsContainer}>
        {(canRemove && currentUserId !== user.id) && (
          <RemoveParticipant {...{ user, conversation, setRemoving }} />
        )}
      </div>
    </div>
  );
}

export default ParticipantItem;
