import {
  RECEIVE_FEATURE_FLAGS
} from "../actions/featureFlagActions";

const _defaultState = {
  featureFlags: [],
  loaded: false
};

export const featureFlagReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let flags;

  switch (action.type) {
    case RECEIVE_FEATURE_FLAGS:
      flags = Object.keys(action.payload);
      return { flags, loaded: true };
    default:
      return state;
  }
};

export default featureFlagReducer;
