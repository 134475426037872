import { Button, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { renameConversation } from "../../../../../../actions/conversationActions";
import TextField from "../../../../../General/FormInputs/TextField";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
  },
  inputContainer: {
    position: "relative",
    left: theme.spacing(1),
    width: "calc(100% - 150px)",
  },
  helperText: {
    position: "absolute",
    fontSize: 11,
    bottom: -20,
  },
  actionsContainer: {
    display: "flex",
  },
  button: {
    borderRadius: theme.spacing(2),
    boxShadow: "none",
    marginLeft: theme.spacing(3)
  },
  editButton: {
    marginLeft: theme.spacing(2)
  }
}));

function EditNameForm({ initialValue, conversationId, unsetEditing }) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(initialValue);
  const [isSubmitting, setSubmitting] = useState(false);

  const isDisabled = isSubmitting || !value;

  const classes = useStyles();

  const handleSubmit = () => {
    if (isDisabled) return;

    setSubmitting(true);
    dispatch(
      renameConversation({ conversation_id: conversationId, name: value })
    )
      .then(() => {
        setSubmitting(false);
        unsetEditing();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.inputContainer}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {}}
          showError={false}
          styles={{
            container: { width: "100%", marginBottom: 0 },
            input: { height: 32, fontSize: 13, lineHeight: 16 },
          }}
        />
        <div className={classes.helperText}>This is visible to all members</div>
      </div>
      <div className={classes.actionsContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={unsetEditing}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classnames(classes.button, classes.editButton)}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Edit
        </Button>
      </div>
    </div>
  );
}

export default EditNameForm;
