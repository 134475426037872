import React, { useState, useEffect, useContext } from "react";

import Accordion from "./accordion_form_control_checkboxes/Accordion";
import { FilterContext } from "./Filter";

export default function AccordionFormControlCheckboxes({ id, title, options, searchable }) {
  const { context } = useContext(FilterContext);
  const { state } = useContext(context);

  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    let count = 0;
    options.forEach(({ value }) => {
      if (
        (state[value] && state[value]["value"] && state[value]["color"] === options[0].color) ||
        (state[value + "|" + options[0].color] && state[value + "|" + options[0].color]["value"])
        ) count += 1;
    });
    setSelectedCount(count);
  }, [state]);

  const [subtitle, setSubtitle] = useState("All");
  useEffect(() => {
    setSubtitle(selectedCount === 0 ? "All" : selectedCount + " selected");
  }, [selectedCount]);

  return (
    <Accordion
      {...{
        id,
        title,
        subtitle,
        options,
        searchable,
        selectedCount,
      }}
    />
  );
}
