import React from "react";
import { createUseStyles } from "react-jss";
import pluralize from "pluralize";
import UpdateCommentForm from "../CommentForm/UpdateCommentForm";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "12px 0px",
  },
  likesCountContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 50,
  },
  bodyContainer: {
    fontSize: "1.28rem",
    lineHeight: "2.28rem",
    marginLeft: 8,
  },
});

const CommentContent = ({ comment, isEditing, setEditing }) => {
  const classes = useStyles();

  const { totalLikes, body } = comment.attributes;

  return (
    <div className={classes.container}>
      <div className={classes.likesCountContainer}>
        <div>{totalLikes}</div>
        <div>{pluralize("Likes", totalLikes)}</div>
      </div>
      <div className={classes.bodyContainer}>
        {isEditing ? (
          <UpdateCommentForm
            comment={comment}
            handleSuccess={(context) => {
              context.resetForm();
              setEditing(false);
            }}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CommentContent;
