import { createSelector } from "reselect";
import moment from "moment";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

export const makeGetCommentsByTopicId = createSelector(
  (state) => state.comments.items,
  (items) => {
    return (topicId) => {
      return orderBy(
        filter(items, (item) => {
          return (
            item.attributes.ancestryDepth === 0 &&
            item.attributes.subjectId === topicId
          );
        }),
        (item) => moment().diff(item.attributes.createdAt)
      );
    };
  }
);

export const makeGetSubcomments = createSelector(
  (state) => state.comments.items,
  (items) => (ancestryId) => {
    return orderBy(
      filter(items, (item) => item.attributes.ancestry === ancestryId),
      (item) => moment().diff(item.attributes.createdAt)
    );
  }
);
