import { combineReducers } from "redux";

import items from "./itemsReducer";
import messages from "./messagesReducer";
import attachments from "./attachmentsReducer";
import participants from "./participantsReducer";
import events from "./eventsReducer";

export default combineReducers({
  items,
  messages,
  attachments,
  participants,
  events,
});
