import map from "lodash/map";
import React, { useEffect } from "react";
import { Row, Col } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";

import { fetchPoll } from "../../actions/pollActions";
import withGlobalProviders from "../General/withGlobalProviders";

import CommentsList from "./show/CommentsList";
import PollActionsList from "./show/PollActionsList";
import PollReplyCard from "./show/PollReplyCard";
import PollSubject from "./show/PollSubject/PollSubject";



const Show = ({ id }) => {
  const dispatch = useDispatch();

  const poll = useSelector((state) => state.polls.items[id]);
  
  useEffect(() => {
    dispatch(fetchPoll(id));
  }, [dispatch]);

  const renderContent = () =>
    map(
      [PollActionsList, PollSubject, PollReplyCard, CommentsList],
      (SectionComponent, key) =>
        React.createElement(SectionComponent, { key, poll })
    );

  return (
    <Row>
      <Col xs={12}>{poll && renderContent()}</Col>
    </Row>
  );
};

export default withGlobalProviders(Show);
