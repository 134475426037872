export const TA_LEVEL_OPTIONS = [
  "Coordinator",
  "Generalist",
  "Practitioner",
  "Manager",
  "Senior Leadership",
];

export const COMPANY_INDUSTRY_OPTIONS = [
  "Agriculture",
  "Arts",
  "Business Services",
  "Civic, Government & Military",
  "Construction & Architecture",
  "Consumer Goods & Services",
  "Design & Media Production",
  "Education",
  "Energy & Utilities",
  "Entertainment",
  "Finance & Insurance",
  "Healthcare",
  "Hospitality",
  "Legal",
  "Manufacturing & Materials",
  "Medical Manufacturing",
  "Non-Profit",
  "Publishing & Writing",
  "Real Estate",
  "Tech",
  "Telecom",
  "Transportation & Logistics",
];

export const USA_LOCATION_OPTIONS = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]

export const INTERNATIONAL_LOCATION_OPTIONS = [
  "Albania",
  "Argentina",
  "Australia",
  "Austria",
  "Bahrain",
  "Belarus",
  "Belgium",
  "Belize",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Cayman Islands",
  "Chile",
  "China",
  "Colombia",
  "Costa Rica",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Dominican Republic",
  "Egypt",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Guatemala",
  "Guyana",
  "Hong Kong",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Kenya",
  "Korea, Republic of",
  "Kosovo",
  "Lithuania",
  "Macau",
  "Macedonia",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Norway",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russian Federation",
  "Saudi Arabia",
  "Serbia",
  "Singapore",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Turkey",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Vietnam",
]

export const CANDIDATE_INDUSTRY_OPTIONS = [
  "Agriculture",
  "Art",
  "Construction",
  "Corporate",
  "Education",
  "Finance",
  "Goods",
  "Government",
  "Health",
  "Legal",
  "Manufacturing",
  "Media",
  "Non-Profit Organization",
  "Recreation",
  "Service",
  "Technology",
  "Transportation",
];

export const CANDIDATE_LEVEL_OPTIONS = [
  "Junior",
  "Mid-Level",
  "Senior",
  "Executive",
];

export const CANDIDATE_ROLE_OPTIONS = [
  "Civic & Education",
  "Consulting",
  "Finance",
  "Health Care",
  "Legal",
  "Marketing & Creative",
  "Manufacturing & Labor",
  "Operations & HR",
  "Sales",
  "Science & Engineering",
  "Service & Hospitality",
  "Technology & Software",
];

export const RACIAL_DIVERSITY = "Racial diversity";
export const SEX_GENDER_DIVERSITY = "Sex / Gender diversity";
export const SEXUAL_ORIENTATION = "Sexual orientation";
export const DISABILITY = "Disability";
export const MILITARY_VETERAN = "Military / Veteran";
export const AGE_DIVERSITY = "Age diversity";
export const CULTURAL_DIVERSITY = "Cultural diversity";

export const DIVERSITY_FOCUS_OPTIONS = [
  RACIAL_DIVERSITY,
  SEX_GENDER_DIVERSITY,
  SEXUAL_ORIENTATION,
  DISABILITY,
  MILITARY_VETERAN,
  AGE_DIVERSITY,
  CULTURAL_DIVERSITY,
];

export const COMPANY_SIZE_OPTIONS = [
  "Self-employed",
  "1-10 Employees",
  "11-50 Employees",
  "51-200 Employees",
  "201-500 Employees",
  "501-1000 Employees",
  "1001-5000 Employees",
  "5001-10,000 Employees",
  "10,001+ Employees",
];

export const MEMBER_TYPE_OPTIONS = ["Board Member", "Regular Member"];

export const PERSONAL = "personal";
export const PROFESSIONAL = "professional";
export const PREFERRED_CONTACT_OPTIONS = [PERSONAL, PROFESSIONAL];
