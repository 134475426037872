import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { RECEIVE_POLLS, REMOVE_POLL } from "../actions/pollActions";

const defaultState = {
  items: {},
};

const pollsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  let newState;
  switch (action.type) {
    case RECEIVE_POLLS:
      return merge({}, state, { items: action.payload });
    case REMOVE_POLL:
      newState = cloneDeep(state);
      delete newState.items[action.payload];

      return newState;
    default:
      return state;
  }
};

export default pollsReducer;
