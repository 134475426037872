import React from 'react';
import { connect } from "formik";

import map from "lodash/map";
import get from "lodash/get";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    color: "#495057",
    fontWeight: 400,
    padding: "12px 24px",
    lineHeight: "1.1876em"
  },
  underline: {
    "&:after": {
      backgroundColor: "#357BA6",
    },
  },
  icon: {
    maxHeight: "1em"
  }
}));

const ConnectedNativeSelect = connect(({
  formik: { values, setFieldValue, handleBlur, errors, touched },
  InputField,
  id,
  label,
  options,
  placeholder,
  removeFormGroupMargin = false,
  ...inputFieldProps
}) => {
  const classes = useStyles();
  const showError = Boolean(get(touched, id) && get(errors, id));

  const onChange = (event) => {
    setFieldValue(
      id,
      event.currentTarget.value
    );
  };

  return (
    <select
      value={values[id]}
      onChange={onChange}
      name={id}
      className={"block w-full pl-3 pr-10 py-2 text-2xl border-gray-300 focus:outline-none focus:ring-astral-500 focus:border-astral-500 rounded-md " + (values[id] === "" ? "text-gray-400" : "text-gray-800")}
    >
      {placeholder && <option value="" disabled hidden>
        {placeholder}
      </option>}
      {map(options, ({ value, label }) => <option key={value} value={value}>{label}</option>)}
    </select>
  );
})

export default ConnectedNativeSelect;
