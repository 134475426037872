import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import merge from "lodash/merge";

export default function JoinWebinarButton({ id, overrideStyle }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { invitationStatus, webinar, webinarLink, status } = event.attributes;

  if (invitationStatus !== "accepted") return null;
  if (status !== "in_progress") return null;
  if (!webinar) return null;
  if (!webinarLink) return null;

  return (
    <a
      rel="noopener noreferrer"
      href={webinarLink}
      style={merge({}, { width: "100%", textAlign: "center" }, overrideStyle)}
    >
      <button
        style={{ width: "100%" }}
        className="btn btn-sm btn-brand-secondary"
      >
        Join Webinar
      </button>
    </a>
  );
}
