import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import FormPageLayout from "../../General/FormPage/FormPageLayout";
import FormHeader from "../../General/FormPage/FormHeader";

import { POST } from "../../../util/apiHelpers";

import { search } from "../search/utils";

import Info from "./Info";
import SearchResult from "./SearchResult";
import Footer from "./Footer";

import {
  initialValues,
  validationSchema,
  createHandleSubmit,
} from "./fieldHelpers";

export const submitSuggestion = (data) =>
  POST("/api/v1/vendor_products/suggest", { data });

const Form = ({ productCategories }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [finishedSearch, setFinishedSearch] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = (data) => {
    if (currentSection === 0) {
      // if on section 0
      const searchCallback = (results) => {
        if (results.length > 0) {
          // if there is a match, move to confirm match page
          setSearchResults(results);
          setCurrentSection(1);
        } else {
          // if there is not a match, submit
          submitSuggestion(data).then(() => {
            setCurrentSection(2);
          });
        }
      };

      // look for match
      search("vendorProduct", data.name, searchCallback);
    } else if (currentSection === 1) {
      // if on section 1
      // submit
      submitSuggestion(data).then(() => {
        setCurrentSection(2);
      });
    }
  };

  let content;

  switch (currentSection) {
    case 0:
      content = <Info {...{ productCategories }} />;
      break;
    case 1:
      content = <SearchResult {...{ searchResults, handleSubmit }}/>;
      break;
    case 2:
      content = (
        <div
          style={{
            height: 450,
            fontWeight: 400,
            fontSize: 22,
            color: "#263238",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Thank you for suggesting a Vendor!</div>
          <div style={{marginTop: 24, marginBottom: 36}}>
            If they meet our criteria they will be added to the TALK Vendor
            Directory.
          </div>
          <a
            className="btn btn-outline-brand-secondary"
            href="/vendors"
            style={{ marginLeft: 10 }}
          >
            All Categories
          </a>
        </div>
      );
      break;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <FormPageLayout>
          <FormHeader title={"Suggest a Vendor"} />
          {content}
          {currentSection === 0 && <Footer {...{ currentSection, loading }} />}
        </FormPageLayout>
      )}
    </Formik>
  );
};

export default Form;
