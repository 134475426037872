import React from "react";
import classnames from "classnames";

export const CancelButton = ({ ...props }) => (
  <button
    className={classnames("btn", "btn-outline-brand-secondary")}
    {...props}
  >
    Cancel
  </button>
);

export const SubmitButton = ({ ...props }) => (
  <button
    className={classnames("btn", "btn-secondary")}
    type="submit"
    {...props}
  >
    Submit
  </button>
);

export const AddEntryButton = ({ ...props }) => (
  <button
    type="button"
    className={classnames("btn", "font-weight-bold", "btn-secondary")}
    {...props}
  >
    <i className="la la-plus"></i>
    Add
  </button>
);

export const RemoveButton = ({ ...props }) => (
  <button
    type="button"
    className={classnames("btn", "font-weight-bold", "btn-brand btn-icon")}
    style={{ position: "relative", top: 37 }}
    {...props}
  >
    <i className="la la-remove"></i>
  </button>
);
