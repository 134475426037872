import React from "react";
import reduce from "lodash/reduce";
import includes from "lodash/includes";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import camelCase from "lodash/camelCase";
import { BOARD_MEMBER, CHAPTER } from "./fieldHelpers";

const AlertBanner = ({
  count,
  setCount,
  totalMemberCount,
  announcementEntity,
  admin,
}) => {
  const { values } = useFormikContext();
  const allChapters = useSelector((state) => state.groups.items);
  const { chapterId } = values;

  if (admin && chapterId) {
    if (chapterId === "all_chapters") {
      setCount(
        reduce(
          allChapters,
          (sum, chapter) =>
            (sum +=
              chapter.attributes.memberCounts &&
              chapter.attributes.memberCounts[camelCase(values.memberType)]),
          0
        )
      );
    } else {
      setCount(
        allChapters[chapterId].attributes.memberCounts &&
          allChapters[chapterId].attributes.memberCounts[
            camelCase(values.memberType)
          ]
      );
    }
  } else {
    setCount(totalMemberCount);
  }

  const renderAlertText = () => {
    let memberText = `${count} `;
    if (announcementEntity === BOARD_MEMBER) {
      memberText += "board ";
    }

    if (count > 1) {
      memberText += "members ";
    } else {
      memberText += "member ";
    }

    return (
      <div className="alert-text" style={{ fontSize: 18 }}>
        This email will be sent to <strong>{memberText}</strong>
        {includes([CHAPTER, BOARD_MEMBER], announcementEntity)
          ? "in your TALK Chapter"
          : "who attended your TALK event"}
      </div>
    );
  };

  if (!count && count !== 0) return null;

  return (
    <div
      className="alert alert-warning fade show"
      role="alert"
      style={{ maxWidth: 602, width: "100%" }}
    >
      <div className="alert-icon">
        <i className="flaticon-warning"></i>
      </div>
      {renderAlertText()}
      <div className="alert-close">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="la la-close"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default AlertBanner;
