import { createSelector } from "reselect";

import find from "lodash/find";
import each from "lodash/each";
import filter from "lodash/filter";

export const getSubscribedGroups = createSelector(
  [(state) => state.settings, (state) => state.groups],
  (settings, groups) => {
    if (!settings.loaded) {
      return [];
    }

    const isSubscribed = {};
    each(settings.relationships.subscribedGroups.data, ({ id }) => {
      isSubscribed[id] = true;
    });

    return filter(groups.items, ({ id }) => isSubscribed[id]);
  }
);

export const getPrimaryGroup = createSelector(
  [getSubscribedGroups],
  (subscribedGroups) => {
    return find(subscribedGroups, ({ attributes }) => !attributes.isNational);
  }
);
