import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import CheckInButton from "./CheckInButton";
import DeleteEventButton from "./DeleteEventButton";
import EmailAttendeesButton from "./EmailAttendeesButton";
import JoinWebinarButton from "./JoinWebinarButton";
import RSVPListButton from "./RSVPListButton";
import StartEventButton from "./StartEventButton";

export default function BoardMemberActions({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const settings = useSelector((state) => state.settings);
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const { creator, groupId } = event.attributes;
  const { admin, boardMemberGroups } = settings.attributes;

  const editable =
    admin || (creator === currentUserId) || boardMemberGroups.includes(groupId);

  if (!editable) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "12px",
        alignItems: "space-between",
        width: "100%",
        maxWidth: 315,
        borderTop: "1px dashed #8c979a",
        margin: "0 auto",
      }}
    >
      <JoinWebinarButton {...{ id, overrideStyle: { marginRight: 12 } }} />
      <CheckInButton {...{ id }} />
      <DeleteEventButton {...{ id }} />
      <RSVPListButton {...{ id }} />
      <StartEventButton {...{ id }} />
      <EmailAttendeesButton {...{ id }} />
    </div>
  );
}
