import { withStyles } from "@material-ui/styles";
import { Editor } from "@tinymce/tinymce-react";
import classnames from "classnames";
import $ from "jquery";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import React from "react";

import {
  addAttachFileButton,
  addHashtagAutocompleter,
  addHashtagButton,
  filePickerCallback,
  imagesUploadHandler,
} from "./util";


const styles = () => ({
  label: {
    fontSize: "1.2857142857142858rem !important",
    lineHeight: "2.2857142857142856rem !important",
    fontWeight: 400,
    color: ({ showError }) => (showError ? "#f45b5e" : "inherit"),
  },
  container: {
    "& > .tox.tox-tinymce": {
      borderColor: ({ showError }) => (showError ? "#f45b5e" : "#ccc"),
    },
  },
});

// note: opted for class component in order to bind custom util functions to component
// context rather than including all their definitions within single functional component closure
class Wysiwyg extends React.Component {
  constructor(props) {
    super(props);

    this.selector = `wysiwyg-${Math.round(Math.random() * 10000)}`;
    this.filePickerCallback = filePickerCallback.bind(this);
    this.imagesUploadHandler = imagesUploadHandler.bind(this);
    this.addAttachFileButton = addAttachFileButton.bind(this);
    this.addHashtagAutocompleter = addHashtagAutocompleter.bind(this);
    this.addHashtagButton = addHashtagButton.bind(this);

    // only read from on initial tinymce render, so built and stored just once
    this.initProperties = this.buildInitProps();
  }

  parseValue = () => {
    const parsedContainer = $("<div>").html(this.props.value);

    // tinymce exposes too much user customization for elements with href attribute,
    // disable this for hashtag links
    parsedContainer.find("a.hashtag_link").removeAttr("href");

    return parsedContainer.html();
  };

  buildInitProps = () => {
    const fileAttachable = Boolean(this.props.uploadFolder);

    const initProperties = {
      height: 300,
      branding: false,
      relative_urls: false,
      paste_retain_style_properties: "all",
      remove_script_host: false,
      menubar: false,
      statusbar: false,
      content_style: "p {margin: 0; padding: 0;}",
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media paste",
      ],
      contextmenu: "",
      toolbar:
        "insert-hashtag | formatselect | bold italic underline strikethrough backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | image insertfile media link attach-file",
    };

    if (!fileAttachable) {
      initProperties.toolbar = initProperties.toolbar.replace(
        /\s(image|insertfile|media|attach-file)/g,
        ""
      );
    }

    if (!this.props.hashtaggable) {
      initProperties.toolbar = initProperties.toolbar.replace(
        /\s(insert-hashtag)/g,
        ""
      );
    }

    fileAttachable &&
      merge(initProperties, {
        file_picker_types: "file image",
        file_browser_callback_types: "file image",
        file_picker_callback: this.filePickerCallback,
        images_upload_handler: this.imagesUploadHandler,
      });

    initProperties.setup = (editor) => {
      fileAttachable && this.addAttachFileButton(editor);

      this.props.hashtaggable && this.addHashtagAutocompleter(editor);
      this.props.hashtaggable && this.addHashtagButton(editor);
    };

    return merge(initProperties, this.props.customInitProps);
  };

  render() {
    const {
      classes,
      style = {},
      onChange,
      onBlur,
      label,
      helperText,
    } = this.props;

    return (
      <div
        className={classnames("form-group", classes.container)}
        style={style.container || {}}
      >
        {label && (
          <div>
            <label className={classnames(classes.label)}>{label}</label>
          </div>
        )}
        <Editor
          id={this.selector}
          value={this.parseValue()}
          onEditorChange={onChange}
          onBlur={onBlur}
          style={{ borderColor: "#f45b5e" }}
          init={this.initProperties}
          apiKey="rsuxk8lugk5p32d6ag8akuaf5narxncb4kcrg4vqkhp0thhv" // we should probably store this server side
        />
        <div style={{ fontSize: 13, display: "flex" }}>{helperText}</div>
      </div>
    );
  }
}

Wysiwyg.defaultProps = {
  label: "",
  helperText: "",
  hashtaggable: false,
  showError: false,
};

Wysiwyg.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  hashtaggable: PropTypes.bool,
  uploadFolder: PropTypes.string,
  showErrow: PropTypes.bool,
};

export default withStyles(styles)(Wysiwyg);
