import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Col, useScreenClass } from "react-grid-system";

import PopoverLink from "../../../General/PopoverLink";
import ChatboxCTA from "./ChatboxCTA";

import { styles } from "./utils";
import {
  DIRECTORY_CHAT,
  DIRECTORY_LINKEDIN,
} from "../../../../util/fathomEventCodes";

export default function CTAColumn({ id }) {
  const profile = useSelector(
    (state) => state.profiles.items[id],
    shallowEqual
  );
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = styles();

  const { userId, firstName, lastName, linkedinUrl } = profile.attributes;

  return (
    <Col
      xs={12}
      className={classes.ctaColumn}
      style={{
        maxWidth: isDesktop ? "140px" : "100%",
        width: isDesktop ? "140px" : "100%",
        flex: isDesktop ? "0 0 140px" : "0 0 100%",
        justifyContent: isDesktop ? "flex-end" : "center",
      }}
    >
      {currentUserId !== userId && (
        <PopoverLink
          cardStyle={{ marginTop: 10, marginLeft: 0 }}
          renderLink={(handleClick, _isSelected) => (
            <button
              style={{ display: "block", float: "right" }}
              className="btn btn-sm btn-outline-brand-secondary"
              onClick={(...args) => {
                window.fathom && window.fathom.trackGoal(DIRECTORY_CHAT, 0);
                handleClick(...args);
              }}
            >
              Chat
            </button>
          )}
          renderPopoverContent={() => <ChatboxCTA {...{ userId, firstName }} />}
        />
      )}
      {linkedinUrl ? (
        <a
          href={linkedinUrl}
          target="_blank"
          rel="noopener noreferrer"
          alt={firstName + " " + lastName + " LinkedIn Profile"}
          className={classes.linkedinIcon}
          onClick={() => {
            window.fathom && window.fathom.trackGoal(DIRECTORY_LINKEDIN, 0);
          }}
        >
          <i className="fab fa-linkedin" />
        </a>
      ) : (
        <div style={{ marginLeft: 46.13 }}></div>
      )}
    </Col>
  );
}
