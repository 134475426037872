export const TALK_SETTINGS = "talk_settings";

// constants to match Notification key
export const EVENT_APPROVED = "event.approved";
export const POLL_CREATE = "poll.create";
export const TOPIC_CREATE = "topic.create";
export const COMMENT_LIKE = "comment.like";
export const REPLY_COMMENT_CREATE = "reply.comment.create";
export const EVENT_COMMENT_CREATE = "event.comment.create";
export const FORUM_COMMENT_CREATE = "forum.comment.create";

export const GROUPLESS = "groupless";
