import merge from "lodash/merge";
import React from "react";

const defaultStyles = {
  container: {},
  label: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    fontWeight: 400,
  },
  input: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    "&:focus": {
      borderColor: "#357BA6 !important",
      boxShadow: "0 0 0 1px #357BA6 !important",
    },
  },
};

const TextField = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  showError,
  helperText,
  styles = {},
  placeholder,
  inputProps,
}) => {
  const mergedStyles = merge({}, defaultStyles, styles);

  return (
    <div className="form-group" style={mergedStyles.container}>
      {label && (
        <React.Fragment>
          <label
            htmlFor={id}
            style={Object.assign(
              mergedStyles.label,
              showError ? { color: "#f45b5e" } : {}
            )}
          >
            {label}
          </label>
          <br />
        </React.Fragment>
      )}
      <input
        id={id}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="form-control"
        style={Object.assign(
          mergedStyles.input,
          showError ? { borderColor: "#f45b5e" } : {}
        )}
        {...inputProps}
      />
      {helperText}
    </div>
  );
};
export default TextField;
