import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import map from "lodash/map";
import includes from "lodash/includes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  labelPlacementStart: {
    display: "flex",
    justifyContent: "space-between",
  },
  formGroup: {
    marginLeft: 24,
  }
}));

const MultiToggle = ({
  id,
  label,
  checkedValues,
  options,
  onChange,
  showError,
  helperText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl
        required
        component="fieldset"
        className={classes.formControl}
      >
        <label htmlFor={id} style={showError ? { color: "red" } : {}}>
          {label}
        </label>
        <FormGroup className={classes.formGroup}>
          {map(options, (option, idx) => (
            <FormControlLabel
              key={idx}
              labelPlacement="end"
              classes={{
                labelPlacementStart: classes.labelPlacementStart,
              }}
              control={
                <Switch
                  checked={includes(checkedValues, option.value)}
                  onChange={onChange}
                  name={option.value || ""}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        <div style={{ fontSize: 13, display: "flex" }}>{helperText}</div>{" "}
      </FormControl>
    </div>
  );
};

export default MultiToggle;
