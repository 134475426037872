import React from "react";
import { Row, Col, useScreenClass, Visible } from "react-grid-system";

import Image from "./skeleton/Image";
import Details from "./skeleton/Details";
import Actions from "./skeleton/Actions";

export default function Item() {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);

  return (
    <Row
      className="row"
      style={{
        width: "100%",
        marginBottom: 20,
        backgroundColor: "#fff",
        boxShadow: "0px 0px 13px 0px rgba(82, 82, 82, 0.2)",
        marginLeft: 0,
      }}
    >
      <Image />
      <Visible xs lg xl xxl>
        <Col
          xs={6}
          style={{
            minHeight: 160,
            padding: 0,
            maxWidth: isDesktop ? "calc(100% - 649px)" : "100%",
            width: "100%",
            flex: isDesktop ? "0 0 calc(100% - 649px)" : "0 0 100%",
          }}
        >
          <Details />
        </Col>
      </Visible>

      <Actions />

      <Visible sm md>
        <Col style={{ minHeight: 160, padding: 0, width: "100%" }} xs={12}>
          <Details />
        </Col>
      </Visible>
    </Row>
  );
}
