import moment from "moment";
import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";

import { AvatarIcon } from "../../General/AvatarIcon";
import ProfileHover from "../../Shared/ProfileHover/ProfileHover";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    width: "calc(100% - 30px)",
    minHeight: 50,
  },
  description: {
    marginLeft: 12,
    marginRight: 12,
    width: "100%",
  },
  name: {
    color: "#48465B",
    overflow: "hidden",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 140px)",
  },
  time: {
    color: "#212121",
    fontSize: "14px",
    fontWeight: "400",
    color: "#48465B",
    fontFamily: "Poppins",
  },
  header: {
    display: "flex",
    fontSize: "14px",
    color: "#48465B",
    fontWeight: "400",
    alignItems: "baseline",
  },
  subheader: {
    color: "#595d6e",
    overflow: "hidden",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: "400",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    maxWidth: "calc(100% - 280px)",
    position: "absolute",
  },
});

const UserInfo = (props) => {
  const { userId, createdAt, vendorProduct } = props.attributes;
  const classes = useStyles();
  const user = useSelector((state) => state.users.items[userId]);

  const { jobTitle, companyName, firstName, lastName } = user.attributes;

  return (
    <div className={classes.container}>
      {props.displayAvatar && (
        vendorProduct ? (
          <AvatarIcon height={50}
          user={{ attributes: { avatarUrl: vendorProduct.logoUrl, firstName: vendorProduct.name, lastName: "" } }} />
        ) : (
          <ProfileHover {...{ userId }}>
            <AvatarIcon user={user} height={50} />
          </ProfileHover>
        )
      )}
      <div
        className={classes.description}
        style={{
          marginLeft: props.displayAvatar ? 12 : 0,
          maxWidth: "100%",
        }}
      >
        <div className={classes.header}>
          <div className={classes.name}>
            {vendorProduct ? (
              vendorProduct.name
            ) : (
              `${firstName} ${lastName}`
            )}
          </div>
          {props.type === "comment" && (
            <div className={classes.time}>
              <i
                className="la la-clock-o"
                style={{ marginLeft: 6, marginRight: 2 }}
              ></i>
              {moment(createdAt).fromNow()}
            </div>
          )}
        </div>
        <div className={classes.subheader}>
          {vendorProduct ? (
            `Vendor Post`
          ) : (
            `${jobTitle || ""} - ${companyName}`
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
