import React, { useCallback } from "react";
import includes from "lodash/includes";

import Accordion from "../Accordion";
import CheckboxRow from "./CheckboxRow";
import useSharedStyles from "../useSharedStyles";
import { EVENT_APPROVED, POLL_CREATE, TOPIC_CREATE } from "../constants";

function ChapterSection({ updateSettings, settings = {}, groupName, groupId }) {
  const sharedClasses = useSharedStyles();
  const updatedSettings = settings.toUpdate;

  const handleChange = useCallback((e) => {
    updateSettings(e.target.id);
  });

  return (
    <Accordion
      summaryOverride={sharedClasses.summary}
      summary={<div>New {groupName} Activity</div>}
    >
      <CheckboxRow
        id={`${groupId}_${EVENT_APPROVED}`}
        onChange={handleChange}
        checked={includes(updatedSettings, EVENT_APPROVED)}
        rowStyle="chapterSectionRow"
        title="New Event"
      />
      <CheckboxRow
        id={`${groupId}_${TOPIC_CREATE}`}
        onChange={handleChange}
        checked={includes(updatedSettings, TOPIC_CREATE)}
        rowStyle="chapterSectionRow"
        title="New Topic"
      />
      <CheckboxRow
        id={`${groupId}_${POLL_CREATE}`}
        onChange={handleChange}
        checked={includes(updatedSettings, POLL_CREATE)}
        rowStyle="chapterSectionRow"
        title="New Poll"
      />
    </Accordion>
  );
}

export default ChapterSection;
