import { POST } from "../util/apiHelpers";
import normalize from "json-api-normalizer";
import { mapKeysToSnakeCase } from "../util/formatHelpers";

export const createReview = (data) => () => {
  return POST("/api/v1/vendor_product_reviews", mapKeysToSnakeCase(data)).then(
    (res) => {
      const normalized = normalize(res.data);
      return Object.values(normalized.vendorProductReview)[0];
    }
  );
};
