import React from "react";
import { Icon } from "@material-ui/core";

export default function ColorfulCityIcon(props) {
  return (
    <Icon {...props}>
      <img
        style={{ margin: "8px", width: "25px", display: "inline" }}
        alt="ColorfulCityIcon"
        src="/assets/media/TAD/city.svg"
      />
    </Icon>
  );
}
