import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 104,
    width: 102,
    marginRight: 24,
    marginBottom: 12,
  },
  logo: {
    maxHeight: 116,
    minWidth: 102, 
    objectFit: "contain",
  },
  shrink: {
    "@media only screen and (max-width: 950px)": {
      minWidth: 75,
      maxHeight: 75,
      maxWidth: 75,
    },
  },
});

const ProductLogo = ({ logoUrl, shrink }) => {
  const classes = useStyles();
  return (
    <div className={classes.logoContainer}>
      <img
        className={`${classes.logo} ${shrink ? classes.shrink : ""}`}
        src={logoUrl}
      />
    </div>
  );
};

export default ProductLogo;
