import { GET, POST } from "../util/apiHelpers";
import normalize from "json-api-normalizer";

export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_USER = "RECEIVE_USER";

export const receiveUsers = (users) => ({
  type: RECEIVE_USERS,
  payload: users,
});

const receiveUser = (user) => ({
  type: RECEIVE_USER,
  payload: user,
});

export const fetchUsers = () => (dispatch) => {
  return GET("/api/v1/users").then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveUsers(normalized.user));
  });
};

export const fetchUser = (id) => (dispatch) => {
  return GET(`/api/v1/users/${id}`).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveUser(normalized));
  });
};


export const fetchBoardMembersAndAdmins = (chapterId) => (dispatch) => {
  return GET(`/api/v1/user/board_members_and_admins/${chapterId}`).then(
    (res) => {
      const normalized = normalize(res.data);

      return dispatch(receiveUsers(normalized.user));
    }
  );
};

export const fetchBoardMembers = (chapterId) => (dispatch) => {
  return GET(`/api/v1/user/board_members/${chapterId}`).then(
    (res) => {
      const normalized = normalize(res.data);

      return dispatch(receiveUsers(normalized.user));
    }
  );
};

export const createUserInvitation = (data) => {
  return POST("/api/v1/user_invitations", data);
}
