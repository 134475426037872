import React from "react";
import { useSelector } from "react-redux";

const OtherVendorLink = ({ relationship, index, length, pageUrl, name, newTab }) => {
  if (relationship) {
    const vendorProduct = useSelector(
      (state) => state.vendorProducts.items[relationship.id]
    );
    pageUrl = vendorProduct.attributes.pageUrl;
    name = vendorProduct.attributes.name;
  }

  let link = newTab ? (
    <a target="_blank" rel="noopener noreferrer" href={pageUrl}>
      {name}
    </a>
  ) : (
    <a href={pageUrl}>{name}</a>
  );
  let prefix, suffix;

  if (length > 1) {
    if (index === 0 && length > 2) {
      suffix = ", ";
    } else if (index === length - 1) {
      prefix = "and ";
    } else if (index > 0) {
      suffix = ", ";
    }
  }

  return (
    <React.Fragment>
      {prefix}
      {link}
      {suffix || " "}
    </React.Fragment>
  );
};

export default OtherVendorLink;
