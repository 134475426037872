import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { clearModal } from "../../../actions/uiActions";

const useStyles = makeStyles(() => ({
  paper: {
    width: "780px",
    maxWidth: "780px",
    margin: "0",
  },
}));

const ActionModalContainer = ({ color, open = true, children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = !useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();

  const handleClose = useCallback(() => dispatch(clearModal()), [dispatch]);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      onClose={handleClose}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { color, handleClose })
      )}
    </Dialog>
  );
};

export default ActionModalContainer;
