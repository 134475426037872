import map from "lodash/map";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";

import { PENDING, makeGetFilteredEvents } from "../../selectors/eventSelectors";

import EventItem from "./EventItem";

const useStyles = createUseStyles({
  expandButton: {
    "&:hover": {
      borderRadius: "0 0 5px 5px",
      backgroundColor: "#EAEEF3",
    },
  }
});

const NewEventsCard = ({ events }) => {
  const [expanded, setExpanded] = useState(false);

  const sortedEvents = sortBy(events, ({ attributes }) =>
    moment(attributes.scheduledAt).diff(moment())
  );
  const displayCount = expanded ? events.length : 2;
  const classes = useStyles();

  return sortedEvents.length > 0 ? (
    <div
      className="kt-portlet kt-portlet--height-fluid"
      style={{ height: "auto" }}
    >
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div
          style={{
            color: "#595d6e",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 500,
            letterSpacing: "0.07px",
            lineHeight: "24px",
            padding: "20px",
          }}
        >
          New Events
        </div>
        {map(sortedEvents.slice(0, displayCount), (event) => (
          <EventItem
            event={event}
            showInviteActions={true}
            key={event.id}
          />
        ))}
      </div>
      {!expanded && events.length > displayCount && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid rgba(34,34,34,0.2)",
            height: "50px",
            color: "#595d6e",
            fontFamily: "Poppins",
            fontSize: "13px",
            letterSpacing: "0.07;px",
            lineHeight: "24px",
            cursor: "pointer",
          }}
          className={`${classes.expandButton} event-item`}
          onClick={() => setExpanded(true)}
        >
          See More
        </div>
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

const makeMapStateToProps = () => {
  const getFilteredEvents = makeGetFilteredEvents();

  const mapStateToProps = (state) => {
    return {
      events: getFilteredEvents(state, { invitationStatus: PENDING }),
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(NewEventsCard);
