import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from 'react-redux';

import { fetchPolls } from '../../actions/pollActions';

import PollResultsContent from './PollsCard/PollContent/PollResultsContent';

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(34,34,34,0.2)",
    backgroundColor: "white",
  },  
  loadingContainer: {
    width: "100%",
    height: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
});

const VendorPolls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const { items, loaded } = useSelector(state => state.polls);

  useEffect(() => {
    if (!loaded) {
      setFetching(true);
      dispatch(fetchPolls()).finally(() => setFetching(false));
    }
  }, [dispatch, loaded]);

  // disable if poll created this month
  const disabled = loaded || Object.values(items).some(poll => moment(poll.attributes.createdAt).isSame(new Date(), 'month'));

  return (
    <>
      <div
        className={`kt-portlet kt-portlet__body ${classes.header}`}
        style={{ borderBottom: 0, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}
      >
        Your Polls
        <button className='btn btn-sm btn-brand-secondary kt-subheader__btn-options'
          onClick={() => window.location.href = '/polls/new'}
          style={{ width: 100 }}
          disabled={fetching || disabled}
        >Create Poll</button>
      </div>
      {fetching && <div className='kt-portlet'>
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      </div>}
      {(Object.values(items)).map((poll, index) => (
        <div className='kt-portlet' key={poll.id}>
          <div style={{ padding: 12 }}>
            <PollResultsContent poll={poll} />
          </div>
        </div>
      ))}
    </>
  )
};

export default VendorPolls;
