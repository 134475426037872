import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";
import { useSelector } from "react-redux";

import { getConversationParticipant } from "../../../selectors/conversationSelectors";
import { getUser } from "../../../selectors/userSelectors";
import { buildFullName } from "../../../util/formatHelpers";

const useStyles = makeStyles(() => ({
  userName: {
    fontWeight: 400,
  },
}));

function AddedUserText({ event }) {
  const classes = useStyles();

  const { actorId, subjectId: participantId } = event.attributes;

  const currentUserId = useSelector((state) => state.settings.attributes.id);
  const participant = useSelector((state) => getConversationParticipant(state, participantId));
  const addedUser = useSelector((state) => getUser(state, { id: participant.attributes.userId }));

  const actor = useSelector((state) => getUser(state, { id: actorId }));

  if (actor.id === addedUser.id) {
    return (
      <>
        <span className={classes.userName}>
          {buildFullName(
            addedUser.attributes.firstName,
            addedUser.attributes.lastName
          )}
        </span>
        {" has joined the chat"}
      </>
    );
  }

  const senderName = (actor.id === currentUserId)
    ? "You"
    : buildFullName(actor.attributes.firstName, actor.attributes.lastName);

  const addedName = (addedUser.id === currentUserId)
    ? "You"
    : buildFullName(
      addedUser.attributes.firstName,
      addedUser.attributes.lastName
    );

  return (
    <>
      <span className={classes.userName}>
        {senderName}
      </span>
      {" added "}
      <span className={classes.userName}>
        {addedName}
      </span>
      {" to the chat"}
    </>
  );
}

export default AddedUserText;
