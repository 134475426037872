import { object, string, bool, ref } from "../../util/yup";

export const initialValues = {
  chapterId: "",
  otherChapterInput: "",
  firstNameInput: "",
  lastNameInput: "",
  workEmailInput: "",
  personalEmailInput: "",
  companyNameInput: "",
  jobTitleInput: "",
  linkedInUrlInput: "",
  leadershipInterest: "",
  agreesToTerms: false,
};

export const buildValidationSchema = (nationalId) => {
  return object().shape({
    chapterId: string().required("Cannot be blank"),
    firstNameInput: string().nullable().required("Cannot be blank"),
    lastNameInput: string().nullable().required("Cannot be blank"),
    workEmailInput: string()
      .nullable()
      .required("Cannot be blank")
      .validEmail(),
    personalEmailInput: string()
      .required("Cannot be blank")
      .validEmail()
      .notOneOf([ref("workEmailInput")], "Cannot be same as work email"),
    companyNameInput: string().required("Cannot be blank"),
    jobTitleInput: string().required("Cannot be blank"),
    linkedInUrlInput: string()
      .nullable()
      .validLinkedinUrl()
      .required("Cannot be blank"),
    leadershipInterest: bool()
      .required("Cannot be blank")
      .oneOf([true, false], "Cannot be blank"),
    agreesToTerms: bool()
      .required("You must agree to the terms to join TALK")
      .oneOf([true], "You must agree to the terms to join TALK"),
    otherChapterInput: string()
      .nullable()
      .when("chapterId", {
        is: nationalId,
        then: string().required("Cannot be blank"),
      }),
  });
};
