import React from "react";
import classnames from "classnames";
import { Row } from "react-grid-system";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 12,
    marginTop: 12,
    width: 120,
  },
  chatButton: {
    borderRadius: 20,
    height: 40,
    width: "100%",
    fontSize: "1.075rem"
  },
  boardMemberStar: {
    color: "#357BA6",
  },
}));

const Chat = ({ user, expanded, setExpanded }) => {
  const classes = useStyles();

  const {
    attributes: { boardMember },
  } = user;

  return (
    <React.Fragment>
      <Row nogutter style={{ justifyContent: "center" }}>
        <div className={classes.chatContainer}>
          <button
            disabled={expanded}
            onClick={() => {
              setExpanded(true);
            }}
            className={classnames(
              "btn",
              "btn-sm",
              "btn-secondary",
              classes.chatButton
            )}
          >
            Chat
          </button>
        </div>
      </Row>
      {boardMember && !expanded && (
        <Row nogutter style={{ alignItems: "center" }}>
          <i className={classnames("la la-star", classes.boardMemberStar)}></i>{" "}
          Board Member
        </Row>
      )}
    </React.Fragment>
  );
};

export default Chat;
