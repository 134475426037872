import React, { useEffect } from "react";

import { Row, Col, Visible } from "react-grid-system";
import { useDispatch } from "react-redux";


import { fetchEvents } from "../../actions/eventActions";
import SideCards from "../General/SideCards";
import withGlobalProviders from "../General/withGlobalProviders";

import AttendingEventsCard from "./AttendingEventsCard";
import NewEventsCard from "./NewEventsCard";
import PollsCard from "./PollsCard/PollsCard";
import RecentActivity from "./RecentActivity";

function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  return (
    <Row>
      <Visible lg xl xxl>
        <Col lg={7.5} style={{ paddingRight: 0 }}>
          <RecentActivity />
        </Col>
      </Visible>
      <Col md={12} lg={4.5} style={{ paddingLeft: 0 }}>
        <SideCards>
          <NewEventsCard />
          <PollsCard />
          <AttendingEventsCard />
        </SideCards>
      </Col>
      <Visible xs sm md>
        <Col>
          <RecentActivity />
        </Col>
      </Visible>
    </Row>
  );
};

export default withGlobalProviders(Index);
