import React from "react";

import { ConnectedSelect } from "../../../General/FormInputs/ConnectedInputs";
import {
  lastUseOptions,
  useDurationOptions,
  reviewerRolesOptions,
} from "./fieldHelpers";

const LastUseField = () => (
  <ConnectedSelect
    id="lastUse"
    label="When did you last use this vendor?"
    options={lastUseOptions}
  />
);

const UseDurationField = () => (
  <ConnectedSelect
    id="useDuration"
    label="How long have you used this vendor?"
    options={useDurationOptions}
  />
);

const ReviewerRolesField = () => (
  <ConnectedSelect
    id="reviewerRoles"
    label="What is your role using this vendor?"
    isMulti={true}
    options={reviewerRolesOptions}
  />
);

const VendorProductReviewsProductsField = ({
  vendorProductReviewsProductsOptions,
}) => (
  <ConnectedSelect
    id="vendorProductReviewsProducts"
    label="Have you used any other vendors in this category? (optional, select one or more)"
    isMulti={true}
    options={vendorProductReviewsProductsOptions}
  />
);

const BackgroundInfo = ({ otherProductsInCategory }) => (
  <div style={{ padding: 24 }}>
    <LastUseField />
    <UseDurationField />
    <ReviewerRolesField />
    <VendorProductReviewsProductsField
      vendorProductReviewsProductsOptions={otherProductsInCategory}
    />
  </div>
);

export default BackgroundInfo;
