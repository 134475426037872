import React, { useMemo } from "react";

import { buildFullName } from "../../../util/formatHelpers";
import { useConversationUsers } from "./hooks";
import map from "lodash/map";

const ParticipantsLabel = ({ conversation }) => {
  const users = useConversationUsers(conversation);

  const participantString = useMemo(() => {
    const otherUsersList = map(users, ({ attributes }) =>
      buildFullName(attributes.firstName, attributes.lastName)
    ).join(", ");

    return users.length > 1 ? `${otherUsersList} and you` : otherUsersList;
  }, [users]);

  return participantString;
};

const ConversationLabel = ({ conversation }) =>
  conversation.attributes.name || (
    <ParticipantsLabel conversation={conversation} />
  );

export default ConversationLabel;
