import React from "react";

const LinkedinButton = ({ onClick }) => {
  return (
    <div style={{ marginTop: 30 }}>
      <button
        id="sign-up-link"
        className="btn btn-linkedin"
        style={{
          width: "100%",
          fontSize: "1.5rem",
          padding: "11.8px",
          textAlign: "left",
          display: "flex",
        }}
        type="submit"
        onClick={onClick}
      >
        <i className="socicon-linkedin"></i>
        <div
          style={{
            width: "calc(100% - 51.1px)",
            textAlign: "center",
            textTransform: "none",
          }}
        >
          Submit and Verify with LinkedIn
        </div>
      </button>
    </div>
  );
};

export default LinkedinButton;
