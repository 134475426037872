export const ASC_NAME = "asc_name";
export const ASC_COMPANY = "asc_company";
export const ASC_TITLE = "asc_title";
export const DEFAULT = "default";

export const PROFILES_SORT_OPTIONS = [
  {
    value: DEFAULT,
    label: "TALK Sort",
    defaultValue: true,
  },
  {
    value: ASC_NAME,
    label: "Last Name",
  },
  {
    value: ASC_COMPANY,
    label: "Company"
  },
  {
    value: ASC_TITLE,
    label: "Job Title"
  }
];

export const defaultValue = PROFILES_SORT_OPTIONS.find(e => e.defaultValue);
