import React from "react";

const Footer = () => {
  return (
    <tr>
      <td
        style={{
          color: "#B0BEC5",
          fontFamily: "proxima-nova, helvetica",
          fontSize: 12,
          fontWeight: 400,
          letterSpacing: 0.4,
          lineHeight: "12px",
          padding: "48px 30px 24px 30px",
        }}
      >
        <p>
          <span>&copy;</span>
          {new Date().getFullYear()} TALK Inc
        </p>
      </td>
    </tr>
  );
};
export default Footer;
