import React from "react";

import SkeletonItem from "./SkeletonItem";

export default function Skeletons() {
  return (
    <React.Fragment>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </React.Fragment>
  );
}
