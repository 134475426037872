import React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

import {
  initialValues,
  buildValidationSchema,
  determineUniqueEmails,
} from "./fieldHelpers";
import { createUserInvitation } from "../../../actions/userActions";
import { CancelButton, SubmitButton, AddEntryButton } from "./buttons";
import { openSnackbar } from "../../../actions/uiActions";
import InviteEntry from "./InviteEntry";

import { mapKeysToSnakeCase } from "../../../util/formatHelpers";
import { confettiSpray } from "../../../util/animationHelpers";
import each from "lodash/each";
import keys from "lodash/keys";
import { useScreenClass } from "react-grid-system";

const useStyles = makeStyles(() => ({
  dialogTitleRoot: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  dialogActionsRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
  container: {
    padding: "10px 0px",
  },
  description: {
    fontSize: 16,
    color: "#292E33",
    marginBottom: 20,
  },
}));

const InviteTeammateModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm, setFieldError, setSubmitting }) => {
        const uniqueEmails = determineUniqueEmails(values.userInvitations);

        if (!uniqueEmails) {
          setSubmitting(false);

          return setFieldError(
            "footerErrorMessage",
            "There are duplicate emails."
          );
        }

        const data = mapKeysToSnakeCase(values);

        createUserInvitation(data).then((res) => {
          const { errors } = res.data;
          if (keys(errors).length === 0) {
            dispatch(
              openSnackbar("Thank you for inviting new members to TALK!")
            );
            setOpen(false);
            resetForm({});
            confettiSpray();
          } else {
            each(values.userInvitations, (invitation, idx) => {
              if (errors[invitation.email]) {
                if (errors[invitation.email].email) {
                  setFieldError(`userInvitations.${idx}`, {
                    customError: errors[invitation.email].email,
                  });
                } else {
                  setFieldError(`userInvitations.${idx}`, {
                    customError: "Error processing your request.",
                  });
                }
                setSubmitting(false);
              }
            });
          }
        });
      }}
      validationSchema={buildValidationSchema()}
      render={({ values, errors, handleSubmit, resetForm, isSubmitting }) => (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            resetForm();
          }}
          maxWidth="xl"
        >
          <DialogTitle
            id="invite-teammate-modal-title"
            classes={{ root: classes.dialogTitleRoot }}
            disableTypography={true}
          >
            Invite New Members
          </DialogTitle>
          <DialogContent>
            <div className={classes.container} style={{ minWidth: isDesktop ? 900 : "none" }}>
              <div className={classes.description}>
                Invite other senior TA Professionals to join TALK!
              </div>
              <Form>
                <FieldArray
                  name="userInvitations"
                  render={(arrayHelpers) => (
                    <div>
                      {values.userInvitations.map((_, idx) => (
                        <InviteEntry
                          idx={idx}
                          key={idx}
                          listType="userInvitations"
                          removeEntry={() => arrayHelpers.remove(idx)}
                        />
                      ))}
                      <div style={{ marginTop: 10 }}>
                        <AddEntryButton onClick={() => arrayHelpers.push("")} />
                      </div>
                    </div>
                  )}
                />
              </Form>
            </div>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActionsRoot }}>
            {errors.footerErrorMessage && (
              <div
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                  color: "#f45b5e",
                }}
              >
                {errors.footerErrorMessage}
              </div>
            )}
            <CancelButton
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
              disabled={isSubmitting}
            />
            <SubmitButton onClick={handleSubmit} disabled={isSubmitting} />
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default InviteTeammateModal;
