import React from "react";

export default function EmptyState() {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "12px 0 20px 0px",
        fontSize: 14,
      }}
    >
      There are no results matching your search.
    </div>
  );
}
