import { Formik } from "formik";
import React from "react";

import { useDispatch } from "react-redux";

import { createPoll } from "../../actions/pollActions";
import FormPageLayout from "../General/FormPage/FormPageLayout";
import withGlobalProviders from "../General/withGlobalProviders";


import {
  initialValues,
  validationSchema,
} from "./create/fieldHelpers";
import Footer from "./create/Footer";
import FormFields from "./create/FormFields";
import Header from "./create/Header";

const CreatePoll = ({ disabled = false }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values, context) =>
    dispatch(createPoll(values))
      .then((createdPoll) => {
        window.location.href = createdPoll.attributes.urlPath;
      })
      .catch(() => {
        context.setSubmitting(false);
      });

  return (
    <FormPageLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <React.Fragment>
            <Header />
            <FormFields />
            <Footer disabled={disabled} />
          </React.Fragment>
        )}
      </Formik>
    </FormPageLayout>
  );
};

export default withGlobalProviders(CreatePoll);
