import React from "react";

import Header from "./partials/Header";
import Question from "../../../Polls/shared/PollContent/Question";
import Footer from "../../../Polls/shared/PollContent/Footer";
import SubmitResponseForm from "../../../Polls/shared/PollContent/SubmitResponseForm/SubmitResponseForm";
import Choices from "../../../Polls/shared/PollContent/SubmitResponseForm/Choices";
import SubmitButton from "../../../Polls/shared/PollContent/SubmitResponseForm/SubmitButton";
import { POLL_ANSWER_NEWSFEED } from "../../../../util/fathomEventCodes";

const PollResponseForm = ({ poll }) => {
  const { multiSelect } = poll.attributes;

  return (
    <SubmitResponseForm poll={poll}>
      <Header poll={poll} />
      <div style={{ paddingLeft: 12 }}>
        <Question poll={poll} />
        <Choices poll={poll} fathomCode={POLL_ANSWER_NEWSFEED} />
        <Footer
          poll={poll}
          action={
            multiSelect && <SubmitButton fathomCode={POLL_ANSWER_NEWSFEED} />
          }
        />
      </div>
    </SubmitResponseForm>
  );
};

export default PollResponseForm;
