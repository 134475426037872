import React from "react";
import { Row, Col } from "react-grid-system";
import { Provider } from "react-redux";

import configureStore from "../../configureStore";

import Form from "./SuggestProduct/Form";

const store = configureStore();

const SuggestProduct = ({ productCategories }) => {
  return (
    <Provider store={store}>
      <Row justify="center">
        <Col md={12} lg={8} style={{ paddingRight: 0 }}>
          <Form {...{ productCategories }} />
        </Col>
      </Row>
    </Provider>
  );
};

export default SuggestProduct;
