import React from "react";

const FormCard = ({ children, overrideStyles }) => (
  <div className="col-sm-12 col-md-12 col-lg-6">
    <div className="kt-portlet" id="kt_wizard_v2-notifications">
      <div
        className="kt-portlet__body kt-portlet__body--fluid"
        style={Object.assign(
          {},
          { paddingTop: 0, display: "flex", flexDirection: "column" },
          overrideStyles
        )}
      >
        {children}
      </div>
    </div>
  </div>
);

export default FormCard;
