/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-body-style */
import normalize from "json-api-normalizer";
import merge from "lodash/merge";
import queryString from "query-string";
import { batch } from "react-redux";

import {
  GET, PATCH, POST
} from "../util/apiHelpers";
import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";

import { receiveUsers } from "./userActions";

export const RECEIVE_CONVERSATIONS = "RECEIVE_CONVERSATIONS";
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";
export const RECEIVE_MESSAGES = "RECEIVE_MESSAGES";
export const RECEIVE_CONVERSATION_ATTACHMENTS =
  "RECEIVE_CONVERSATION_ATTACHMENTS";
export const RECEIVE_UNREAD_EVENTS_COUNT = "RECEIVE_UNREAD_EVENTS_COUNT";
export const RECEIVE_CONVERSATION_PARTICIPANTS =
  "RECEIVE_CONVERSATION_PARTICIPANTS";
export const RECEIVE_CONVERSATION_EVENTS = "RECEIVE_CONVERSATION_EVENTS";

const receiveConversations = (conversations) => ({
  type: RECEIVE_CONVERSATIONS,
  payload: conversations,
});

export const removeConversation = (conversationId) => ({
  type: REMOVE_CONVERSATION,
  payload: conversationId,
});

const receiveMessages = (messages) => ({
  type: RECEIVE_MESSAGES,
  payload: messages,
});

const receiveConversationAttachments = (attachments) => ({
  type: RECEIVE_CONVERSATION_ATTACHMENTS,
  payload: attachments,
});

const receiveConversationParticipants = (participants) => ({
  type: RECEIVE_CONVERSATION_PARTICIPANTS,
  payload: participants,
});

const receiveConversationEvents = (events) => ({
  type: RECEIVE_CONVERSATION_EVENTS,
  payload: events,
});

const receiveUnreadEventsCount = (unreadCount) => ({
  type: RECEIVE_UNREAD_EVENTS_COUNT,
  payload: unreadCount,
});

export const handleConversationResponse = (data, dispatch) => {
  const normalized = normalize(data);

  batch(() => {
    dispatch(receiveUsers(merge({}, normalized.user, normalized.vendorUser)));
    dispatch(
      receiveConversationParticipants(normalized.conversationParticipant)
    );
    dispatch(receiveMessages(normalized.message));
    dispatch(receiveConversationAttachments(normalized.attachment));
    dispatch(receiveUnreadEventsCount(data.meta.unread_events_count));
    dispatch(receiveConversationEvents(normalized.conversationEvent));
    dispatch(receiveConversations(normalized.conversation));
  });

  return normalized.conversation;
};

export const fetchConversations = (params = {}) => (dispatch) =>
  GET(`/api/v1/conversations?${queryString.stringify(mapKeysToSnakeCase(params))}`)
  .then((res) => {
    const conversations = handleConversationResponse(res.data, dispatch);

    return { conversations, metaData: mapKeysToCamelCase(res.data.meta) };
  });

export const markConversationRead = (conversationId) => (dispatch) =>
  PATCH(`/api/v1/conversations/${conversationId}/mark_read`).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveUnreadEventsCount(res.data.meta.unread_events_count));
      dispatch(
        receiveConversationParticipants(normalized.conversationParticipant)
      );
    });
  });

export const createConversation = (data) => (dispatch) =>
  POST("/api/v1/conversations", data).then((res) => {
    return handleConversationResponse(res.data, dispatch);
  });

export const leaveConversation = (conversationId) => (dispatch) =>
  PATCH(`/api/v1/conversations/${conversationId}/leave`).then((res) => {
    batch(() => {
      dispatch(removeConversation(conversationId));
      dispatch(receiveUnreadEventsCount(res.data.meta.unread_events_count));
    });
  });

export const fetchConversationEvents = (conversationId) => (dispatch) =>
  GET(`/api/v1/conversations/${conversationId}/conversation_events`).then(
    (res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(receiveMessages(normalized.message || {}));
        dispatch(receiveConversationAttachments(normalized.attachment || {}));
        dispatch(receiveConversationEvents(normalized.conversationEvent));
      });
    }
  );

export const submitConversationContent = (data) => (dispatch) =>
  POST(
    `/api/v1/conversations/${data.conversation_id}/submit_content`,
    data
  ).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveMessages(normalized.message || {}));
      dispatch(receiveConversationAttachments(normalized.attachment || {}));
      dispatch(receiveConversationEvents(normalized.conversationEvent));
    });
  });

export const renameConversation = (data) => (dispatch) =>
  PATCH(`/api/v1/conversations/${data.conversation_id}/rename`, data).then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receiveConversations(normalized.conversation));
    }
  );

export const addParticipants = (data) => (dispatch) => (
  PATCH(`/api/v1/conversations/${data.conversation_id}/add_participants`, data)
  .then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveConversations(normalized.conversation));
      dispatch(receiveConversationEvents(normalized.conversationEvent));
      dispatch(
        receiveConversationParticipants(normalized.conversationParticipant)
      );
    });
  })
);

export const removeParticipant = (data) => (dispatch) => (
  PATCH(`/api/v1/conversations/${data.conversation_id}/remove_participant`, data)
  .then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveConversations(normalized.conversation));
      dispatch(receiveConversationEvents(normalized.conversationEvent));
      dispatch(
        receiveConversationParticipants(normalized.conversationParticipant)
      );
    });
  })
);
