/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { Col, useScreenClass } from "react-grid-system";
import { css, keyframes, jsx } from "@emotion/core";
import classnames from "classnames";

import { checkDesktop, checkMobile, checkTablet } from "../item/utils";

const STYLES = {
  desktop: {
    padding: 0,
    maxWidth: 285,
    width: 285,
    flex: "0 0 285px",
  },
  tablet: {
    padding: 0,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 50%",
  },
  mobile: {
    padding: 0,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 100%",
  },
};

const defaultBaseColor = "#eee";

const defaultHighlightColor = "#f5f5f5";

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const skeletonStyles = css`
  background-color: ${defaultBaseColor};
  background-image: linear-gradient(
    90deg,
    ${defaultBaseColor},
    ${defaultHighlightColor},
    ${defaultBaseColor}
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
`;

const duration = 1.2;

export default function Image() {
  const screenClass = useScreenClass();

  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (checkDesktop(screenClass)) {
      setStyles(STYLES.desktop);
    } else if (checkTablet(screenClass)) {
      setStyles(STYLES.tablet);
    } else if (checkMobile(screenClass)) {
      setStyles(STYLES.mobile);
    }
  }, [screenClass]);

  return (
    <Col xs={12} sm={6} lg={3} style={styles}>
      <span>
        <div
          css={css`
            ${skeletonStyles}
            animation: ${skeletonKeyframes} ${duration}s ease-in-out infinite
          `}
          className={classnames("react-loading-skeleton", styles.image)}
          style={{ height: "100%", width: "100%", paddingBottom: "56.25%", borderRadius: 0 }}
        ></div>
      </span>
    </Col>
  );
}
