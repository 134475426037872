import React from "react";
import TalkWhiteStrokeLogo from "media/logos/talk-white-stroke.png";
const Header = () => {
  return (
    <tr>
      <td
        align="center"
        style={{
          color: "#ffffff",
          fontSize: 18,
          fontWeight: 60,
          letterSpacing: "0.1e",
          backgroundColor: "#F35B5E",
          height: 70,
        }}
      >
        <img
          src={TalkWhiteStrokeLogo}
          alt="Logo"
          style-={{ display: "bloc", height: 35, border: 0 }}
        />
      </td>
    </tr>
  );
};

export default Header;
