import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  searchContainer: {
    position: "relative",
    display: "inline-block",
    margin: 0,
    marginBottom: 8,
  },
  inputIcon: {
    position: "absolute",
    top: 14,
    left: 12,
  },
  inputClose: {
    position: "absolute",
    top: 14,
    right: 12,
    cursor: "pointer",
  },
}));

export default function OptionSearch({ searchable, searchValue, setSearchValue }) {
  if (!searchable) return null;

  const classes = useStyles();

  return (
    <div
      className={`form-group ${classes.searchContainer}`}
      style={{ width: "100%" }}
    >
      <i className={`fas fa-search ${classes.inputIcon}`} />
      <input
        value={searchValue || ""}
        placeholder="Search"
        onChange={(e) => setSearchValue(e.target.value)}
        className="form-control"
        style={{ paddingLeft: 36 }}
      />
      {searchValue && searchValue.length > 0 && (
        <i
          onClick={() => setSearchValue("")}
          className={`fas fa-times ${classes.inputClose}`}
        />
      )}
    </div>
  );
}
