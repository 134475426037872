import React from "react";

import Header from "./shared/Header";
import { ViewAllButton } from "./shared/HeaderActions";

import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 20,
  },
  graphicContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginRight: 15,
    marginBottom: 6,
    "& > div": {
      width: 12,
      borderWidth: 3,
      borderStyle: "solid",
    },
  },
  leftBar: {
    height: 26,
    borderColor: "#F7E586",
  },
  middleBar: {
    height: 16,
    borderColor: "#A186F7",
  },
  rightBar: {
    height: 36,
    borderColor: "#86F794",
  },
  textContainer: {
    fontSize: 18,
    color: "#595d6e",
  },
});

const PollsGraphic = () => {
  const classes = useStyles();

  return (
    <div className={classes.graphicContainer}>
      <div className={classes.leftBar} />
      <div className={classes.middleBar} />
      <div className={classes.rightBar} />
    </div>
  );
};

const CreatePollLink = () => {
  const handleClick = () => {
    window.location.href = "/polls/new";
  };

  return (
    <button
      className={classnames("btn", "btn-sm", "btn-outline-brand-secondary")}
      onClick={handleClick}
    >
      Create Poll
    </button>
  );
};

const EmptyContent = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Header renderActions={() => <ViewAllButton />} />
      <div className={classes.container}>
        <div className={classes.middleSection}>
          <PollsGraphic />
          <div className={classes.textContainer}>
            We are out of polls right now.
            <br /> Have a burning question?
          </div>
        </div>
        <CreatePollLink />
      </div>
    </React.Fragment>
  );
};

export default EmptyContent;
