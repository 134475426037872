import normalize from "json-api-normalizer";
import merge from "lodash/merge";
import { batch } from "react-redux";

import { POST, PATCH, DELETE } from "../util/apiHelpers";
import { mapKeysToSnakeCase } from "../util/formatHelpers";


import { receiveUsers } from "./userActions";


export const RECEIVE_COMMENTS = "RECEIVE_COMMENTS";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

export const receiveComments = (comment) => ({
  type: RECEIVE_COMMENTS,
  payload: comment,
});

export const removeComment = (id) => ({
  type: REMOVE_COMMENT,
  payload: id,
});

export const createComment = (data) => (dispatch) =>
  POST(`/api/v1/comments`, mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveComments(normalized.comment));
      dispatch(receiveUsers(merge({}, normalized.user, normalized.vendorUser)));
    });

    return normalized.comment;
  });

export const updateComment = (data) => (dispatch) =>
  PATCH(`/api/v1/comments/${data.id}`, mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveComments(normalized.comment));
  });

export const destroyComment = (id) => (dispatch) =>
  DELETE(`/api/v1/comments/${id}`).then((res) => {
    return dispatch(removeComment(res.data.id));
  });
