import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import map from "lodash/map";
import includes from "lodash/includes";
import without from "lodash/without";
import cloneDeep from "lodash/cloneDeep";
import pickBy from "lodash/pickBy";
import isEqual from "lodash/isEqual";

import useSharedStyles from "../useSharedStyles";
import FieldGroupHeader from "../../General/FormCard/FieldGroupHeader";
import FormCard from "../../General/FormCard/FormCard";
import FormFooter from "..//FormFooter";
import ChapterSection from "./ChapterSection";
import { updateEmailSettings } from "../../../actions/subscriptionActions";
import { openSnackbar } from "../../../actions/uiActions";
import { usePrevious } from "../../../util/customHooks";
import { GROUPLESS } from "../constants";
import GrouplessSection from "../GrouplessSection";

function EmailSettings({ subscriptions }) {
  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSettings, setEmailSettings] = useState({});
  const prevSettings = usePrevious(subscriptions);

  const initializeEmailSettings = () => {
    const initialSettings = {};
    map(
      subscriptions,
      ({ groupId, immediateEmails }) =>
        (initialSettings[groupId] = {
          immediateEmails,
          toUpdate: immediateEmails,
          dirty: false,
        })
    );
    setEmailSettings(initialSettings);
  };

  useEffect(() => {
    if (!isEqual(prevSettings, subscriptions)) {
      initializeEmailSettings();
    }
  }, [subscriptions, prevSettings]);

  const updateSettings = (targetId) => {
    const [groupId, settingName] = targetId.split("_");
    const clonedSettings = cloneDeep(emailSettings);
    const currentSettings = emailSettings[groupId].toUpdate;

    clonedSettings[groupId].toUpdate = includes(currentSettings, settingName)
      ? without(currentSettings, settingName)
      : currentSettings.concat(settingName);

    clonedSettings[groupId].dirty =
      clonedSettings[groupId].toUpdate !==
      clonedSettings[groupId].immediateEmails;

    setEmailSettings(clonedSettings);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const dirtySettings = pickBy(emailSettings, ({ dirty }) => dirty);
    const submitData = {};
    map(dirtySettings, ({ toUpdate }, groupId) => {
      submitData[groupId] = toUpdate;
    });

    return dispatch(updateEmailSettings({ data: submitData }))
      .then(() => {
        setIsSubmitting(false);
        return dispatch(openSnackbar("Settings successfully updated"));
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <FormCard overrideStyles={{ padding: 0 }}>
      <FieldGroupHeader
        title="Email"
        iconClass="flaticon-multimedia-2"
        overrideStyles={{ paddingLeft: 25 }}
        tooltipText="Receive email notifications to your preferred email address."
      />
      <div className={sharedClasses.headerRow}>
        <div className="col-lg-8" />
        <div className="col-lg-2" style={{ textAlign: "center" }}>
          Immediately
        </div>
        <div className="col-lg-2">Digest</div>
      </div>
      <div className={sharedClasses.root}>
        <GrouplessSection
          settings={emailSettings[GROUPLESS]}
          {...{ updateSettings }}
        />
        {map(
          subscriptions,
          ({ groupId, groupName }) =>
            groupId !== GROUPLESS && (
              <ChapterSection
                key={groupId + "-chapter-section"}
                settings={emailSettings[groupId]}
                {...{ updateSettings, groupId, groupName }}
              />
            )
        )}
      </div>
      <FormFooter {...{ handleSubmit, isSubmitting }} />
    </FormCard>
  );
}

export default EmailSettings;
