import { IconButton, makeStyles } from "@material-ui/core";
import React, {
  useEffect, useState, useRef, useCallback
} from "react";

import { useConversationContext } from "../ConversationsContext";

export const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    minHeight: 60,
    borderBottom: "1px solid #ebedf2",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: ({ actionMenuWidth, drawerToggleWidth }) => ({
    fontSize: 15,
    fontWeight: 500,
    maxWidth: `calc(100% - ${
      theme.spacing(4) + actionMenuWidth + drawerToggleWidth
    }px)`, // offset action menu width and padding
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  drawerToggleContainer: {
    position: "absolute",
    left: theme.spacing(1),
  },
  actionMenuContainer: {
    position: "absolute",
    right: theme.spacing(1),
  },
}));

function ConversationHeader({ title, actionMenu }) {
  const { isMobile, handleDrawerOpen } = useConversationContext();

  const actionMenuContainer = useRef();
  const drawerToggleContainer = useRef();

  const [drawerToggleWidth, setDrawerToggleWidth] = useState(100);
  const [actionMenuWidth, setActionMenuWidth] = useState(100);

  const handleWidthAdjustment = useCallback((ref, setterFunc) => {
    ref.current && setterFunc(ref.current.offsetWidth);
  }, []);

  const adjustWidths = useCallback(() => {
    handleWidthAdjustment(drawerToggleContainer, setDrawerToggleWidth);
    handleWidthAdjustment(actionMenuContainer, setActionMenuWidth);
  }, [
    setDrawerToggleWidth,
    setActionMenuWidth,
    actionMenuContainer,
    drawerToggleContainer,
    handleWidthAdjustment,
  ]);

  useEffect(() => {
    adjustWidths();

    window.addEventListener("resize", adjustWidths);

    return () => {
      window.removeEventListener("resize", adjustWidths);
    };
  }, [adjustWidths]);

  useEffect(() => {
    actionMenuContainer.current &&
      setActionMenuWidth(actionMenuContainer.current.offsetWidth);
  }, [setActionMenuWidth]);

  const classes = useStyles({ actionMenuWidth, drawerToggleWidth });

  const handleClick = () => {
    handleDrawerOpen(null, true);
  };

  return (
    <div className={classes.header}>
      <div
        className={classes.drawerToggleContainer}
        ref={drawerToggleContainer}
      >
        {isMobile && (
          <IconButton onClick={handleClick}>
            <i className="la la-arrow-left" />
          </IconButton>
        )}
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.actionMenuContainer} ref={actionMenuContainer}>
        {actionMenu}
      </div>
    </div>
  );
}

export default ConversationHeader;
