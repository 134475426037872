import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    fontSize: 18,
    color: "#595D6E",
    padding: "12px 0px",
    fontWeight: 400,
  },
  selectText: {
    fontWeight: 300,
    color: "gray",
  },
});

const Question = ({ poll, style = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={style}>
      {poll.attributes.question}
      <i className={classes.selectText}>
        {poll.attributes.multiSelect && " (select all that apply)"}
      </i>
    </div>
  );
};

export default Question;
