import { makeStyles } from "@material-ui/core";
import { connect } from "formik";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentUserId } from "../../../selectors/userSelectors";

import { useConversationContext } from "../ConversationsContext";

import AttachmentsList from "../form/AttachmentsList";
import FormContainer from "../form/FormContainer";
import FormFooter from "../form/FormFooter";
import MessageBodyInput from "../form/MessageBodyInput";
import ConversationHeader from "../partials/ConversationHeader";
import ConversationLabel from "../partials/ConversationLabel";
import ConversationEventsIndex from "../show/ConversationEvents/ConversationEventsIndex";

import ParticipantsSearch from "./ParticipantsSearch/ParticipantsSearch";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  scrollableContent: {
    overflowY: "scroll",
  },
}));

function NewConversation({ formik }) {
  const classes = useStyles();

  const { selectedConversationId } = useConversationContext();

  const selectedConversation = useSelector(
    (state) => selectedConversationId &&
      state.conversations.items[selectedConversationId]
  );

  const currentUserId = useSelector(getCurrentUserId);

  const updateParticipantIds = (participantIds) => {
    formik.setFieldValue("participantIds", participantIds);
  };

  return (
    <div className={classes.container}>
      <ConversationHeader
        title={
          selectedConversation ? (
            <ConversationLabel conversation={selectedConversation} />
          ) : (
            "New Conversation"
          )
        }
      />
      <ParticipantsSearch
        setParticipantIds={updateParticipantIds}
        participantIds={[...formik.values.participantIds, currentUserId]}
      />
      <div className={classes.scrollableContent}>
        {selectedConversationId && (
          <ConversationEventsIndex conversationId={selectedConversationId} />
        )}
      </div>
      <FormContainer>
        <AttachmentsList />
        <MessageBodyInput placeholder="Start a chat here..." />
        <FormFooter />
      </FormContainer>
    </div>
  );
}

export default connect(NewConversation);
