import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    display: "flex",
  },
  popover: {
    zIndex: 9999,
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const PopoverHover = ({ children, PopoverContent, placement }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={classes.popoverContainer}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {children}

      <Popper
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        placement={placement}
      >
        <PopoverContent />
      </Popper>
    </div>
  );
};

export default PopoverHover;
