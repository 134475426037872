import map from "lodash/map";

import {
  COMPANY_INDUSTRY_OPTIONS,
  TA_LEVEL_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  MEMBER_TYPE_OPTIONS,
} from "../../Settings/shared/constants";

const industryFilters = {
  id: "industry",
  title: "Industry",
  options: map(COMPANY_INDUSTRY_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(236, 242, 239)",
  })),
  searchable: true,
};

const levelFilters = {
  id: "level",
  title: "Level",
  options: map(TA_LEVEL_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(258, 248, 240)",
  })),
};

const companySizeFilters = {
  id: "companySize",
  title: "Company Size",
  options: map(COMPANY_SIZE_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(237, 247, 251)",
  })),
};

const memberTypeFilters = {
  id: "memberType",
  title: "Member Type",
  options: map(MEMBER_TYPE_OPTIONS, (option) => ({
    label: option,
    value: option,
    color: "rgb(244, 236, 236)",
  })),
};

const makeChapterFilters = (chapters) => ({
  id: "chapters",
  title: "Chapter",
  searchable: true,
  options: map(chapters, ({ state, name, id }) => ({
    label: (name === "National" || name === "International") ? name : [state, name].join(" - "),
    value: id,
    color: "rgb(235, 237, 242)"
  }))
});

export function createFilters(chapterOptions) {
  return [
    makeChapterFilters(chapterOptions),
    industryFilters,
    levelFilters,
    companySizeFilters,
    memberTypeFilters,
  ]
}

export function createInitialState(filterSettings) {
  const initialState = {};

  filterSettings.forEach((filter) => {
    filter.options.forEach(
      ({ value, label }) =>
        (initialState[value] = {
          label,
          value: false,
        })
    );
  });

  return initialState;
};

export function updateFiltersHelper(state, filterSettings, callback) {
  const newFilters = {};
  filterSettings.forEach((filterSetting) => {
    const newTypeFilters = [];
    filterSetting.options.forEach(({ value, color }) => {
      if (
        (state[value] && state[value]["value"] && state[value]["color"] === color) ||
        (state[value + "|" + color] && state[value + "|" + color]["value"])
      ) {
        newTypeFilters.push(value);
      }
    });

    if (newTypeFilters.length > 0) {
      newFilters[filterSetting.id] = newTypeFilters.join("|");
    }
  });

  callback(newFilters);
}
