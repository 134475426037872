import map from "lodash/map";
import pickBy from "lodash/pickBy";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Item from "./Item";
import NoResults from "./items/NoResults";
import Skeletons from "./Skeletons";
import { EventsContext } from "./Wrapper";

export default function Items() {
  const {
    items,
    search,
    setSearch,
    setStatus,
    setChapters,
    handlePageSelection,
    status,
    loading,
    chapters,
    currentPage,
  } = useContext(EventsContext);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (search !== queryParams.search) {
      setSearch(queryParams.search || "");
    }

    if (status !== queryParams.status) {
      setStatus(queryParams.status || "all");
    }

    if (chapters !== queryParams.chapters) {
      setChapters(queryParams.chapters || "all");
    }

    if (currentPage !== queryParams.page) {
      handlePageSelection(parseInt(queryParams.page) || 1);
    }
  }, [location]);

  useEffect(() => {
    if (items) {
      const params = {};

      params.search = search && search !== "" ? search : null;
      params.status = status === "all" ? null : status;
      params.chapters = chapters === "all" ? null : chapters;
      params.page = currentPage === 1 ? null : currentPage;

      const paramsStr = queryString.stringify(pickBy(params));

      navigate(`${location.pathname}${paramsStr.length > 0 ? `?${paramsStr}` : ''}`);
    }
  }, [search, status, chapters, currentPage]);

  if (loading) return <Skeletons />;
  if (!items) return null;

  if (Object.keys(items).length === 0) {
    return <NoResults />;
  }

  return (
    <React.Fragment>
      {map(Object.keys(items), (id) => (
        <Item key={id} {...{ id }} />
      ))}
    </React.Fragment>
  );
}
