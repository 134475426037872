import React from "react";
import { connect } from "formik";
import FooterButton from "../FooterButton";

const SubmitButton = ({ fathomCode, formik: { handleSubmit, isValid } }) => {
  if (!isValid) {
    return null;
  } else {
    if (fathomCode) {
      window.fathom && window.fathom.trackGoal(fathomCode, 0);
    }

    return <FooterButton onClick={handleSubmit} buttonText="Submit" />;
  }
};

export default connect(SubmitButton);
