import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useMemo } from "react";
import { Visible } from "react-grid-system";
import { createUseStyles } from "react-jss";
import { useSelector, shallowEqual } from "react-redux";

import Order from "../../General/Order";
import { useDirectoryContext } from "../../Profiles/index/DirectoryContext";

import { PROFILES_SORT_OPTIONS, defaultValue } from "../../Profiles/index/order/orderHelpers";
import Search from '../../Profiles/index/Search'

import CheckInButton from "./CheckInButton";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 60,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  secondPartHeader: { display: "flex" },
  rsvpd: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#F35B5E",
    display: "flex",
    alignItems: "center",
  },
  "@media only screen and (max-width: 950px)": {
    header: {
      flexDirection: "column",
    },
    secondPartHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 12,
    },
  },
});

export default function Toolbar({ eventId, loading, search, setSearch, setOrder, totalItems }) {
  const classes = useStyles();

  const event = useSelector((state) => state.events.items[eventId], shallowEqual);
  const settings = useSelector((state) => state.settings, shallowEqual);

  const { state, toggleDrawer } = useDirectoryContext();

  const filterCount = useMemo(() => {
    let count = 0;
    Object.keys(state).forEach((key) => {
      if (state[key]["value"]) count += 1;
    });
    return count;
  }, [state])

  if (!event) return null;

  const { creator, groupId, acceptedInvitationsCount } = event.attributes;
  const { admin, boardMemberGroups, id: currentUserId } = settings.attributes;

  const checkIn = admin || (creator === currentUserId) || boardMemberGroups.includes(groupId);

  const attendeesCount = (() => {
    if (loading) return acceptedInvitationsCount;
    else return totalItems ?? 0;
  })();

  return (
    <div className={`kt-portlet kt-portlet__body ${classes.header}`}>
      <Search {...{ loading, search, setSearch }} style={{ flex: "3 3 0" }}/>
      <div className={classes.secondPartHeader} style={{ flex: "2 1 0" }}>
        <Visible xs sm md>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              fontFamily: "Poppins",
              lineHeight: "36px",
              color: "#F35B5E",
              cursor: "pointer",
            }}
            onClick={toggleDrawer}
          >
            Filters {filterCount > 0 && `[${filterCount}]`}
            <AddIcon fontSize={"small"} />
          </div>
        </Visible>
        <Order
          setOrder={setOrder}
          defaultValue={defaultValue}
          options={PROFILES_SORT_OPTIONS}
          isSearchable={false}
        />
      </div>
      {checkIn && <div style={{ flex: "1 1 0" }}><CheckInButton {...{ id: eventId }}/></div>}
      <div className={classes.rsvpd} style={{ flex: "1 1 0" }}>{`${attendeesCount} RSVP'd Attendees`}</div>
    </div>
  );
}
