import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    minHeight: "70px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: "70px",
  },
  buttonProgress: {
    position: "absolute",
    top: "20%",
    left: "50%",
    marginRight: "-12px",
    marginLeft: "-12px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("780")]: {
      paddingRight: 70,
    },
  },
  childrenContainer: {
    paddingLeft: "24px",
  },
}));

const ActionModalFooter = ({
  isSubmitting,
  color,
  actionText,
  customButtons,
  handleAction,
  submissionError,
  customErrorMessage,
  children,
  overrideErrorStyles = {},
}) => {
  const classes = useStyles();
  const text = actionText ? actionText : "SUBMIT";

  const renderAction = () => (
    <div className={classes.actionContainer}>
      {submissionError ? (
        <div
          className="error"
          style={Object.assign(
            {
              whiteSpace: "pre-wrap",
              textAlign: "right",
              width: 340,
              margin: "auto 20px",
            },
            overrideErrorStyles
          )}
        >
          {customErrorMessage || "Please review the fields highlighted in red."}
        </div>
      ) : (
        <React.Fragment />
      )}
      <div
        className={classes.buttonContainer}
        style={{ position: "relative", margin: "auto 24px" }}
      >
        {customButtons || (
          <button
            className="btn btn-sm btn-brand-secondary"
            onClick={handleAction}
            disabled={isSubmitting}
          >
            {text}
          </button>
        )}
        {isSubmitting && (
          <CircularProgress
            size={24}
            className={classnames(classes.buttonProgress, classes[color])}
          />
        )}
      </div>
    </div>
  );

  return (
    <DialogActions classes={{ root: classes.root }}>
      <div className={classes.childrenContainer}>{children}</div>
      {renderAction()}
    </DialogActions>
  );
};

export default ActionModalFooter;
