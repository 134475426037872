import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getSubscribedGroups } from "../../../selectors/groupSelectors";
import {
  ConnectedTextField,
  ConnectedDropdown,
  ConnectedDatePicker,
  ConnectedInputList,
  ConnectedWysiwyg,
} from "../../General/FormInputs/ConnectedInputs";



const QuestionField = () => (
  <ConnectedTextField id="question" label="Poll Question" maxCharacters={150} />
);

const ChoicesField = () => (
  <ConnectedInputList
    id="choices"
    minItems={2}
    maxItems={10}
    styles={{
      container: { marginLeft: 48 },
      row: { width: "calc(100% - 48px)" },
    }}
    renderInputRow={(_value, index) => (
      <ConnectedTextField
        id={`choices.${index}`}
        label="Option"
        styles={{ container: { width: "calc(100% - 45px)" } }}
        maxCharacters={60}
      />
    )}
  />
);

const multiSelectOptions = [
  { value: false, label: "Only one" },
  { value: true, label: "More than one" },
];
const MultiSelectField = () => (
  <ConnectedDropdown
    id="multiSelect"
    label="How many options can be selected?"
    options={multiSelectOptions}
    styles={{ container: { maxWidth: 500 } }}
  />
);

const ClosesAtField = () => (
  <ConnectedDatePicker
    id="closesAt"
    label={
      <span>
        When should this poll end?{" "}
        <Tooltip title="Each poll needs to be a minimum of 1 week">
          <InfoOutlinedIcon
            style={{ fontSize: 16, color: "#90A4AE", marginBottom: 2 }}
          />
        </Tooltip>{" "}
      </span>
    }
    minDate={moment().add(1, "weeks").format("YYYY-MM-DD")}
    styles={{ container: { maxWidth: 500 } }}
  />
);

const FormFields = () => (
  <div style={{ padding: 24 }}>
    <QuestionField />
    <ChoicesField />
    <MultiSelectField />
    <ClosesAtField />
  </div>
);

export default FormFields;
