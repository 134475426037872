import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    padding: "20px",
    borderBottom: "1px solid #ECEDF2",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
  },
  actionsContainer: {
    "& > button": {
      marginLeft: 10
    }
  },
});

const Header = ({ renderActions = () => {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>Polls</div>
      <div className={classes.actionsContainer}>{renderActions()}</div>
    </div>
  );
};

export default Header;
