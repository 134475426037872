import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import AvatarIcon from "../../../../General/AvatarIcon";

const Signature = ({ user }) => {
  const { values } = useFormikContext();
  const { senderId } = values;
  const sender = useSelector((state) => state.users.items[senderId]);

  const {
    firstName,
    lastName,
    jobTitle,
    companyName,
    chapters,
    boardMember,
  } = sender ? sender.attributes : user.attributes;

  if (user.loaded !== true || !sender) return null;

  return (
    <table
      style={{ padding: "0 0 12px 75px" }}
      cellPadding="0"
      cellSpacing="0"
      className="table-layout"
      width="475"
    >
      <tbody>
        <tr
          style={{
            padding: "0 30px",
            color: "#37474f",
            fontFamily: "proxima-nova, helvetica",
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 0.5,
            lineHeight: "22px",
            margin: 0,
          }}
        >
          <td style={{ padding: "12px 0" }}>Sincerely,</td>
        </tr>
        <tr>
          <td
            style={{
              color: "#37474f",
              fontFamily: "proxima-nova, helvetica",
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.5,
              lineHeight: "22px",
              padding: "6px 0",
              width: 110,
            }}
          >
            <AvatarIcon member={sender || user} height={120} />
          </td>
          <td
            style={{
              padding: "0 0 0 24px",
              color: "#37474f",
              fontFamily: "proxima-nova, helvetica",
              fontSize: 16,
              fontWeight: 400,
              letterSpacing: 0.5,
              lineHeight: "22px",
              margin: 0,
              width: 350,
            }}
          >
            {`${firstName} ${lastName}`}
            <br />
            {jobTitle}
            <br />
            {companyName}
            <br />
            {boardMember
              ? `Board Member, TALK ${chapters[0]}`
              : `TALK ${chapters[0]}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Signature;
