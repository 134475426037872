import {
  useRef, useState, useEffect, useCallback
} from "react";

export default function useFadeAnimation(ref) {
  const timeout = useRef(null);
  const [fadeInCallback, setFadeInCallback] = useState(null);
  const [fadeOutCallback, setFadeOutCallback] = useState(null);

  useEffect(() => {
    if (!ref?.current) return;

    const { classList } = ref.current;

    if (!classList.contains("animated")) {
      classList.add("animated");
    }
  }, [ref]);

  // Fade In
  useEffect(() => {
    if (fadeInCallback === null || !ref?.current) return;

    const { classList, addEventListener, removeEventListener } = ref.current;

    if (!(classList.contains("fadeIn") || classList.contains("fadeOut"))) {
      const handleAnimationEnd = () => {
        fadeInCallback();
        setFadeInCallback(null);
        classList.remove("fadeIn");
        removeEventListener("animationend", handleAnimationEnd);
      };

      addEventListener("animationend", handleAnimationEnd);
      classList.add("fadeIn");
    }
  }, [fadeInCallback, ref]);

  // Fade Out
  useEffect(() => {
    if (fadeOutCallback === null || !ref?.current) return;

    const { classList, addEventListener, removeEventListener } = ref.current;

    if (!(classList.contains("fadeIn") || classList.contains("fadeOut"))) {
      const handleAnimationEnd = () => {
        fadeOutCallback();
        setFadeOutCallback(null);
        classList.remove("fadeOut");
        removeEventListener("animationend", handleAnimationEnd);
      };

      addEventListener("animationend", handleAnimationEnd);
      classList.add("fadeOut");
    }
  }, [fadeOutCallback, ref]);

  // Animation function
  // Returns a promise that resolves with either the fade in or fade out resolve
  return useCallback((fadeOutAfter = 0) => (
    new Promise((resolve) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      if (fadeOutAfter > 0) {
        setFadeInCallback(() => () => {});
        timeout.current = setTimeout(() => {
          setFadeOutCallback(() => resolve);
          timeout.current = null;
        }, fadeOutAfter);
      }
      else {
        setFadeInCallback(() => resolve);
      }
    })
  ), []);
}
