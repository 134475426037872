import { makeStyles } from "@material-ui/core";

const useSharedStyles = makeStyles(() => ({
  label: {
    marginLeft: 0,
    justifyContent: "space-between",
  },
  root: {
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
    fontWeight: 400,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    minHeight: 56,
  },
  borderedRow: {
    display: "flex",
    alignItems: "center",
    borderBottom: "0.5px solid #ECEDF2",
    paddingLeft: 15,
    height: 48,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  chapterSectionRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
  },
  headerRow: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
    padding: "0 15px",
  },
  summary: {
    paddingRight: "25px !important",
  },
}));

export default useSharedStyles;
