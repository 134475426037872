import React from "react";
import { connect } from "formik";

import { makeStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";

import classnames from "classnames";
import { uploadFile } from "../../../util/uploadHelpers";

const useStyles = makeStyles((theme) => ({
  container: {
    alignSelf: "flex-end",
    marginRight: 24,
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(3),
    },
  },
  attachmentIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(3),
    cursor: "pointer",
  },
  submitButton: {
    width: 110,
  },
}));

const ACCEPTED_FILE_TYPES = [
  "image/*",
  "video/*",
  ".HEIC",
  ".pdf",
  ".doc",
  ".docx",
  ".pptx",
  ".ppt",
  ".xlsx",
  ".xls",
  ".csv",
];

const AttachmentButton = connect(
  ({ formik: { setSubmitting, setFieldValue, values } }) => {
    const classes = useStyles();

    const handleAttachment = () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", ACCEPTED_FILE_TYPES.join(","));

      input.onchange = function () {
        const file = this.files[0];

        setSubmitting(true);
        const reader = new FileReader();
        reader.onload = function () {
          uploadFile({
            file,
            uploadFolder: "/conversations/",
            success: (location) => {
              setFieldValue(
                "attachments",
                values.attachments.concat({ url: location, name: file.name })
              );
              setSubmitting(false);
            },
            failure: (msg) => {
              console.log("FAILED TO ATTACH", msg);
              setSubmitting(false);
            },
          });
        };

        reader.readAsDataURL(file);
      };

      input.click();
    };

    return (
      <Tooltip title="Attach a file">
        <i
          className={classnames("fas", "fa-paperclip", classes.attachmentIcon)}
          onClick={handleAttachment}
        ></i>
      </Tooltip>
    );
  }
);

const SubmitButton = connect(
  ({ formik: { errors, isSubmitting, handleSubmit } }) => {
    const classes = useStyles();
    const isDisabled = Object.keys(errors).length || isSubmitting;

    return (
      <button
        className={classnames(
          "btn",
          "btn-sm",
          "btn-brand-secondary",
          classes.submitButton,
          { disabled: isDisabled }
        )}
        style={{ cursor: isDisabled ? "default" : "pointer" }}
        onClick={handleSubmit}
      >
        Send
      </button>
    );
  }
);

const FormFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AttachmentButton />
      <SubmitButton />
    </div>
  );
};

export default FormFooter;
