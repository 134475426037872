import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";

import Summary from "./accordion/Summary";
import Content from "./accordion/Content";
import FormControl from "./FormControl";

const Container = withStyles({
  root: {
    borderTop: "0.5px solid #ECEDF2",
    borderBottom: 0,
    boxShadow: "none",
    "&$expanded": {
      margin: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    margin: 0,
  },
})(MuiAccordion);

export default function Accordion({
  id,
  title,
  subtitle,
  options,
  searchable,
  selectedCount,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = () => setExpanded(!expanded);

  return (
    <Container square expanded={expanded} onChange={handleChange}>
      <Summary {...{ id, title, subtitle }} />
      <Content>
        <FormControl
          {...{
            id,
            options,
            searchable,
            selectedCount,
          }}
        />
      </Content>
    </Container>
  );
}
