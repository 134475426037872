import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { openSnackbar } from "../../../../../actions/uiActions";
import { submitEvent } from "../../../../../actions/eventActions";

export default function SubmitEventButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const settings = useSelector((state) => state.settings);

  const { status, groupId } = event.attributes;
  const { admin, boardMemberGroups } = settings.attributes;

  if (!(status === "draft" && (admin || boardMemberGroups.includes(groupId)))) {
    return null;
  }

  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(submitEvent(id))
      .then(() => {
        dispatch(openSnackbar("Event successfully submitted."));
      })
      .catch(({ response }) => {
        dispatch(
          openSnackbar(
            "Event submission failed. " +
              ((response && response.data && response.data.sentence) || "")
          )
        );
      });
  };

  return (
    <button
      style={{ width: "100%", maxWidth: 315 }}
      className="btn btn-sm btn-brand-secondary"
      onClick={handleSubmit}
    >
      Submit for Approval
    </button>
  );
}
