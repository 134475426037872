import React from "react";
import Form from "./Form";

const SignUp = ({ event, nationalId }) => {
  return (
    <div>
      <div>
        <p className="public-section" style={{ marginBottom: 24 }}>
          For the privacy and protection of all members, we require all{" "}
          {event ? "event attendees" : "members"} to verify their identity with
          LinkedIn. Your privacy is important to us, member contact information
          is never shared or sold.
        </p>
        <div>
          <p className="public-section" style={{ marginBottom: 20 }}>
            Already a member?{" "}
            <a
              href="/sign-in"
              style={{
                textDecoration: "underline",
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              Sign In
            </a>
          </p>
          <Form event={event} nationalId={nationalId} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
