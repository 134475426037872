import { RECEIVE_TOPICS } from "../actions/topicActions";
import merge from "lodash/merge";

const defaultState = {
  items: {}
};

const topicsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_TOPICS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default topicsReducer;
