import React from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import { merge } from "lodash";

import AvatarIcon from "../../../General/AvatarIcon";
import ProfileHover from "../../../Shared/ProfileHover/ProfileHover";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    padding: "0px 6px 6px 6px",
    borderBottom: "1px solid #ECEDF2",
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#48465B",
    marginLeft: 12,
  },
  userName: {
    fontSize: 14,
  },
  userBackground: {
    fontSize: 12,
  },
  tagsContainer: {
    marginLeft: "auto",
    color: "#48465B",
    fontSize: 12,
  },
});

const Header = ({ poll, avatarSize = 36, overloadedClasses = {} }) => {
  const { userId, groupId } = poll.attributes;

  const user = useSelector((state) => state.users.items[userId]);
  const group = useSelector((state) => state.groups.items[groupId]);

  const classes = merge({}, useStyles(), overloadedClasses);

  if (!(user && group)) {
    return null;
  }

  const { firstName, lastName, jobTitle, companyName } = user.attributes;

  return (
    <div className={classes.container}>
      <ProfileHover {...{ userId }}>
        <AvatarIcon avatarId={user.id} height={avatarSize} />
        <div className={classes.userDetails}>
          <div className={classes.userName}>
            {firstName} {lastName}
          </div>
          <div className={classes.userBackground}>
            {jobTitle}, {companyName}
          </div>
        </div>
      </ProfileHover>
      <div className={classes.tagsContainer}>#{group.attributes.name}</div>
    </div>
  );
};

export default Header;
