import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

import ConnectedCheckbox from "../../General/FormInputs/connected/ConnectedCheckbox";
import { openSnackbar } from "../../../actions/uiActions";
import { updateSettings } from "../../../actions/settingActions";

const useStyles = makeStyles(() => ({
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  actions: {
    flexDirection: "row",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
  paper: {
    maxWidth: 570,
  }
}));

const initialValues = {
  boardMemberInterest: false,
  groupCallInterest: false,
};

const BoardMemberInterestModal = ({ open, handleClose, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = (data, { setErrors }) => {
    const boardMemberInterest = data["boardMemberInterest"];
    const groupCallInterest = data["groupCallInterest"];

    if (!boardMemberInterest && !groupCallInterest) {
      setErrors({
        boardMemberInterest: true,
        groupCallInterest: true,
        customError: "You must choose one of the options.",
      });
    } else {
      const interests = [];
      boardMemberInterest && interests.push("board_member");
      groupCallInterest && interests.push("innovation_call");

      dispatch(updateSettings({ interests }))
        .then(() => {
          dispatch(openSnackbar("Thank you for your interest!"));
          handleClose();
        })
        .catch(({ errors }) => {
          setErrors({
            customError:
              errors ||
              "An issue occurred. Please reach out to support@talktalent.com",
          });
        });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper}}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, errors }) => (
          <React.Fragment>
            <DialogTitle classes={{ root: classes.title }}>
              Learn About TALK Leadership Positions
            </DialogTitle>
            <DialogContent
              style={{ textAlign: "center", padding: "1rem", fontSize: 15 }}
            >
              <div style={{ marginBottom: 16 }}>
                Thank you for your interest! Please note, many boards are
                currently full, but we will use this to gauge interest for
                future board openings, and new leadership positions. We will
                reach out to you via email if we have any questions.
              </div>
              <div style={{ textAlign: "left" }}>
                <ConnectedCheckbox
                  id="boardMemberInterest"
                  label="I am interested in learning about TALK Leadership positions"
                  styles={{ container: { marginBottom: 20 } }}
                />
                <ConnectedCheckbox
                  id="groupCallInterest"
                  label="I am interested in participating in TALK Product Innovation Group Calls"
                />
              </div>
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
              {errors.customError && (
                <div style={{ color: "#f45b5e", textAlign: "right" }}>
                  {errors.customError}
                </div>
              )}
              <button
                className="btn btn-outline-brand-secondary"
                onClick={handleClose}
              >
                Cancel
              </button>
              <a>
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </a>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

export default BoardMemberInterestModal;
