import React from "react";
import map from "lodash/map";

import FilterTag from "./FilterTag";

export default function FilterTags({ filterTags }) {
  if (filterTags && filterTags.length === 0) return null;

  return (
    <div style={{ padding: 20, paddingBottom: 0 }}>
      {map(filterTags, ({ key, label, color }) => (
        <FilterTag {...{ key, label, color, id: key }} />
      ))}
    </div>
  );
}
