import React from "react";

import {
  ConnectedSelect,
  ConnectedTextField,
} from "../../General/FormInputs/ConnectedInputs";

const NameField = () => (
  <ConnectedTextField
    id="name"
    label="Vendor Name"
  />
);

const VendorProductCategoriesField = ({ productCategories }) => (
  <ConnectedSelect
    id="categories"
    label="Vendor Categories"
    isMulti={true}
    options={productCategories}
  />
);

const UrlField = () => (
  <ConnectedTextField
    id="url"
    label="Vendor Website"
  />
);

const EmailField = () => (
  <ConnectedTextField
    id="email"
    label="Vendor Contact Email (optional)"
  />
);

const Info = ({ productCategories }) => (
  <div style={{ padding: 24 }}>
    <div
      style={{
        fontWeight: 400,
        color: "#48465B",
        marginBottom: 12,
        fontSize: "1.2857142857142858rem",
        lineHeight: "2.2857142857142856rem",
      }}
    >
      Is there a vendor missing that you would like to review or evaluate? Let
      us know!
    </div>
    <NameField />
    <VendorProductCategoriesField {...{ productCategories }} />
    <UrlField />
    <EmailField />
  </div>
);

export default Info;
