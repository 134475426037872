import React from "react";
import Select from "react-select";
import { createUseStyles } from "react-jss";
import get from "lodash/get";

const useStyles = createUseStyles({
  orderContainer: {
    margin: 0,
    minWidth: 160,
    width: 160,
    marginLeft: 24,
    fontWeight: 300,
  },
  "@media only screen and (max-width: 950px)": {
    orderContainer: {
      marginLeft: 0,
    },
  },
});

const Order = ({
  setOrder,
  options,
  defaultValue = {},
  value,
  isSearchable = true,
}) => {
  const classes = useStyles();
  const customStyles = {
    singleValue: (base) => ({
      ...base,
      fontSize: "14px",
      color: "#495057",
      fontWeight: 400,
    }),
    multiValue: (base) => ({
      ...base,
      paddingLeft: 5,
      fontSize: "14px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#357BA6" : "inherit",
      "&:hover": {
        backgroundColor: isSelected ? "#357BA6" : "#e2e8ea",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 95,
    }),
    menuList: (base, state) => {
      const customStyles =
        get(state, "selectProps.customStyles.menuList") || {};

      return {
        ...base,
        ...customStyles,
        fontSize: 14,
      };
    },
    control: (base, state) => {
      let controlStyle = {
        ...base,
        borderColor: "#e2e5ec",
        boxShadow: "0 0 0 0 #357BA6 !important",
        fontSize: 14,
      };

      if (state.isFocused) {
        controlStyle = {
          ...base,
          borderColor: "#357BA6 !important",
          boxShadow: "0 0 0 0 #357BA6 !important",
          "&:focus": {
            borderColor: "#357BA6 !important",
            boxShadow: "0 0 0 0 #357BA6 !important",
          },
          "&:active": {
            borderColor: "#357BA6 !important",
            boxShadow: "0 0 0 0 #357BA6 !important",
          },
        };
      } else if (state.selectProps.isError) {
        controlStyle = {
          ...base,
          borderColor: "#F35B5E !important",
          boxShadow: "0 0 0 0 #F35B5E !important",
          "&:focus": {
            borderColor: "#F35B5E !important",
            boxShadow: "0 0 0 0 #F35B5E !important",
          },
          "&:active": {
            borderColor: "#F35B5E !important",
            boxShadow: "0 0 0 0 #F35B5E !important",
          },
        };
      }

      return controlStyle;
    },
  };

  return (
    <div className={`form-group ${classes.orderContainer}`}>
      <Select
        defaultValue={defaultValue}
        options={options}
        onChange={({ value }) => setOrder(value)}
        styles={customStyles}
        value={value}
        isSearchable={isSearchable}
      />
    </div>
  );
};

export default Order;
