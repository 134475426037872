import React, { memo } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import orderBy from "lodash/orderBy";

import { ConnectedDropdown } from "../../../General/FormInputs/ConnectedInputs";

const ChapterDropdown = () => {
  const chapters = useSelector((state) => state.groups.items);
  const chapterOptions = map(chapters, (chapter) => ({
    value: chapter.id,
    label: chapter.attributes.name,
  })).concat({
    value: "all_chapters",
    label: "All Chapters",
  })

  const options = orderBy(
    chapterOptions,
    "label",
    "asc"
  );

  return (
    <ConnectedDropdown id="chapterId" options={options} label="Chapter" />
  );
};

export default memo(ChapterDropdown);
