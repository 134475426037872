import merge from "lodash/merge";

import { RECEIVE_SUBSCRIPTIONS } from "../actions/subscriptionActions";

const defaultState = {
  items: {},
  loaded: false,
};

const subscriptionsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_SUBSCRIPTIONS:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default subscriptionsReducer;
