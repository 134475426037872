import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import { Row, Col } from "react-grid-system";


const useStyles = makeStyles({
  container: {
    boxSizing: "border-box",
    color: "#181C32",
    paddingLeft: 24,
    height: "72px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #EBEDF2",
  },
  headerText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 18,
  },
  closeIconContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  closeIcon: {
    fontSize: "large",
    marginRight: -7,
    cursor: "pointer",
    padding: 12,
    marginRight: 12,
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
});

const ActionModalHeader = ({ title, handleClose }) => {
  const classes = useStyles();

  return (
    <DialogTitle id="responsive-dialog-title" style={{ padding: 0 }}>
      <Row nogutter>
        <Col xs={9} style={{ padding: 0, margin: 0 }}>
          <div className={classnames(classes.container)}>
            {title && (
              <div className={classnames(classes.headerText)}>{title}</div>
            )}
          </div>
        </Col>
        <Col xs={3} style={{ padding: 0, margin: 0 }}>
          <div
            className={classnames(
              classes.closeIconContainer,
              classes.container
            )}
          >
            <div className={classes.closeIcon} onClick={handleClose}>
              <i className="la la-times"></i>
            </div>
          </div>
        </Col>
      </Row>
    </DialogTitle>
  );
};

export default ActionModalHeader;
