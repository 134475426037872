import React from "react";

const PageSection = ({ children }) => (
  <div className="col-lg-12">
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="kt-widget kt-widget--project-1">{children}</div>
      </div>
    </div>
  </div>
);

export default PageSection;
