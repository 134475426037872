import every from "lodash/every";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";

export const getAllUsers = (state) => state.users.items;

export const getUser = (state, { id }) => state.users.items[id];

export const getCurrentUserId = (state => state.settings.attributes.id);

export const getCurrentUser = ((state) => (
  getUser(state, { id: getCurrentUserId(state) })
));

export const getCurrentUserProfileId = createSelector(
  [getCurrentUser],
  (user) => user?.attributes?.profileId
);

const buildFilterFunctions = ({ ids }) => {
  const filterFunctions = [];

  if (ids) {
    const idInclusion = {};

    forEach(ids, (id) => {
      idInclusion[id] = true;
    });

    filterFunctions.push((user) => idInclusion[user.id]);
  }

  return filterFunctions;
};

export const makeGetFilteredUsers = () => {
  const getUserFilters = (_state, filters) => filters;

  const getMemoizedUserFilters = createDeepEqualSelector(
    [getUserFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllUsers, getMemoizedUserFilters],
    (users, userFilters) => {
      const filterFunctions = buildFilterFunctions(userFilters);

      return filter(users, (user) => every(filterFunctions, (filterFunction) => filterFunction(user)));
    }
  );
};
