import { object, string } from "../../../../util/yup.js";
import {
  TA_LEVEL_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  COMPANY_INDUSTRY_OPTIONS,
} from "../../shared/constants.js";

export const buildInitialValues = (attributes) => {
  const values = {
    companyName: attributes.companyName || "",
    jobTitle: attributes.jobTitle || "",
    taLevel: null,
    companySize: null,
    companyIndustry: null
  };

  if (attributes.hasMembershipsAccess) {
    values.taLevel = attributes.taLevel || ""
    values.companySize = attributes.companySize || ""
    values.companyIndustry = attributes.companyIndustry || ""
  }

  return values;
};

// brychael-lezul: once we rewrite the application form, we should add backend validations
// for email and linkedin_url fields
export const taValidationSchema = object().shape({
  taLevel: string()
    .nullable()
    .required("This field is required")
    .oneOf(TA_LEVEL_OPTIONS, "Must be one of the available options"),
  companyName: string().required("This field is required"),
  companySize: string()
    .nullable()
    .required("This field is required")
    .oneOf(COMPANY_SIZE_OPTIONS, "Must be one of the available options"),
  companyIndustry: string()
    .nullable()
    .required("This field is required")
    .oneOf(COMPANY_INDUSTRY_OPTIONS, "Must be one of the available options"),
  jobTitle: string().required("This field is required"),
});

export const validationSchema = object().shape({
  companyName: string().required("This field is required"),
  jobTitle: string().required("This field is required"),
});
