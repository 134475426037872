import { sortBy, sortedUniq } from "lodash";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";

import { fetchConversations } from "../../../../actions/conversationActions";
import { fetchProfiles } from "../../../../actions/profileActions";
import { getCurrentUserProfileId } from "../../../../selectors/userSelectors";
import { buildFullName } from "../../../../util/formatHelpers";
import ConversationLabel from "../../partials/ConversationLabel";

import { CONVERSATION, MAX_OPTION, USER } from "./constants";
import SearchResult from "./SearchResult";
import useConversationAutoSelector from './useConversationAutoSelector';

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderWidth: "1px",
    borderRadius: 0,
    borderStyle: "solid",
    borderColor: state.isFocused ? "#357BA6" : "#ebedf2",
  }),
};

export const MAX_SELECTIONS = 15;

const mapUsersToOptions = (users) => map(users || [], ({ id, attributes }) => ({
  value: id,
  label: buildFullName(attributes.firstName, attributes.lastName),
  type: USER,
}));

const mapConversationsToOptions = (conversations) => map(conversations || [], (conversation) => ({
  value: conversation.id,
  label: <ConversationLabel {...{ conversation }} />,
  type: CONVERSATION,
}));

export const MAX_OPTION_ITEM = {
  value: MAX_OPTION,
  type: MAX_OPTION,
  label: `You currently have ${MAX_SELECTIONS} participants selected. Please remove some participants before adding more.`,
};

function ParticipantsSearch({ participantIds, setParticipantIds, excludedIds: excluded = [] }) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [value, setValue] = useState([]);

  const currentUserProfileId = useSelector(getCurrentUserProfileId);

  useConversationAutoSelector(value);

  const handleChange = (newValue = []) => {
    setValue(newValue);
    setParticipantIds(map(newValue, ({ value }) => value));
  };

  // In addition to excluding any currently added,
  // exclude the provided if excluded list is provided, otherwise exclude the current user
  const excludedIds = useMemo(() => (
    sortedUniq(sortBy(
      map(value, ({ value }) => value)
      .concat(excluded.length > 0 ? excluded : [currentUserProfileId])
    ))
    .join(",")
  ), [currentUserProfileId, excluded, value]);

  const fetchOptions = useCallback(
    debounce((inputValue, setOptions) => {
      // Need to account for the current user
      if (participantIds.length >= MAX_SELECTIONS) {
        setOptions([MAX_OPTION_ITEM]);
        return;
      }

      dispatch(
        fetchProfiles({
          search: inputValue,
          perPage: 10,
          searchMethod: "full_name",
          asUsers: true,
          excludedIds,
        })
      )
      .then(({ items: userItems }) => {
        const userIds = map(userItems, ({ id }) => id);

        dispatch(
          fetchConversations({ participantIds: userIds.join(",") })
        ).then(({ conversations: conversationItems }) => {
          const groupConversationItems = filter(
            conversationItems,
            ({ attributes }) => attributes.conversationParticipantsCount > 2
          );

          const newOptions = mapUsersToOptions(userItems).concat(
            mapConversationsToOptions(groupConversationItems)
          );

          setOptions(newOptions);
        });
      });
    }, 300),
    [dispatch, excludedIds, value]
  );

  return (
    <AsyncSelect
      isMulti
      value={value}
      onChange={handleChange}
      loadOptions={fetchOptions}
      inputValue={query}
      onInputChange={(newQuery) => setQuery(newQuery)}
      autoFocus
      styles={customStyles}
      components={{ Option: SearchResult }}
      placeholder={(
        <span>
          <i className="fas fa-search" />
          {' '}
          Search for members to start a chat
        </span>
      )}
    />
  );
}

ParticipantsSearch.propTypes = {
  participantIds: PropTypes.array.isRequired,
  setParticipantIds: PropTypes.func.isRequired,
  excludedIds: PropTypes.array
};

export default ParticipantsSearch;
