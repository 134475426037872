import { RECEIVE_PRODUCT_CATEGORIES } from "../actions/productCategoryActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  popular: [],
  loaded: false
};

const productCategoriesReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PRODUCT_CATEGORIES:
      return merge({}, state, {
        items: action.payload,
        popular: action.popular,
        loaded: true
      });
    default:
      return state;
  }
};

export default productCategoriesReducer;
