// import queryString from "query-string";
import React from "react";

import SignUp from "./SignUp";

const MemberText = () => (
  <div style={{ textAlign: "center" }}>
    You are already a TALK member, you may share this URL to invite others to
    join.
  </div>
);

const Registration = ({ event, currentUser, nationalId }) => {
  // const isTA = queryString.parse(window.location.search).ta === "true"

  return (
    <div className="kt-portlet" style={{ paddingBottom: 80 }}>
      <h4 className="public-section" style={{ paddingTop: 24, margin: 0 }}>
        Join Talk
      </h4>
      <h4 className="public-section" style={{ fontSize: 24, color: "#212121" }}>
        Internal TA/HR Professional
      </h4>
      {currentUser ? (
        <MemberText />
      ) : (
        <SignUp event={event} nationalId={nationalId} />
      )}
    </div>
  );
};

export default Registration;
