import { batch } from "react-redux";
import normalize from "json-api-normalizer";

import { GET } from "../util/apiHelpers";

import { receiveVendorProducts } from "./vendorProductActions";

export const RECEIVE_PRODUCT_CATEGORIES = "RECEIVE_PRODUCT_CATEGORIES";

const receiveProductCategories = (productCategories, popular) => ({
  type: RECEIVE_PRODUCT_CATEGORIES,
  payload: productCategories,
  popular: popular
});

export const fetchProductCategories = () => (dispatch) => {
  return GET('/api/v1/product_categories').then(
    (res) => {
      const normalizedProductCategories = normalize(res.data.items);
      const normalizedVendorProducts = normalize(res.data.popular_products);

      return batch(() => {
        dispatch(
          receiveProductCategories(
            normalizedProductCategories.productCategory,
            res.data.popular_categories
          )
        );
        dispatch(receiveVendorProducts(normalizedVendorProducts.vendorProduct));
      });
    }
  );
};
