import { makeStyles } from "@material-ui/core";
import React, { forwardRef } from "react";
import Linkify from "linkify-react";
import { useSelector } from "react-redux";
import { getMessage } from "../../../../selectors/conversationSelectors";

const useStyles = makeStyles((theme) => ({
  container: ({ fromSelf }) => ({
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    fontSize: 16,
    fontWeight: 350,
    maxWidth: "66%",
    alignSelf: fromSelf ? "flex-end" : "flex-start",
    backgroundColor: fromSelf ? "#EBEAF2" : "#EAF2F1",
    borderRadius: 8,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  }),
  link: {
    color: theme.palette.primary.main,
  },
}));

const MessageContent = ({ id }, ref) => {
  const message = useSelector((state) => getMessage(state, { id }));
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const fromSelf = currentUserId === message.attributes.userId;

  const classes = useStyles({ fromSelf });

  return (
    <div
      className={classes.container}
      title={message.attributes.createdAt}
      ref={ref}
    >
      <Linkify
        options={{
          target: "_blank",
          rel: "noreferrer",
          className: classes.link,
        }}
      >
        {message.attributes.body}
      </Linkify>
    </div>
  );
};

export default forwardRef(MessageContent);
