import { array, object, string } from "../../../util/yup";
import map from "lodash/map";
import uniq from "lodash/uniq";

export const determineUniqueEmails = (userInvitations) => {
  const allEmails = map(userInvitations, (invitation) => invitation.email);
  const uniqueEmails = uniq(allEmails);

  return allEmails.length === uniqueEmails.length;
};

export const initialValues = {
  userInvitations: [
    {
      email: "",
      firstName: "",
      lastName: "",
    },
  ],
};

export const buildValidationSchema = () => {
  const validations = {
    userInvitations: array().of(
      object().shape({
        email: string().nullable().validEmail().required("Cannot be blank"),
        firstName: string().required("Cannot be blank"),
        lastName: string().required("Cannot be blank"),
      })
    ),
  };

  return object().shape(validations);
};
