export const ALL = "all";
export const IN_PROGRESS = "in_progress";
export const UPCOMING = "upcoming";
export const PAST = "past";
export const SUBMITTED = "submitted";
export const DRAFTS = "drafts";

export const ALL_OPTION = {
  value: ALL,
  label: "All Events",
  defaultValue: true,
};

const IN_PROGRESS_OPTION = {
  value: IN_PROGRESS,
  label: "In Progress",
};

const UPCOMING_OPTION = {
  value: UPCOMING,
  label: "Upcoming",
};

const PAST_OPTION = {
  value: PAST,
  label: "Past",
};

const SUBMITTED_OPTION = {
  value: SUBMITTED,
  label: "Submitted",
};

const DRAFTS_OPTION = {
  value: DRAFTS,
  label: "Draft",
};

export const OPTIONS = [ALL_OPTION, UPCOMING_OPTION, PAST_OPTION];
export const IN_PROGRESS_OPTIONS = [ALL_OPTION, IN_PROGRESS_OPTION, UPCOMING_OPTION, PAST_OPTION];
export const BOARD_MEMBER_OPTIONS = [SUBMITTED_OPTION, DRAFTS_OPTION];
