import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { clearModal } from "../../../actions/uiActions";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 50000, // must be larger than drawer zIndex
  },
  paper: {
    width: 400,
    maxWidth: 400,
  },
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  contentContainer: {
    height: 100,
    fontSize: 15,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      4
    )}px ${theme.spacing(3)}px`,
  },
  actions: {
    flexDirection: "row",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
}));

function DiscardModal({ handleProceed }) {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.ui.modal.visible);

  const handleClose = () => {
    dispatch(clearModal());
  };

  const classes = useStyles();

  const handleContinue = () => {
    handleClose();
    handleProceed();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper, root: classes.root }}
      disableBackdropClick
    >
      <DialogTitle classes={{ root: classes.title }}>Discard Chat</DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        Are you sure you want to discard this chat?
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <button
          className="btn btn-outline-brand-secondary"
          onClick={handleClose}
          type="submit"
        >
          Cancel
        </button>
        <button
          className="btn btn-secondary"
          onClick={handleContinue}
          type="submit"
        >
          Discard
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default DiscardModal;
