import React from "react";

const FormPageLayout = ({ children }) => (
  <div className="row">
    <div className="col-xl-12">
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body kt-portlet__body--fit">{children}</div>
      </div>
    </div>
  </div>
);

export default FormPageLayout;
