import React from "react";
import { useSelector } from "react-redux";

import {
  CompanyNameField,
  JobTitleField,
  TALevelField,
  CompanySizeField,
  CompanyIndustryField,
} from "../../shared/FormFields";

const ProfessionalDetailsFields = () => {
  const settings = useSelector((state) => state.settings);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-6">
          <CompanyNameField />
        </div>
        <div className="col-lg-6">
          <JobTitleField />
        </div>
      </div>
      {settings.attributes.hasMembershipsAccess && (<React.Fragment>
        <div className="row">
          <div className="col-lg-6">
            <TALevelField />
          </div>
          <div className="col-lg-6">
            <CompanySizeField />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <CompanyIndustryField />
          </div>
        </div>
      </React.Fragment>)}
    </React.Fragment>
  )
};

export default ProfessionalDetailsFields;
