import React, { useContext } from "react";
import { createUseStyles } from "react-jss";

import FilterTags from "./FilterTags";
import { FilterContext } from "./Filter";

const useStyles = createUseStyles({
  container: {
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#48465B",
  },
  resetAction: {
    fontSize: "14px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "#F35B5E",
    cursor: "pointer",
  },
});

export default function Header({
  filterTags,
}) {
  const { context } = useContext(FilterContext);
  const { resetFilters } = useContext(context);
  const classes = useStyles()
  return (
    <div style={{ marginBottom: 20 }}>
      <div className={classes.container}>
        <div className={classes.title}>Filters</div>
        {filterTags.length > 0 && (
          <div className={classes.resetAction} onClick={resetFilters}>
            Reset Filters
          </div>
        )}
      </div>
      <FilterTags {...{ filterTags }} />
    </div>
  );
}
