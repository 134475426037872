import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import RootReducer from "./reducers/rootReducer";

const middlewares = [thunk];

const composeEnhancers = compose;
// (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(preloadedState = {}) {
  return createStore(
    RootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
