import React from "react";
import { createUseStyles } from "react-jss";
import { Visible } from "react-grid-system";

const useStyles = createUseStyles({
  titleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: 500,
    fontSize: "1.75rem",
    lineHeight: "1.75rem",
    color: "#48465B",
  },
  titleLink: {
    color: "#357BA6",
    "&:hover": {
      color: "#2A5C84",
      textDecoration: "underline !important",
    },
  },
});

const Title = ({ name, pageUrl, ctaLink, titleLink }) => {
  const classes = useStyles();

  const title = titleLink ? (
    <a className={`${classes.title} ${classes.titleLink}`} href={pageUrl}>
      {name}
    </a>
  ) : (
    <div className={classes.title}>{name}</div>
  );

  const cta = ctaLink && (
    <Visible lg xl xxl>
      {ctaLink}
    </Visible>
  );

  return (
    <div className={classes.titleContainer}>
      {title}
      {cta}
    </div>
  );
};

export default Title;
