import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { map, round, orderBy, merge } from "lodash";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = createUseStyles({
  resultsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    listStyle: "none",
    padding: 0,
    "& li+li": {
      marginTop: 12,
    },
  },
  resultItem: {
    position: "relative",
    height: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  resultItemBar: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    justifySelf: "flex-start",
    width: "90%",
  },
  barFill: {
    backgroundColor: "#357ba636",
    height: "100%",
    position: "absolute",
    transition: "width 0.75s ease",
  },
  barLabel: {
    display: "flex",
    justifySelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 1,
    color: "#3A7EA8",
    fontSize: 14,
  },
  selectedIconContainer: {
    width: 20,
    margin: "0px 8px",
  },
  resultItemPercentage: {
    width: "10%",
    justifySelf: "flex-end",
    textAlign: "right",
    fontSize: 16,
  },
});

const ResultItem = ({ choice, totalVotes, overloadedClasses = {} }) => {
  const classes = merge({}, useStyles(), overloadedClasses);
  const [barWidth, setBarWidth] = useState(0);

  const { selectionsCount, selectedByUser } = choice;
  const percentage = Math.floor(round(selectionsCount / totalVotes, 2) * 100);

  useEffect(() => { 
    setTimeout(() => setBarWidth(`${percentage}%`), 0);
  }, []);

  return (
    <li className={classes.resultItem}>
      <div className={classes.resultItemBar}>
        <div
          className={classes.barFill}
          style={{ width: barWidth, minWidth: "0.5%" }}
        ></div>
        <div className={classes.barLabel}>
          <div className={classes.selectedIconContainer}>
            {selectedByUser && <CheckBoxIcon size={13} />}
          </div>
          <div>{choice.text}</div>
        </div>
      </div>
      <div className={classes.resultItemPercentage}>{percentage}%</div>
    </li>
  );
};

const Results = ({ poll, overloadedClasses = {} }) => {
  const classes = merge({}, useStyles(), overloadedClasses);

  const { choices, responseCount } = poll.attributes;

  const orderedChoices = orderBy(choices, (choice) => choice.text);

  return (
    <ul className={classes.resultsList}>
      {map(orderedChoices, (choice) => (
        <ResultItem
          choice={choice}
          totalVotes={responseCount}
          key={choice.id}
          overloadedClasses={overloadedClasses}
        />
      ))}
    </ul>
  );
};

export default Results;
