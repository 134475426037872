import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeGetFilteredConversationParticipants } from "../../../../../selectors/conversationSelectors";
import useSharedStyles from "./useSharedStyles";

const ConversationItemUnreadLabel = ({ conversation, recentEvent }) => {
  const classes = useSharedStyles();
  const getFilteredConversationParticipants = useMemo(
    makeGetFilteredConversationParticipants,
    []
  );

  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const currentUserParticipation = useSelector(
    (state) =>
      (getFilteredConversationParticipants(state, {
        conversationId: conversation.id,
        includedUserIds: [currentUserId],
      }) || [])[0]
  );

  const isUnread =
    recentEvent &&
    currentUserParticipation &&
    recentEvent.attributes.actorId !== currentUserId &&
    (!currentUserParticipation.attributes.readAt ||
      moment(recentEvent.attributes.createdAt).isAfter(
        currentUserParticipation.attributes.readAt
      ));

  if (!isUnread) return null;

  return <div className={classes.unreadIcon}></div>;
};

export default ConversationItemUnreadLabel;
