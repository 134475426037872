import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#357BA6",
        borderColor: "#357BA6",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#357BA6",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#357BA6",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const TalkSwitch = ({
  id,
  label,
  optionLabel,
  value,
  onChange,
  showError,
  helperText,
  styles = {
    container: {},
    label: {
      fontSize: "1.2857142857142858rem",
      lineHeight: "2.2857142857142856rem",
      fontWeight: 400,
    },
    input: {},
  },
}) => {
  return (
    <div className="form-group" style={styles.container || {}}>
      {label && (
        <React.Fragment>
          <label
            htmlFor={id}
            style={Object.assign(
              styles.label || {},
              showError ? { color: "red" } : {}
            )}
          >
            {label}
          </label>
          <br />
        </React.Fragment>
      )}
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid
            item
            style={{
              fontWeight: 400,
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
              maxWidth: "calc(100% - 100px)"
            }}
          >
            {optionLabel}
          </Grid>
          <Grid item>
            <IOSSwitch checked={value} onChange={onChange} name={id} />
          </Grid>
        </Grid>
      </Typography>
      {helperText}
    </div>
  );
};

export default TalkSwitch;
