import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import Skeleton from "react-loading-skeleton";
import times from "lodash/times";

const useStyles = createUseStyles({
  container: {
    width: "100%",
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    padding: "0px 6px 6px 6px",
    borderBottom: "1px solid #ECEDF2",
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#48465B",
    marginLeft: 12,
  },
  choiceContainer: {
    marginBottom: 8,
    height: 40,
    "& span span": {
      borderRadius: "40px",
    },
  },
});

const SkeletonPoll = () => {
  const classes = useStyles();

  return (
    <div className={classnames("kt-portlet", classes.container)}>
      <div className={classes.headerContainer}>
        <Skeleton circle={true} height={36} width={36} />
        <div className={classes.userDetails}>
          <Skeleton width={100} height={36} />
        </div>
      </div>
      <div
        style={{
          padding: "24px 24px 16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {times(5, () => (
          <div className={classes.choiceContainer}>
            <Skeleton width="100%" height="100%" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonPoll;
