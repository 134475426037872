import Drawer from "@material-ui/core/Drawer";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import queryString from "query-string";
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Visible } from "react-grid-system";
import { useNavigate, useLocation } from 'react-router-dom'

import Filter from "../../General/Filter/Filter";
import SideCards from "../../General/SideCards";

import ContentSection from "./ContentSection";
import DirectoryContext from "./DirectoryContext";
import PaginationSection from "./PaginationSection";
import Toolbar from "./Toolbar";
import { createFilters, createInitialState, updateFiltersHelper } from "./util";

export default function IndexComponent({
  fetchAction,
  handlePageSelection,
  currentPage,
  totalPages,
  search,
  setSearch,
  order,
  setOrder,
  loading,
  setLoading,
  setFilters,
  chapterOptions,
  items,
  toolbar,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const filterSettings = createFilters(chapterOptions);
  const [state, setState] = useState(createInitialState(filterSettings));

  const handleChangeState = (event, value, label, checked, color = false) => {
    if (!loading) {
      setLoading(true);
    }
    if (event) {
      setState((state) => ({
        ...state,
        [event.target.value.split("|")[0] + "|" + event.target.dataset.color]: {
          label: event.target.name,
          value: event.target.checked,
          color: event.target.dataset.color,
        },
      }));
    }
    else {
      setState((state) => ({
        ...state,
        [value.split("|")[0] + "|" + color]: { label: label, value: checked, color },
      }));
    }
  };

  useEffect(() => {    
    let params = {};
    
    params.order = order && order !== "default" ? order : null;
    params.search = search && search !== "" ? search : null;
    params.page = currentPage === 1 ? null : currentPage;
    
    if (state) {
      const selected = map(
        filter(Object.values(state), "value"),
        ({ label, color }) => label + color
      );

      if (selected.length > 0) {
        forEach(filterSettings, ({ id, options }) => {
          const sectionParams = [];
          const optionsLabels = map(options, ({ label }) => label);
          const targetColor = options[0].color

          forEach(optionsLabels, label => {
            if (includes(selected, label + targetColor)) {
              sectionParams.push(label);
            }
          })

          if (sectionParams.length > 0) {
            params[id] = sectionParams;
          }
        });
      }
    }

    if (Object.keys(params).length > 0) {
      const paramsStr = queryString.stringify(pickBy(params), {
        arrayFormat: "separator",
        arrayFormatSeparator: "|",
      });

      navigate(`${location.pathname}${paramsStr.length > 0 ? `?${paramsStr}` : ''}`);
    }
  }, [state, currentPage, search, order]);

  useEffect(() => updateFilters(state), [state]);

  const updateFilters = useCallback(
    debounce(
      (state) => updateFiltersHelper(state, filterSettings, setFilters),
      1500
    ),
    []
  );

  const resetFilters = () => {
    setLoading(true);
    setState(createInitialState(filterSettings));
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <DirectoryContext.Provider
      value={{
        fetchAction,
        filterSettings,
        createInitialState,
        handleChangeState,
        state,
        setState,
        loading,
        search,
        setSearch,
        setOrder,
        resetFilters,
        toggleDrawer,
      }}
    >
      <Row>
        <Visible xs sm md>
          <Drawer anchor={"left"} open={drawerOpen} onClose={toggleDrawer}>
            <div style={{ width: "380px", maxWidth: "calc(100vw * 0.75)" }}>
              <Filter boxShadow={false} context={DirectoryContext} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="btn btn-secondary" onClick={toggleDrawer}>
                  Apply
                </button>
              </div>
            </div>
          </Drawer>
        </Visible>
        <Visible lg xl xxl>
          <Col md={12} lg={4} style={{ paddingRight: 0 }} id="sidearea">
            <SideCards alignment="left">
              <Filter context={DirectoryContext} />
            </SideCards>
          </Col>
        </Visible>
        <Col xs={12} lg={8} style={{ paddingLeft: 20 }}>
          {toolbar || <Toolbar />}
          <ContentSection {...{ items }} />
          <PaginationSection
            {...{
              loading,
              totalPages,
              currentPage,
              handlePageSelection,
            }}
          />
        </Col>
      </Row>
    </DirectoryContext.Provider>
  );
}
