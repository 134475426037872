import { createContext, useContext } from 'react';

/**
 * @typedef {Object} context
 * @property {callback} fetchAction
 * @property {Object} filterSettings
 * @property {callback} createInitialState
 * @property {callback} handleChangeState
 * @property {Object} state
 * @property {callback} setState
 * @property {boolean} loading
 * @property {string} search
 * @property {callback} setSearch
 * @property {callback} setOrder
 * @property {callback} resetFilters
 * @property {callback} toggleDrawer
 */

/** @type React.Context<context> */
const DirectoryContext = createContext();

export const useDirectoryContext = () => {
  const context = useContext(DirectoryContext);
  if (context === undefined) {
    throw new Error("useDirectoryContext must be used within DirectoryContext");
  }
  return context;
}


export default DirectoryContext;
