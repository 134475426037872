import React from "react";
import { createUseStyles } from "react-jss";

import SkeletonItem from "../item/SkeletonItem";

const useStyles = createUseStyles({
  unorderedList: {
    margin: "1rem 0 1rem 0",
    padding: 0,
    listStyleType: "none",
    "& li+li": {
      borderTop: "1px solid #ECEDF2",
    },
  },
});

export default function Skeletons() {
  const classes = useStyles();

  return (
    <ul className={classes.unorderedList}>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </ul>
  );
}
