import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ScreenClassProvider } from "react-grid-system";
import momentConverter from "@date-io/moment";

import configureStore from "../../configureStore";
import muiTheme from "./muiTheme";

import Settings from "./Settings";
import Snackbar from "./Snackbar";
import Modal from "./ActionModal/ConnectedModal";
import FeatureFlags from "./FeatureFlags";

const store = configureStore();

const withGlobalProviders = (Component) => (props) => (
  <Provider store={store}>
    <BrowserRouter>
      <FeatureFlags>
        <Settings>
          <ScreenClassProvider>
            <ThemeProvider theme={muiTheme}>
              <MuiPickersUtilsProvider utils={momentConverter}>
                <Component {...props} />
                <Snackbar />
                <Modal />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </ScreenClassProvider>
        </Settings>
      </FeatureFlags>
    </BrowserRouter>
  </Provider>
);

export default withGlobalProviders;
