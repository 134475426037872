import React from "react";
import { connect } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import keys from "lodash/keys";
import each from "lodash/each";
import intersection from "lodash/intersection";
import CircularProgress from "@material-ui/core/CircularProgress";

const keyConverter = {
  user: "creator",
  group: "chapter",
};

const getTouchedKeys = (keys) => {
  const touched = {};
  each(keys, (key) => {
    const formikKey = keyConverter[key] || key;
    touched[formikKey] = true;
  });
  return touched;
};

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: "#F35B5E",
    borderColor: "#F35B5E",
    "&:hover": {
      backgroundColor: "#F35B5E",
      color: "white",
      borderColor: "#F35B5E",
    },
  },
}));

const SubmitButton = connect(({ formik: { handleSubmit }, loading, submittedAt }) => {
  const buttonText = submittedAt ? "Save" : "Submit for Approval"

  return (
    <button
      className="btn btn-sm btn-brand-secondary"
      onClick={handleSubmit}
      style={{ margin: 4, minWidth: 90 }}
      type="button"
      disabled={loading}
    >
      {buttonText}
    </button>
  );
});

const SaveButton = connect(
  ({
    formik: { setFieldValue, setTouched, values },
    event,
    loading,
    handleSubmit,
    setLoading,
  }) => {
    // We don't use formik's handle submit here to bypass validation for drafts
    return (
      <button
        className="btn btn-sm btn-tertiary"
        style={{ minWidth: "120px", margin: 4 }}
        onClick={() => {
          setTouched({});
          setFieldValue("is_submit", false, false);
          setLoading(true);
          handleSubmit(values)
            .then(() => {
              window.location.href = '/events?tab=draft';
            })
            .catch((e) => {
              const errors = e.response.data.errors;
              const touched = getTouchedKeys(keys(errors));
              setTouched(touched);
              setLoading(false);
            });
        }}
        disabled={loading}
      >
        {event ? "Save Changes" : "Save Draft"}
      </button>
    );
  }
);

const CancelButton = ({ loading, handleBack }) => {
  const classes = useStyles();

  return (
    <button
      className={classnames(
        "btn btn-sm btn-outline-brand-secondary",
        classes.cancelButton
      )}
      style={{
        minWidth: "120px",
        margin: 4,
      }}
      onClick={handleBack}
      disabled={loading}
    >
      Cancel
    </button>
  );
};

const StyledErrorMessage = connect(({ formik: { errors, touched } }) => {
  return intersection(keys(touched), keys(errors)).length >= 1 ? (
    <div
      style={{
        fontSize: 13,
        lineHeight: "2.2857142857142856rem",
        fontWeight: 400,
        color: "#f45b5e",
      }}
    >
      There are some errors in your event.
    </div>
  ) : (
    <React.Fragment />
  );
});

const Footer = ({ event, loading, handleBack, submitFunction, setLoading }) => {
  const submittedAt = event && event.attributes.submittedAt;

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            width: 300,
            float: "right",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ color: "#F35B5E" }} />
        </div>
      ) : (
        <React.Fragment>
          <div style={{ float: "right" }}>
            <CancelButton loading={loading} handleBack={handleBack} />
            {!submittedAt && (
              <SaveButton
                event={event}
                loading={loading}
                handleSubmit={submitFunction}
                setLoading={setLoading}
              />
            )}
            <SubmitButton loading={loading} submittedAt={submittedAt} />
          </div>
          <div
            style={{
              float: "right",
              height: 40,
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledErrorMessage />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Footer;
