import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Row, Col, Visible, useScreenClass } from "react-grid-system";

import { styles, Name, Detail } from "./utils";

export default function InfoColumn({ id }) {
  const profile = useSelector(
    (state) => state.profiles.items[id],
    shallowEqual
  );

  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = styles();

  const {
    firstName,
    lastName,
    jobTitle,
    companyName,
    groupName,
    boardMember,
    taLevel,
    companyIndustry,
    companySize,
  } = profile.attributes;

  return (
    <Col
      xs={12}
      lg={8}
      className={classes.infoColumn}
      style={{
        width: isDesktop ? "calc(100% - 260px)" : "100%",
        maxWidth: isDesktop ? "calc(100% - 260px)" : "100%",
        flex: isDesktop ? "0 0 calc(100% - 260px)" : "0 0 100%",
        marginTop: isDesktop ? 0 : 20,
        marginBottom: isDesktop ? 0 : 20,
      }}
    >
      <Row>
        <Visible lg xl xxl>
          <Col xs={12}>
            <Name {...{ firstName, lastName }} />
          </Col>
        </Visible>
        <Col xs={12} lg={taLevel || companyIndustry || companySize ? 6 : 12}>
          <Detail icon={"id-card-alt"}>{jobTitle}</Detail>
          <Detail icon={"city"}>{companyName}</Detail>
          <Detail icon={"map-marked"}>
            <span style={{ fontWeight: boardMember ? 500 : 300 }}>
              {groupName}
              {boardMember && " Board Member"}
            </span>
          </Detail>
        </Col>
        {(taLevel || companyIndustry || companySize) && (
          <Col xs={12} lg={6}>
            <Detail icon={"user"}>{taLevel}</Detail>
            <Detail icon={"briefcase"}>{companyIndustry}</Detail>
            <Detail icon={"building"}>{companySize}</Detail>
          </Col>
        )}
      </Row>
    </Col>
  );
}
