import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { fetchEvent } from "../../actions/eventActions";
import { fetchEventProfiles } from "../../actions/profileActions";
import PaginatedFetch from "../General/PaginatedFetch";
import withGlobalProviders from "../General/withGlobalProviders";
import IndexComponent from "../Profiles/index/IndexComponent";
import { DEFAULT } from "../Profiles/index/order/orderHelpers";

import Toolbar from "./attendees/Toolbar";

function Attendees ({ chapterOptions, eventId }) {
  let location = useLocation();
  const dispatch = useDispatch();

  const event = useSelector((state) => state.events.items[eventId], shallowEqual);

  const queryParams = queryString.parse(location.search);

  const [search, setSearch] = useState(queryParams?.search || "");
  const [order, setOrder] = useState(queryParams?.order || DEFAULT);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [customParams, setCustomParams] = useState({});

  useEffect(() => {
    if (eventId && !event) {
      dispatch(fetchEvent(eventId));
    }
  }, [dispatch, eventId, event]);

  useEffect(() => {
    setCustomParams({ order, search, ...filters });
  }, [order, search, filters]);

  return (
    <PaginatedFetch
    {...{
      fetchFunction: fetchEventProfiles(eventId),
      loading,
      setLoading,
      customParams,
      initialPage: (queryParams.page && parseInt(queryParams.page)) || 1,
    }}
  >
    {({ fetchAction, handlePageSelection, currentPage, totalPages, totalItems, items, loading }) => (
      <IndexComponent
        {...{
          fetchAction,
          handlePageSelection,
          currentPage,
          totalPages,
          search,
          setSearch,
          order,
          setOrder,
          loading,
          setLoading,
          setFilters,
          items,
          chapterOptions,
          toolbar: <Toolbar {...{ eventId, loading, search, setSearch, order, setOrder, totalItems }} />
        }}
      />
    )}
    </PaginatedFetch>
  )
}

export default withGlobalProviders(Attendees);
