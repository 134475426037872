import React from "react";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  errorMessage: {
    color: "#f45b5e",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    "& > button": {
      marginLeft: 10,
    },
  },
});

export const SectionMessage = ({ currentSection, sectionCount }) => (
  <div style={{lineHeight: "36px", margin: "0 10px"}}>
    {currentSection} of {sectionCount}
  </div>
);

const FormFooter = ({ actions, errorMessage, message }) => {
  const styles = useStyles();

  return (
    <div
      className="kt-portlet__foot"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      {errorMessage && <div style={{lineHeight: "36px", color: 'red'}}>
        {errorMessage}
      </div>}
      {message}
      <div
        className={classnames("kt-form__actions", styles.actions)}
      >
        {actions}
      </div>
    </div>
  );
};

export default FormFooter;
