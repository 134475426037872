// Snackbar actions
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const openSnackbar = (content) => ({
  type: OPEN_SNACKBAR,
  payload: content
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR
})

// Modal actions
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";

export const setModalVisible = isVisible => ({
  type: SET_MODAL_VISIBLE,
  payload: isVisible
});
export const openModal = content => ({ type: OPEN_MODAL, payload: content });
export const clearModal = () => ({ type: CLEAR_MODAL });
