// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

import consumer from "./consumer";

window.Sockets = {};

window.Sockets.removeSubscription = (subscription) => {
  return consumer.subscriptions.remove(subscription);
};

const channels = require.context(".", true, /_channel\.js$/);
channels.keys().forEach(channels);
