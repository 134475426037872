import normalize from "json-api-normalizer";
import { POST, DELETE } from "../util/apiHelpers";
import { receiveComments } from "./commentActions";

export const createOpinion = (data) => (dispatch) => {
  return POST(`/api/v1/opinions`, data).then((res) => {
      const normalized = normalize(res.data);

      dispatch(receiveComments(normalized.comment));
  });
};

export const removeOpinion = (data) => (dispatch) => {
  return DELETE(`/api/v1/opinions/${data.opinion_subject_id}`, data).then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receiveComments(normalized.comment));
    }
  );
};
