import React from "react";

import CreateCommentForm from "./CommentForm/CreateCommentForm";

const PollReplyCard = ({ poll }) => (
  <div className="kt-portlet">
    <CreateCommentForm
      handleSuccess={(context) => context.resetForm()}
      staticValues={{ subjectType: "Poll", subjectId: poll.id }}
      style={{ padding: 24 }}
    />
  </div>
);

export default PollReplyCard;
