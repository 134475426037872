import { batch } from "react-redux";
import normalize from "json-api-normalizer";
import find from "lodash/find";

import { GET } from "../util/apiHelpers";
import { mapKeysToSnakeCase } from "../util/formatHelpers";

import { receiveUsers } from "./userActions";

export const RECEIVE_VENDOR_PRODUCTS = "RECEIVE_VENDOR_PRODUCTS";

export const receiveVendorProducts = (vendorProducts, popular) => ({
  type: RECEIVE_VENDOR_PRODUCTS,
  payload: vendorProducts,
  popular: popular,
});

export const fetchVendorProducts =
  (productCategorySlug, vendorProductIds) => (dispatch) => {
    return GET("/api/v1/vendor_products", {
      product_category_slug: productCategorySlug,
      vendorProductIds,
    }).then((res) => {
      const normalizedVendorProducts = normalize(res.data.items);

      return dispatch(
        receiveVendorProducts(
          normalizedVendorProducts.vendorProduct,
          res.data.popular_products
        )
      );
    });
  };

export const fetchVendorProduct =
  (vendorProductSlug, options) => (dispatch) => {
    return GET(`/api/v1/vendor_products/${vendorProductSlug}`, options).then(
      (res) => {
        const normalized = normalize(res.data);
        const vendorProduct = normalized.vendorProduct;

        const subjectProduct = find(
          Object.values(vendorProduct),
          (product) => product.attributes.slug === vendorProductSlug
        );
        subjectProduct.attributes.reviews = normalized.vendorProductReview;

        return batch(() => {
          dispatch(receiveVendorProducts(vendorProduct));
          dispatch(receiveUsers(normalized.user));
        });
      }
    );
  };
