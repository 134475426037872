import React from "react";

import merge from "lodash/merge";

const defaultStyles = {
  label: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    fontWeight: 400,
  },
  input: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    position: "relative",
    borderRadius: 4,
    zIndex: 0,
    "&:focus": {
      borderColor: "#357BA6 !important",
      boxShadow: "0 0 0 1px #357BA6 !important",
    },
  },
};

const TimePickerTextField = ({
  id,
  label,
  value,
  showError,
  helperText,
  onClick,
  onKeyDown,
  onChange,
  inputRef,
  styles: propStyles,
}) => {
  const styles = merge({}, defaultStyles, propStyles, {
    container: {},
    label: showError ? { color: "#f45b5e" } : {},
    input: showError ? { borderColor: "#f45b5e" } : {},
  });

  return (
    <div className="form-group" style={styles.container}>
      <label htmlFor={id} style={styles.label}>
        {label}
      </label>
      <br />
      <div className="input-group time">
        <input
          type="time"
          className="form-control"
          value={moment(value).format("HH:mm")}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onChange={onChange}
          style={showError ? { borderColor: "#f45b5e" } : {}}
          style={styles.input}
          ref={inputRef}
        />
        <div
          className="input-group-append"
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            pointerEvents: "none",
            zIndex: 0,
            border: "1px solid transparent",
            borderLeft: "1px solid #e2e5eb",
          }}
        >
          <span
            className="input-group-text"
            style={{ border: "1px solid transparent" }}
          >
            <i className="la la-clock-o" />
          </span>
        </div>
      </div>
      <div style={{ fontSize: 13, display: "flex" }}>{helperText}</div>
    </div>
  );
};

export default TimePickerTextField;
