import React from "react";
import { createUseStyles } from "react-jss";

import classnames from "classnames";

const useStyles = createUseStyles({
  button: {
    "&:hover": {
      backgroundColor: "#357BA6",
      color: "white",
    },
    color: "#357BA6",
    marginLeft: 12,
    padding: 3,
  },
});

const FooterButton = ({ onClick, buttonText }) => {
  const classes = useStyles();

  return (
    <button
      className={classnames("btn", classes.button)}
      type="button"
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default FooterButton;
