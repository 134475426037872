import React from "react";
import times from "lodash/times";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  starIcon: {
    color: "#FFAE28",
    lineHeight: "25px",
    fontSize: "25px",
    verticalAlign: "middle",
    marginTop: -5,
  },
  smallStarIcon: {
    color: "#FFAE28",
    lineHeight: "18px",
    fontSize: "18px",
    verticalAlign: "middle",
    marginTop: -5,
  },
  noRatingColor: {
    color: "#48465B",
  },
});

const StarRatingDisplay = ({ rating, customInfo, small = false }) => {
  const classes = useStyles();
  let currRating = rating;
  const stars = [];

  times(5, (i) => {
    if (currRating >= 1) {
      stars.push(
        <i
          key={`full${i}`}
          className={`icon-xl la la-star ${small ? classes.smallStarIcon : classes.starIcon} ${
            rating === 0 && classes.noRatingColor
          }`}
        />
      );
    } else if (currRating === 0.5) {
      stars.push(
        <i
          key={`half${i}`}
          className={`icon-xl la la-star-half-alt ${small ? classes.smallStarIcon : classes.starIcon} ${
            rating === 0 && classes.noRatingColor
          }`}
        />
      );
    } else {
      stars.push(
        <i
          key={`empty${i}`}
          className={`icon-xl la la-star-o ${small ? classes.smallStarIcon : classes.starIcon} ${classes.noRatingColor}`}
        />
      );
    }
    currRating--;
  });

  return (
    <div>
      {stars}
      {customInfo}
    </div>
  );
};

export default StarRatingDisplay;
