import React from "react";
import FormHeader from "../../General/FormPage/FormHeader";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const InfoTooltip = () => (
  <Tooltip
    title={"Votes are anonymous and results can only be seen in aggregate."}
  >
    <InfoOutlinedIcon
      style={{ color: "#90A4AE", marginLeft: 5, marginBottom: 2 }}
    />
  </Tooltip>
);

const Header = () => (
  <FormHeader
    title={
      <span>
        New Poll <InfoTooltip />
      </span>
    }
  />
);

export default Header;
