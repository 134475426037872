// status filter options
export const ALL = "ALL";
export const NEW = "NEW";
export const COMPLETED = "COMPLETED";
export const POSTED = "POSTED_DATE";
export const CLOSING = "CLOSING_DATE";
export const MY_POLLS= "MY_POLLS"

export const ALL_POLLS_OPTION = { value: ALL, label: "All Polls" };
export const ALL_GROUPS_OPTION = { value: ALL, label: "My Chapters" };
