import React from "react";
import { Field } from "formik";
import map from "lodash/map";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  starIcon: {
    color: "#FFAE28",
    lineHeight: "25px",
    fontSize: "25px",
    verticalAlign: "middle",
    marginTop: -5,
  },
  noRatingColor: {
    color: "#48465B",
  },
});

const FiveStarRadio = ({
  id,
  label,
  value,
  showError,
  helperText,
  styles = {
    container: {},
    label: {
      fontSize: "1.2857142857142858rem",
      lineHeight: "2.2857142857142856rem",
      fontWeight: 400,
    },
    input: {},
  },
}) => {
  const classes = useStyles();
  return (
    <div className="form-group" style={styles.container || {}}>
      {label && (
        <React.Fragment>
          <label
            htmlFor={id}
            style={Object.assign(
              styles.label || {},
              showError ? { color: "#f45b5e" } : {}
            )}
          >
            {label}
          </label>
          <br />
        </React.Fragment>
      )}
      <div className="star-rating">
        <div className="star-rating__wrap">
          {map([1, 2, 3, 4, 5], (rating) => (
            <label
              key={`${id}-star-rating-${rating}`}
              className="star-rating__ico"
              title={`${rating} out of 5 stars`}
              tabIndex={rating === 1 ? 0 : -1}
            >
              <Field
                className="star-rating__input"
                id={`star-rating-${rating}`}
                type="radio"
                name={id}
                value={rating}
                aria-checked={value === rating}
              />
              <i
                style={{
                  marginLeft: -15,
                  ...(showError ? { color: "#f45b5e" } : {}),
                }}
                className={`icon-xl la ${
                  value >= rating ? "la-star" : "la-star-o"
                } ${classes.starIcon} ${
                  value < rating && classes.noRatingColor
                }`}
              />
            </label>
          ))}
        </div>
      </div>
      {helperText}
    </div>
  );
};

export default FiveStarRadio;
