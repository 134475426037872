import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import map from "lodash/map";

import Item from "./popularVendors/Item";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    "@media (minWidth: 950px)": {
      overflowY: "scroll",
      height: "calc(100vh - 60px)",
    },
  },
  unorderedList: {
    padding: "0px 20px 4px 44px",
    listStyleType: "none",
    "& li+li": {
      margin: "4px 0",
    },
  },
});

const PopularVendors = ({ categoriesIndex }) => {
  const { loaded } = useSelector((state) => state.productCategories);

  const classes = useStyles();
  
  let vendorProducts, popularProducts;

  if (categoriesIndex) {
    vendorProducts = useSelector((state) => state.vendorProducts.items);
  } else {
    popularProducts = useSelector((state) => state.vendorProducts.popular);
  }

  return loaded || popularProducts ? (
    <React.Fragment>
      <div
        style={{
          color: "#595d6e",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 500,
          letterSpacing: "0.07px",
          lineHeight: "20px",
          padding: "20px",
        }}
      >
        Popular Vendors
      </div>
      <ul className={classes.unorderedList}>
        {map(vendorProducts || popularProducts, (item) => {
          if ((typeof item == 'string') || (item instanceof String)) {
            return <Item key={item} productId={item} />;
          } else {
            return <Item key={item.id} vendorProduct={item} />;
          }
        })}
      </ul>
    </React.Fragment>
  ) : null;
};

export default PopularVendors;
