import { makeStyles } from '@material-ui/core';
import Icon from "@material-ui/core/Icon";
import classnames from "classnames";
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { openSnackbar } from '../../../../../actions/uiActions';

const useStyles = makeStyles(() => ({
  outerContainer: {
    justifyContent: "center",
    borderTop: "1px dashed #8c979a",
    paddingTop: "12px",
    paddingBottom: "10px"
    // marginBottom: "-10px"
  },
  container: {
    display: "flex",
    textTransform: "uppercase",
    whiteSpace: "pre-wrap",
    lineHeight: "22px"
  },
  linkedinButton: {
    cursor: "pointer",
    // margin: "-1px 0 0",
    fontSize: 22,
    color: "#0c76aa",
  },
  copyLink: {
    cursor: "pointer",
    color: "rgb(53, 123, 166)",
    fontWeight: "500",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));


const ShareEventActions = ({ id, customClass, trackUrlGoal, trackLinkedinGoal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const { invitationStatus, status, link } = event.attributes;

  if (invitationStatus !== "accepted" || status !== "upcoming") return null;

  const eventUrl = (new URL(link, document.baseURI)).href;

  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${eventUrl}`;

  const onCopy = () => {
    window.fathom && window.fathom.trackGoal(trackUrlGoal, 0);
    navigator.clipboard.writeText(eventUrl);
    dispatch(openSnackbar("Event link copied to clipboard"));
  };

  return (
    <div className={classnames(classes.container, customClass ?? classes.outerContainer)}>
      <span>{"share event:  "}</span>
      <a
        href={linkedinUrl}
        onClick={() => window.fathom && window.fathom.trackGoal(trackLinkedinGoal, 0)}
        target="_blank"
        rel="noreferrer"
        style={{ height: "20px" }}
      >
        <Icon className={classnames(classes.linkedinButton, "fab", "fa-linkedin")} />
      </a>
      {" | "}
      <span className={classes.copyLink} onClick={onCopy}>copy url</span>
    </div>
  );
}

export default ShareEventActions;
