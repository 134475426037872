import React from "react";
import CommentForm from "./CommentForm";
import { useDispatch } from "react-redux";

import { updateComment } from "../../../../actions/commentActions";

const UpdateCommentForm = ({ comment, handleSuccess, ...props }) => {
  const dispatch = useDispatch();

  const { id, body } = comment.attributes;

  const submitFunction = (values, context) =>
    dispatch(updateComment({ id, body: values.body })).then(() =>
      handleSuccess(context)
    );

  return (
    <CommentForm
      submitFunction={submitFunction}
      initialValues={{ body }}
      actionText="Save"
      {...props}
    />
  );
};

export default UpdateCommentForm;
