import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import $ from "jquery";
import InfiniteScroll from "react-infinite-scroller";

import map from "lodash/map";
import ActivityItem from "./Activities/ActivityItem";
import { fetchActivities } from "../../actions/activityActions";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(34,34,34,0.2)",
    backgroundColor: "white",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(34,34,34,0.2)",
    height: "50px",
    fontSize: "18px",
    letterSpacing: "0.07;px",
    lineHeight: "24px",
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  activityContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const RecentActivity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(false);

  const { items, allActivitiesLoaded } = useSelector(
    (state) => state.activities
  );

  const handleMoreActivities = () => {
    if (!fetching) {
      setFetching(true);
      setPage(page + 1);
      dispatch(fetchActivities({ page })).then(() => {
        handleTrixAttachments();
        setFetching(false);
      });
    }
  };

  const handleTrixAttachments = () => {
    // render videos
    $("[data-trix-content-type='video/mp4']").each((_index, attachment) => {
      let url = JSON.parse(attachment.attributes["data-trix-attachment"].value)
        .url;
      $(attachment).prepend(
        `<video controls width="100%"><source src='${url}' type='video/mp4'>Sorry your browser does not support embedded videos. To download click <a href='${url}'>here</a>.</video>`
      );
      $(attachment).removeAttr("data-trix-content-type");
    });

    $("[data-trix-content-type^=application]").each((_index, attachment) => {
      let url = JSON.parse(attachment.attributes["data-trix-attachment"].value)
        .url;
      $(attachment).prepend(
        `<div style="text-align: center; margin-bottom: 4px;"><a target="_blank" rel="noopener noreferrer" href='${url}' style="color: #357BA6;"><i class='fa fa-file' style='font-size: 38px; margin-bottom: 4px;'></i><br/>Download</a>`
      );
      $(attachment).removeAttr("data-trix-content-type");
    });
  };

  return (
    <React.Fragment>
      <div
        className={`kt-portlet kt-portlet__body ${classes.header}`}
        style={{ borderBottom: 0 }}
      >
        Recent Activity
      </div>
      <div className={classes.activityContainer}>
        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleMoreActivities}
            hasMore={!allActivitiesLoaded && !fetching}
            useWindow={true}
          >
            {map(items, (activity) => (
              <ActivityItem activity={activity} key={activity.id} />
            ))}
          </InfiniteScroll>
        </div>
        {items.length === 0 && (
          <div className={classes.footer}>That's all for now!</div>
        )}
        <div
          onClick={handleMoreActivities}
          className="kt-portlet kt-portlet__body"
        >
          {!allActivitiesLoaded && (
            <div className={classes.expandButton} style={{ borderRadius: 5 }}>
              {allActivitiesLoaded ? "That's all for now!" : "Loading..."}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecentActivity;
