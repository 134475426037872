import merge from "lodash/merge";
import moment from "moment-timezone";

import { object, string, array, ref, bool } from "../../../util/yup";
import { END_AT, SCHEDULED_AT } from "./constants";

export const LOCATION_KEYS = [
  "location_name",
  "address",
  "city",
  "state",
  "how_to_find_us",
  "postal_code",
  "country_code",
];

export const WEBINAR_KEYS = [
  "webinar_blurb",
  "webinar_url",
  "g_2_w_webinar_key",
  "zoom_webinar_id",
  "chapter",
];

export const EVENT_KEYS = [
  "title",
  "date",
  SCHEDULED_AT,
  END_AT,
  "photo_url",
  "description",
  "subtitle",
  "webinar",
  "hosts",
  "timezone",
  "is_submit",
];

export const ADMIN_KEYS = ["creator", "chapter"];

export const buildInitialValues = (customValues) => {
  let initValues = {
    title: "",
    date: moment().add(1, "days").format("YYYY-MM-DD"),
    scheduled_at: null,
    end_at: null,
    photo_url: "",
    description: "",
    subtitle: "",
    webinar: null,
    hosts: [],
    is_submit: false,
    timezone: moment.tz.guess(),
    webinar_blurb: "",
    webinar_url: "",
    g_2_w_webinar_key: "",
    zoom_webinar_id: "",
    location_name: "",
    address: "",
    city: "",
    state: "",
    how_to_find_us: "",
    postal_code: "",
    chapter: "",
    countryCode: "",
  };

  const formattedCustomValues = customValues.date
    ? merge({}, customValues, {
        date: moment
          .utc(customValues.scheduled_at)
          .local()
          .format("YYYY-MM-DD"),
      })
    : customValues;

  return merge({}, initValues, formattedCustomValues);
};

export const buildValidationSchema = (webinar, admin) => {
  const eventValidations = {
    title: string().nullable().max(150).required("Cannot be blank"),
    date: string().required("Cannot be blank"),
    scheduled_at: string()
      .earlierTimeThan(ref(END_AT), "Must be earlier than end time.")
      .nullable()
      .required("Cannot be blank"),
    end_at: string()
      .laterTimeThan(ref(SCHEDULED_AT), "Must be later than start time.")
      .nullable()
      .required("Cannot be blank"),
    description: string()
      .nullable()
      .max(3000, "Description of event must be at most 3000 characters")
      .required("Cannot be blank"),
    subtitle: string()
      .nullable()
      .max(150, "Summary of event must be at most 150 characters")
      .required("Cannot be blank"),
    photo_url: string().required("Cannot be blank"),
    hosts: array().min(1, "Must select at least one host"),
    webinar: bool().nullable().oneOf([true, false], "Cannot be blank"),
  };

  const validations = merge(
    {},
    eventValidations,
    webinar
      ? {
          webinar_blurb: string().nullable().required("Cannot be blank"),
          webinar_url: string().nullable().validURL(),
          chapter: string().required("Cannot be blank"),
        }
      : {
          city: string().nullable().required("Cannot be blank"),
          address: string().nullable().required("Cannot be blank"),
          state: string().nullable().required("Cannot be blank"),
          postal_code: string()
            .when("country_code", {
              is: (country_code) => country_code === "US",
              then: string()
                .required("Cannot be blank")
                .matches(/^\d*$/, "Digits only")
                .min(5, "Must be 5 characters")
                .max(5, "Must be 5 characters"),
            })
            .when("country_code", {
              is: (country_code) => country_code === "CA",
              then: string().validCanadianPostalCode(),
            })
            .nullable()
            .required("Cannot be blank"),
        },
    admin
      ? {
          creator: string().required("Cannot be blank"),
          chapter: string().required("Cannot be blank"),
        }
      : {}
  );

  return object().shape(
    admin
      ? merge({}, validations, {
          creator: string().required("Cannot be blank"),
        })
      : validations
  );
};

export const createHandleSubmit = ({
  handleSubmit,
  handleSuccess = () => {},
  handleErrors = () => {},
}) => {
  return (values, context) => {
    return handleSubmit(values)
      .then(handleSuccess)
      .catch(() => handleErrors(context));
  };
};
