import normalize from "json-api-normalizer";
import { POST } from "../util/apiHelpers";
import { mapKeysToSnakeCase } from "../util/formatHelpers";

export const createChapterAnnouncement = (data) => {
  return POST(
    `/api/v1/announcement/create_chapter_announcement/${data.chapterId}`,
    mapKeysToSnakeCase(data)
  ).then((res) => {
    return res.data;
  });
};

export const createPastEventAnnouncement = (data) => {
  return POST(
    `/api/v1/announcement/create_past_event_announcement/${data.eventSlug}`,
    mapKeysToSnakeCase(data)
  ).then((res) => {
    return res.data;
  });
};
