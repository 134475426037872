import React from "react";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

const FieldGroupHeader = ({
  title,
  iconClass,
  iconStyles = {},
  overrideStyles,
  tooltipText,
}) => {
  return (
    <div
      className={classnames("kt-heading", "kt-heading--md")}
      style={Object.assign(
        {},
        { display: "flex", alignItems: "center" },
        overrideStyles
      )}
    >
      {iconClass && (
        <i
          className={iconClass}
          style={Object.assign({}, { marginRight: 16 }, iconStyles)}
        />
      )}
      <div>{title}</div>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <InfoOutlined style={{ color: "#90A4AE", marginLeft: 5 }} />
        </Tooltip>
      )}
    </div>
  );
};

export default FieldGroupHeader;
