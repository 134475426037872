import React from "react";
import { createUseStyles } from "react-jss";
import { Hidden, Visible } from "react-grid-system";
import ProductLogo from "./ProductLogo";
import Title from "./Title";
import PopoverLink from "../../General/PopoverLink";
import StarRatingDisplay from "./StarRatingDisplay";
import ReviewsPopoverContent from "./ReviewsPopoverContent";

const useStyles = createUseStyles({
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  description: {
    fontSize: "1.125rem",
    lineHeight: "1.6375rem",
    color: "#595D6E",
    "&:hover": {
      color: "#48465B",
      textDecoration: "underline !important",
    },
  },
  descriptionNoHover: {
    fontSize: "1.125rem",
    lineHeight: "1.6375rem",
    color: "#595D6E",
  },
  ctaIcon: {
    marginLeft: 3,
    lineHeight: "34px",
    fontSize: "1.65rem",
    verticalAlign: "middle",
    marginTop: -5,
  },
  reviewIcon: {
    color: "#FFAE28",
    lineHeight: "25px",
    fontSize: "25px",
    verticalAlign: "middle",
    marginTop: -5,
  },
  reviewLink: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#48465B",
    fontWeight: 400,
    marginLeft: 8,
  },
});

const text_truncate = function (str, length, ending) {
  if (length == null) {
    length = 170;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const ProductHeader = ({ options, product }) => {
  const classes = useStyles();

  const {
    name,
    logoUrl,
    description,
    pageUrl,
    overallRating,
    vendorProductReviewsCount,
  } = product.attributes;
  if (!options) {
    options = {
      justifyContent: "flex-start",
      showDescription: false,
      titleLink: false,
      shrinkLogo: false,
      reviewLink: true,
      hideCTA: false,
    };
  }

  let content;

  const ctaLink = !options.hideCTA && (
    <div>
      {options.reviewLink && (
        <a
          href={product.attributes.websiteLink}
          className={classes.ctaLink}
          target="_blank"
          rel="noopener"
        >
          Visit Website
          <i className={`icon-xl la la-external-link ${classes.ctaIcon}`}></i>
        </a>
      )}
      {options.reviewLink ? (
        <a
          className="btn btn-sm btn-brand-secondary"
          style={{ marginLeft: 12 }}
          href={pageUrl ? `${pageUrl}/review` : ""}
        >
          Write A Review
        </a>
      ) : (
        <a
          href={
            vendorProductReviewsCount > 0
              ? `${product.attributes.pageUrl}/reviews`
              : product.attributes.pageUrl
              ? `${product.attributes.pageUrl}/review`
              : ""
          }
          className={`btn btn-sm btn-brand-secondary`}
        >
          {vendorProductReviewsCount > 0
            ? "View Reviews"
            : "Be The First To Review"}
        </a>
      )}
    </div>
  );

  if (options.showDescription) {
    content = options.hideCTA ? (
      <p className={classes.descriptionNoHover}>{text_truncate(description)}</p>
    ) : (
      <a href={pageUrl} className={classes.description}>
        {text_truncate(description)}
      </a>
    );
  }

  return (
    <React.Fragment>
      <ProductLogo logoUrl={logoUrl} shrink={options.shrinkLogo} />
      <div
        className={classes.infoContainer}
        style={{ justifyContent: options.justifyContent }}
      >
        <Title
          name={name}
          pageUrl={pageUrl}
          ctaLink={ctaLink}
          titleLink={options.titleLink}
        />
        <div style={{ display: "flex", margin: "12px 0" }}>
          <StarRatingDisplay rating={overallRating} />
          {vendorProductReviewsCount > 0 && (
            <PopoverLink
              cardStyle={{ marginTop: 10, marginLeft: 0 }}
              renderLink={(handleClick, _isSelected) => (
                <i
                  onClick={handleClick}
                  style={{
                    width: 20,
                    height: 23,
                    margin: 4,
                    cursor: "pointer",
                  }}
                  className="fas fa-chevron-down"
                ></i>
              )}
              renderPopoverContent={() => (
                <ReviewsPopoverContent product={product} />
              )}
            />
          )}
          <div className={classes.reviewLink}>
            {vendorProductReviewsCount > 0 ? (
              <a
                style={{ color: "#367BA6" }}
                href={pageUrl ? `${pageUrl}/reviews` : ""}
              >
                {vendorProductReviewsCount} Reviews
              </a>
            ) : (
              <div>0 Reviews</div>
            )}
          </div>
        </div>
        <Hidden lg xl xxl>
          <div style={{ marginBottom: 10 }}>{ctaLink}</div>
        </Hidden>
        {content}
      </div>
    </React.Fragment>
  );
};

export default ProductHeader;
