import React from "react";
import classnames from "classnames";

const FormFooter = ({ handleSubmit, error, isSubmitting }) => {
  const showErrorMessage = Boolean(error);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 10,
        padding: "0 0 25px 25px",
      }}
    >
      <div className="kt-form__actions">
        <button
          onClick={handleSubmit}
          className={classnames(
            "btn",
            "btn-brand",
            "btn-md",
            "btn-tall",
            "btn-wide",
            "kt-font-bold",
            "kt-font-transform-u",
            { disabled: isSubmitting }
          )}
        >
          Update
        </button>
      </div>
      {showErrorMessage && (
        <div style={{ color: "#f45b5e", marginLeft: 10 }}>{error}</div>
      )}
    </div>
  );
};

export default FormFooter;
