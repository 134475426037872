import consumer from "./consumer";

window.Sockets.createNotificationsSubscription = () => {
  consumer.subscriptions.create(
    {
      channel: "NotificationsChannel"
    },
    {
      received: function (notification) {
        if (!notification.group_owner_id) {
          $("#notifications-list").prepend($(notification.html));
          notification.unopened_notifications_count && $(
            "#notifications-count-container"
          ).css("display", '');
          $("#notifications-count-container").html(
            notification.unopened_notifications_count
          );
        }
      },
    }
  );
};
