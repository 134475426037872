import React, { useEffect } from "react";
import { confettiSpray } from "../../util/animationHelpers";
import moment from "moment";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { MODAL_EVENT_CONVERSATION } from "../../util/fathomEventCodes";

const useStyles = makeStyles(() => ({
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  actions: {
    flexDirection: "row",
    borderTop: "1px solid #EBEDF3",
    padding: "1.5rem",
  },
}));

const EventAcceptModal = ({ open, handleClose, ...props }) => {
  const classes = useStyles();
  const timeZone = new Date()
    .toLocaleTimeString(undefined, { timeZoneName: "short" })
    .split(" ")[2];

  useEffect(() => {
    if (open) confettiSpray();
  }, [open]);

  if (!open) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle classes={{ root: classes.title }}>
        Amazing! We will see you on{" "}
        {moment(props.scheduledAt).format("MMMM Do [at] h:mm A ")} {timeZone}
      </DialogTitle>
      <DialogContent
        style={{ textAlign: "center", padding: "1rem", fontSize: 15 }}
      >
        <div style={{ marginBottom: 16 }}>
          Add to Calendar:{" "}
          <a target="_blank" rel="noopener noreferrer" href={props.calendarUrl}>
            Outlook/iCal
          </a>{" "}
          |{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.googleCalendarUrl}
          >
            Google
          </a>
        </div>
        <div style={{ marginBottom: 16 }}>
          Join {props.acceptedInvitationsCount} members in a conversation about
          this event! Interact with others, ask questions, and share ideas.
          <br />
        </div>
        {props.webinar && (
          <div>
            The webinar recording will be uploaded in the conversation after the
            webinar.
          </div>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <button
          className="btn btn-outline-brand-secondary"
          onClick={handleClose}
        >
          Not Now
        </button>
        <a href={props.conversationPath}>
          <button
            className="btn btn-secondary"
            onClick={() =>
              window.fathom &&
              window.fathom.trackGoal(MODAL_EVENT_CONVERSATION, 0)
            }
          >
            Join the converstation
          </button>
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default EventAcceptModal;
