import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import includes from "lodash/includes";

import { ConnectedMultiToggle } from "../../General/FormInputs/ConnectedInputs";
import AvatarIcon from "../../General/AvatarIcon";

const CoHostsMultiToggle = () => {
  const { values, setFieldValue } = useFormikContext();
  const { chapter: selectedChapterId, creator } = values;

  const selectBoardMembersAndAdmins = createSelector(
    (state) => state.users.items,
    (state) => state.groups.items,
    (users, chapters) => {
      const selectedChapter = chapters[selectedChapterId];

      return filter(users, (user) => {
        if (selectedChapter && !selectedChapter.attributes.isNational) {
          return (
            user.attributes.admin ||
            (user.attributes.boardMember &&
              selectedChapter &&
              includes(
                user.attributes.chapters,
                selectedChapter.attributes.name
              ))
          );
        } else {
          return user.attributes.admin || user.attributes.boardMember;
        }
      });
    }
  );

  const boardMembersAndAdmins = useSelector(selectBoardMembersAndAdmins);
  const settings = useSelector((state) => state.settings);
  const { admin } = settings.attributes;

  const options = orderBy(
    map(boardMembersAndAdmins, (user) => ({
      value: user.id,
      label: (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <AvatarIcon height={40} member={user} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 20,
              marginBottom: 12,
            }}
          >
            <span>
              {`${user.attributes.firstName} ${user.attributes.lastName}`}
            </span>
            <span>{`${user.attributes.companyName}`}</span>
          </div>
        </div>
      ),
    })),
    "label",
    "asc"
  );

  useEffect(() => {
    if (!creator && !admin) {
      setFieldValue("hosts", [settings.attributes.id]);
    }
  }, [creator, admin]);

  return (
    <ConnectedMultiToggle
      id="hosts"
      label={
        <div>
          <div
            style={{
              fontSize: "1.28571rem",
              lineHeight: "2.28571rem",
              fontWeight: 400,
            }}
          >
            Co-Hosts
          </div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "300",
              fontFamily: "Poppins",
              lineHeight: "2.28571rem",
            }}
          >
            Co-hosts will be listed on the event.
          </div>
        </div>
      }
      options={options}
    />
  );
};

export default CoHostsMultiToggle;
