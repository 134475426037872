import React from "react";

import FieldGroupHeader from "../../../General/FormCard/FieldGroupHeader";

import {
  FirstNameField,
  LastNameField,
  PersonalEmailField,
  LinkedinUrlField,
  WorkEmailField,
  PrimaryEmailAddressField,
} from "../../shared/FormFields";

const Fields = () => (
  <React.Fragment>
    <div className="row">
      <div className="col-lg-6">
        <FirstNameField />
      </div>
      <div className="col-lg-6">
        <LastNameField />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <PersonalEmailField />
      </div>
      <div className="col-lg-6">
        <LinkedinUrlField />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <WorkEmailField />
      </div>
      <div className="col-lg-6">
        <PrimaryEmailAddressField />
      </div>
    </div>
  </React.Fragment>
);

export default Fields;
