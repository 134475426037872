import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import sanitizeHtml from "sanitize-html";
import Tooltip from "@material-ui/core/Tooltip";

import UserInfo from "./UserInfo";
import CollapsableText from "./CollapsableText";
import UpdatePostForm from "./UpdatePostForm";

const useStyles = createUseStyles({
  container: {
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 50,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 25,
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  footerButtons: {
    display: "flex",
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 500,
    color: "#48465B",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  trixContainer: {
    backgroundColor: "white",
  },
});

const getGradientColor = {
  comment: "#F4FAFE",
  topic: "white",
};

const Description = (props) => {
  const classes = useStyles();
  const { editable } = props;
  const text = sanitizeHtml(props.attributes.body);

  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          <UserInfo {...props} />
          {editable && !editing && (
            <div
              onClick={() => setEditing(true)}
              className={classes.button}
              style={{
                display: "flex",
                justifyContent: "center",
                width: 25,
                alignItems: "center",
              }}
            >
              <Tooltip title="Edit">
                <i className="flaticon2-edit" style={{ fontSize: 12 }}></i>
              </Tooltip>
            </div>
          )}
        </div>
        {editing ? (
          <UpdatePostForm
            id={props.id}
            category={props.category}
            initialValues={{ body: text }}
            setEditing={setEditing}
          />
        ) : (
          <CollapsableText
            text={props.attributes.body}
            expanded={expanded}
            truncated={truncated}
            setTruncated={setTruncated}
            gradientColor={getGradientColor[props.category]}
            category={props.category}
          />
        )}
        <div>
          <div className={classes.buttonContainer}>
            <div>{props.children}</div>
            {truncated && !expanded && (
              <div
                className={classes.expandButton}
                onClick={() => setExpanded(true)}
                style={{ padding: 12 }}
              >
                See Full
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Description;
