import React from "react";
import { connect } from "formik";

import ProductHeader from "../general/ProductHeader";

const SearchResult = ({ searchResults, formik: { handleSubmit } }) => {
  return (
    <div style={{ padding: 24 }}>
      <div
        style={{
          textAlign: "center",
          fontWeight: 400,
          color: "#48465B",
          marginBottom: 12,
          fontSize: "1.2857142857142858rem",
          lineHeight: "2.2857142857142856rem",
        }}
      >
        Is this the vendor you were looking for?
      </div>
      <div
        style={{
          padding: 12,
          border: "1px solid #979797",
          display: "flex",
          flexDirection: "row",
          msFlexDirection: "row",
        }}
      >
        <ProductHeader
          product={searchResults[0]}
          options={{
            justifyContent: "center",
            showDescription: true,
            titleLink: false,
            hideCTA: true,
          }}
        />
      </div>
      <div
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "row",
          msFlexDirection: "row",
          justifyContent: "center",
        }}
      >
        <a
          className="btn btn-outline-brand-secondary"
          style={{ marginLeft: 10 }}
          href={searchResults[0].attributes.pageUrl}
        >
          Yes, View profile
        </a>
        <button
          className="btn btn-brand-secondary"
          style={{ marginLeft: 10 }}
          onClick={handleSubmit}
          type="button"
        >
          No, Submit
        </button>
      </div>
    </div>
  );
};

export default connect(SearchResult);
