import { RECEIVE_USERS, RECEIVE_USER } from "../actions/userActions";
import merge from "lodash/merge";
import keys from "lodash/keys";

const defaultState = {
  currentUserId: null,
  items: {},
};

const userReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_USERS:
      return merge({}, { items: action.payload }, state);
    case RECEIVE_USER:
      return merge({}, state, { items: action.payload.user });
    default:
      return state;
  }
};

export default userReducer;
