import makeStyles from "@material-ui/styles/makeStyles";
import includes from "lodash/includes";
import map from "lodash/map";
import React, { forwardRef, useEffect, useRef } from "react";

import { useSelector } from "react-redux";

import {
  ATTACHMENT,
  MESSAGE,
  REMOVE_USER,
  ADD_USER
} from "../../../../selectors/conversationSelectors";
import {
  LOADED, LOADING, IDLE, ERROR
} from "../../../../util/apiHelpers";
import {
  useConversationReader,
  useIntersectionObserver,
} from "../../partials/hooks";
import MessagesIndexSkeleton from "../skeleton/MessagesIndexSkeleton";

import AddedUserEvent from "./AddedUserEvent";
import AttachmentContent from "./AttachmentContent";
import ConversationEventsLoader from "./ConversationEventsLoader";
import EventGroupHeader from "./EventGroupHeader";
import MessageContent from "./MessageContent";
import RemovedUserEvent from "./RemovedUserEvent";

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}));

const TypedEventItem = forwardRef(({ event }, ref) => {
  switch (event.attributes.eventType) {
    case MESSAGE:
      return <MessageContent ref={ref} id={event.attributes.subjectId} />;
    case ATTACHMENT:
      return <AttachmentContent ref={ref} id={event.attributes.subjectId} />;
    case REMOVE_USER:
      return <RemovedUserEvent ref={ref} event={event} />;
    case ADD_USER:
      return <AddedUserEvent ref={ref} event={event} />;
    default:
      return <></>;
  }
});

TypedEventItem.displayName = "TypedEventItem";

function EventsList({ items, onScrollBottom }) {
  const classes = useStyles();
  const listEndRef = useRef();

  useEffect(() => {
    listEndRef.current &&
      listEndRef.current.scrollIntoView({ behavior: "auto", block: "end" });
  }, [listEndRef.current]);

  const observedBottomRefEntry = useIntersectionObserver(listEndRef);

  useEffect(() => {
    onScrollBottom &&
      observedBottomRefEntry &&
      observedBottomRefEntry.isIntersecting &&
      onScrollBottom();
  }, [observedBottomRefEntry, onScrollBottom]);

  return (
    <div className={classes.listContainer}>
      {map(items, ({ primaryEvent, events }, groupIdx) => (
        <React.Fragment key={`${primaryEvent.id}-fragment`}>
          {includes(
            [ATTACHMENT, MESSAGE],
            primaryEvent.attributes.eventType
          ) && <EventGroupHeader event={primaryEvent} />}
          {map(events, (event, eventIdx) => (
            <TypedEventItem
              key={event.id}
              event={event}
              ref={
                groupIdx === items.length - 1 && eventIdx === events.length - 1
                  ? listEndRef
                  : null
              }
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

function ConversationEventsIndex({ conversationId, onScrollBottom }) {
  const conversation = useSelector(
    (state) => conversationId && state.conversations.items[conversationId]
  );

  useConversationReader(conversationId);

  if (!conversation) return <></>;

  const renderContent = ({ loadStatus, items }) => {
    switch (loadStatus) {
      case LOADED:
        return <EventsList items={items} onScrollBottom={onScrollBottom} />;
      case LOADING:
        return <MessagesIndexSkeleton />;
      case IDLE:
      default:
        return <></>;
    }
  };

  return (
    <ConversationEventsLoader conversationId={conversation.id}>
      {renderContent}
    </ConversationEventsLoader>
  );
}

export default ConversationEventsIndex;
