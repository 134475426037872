import React from "react";

const FormContainer = ({ children }) => (
  <div
    style={{
      marginTop: "auto",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }}
  >
    {children}
  </div>
);

export default FormContainer;
