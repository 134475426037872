import React from "react";
import map from "lodash/map";

const withActionHeader = (Component, Actions) => (props) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          position: "absolute",
          alignItems: "center",
          right: 0,
          top: -62,
          height: 44,
        }}
      >
        {map(Actions, (Action, idx) => (
          <Action key={idx} />
        ))}
      </div>
      <Component {...props} />
    </div>
  );
};

export default withActionHeader;
