import {
  filter, orderBy, map, reduce, merge
} from "lodash";
import {
  useEffect, useMemo, useState, useCallback
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchConversations } from "../../actions/conversationActions";
import { makeGetFilteredConversations } from "../../selectors/conversationSelectors";
import {
  IDLE, LOADING, LOADED, ERROR
} from "../../util/apiHelpers";
import { usePrevious } from "../../util/customHooks";

const PER_PAGE = 5;

const determineMinTimestamp = (conversations) => reduce(
  conversations,
  (minTimestamp, conversation) => {
    if (minTimestamp && minTimestamp < conversation.attributes.lastEventAt) {
      return minTimestamp;
    }

    return conversation.attributes.lastEventAt;
  },
  null
);

const ConversationsLoader = ({
  priorityConversationId,
  searchQuery,
  children,
}) => {
  const dispatch = useDispatch();
  const [loadStatus, setLoadStatus] = useState(IDLE);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [minTimestamp, setMinTimestamp] = useState(null); // ensures that we're displaying only fetched (or action cable received) conversations
  const [searchedConversationIds, setSearchedConversationIds] = useState([]);
  const getFilteredConversations = useMemo(makeGetFilteredConversations, []);
  const prevSearchQuery = usePrevious(searchQuery);
  const prevLoadStatus = usePrevious(loadStatus);

  const filteredConversations = useSelector((state) => getFilteredConversations(state, {
    minLastEventAt: minTimestamp,
    includedIds: searchQuery ? searchedConversationIds : undefined,
  }));

  const orderedConversations = useMemo(
    () => orderBy(
      filteredConversations,
      [
        ({ id }) => (priorityConversationId && id === priorityConversationId ? 1 : -1),
        ({ attributes }) => attributes.lastEventAt,
      ],
      ["desc", "desc"]
    ),
    [filteredConversations, priorityConversationId]
  );

  const fetchMore = useCallback(() => {
    if (loadStatus === LOADING || fullyLoaded) return;

    setLoadStatus(LOADING);

    const buildFetchParams = () => {
      const params = { perPage: PER_PAGE, page, query: searchQuery };

      if (priorityConversationId && page === 1 && !searchQuery) {
        merge(params, { priorityConversationId });
      }

      return params;
    };

    dispatch(fetchConversations(buildFetchParams()))
      .then(({ conversations, metaData }) => {
        setLoadStatus(LOADED);
        setInitiallyLoaded(true);
        setPage(page + 1);
        setFullyLoaded(metaData.currentPage >= metaData.totalPages);

        if (Boolean(searchQuery)) {
          setSearchedConversationIds((currSearchedConversationIds) => currSearchedConversationIds.concat(
            map(conversations, ({ id }) => id)
          ));
        }
        else {
          setMinTimestamp(
            determineMinTimestamp(
              filter(conversations, ({ id }) => id !== priorityConversationId)
            )
          );
        }
      })
      .catch(() => {
        setLoadStatus(ERROR);
        setInitiallyLoaded(true);
      });
  }, [dispatch, fullyLoaded, loadStatus, page, priorityConversationId, searchQuery]);

  useEffect(() => {
    !initiallyLoaded && fetchMore();
  }, [fetchMore, initiallyLoaded]);

  useEffect(() => {
    if (initiallyLoaded && searchQuery !== prevSearchQuery) {
      setFullyLoaded(false);
      setPage(1);
      setMinTimestamp(null);
      setSearchedConversationIds([]);
      setLoadStatus(IDLE);
    }
  }, [searchQuery, prevSearchQuery, initiallyLoaded]);

  useEffect(() => {
    if (loadStatus === IDLE && prevLoadStatus !== IDLE) fetchMore();
  }, [fetchMore, loadStatus, prevLoadStatus]);

  return children({
    loadStatus,
    conversations: orderedConversations,
    initiallyLoaded,
    fetchMore,
    fullyLoaded,
  });
};

export default ConversationsLoader;
