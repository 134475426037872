import { RECEIVE_ORGANIZATIONS } from "../actions/organizationActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  loaded: false,
};

const organizationsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ORGANIZATIONS:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default organizationsReducer;
