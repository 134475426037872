import React, {
  useRef, useEffect, useState, useCallback
} from "react";
import { useSelector } from "react-redux";
import Truncate from "react-truncate";

import {
  MESSAGE,
  ATTACHMENT,
  REMOVE_USER,
  ADD_USER,
  getAttachment,
  getMessage,
} from "../../../../../selectors/conversationSelectors";
import { buildFullName } from "../../../../../util/formatHelpers";
import AddedUserText from "../../../partials/AddedUserText";
import RemovedUserText from "../../../partials/RemovedUserText";

import useSharedStyles from "./useSharedStyles";

function MessageContent({ id }) {
  const message = useSelector((state) => getMessage(state, { id }));
  const { body, userId } = message.attributes;

  const user = useSelector((state) => userId && state.users.items[userId]);
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  if (!user) {
    return <></>;
  }

  const senderName =
    user.id === currentUserId
      ? "You"
      : buildFullName(user.attributes.firstName, user.attributes.lastName);

  return `${senderName}: ${body}`;
}

function AttachmentContent({ id }) {
  const attachment = useSelector((state) => getAttachment(state, { id }));

  const { fileName, userId } = attachment.attributes;

  const user = useSelector((state) => userId && state.users.items[userId]);
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  if (!user) {
    return <></>;
  }

  const senderName =
    user.id === currentUserId
      ? "You"
      : buildFullName(user.attributes.firstName, user.attributes.lastName);

  return `${senderName}: ${fileName}`;
}

function EventContent({ event }) {
  switch (event.attributes.eventType) {
    case MESSAGE:
      return <MessageContent id={event.attributes.subjectId} />;
    case ATTACHMENT:
      return <AttachmentContent id={event.attributes.subjectId} />;
    case REMOVE_USER:
      return <RemovedUserText event={event} />;
    case ADD_USER:
      return <AddedUserText event={event} />;
    default:
      return <></>;
  }
}

function ConversationItemBody({ recentEvent }) {
  const containerRef = useRef();
  const [width, setWidth] = useState(0);

  const adjustRefWidth = useCallback(() => {
    containerRef.current && setWidth(containerRef.current.offsetWidth);
  }, [containerRef, setWidth]);

  useEffect(() => {
    adjustRefWidth();

    window.addEventListener("resize", adjustRefWidth);

    return () => {
      window.removeEventListener("resize", adjustRefWidth);
    };
  }, [adjustRefWidth]);

  const classes = useSharedStyles();

  return (
    <div className={classes.bodyContent} ref={containerRef}>
      <Truncate lines={2} width={width}>
        {recentEvent && <EventContent event={recentEvent} />}
      </Truncate>
    </div>
  );
}

export default ConversationItemBody;
