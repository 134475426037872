import React, { useContext } from "react";
import MuiChip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

import { FilterContext } from "./Filter";

const Chip = withStyles((theme) => ({
  root: {
    backgroundColor: "#EBEDF2",
    fontSize: 13,
    borderRadius: 8,
    marginRight: 6,
    marginBottom: 6,
    transition: "none",
  },
}))(MuiChip);

export default function FilterTag({ id, label, color }) {
  const { context } = useContext(FilterContext);
  const { handleChangeState } = useContext(context);

  return (
    <Chip
      label={label}
      style={{ backgroundColor: color || "#EBEDF2" }}
      onDelete={() => handleChangeState(false, id, label, false, color)}
    />
  );
}
