import React from "react";
import { connect } from "formik";

import some from "lodash/some";

import FormFooter from "../../General/FormPage/FormFooter";
import { sectionFields } from "./fieldHelpers";

const SubmitButton = connect(
  ({
    formik: { setFieldTouched, setFieldError, validateForm, handleSubmit },
    currentSection,
  }) => {
    const setSectionFieldsTouched = (section) => {
      sectionFields[section].forEach((field) => {
        setFieldTouched(field, true);
      });
    };

    const handleClick = () => {
      // setFieldsTouched
      setSectionFieldsTouched(currentSection);
      // validateForm
      validateForm().then((errors) => {
        const sectionHasErrors = some(
          sectionFields[currentSection],
          (fieldName) => errors[fieldName]
        );

        if (sectionHasErrors) {
          setFieldError(
            "formError",
            "Please review the field(s) highlighted in red."
          );
        } else {
          handleSubmit();
        }
      });
    };

    return (
      <button
        className="btn btn-brand-secondary"
        style={{ marginLeft: 10 }}
        onClick={handleClick}
        type="button"
      >
        Submit
      </button>
    );
  }
);

const CancelButton = () => {
  return (
    <a
      className="btn btn-outline-brand-secondary"
      href="#"
      style={{marginLeft: 10 }}
      onClick={() => window.history.back()}
    >
      Cancel
    </a>
  );
};

const Footer = connect(({ formik: { errors }, currentSection }) => {
  return (
    <FormFooter
      errorMessage={errors.formError}
      actions={
        <React.Fragment>
          <CancelButton />
          <SubmitButton {...{ currentSection }} />
        </React.Fragment>
      }
    />
  );
});

export default Footer;
