import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ActionModalContainer from "../../ActionModal/ActionModalContainer";
import ActionModalHeader from "../../ActionModal/ActionModalHeader";
import ActionModalContent from "../../ActionModal/ActionModalContent";
import ActionModalFooter from "../../ActionModal/ActionModalFooter";

import EditImageControls from "./EditImageControls";
import { clearModal } from "../../../../actions/uiActions";
import { uploadFile } from "../../../../util/uploadHelpers";

const ImageUploaderModal = ({
  onSuccess,
  errorMessage,
  uploadFolder,
  editingImage,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(clearModal());

  const [editor, setEditor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = (avatarSubmission) => {
    const reader = new FileReader();

    reader.onload = () => {
      uploadFile({
        file: avatarSubmission,
        uploadFolder,
        success: (imageUrl) => {
          onSuccess(imageUrl);
          setLoading(false);
          handleClose();
        },
        failure: () => {
          errorMessage && setError(errorMessage);
          setLoading(false);
        },
        progress: () => setLoading(true),
      });
    };

    reader.readAsDataURL(avatarSubmission);
  };

  const handleSubmit = () => {
    const canvas = editor.getImage();
    let context = canvas.getContext("2d");

    context.globalCompositeOperation = "destination-over";
    context.fillStyle = "#FFFFFF";
    context.fillRect(0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      handleSave(blob);
    });
  };

  return (
    <ActionModalContainer>
      <ActionModalHeader title={"Upload Event Image"} />
      <ActionModalContent>
        {editingImage ? (
          <EditImageControls
            editingImage={editingImage}
            width={500}
            height={281}
            setEditor={setEditor}
          />
        ) : (
          <div
            style={{
              width: 300,
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No image found.
          </div>
        )}
      </ActionModalContent>
      <ActionModalFooter
        customButtons={[
          <button
            key="cancel-modal-btn"
            className="btn btn-sm btn-outline-brand-secondary"
            onClick={handleClose}
            style={{ margin: 10 }}
            disabled={loading}
          >
            cancel
          </button>,
          <button
            key="submit-modal-btn"
            className="btn btn-sm btn-brand-secondary"
            onClick={handleSubmit}
            style={{ margin: 10 }}
            disabled={loading}
          >
            save
          </button>,
        ]}
        customErrorMessage={error}
      />
    </ActionModalContainer>
  );
};

export default ImageUploaderModal;
