import React from "react";
import classnames from "classnames";
import { Row, Col } from "react-grid-system";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  classificationsRow: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  classificationsCol: {
    display: "flex",
    flexDirection: "column",
  },
  boardMemberStar: {
    color: "#357BA6",
  },
}));

const Classifications = ({ user }) => {
  const classes = useStyles();

  const {
    attributes: {
      chapters,
      boardMember,
      taLevel,
      companyIndustry,
      companySize,
    },
  } = user;

  return (
    <Row nogutter className={classes.classificationsRow}>
      <Col className={classes.classificationsCol}>
        <span>
          <i className="la la-map-marked"></i> {chapters[0]}{" "}
          {boardMember && (
            <i
              className={classnames("la la-star", classes.boardMemberStar)}
            ></i>
          )}
        </span>
        {taLevel && (
          <span>
            <i className="la la-user"></i> {taLevel}
          </span>
        )}
      </Col>
      <Col className={classes.classificationsCol}>
        {companyIndustry && (
          <span>
            <i className="la la-briefcase"></i> {companyIndustry}
          </span>
        )}
        {companySize && (
          <span>
            <i className="la la-building"></i> {companySize}
          </span>
        )}
      </Col>
    </Row>
  );
};

export default Classifications;
