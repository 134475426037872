import React from "react";
import { useSelector } from "react-redux";

import AvatarIcon from "../../../../General/AvatarIcon";
import { AVATAR_SIZE } from "./useSharedStyles";

const CurrentUserAvatar = () => {
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  return <AvatarIcon avatarId={currentUserId} height={AVATAR_SIZE} />;
};

const ConversationItemAvatar = ({ recentMessage }) =>
  recentMessage ? (
    <AvatarIcon
      avatarId={recentMessage.attributes.userId}
      height={AVATAR_SIZE}
    />
  ) : (
    <CurrentUserAvatar />
  );

export default ConversationItemAvatar;
