import React from "react";

import Select from "react-select";

import get from "lodash/get";
import find from "lodash/find";

const customStyles = {
  singleValue: (base) => ({
    ...base,
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    color: "#495057",
    fontWeight: 400,
  }),
  multiValue: (base) => ({
    ...base,
    paddingLeft: 5,
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
  }),
  valueContainer: (base) => ({
    ...base,
    lineHeight: "2.2857142857142856rem",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1.2857142857142858rem",
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? "#357BA6" : "inherit",
    "&:hover": {
      backgroundColor: isSelected ? "#357BA6" : "#e2e8ea",
    },
  }),
  menuList: (base, state) => {
    const customStyles = get(state, "selectProps.customStyles.menuList") || {};

    return ({
      ...base,
      ...customStyles
    });
  },
  control: (base, state) => {
    let controlStyle = {
      ...base,
      borderColor: "#e2e5ec",
      boxShadow: "0 0 0 0 #357BA6 !important",
    };

    if (state.isFocused) {
      controlStyle = {
        ...base,
        borderColor: "#357BA6 !important",
        boxShadow: "0 0 0 0 #357BA6 !important",
        "&:focus": {
          borderColor: "#357BA6 !important",
          boxShadow: "0 0 0 0 #357BA6 !important",
        },
        "&:active": {
          borderColor: "#357BA6 !important",
          boxShadow: "0 0 0 0 #357BA6 !important",
        },
      };
    } else if (state.selectProps.isError) {
      controlStyle = {
        ...base,
        borderColor: "#F35B5E !important",
        boxShadow: "0 0 0 0 #F35B5E !important",
        "&:focus": {
          borderColor: "#F35B5E !important",
          boxShadow: "0 0 0 0 #F35B5E !important",
        },
        "&:active": {
          borderColor: "#F35B5E !important",
          boxShadow: "0 0 0 0 #F35B5E !important",
        },
      };
    }

    return controlStyle;
  },
};

const Dropdown = ({
  id,
  label,
  value = "",
  options,
  onChange,
  onBlur,
  showError,
  helperText,
  styles = {},
  selectProps = {},
}) => {
  const selectedOption = find(options, (option) => option.value === value);

  return (
    <div className="form-group" style={styles.container || {}}>
      <label
        htmlFor={id}
        style={{
          fontSize: "1.2857142857142858rem",
          lineHeight: "2.2857142857142856rem",
          fontWeight: 400,
          ...(showError ? { color: "#f45b5e" } : {}),
        }}
      >
        {label}
      </label>
      <br />
      <Select
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        styles={customStyles}
        isError={showError}
        value={selectedOption}
        menuPlacement="auto"
        {...selectProps}
      />
      {helperText}
    </div>
  );
};

export default Dropdown;
