const yup = require("yup");
const { filter } = require("lodash");
const moment = require("moment");

yup.addMethod(
  yup.mixed,
  "uniqWithinParent",
  function (message, equalityCheck = (a, b) => a === b) {
    return this.test(`uniqWithinParent`, message, function (value) {
      return (
        !value ||
        filter(this.parent, (siblingValue) =>
          equalityCheck(value, siblingValue)
        ).length <= 1
      );
    });
  }
);

yup.addMethod(yup.string, "minWords", function (minNum) {
  return this.test(
    `minimum-words`,
    `Must be at least ${minNum} words`,
    (value) =>
      !value ||
      Boolean(value.split(/[^\w]+/g).filter((wrd) => wrd).length >= minNum)
  );
});

// for use within an existing yup schema, does not perform validation on empty strings
const URL_REGEX = /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i; // eslint-disable-line no-useless-escape
yup.addMethod(yup.string, "validURL", function () {
  return this.test(
    `valid-url`,
    "Improper format, example: example.com",
    (value) => Boolean(URL_REGEX.test(value)) || !value
  );
});

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
yup.addMethod(yup.string, "validEmail", function () {
  return this.test(
    `valid-email`,
    "Improper format, example: hello@talktalent.com",
    (value) => Boolean(EMAIL_REGEX.test(value)) || !value
  );
});

yup.addMethod(yup.string, "laterTimeThan", function (ref, message) {
  return this.test(
    `later-time-than`,
    message || "Incompatible time",
    function (value) {
      const otherValue = this.resolve(ref);
      return !value || !otherValue || moment(value).isAfter(otherValue);
    }
  );
});

yup.addMethod(yup.string, "earlierTimeThan", function (ref, message) {
  return this.test(
    `earlier-time-than`,
    message || "Incompatible time",
    function (value) {
      const otherValue = this.resolve(ref);
      return !value || !otherValue || moment(value).isBefore(otherValue);
    }
  );
});

yup.addMethod(yup.string, "validPostalCode", function () {
  return this.test(
    `minimum-characters`,
    `Must be 5 digits`,
    (value) => !Boolean(value) || (!isNaN(value) && value.length === 5)
  );
});

const CANADIAN_POSTAL_CODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
yup.addMethod(yup.string, "validCanadianPostalCode", function () {
  return this.test(
    `minimum-characters`,
    `Must be a valid Canadian code`,
    (value) => Boolean(CANADIAN_POSTAL_CODE_REGEX.test(value)) || !value
  );
});

yup.addMethod(yup.string, "validLinkedinUrl", function () {
  return this.test(
    `valid-linkedin-url`,
    `Must be a valid LinkedIn profile URL, e.g. linkedin.com/in/yourname`,
    (value) =>
      Boolean(value && /linkedin.com\/in\/\w+/.test(value.toLowerCase()))
  );
});

module.exports = yup;
