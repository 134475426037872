import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { styles } from "./item/utils";
import AvatarColumn from "./item/AvatarColumn";
import InfoColumn from './item/InfoColumn';
import CTAColumn from "./item/CTAColumn";

export default function Item({ id }) {
  const profile = useSelector(
    (state) => state.profiles.items[id],
    shallowEqual
  );

  if (!profile) return null;

  const classes = styles({});

  return (
    <li className={classes.itemContainer}>
      <AvatarColumn {...{ id }} />
      <InfoColumn {...{ id }} />
      <CTAColumn {...{ id }} />
    </li>
  );
}
