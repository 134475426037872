import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { createOpinion, removeOpinion } from "../../actions/opinionActions";
import { REACT_TO_COMMENT } from "../../util/fathomEventCodes";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 6,
    marginRight: 6,
  },
  button: {
    cursor: "pointer",
    fontSize: 20,
  },
  count: {
    fontSize: 13,
    margin: "6px 0px 0px 6px",
  },
});

const Like = ({ id, liked, totalLikes }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const createLike = () => {
    setLoading(true);
    if (!!window.fathom) {
      window.fathom.trackGoal(REACT_TO_COMMENT, 0);
    }
    dispatch(
      createOpinion({
        opinion_type: "like",
        opinion_subject_type: "Comment",
        opinion_subject_id: id,
      })
    ).then(() => setLoading(false));
  };

  const removeLike = () => {
    setLoading(true);
    dispatch(
      removeOpinion({
        opinion_type: "like",
        opinion_subject_type: "Comment",
        opinion_subject_id: id,
      })
    ).then(() => setLoading(false));
  };

  const handleClick = () => {
    if (!loading && liked) {
      return removeLike();
    } else if (!loading & !liked) {
      return createLike();
    }
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        className={classes.container}
        style={{
          opacity: loading ? 0.3 : 1,
          color: liked ? "#367BA6" : "#595D6E",
        }}
      >
        <div className={classes.button}>
          <i className={`${liked ? "fas" : "far"} fa-thumbs-up`}></i>
        </div>
        {totalLikes > 0 && (
          <div className={classes.count}>{totalLikes || ""}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Like;
