import React from "react";
import { createUseStyles } from "react-jss";

export const styles = createUseStyles({
  name: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "292E33",
  },
  mobileName: {
    display: "flex",
    paddingLeft: 0,
    alignItems: "center",
  },
  detail: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  itemContainer: {
    margin: 0,
    padding: 20,
    textIndent: 0,
    listStyleType: 0,
    fontSize: "1.125rem",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "2.28571rem",
    color: "48465B",
    display: "flex",
    flexWrap: "wrap",
  },
  avatarColumn: {
    paddingLeft: 0,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
  },
  infoColumn: {
    paddingLeft: 0,
    paddingRight: 20,
  },
  ctaColumn: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
  },
  linkedinIcon: {
    display: "block",
    float: "right",
    fontSize: 38.9999,
    color: "#0e76a8",
    marginLeft: 12,
  },
});

export const Name = ({ firstName, lastName }) => (
  <span className={styles().name}>
    {firstName} {lastName}
  </span>
);

export const Detail = ({ icon, children }) => {
  if (!children) return null;
  return (
    <div className={styles().detail}>
      <i className={"la la-" + icon} style={{ marginRight: 8 }} />
      {children}
    </div>
  );
};
