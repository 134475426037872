import React from "react";

import Header from "../../shared/PollContent/Header";
import Question from "../../shared/PollContent/Question";
import Footer from "../../shared/PollContent/Footer";
import Results from "../../shared/PollContent/PollResults/Results";
import UndoButton from "../../shared/PollContent/PollResults/UndoButton";

const PollResultsContent = ({ poll }) => (
  <React.Fragment>
    <Header poll={poll} />
    <div style={{paddingLeft: 12}}>
      <Question poll={poll} />
      <Results poll={poll} />
      <Footer poll={poll} action={<UndoButton poll={poll} />} />
    </div>
  </React.Fragment>
);

export default PollResultsContent;
