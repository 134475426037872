import { merge, debounce } from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";

const PaginatedFetch = ({
  fetchFunction,
  loading,
  setLoading,
  customParams = {},
  children,
  fetchDebounce = 0,
  initialPage = 1,
}) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const defaultParams = {
    page: initialPage,
    perPage: 10,
  };

  Object.freeze(defaultParams);

  const dispatch = useDispatch();
  const [fetchParams, setFetchParams] = useState(
    merge({}, defaultParams, customParams)
  );
  const [responseData, setResponseData] = useState({});

  useDeepCompareEffect(() => {
    setFetchParams(merge({}, defaultParams, customParams));
  }, [customParams]);

  const performFetch = useCallback(
    debounce((fetchParams) => {
      setLoading(true);
      dispatch(fetchFunction(fetchParams)).then((res) => {
        setResponseData(res);
        setLoading(false);
      });
    }, fetchDebounce),
    []
  );

  const fetchAction = () => {
    setLoading(true);
    performFetch(fetchParams);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialLoad(true);
    }, 2250);

    return () => clearTimeout(timeout);
  }, [initialLoad]);

  useDeepCompareEffect(() => {
    if (initialLoad) {
      fetchAction();
    }
  }, [fetchParams, initialLoad]);

  const handlePageSelection = useCallback((pageNum) => {
    setFetchParams(
      merge({}, fetchParams, {
        page: pageNum,
      })
    );
  });

  return children({
    ...responseData,
    loading,
    handlePageSelection,
    fetchAction,
  });
};

export default PaginatedFetch;
