import { makeStyles } from "@material-ui/core";
import compact from "lodash/compact";
import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { buildFullName } from "../../../../util/formatHelpers";
import ConnectedAvatarIcon from "../../../General/AvatarIcon";
import ConversationsContext from "../../ConversationsContext";
import ConversationAvatar from "../../partials/ConversationAvatar";
import ConversationLabel from "../../partials/ConversationLabel";

import { CONVERSATION, MAX_OPTION, USER } from "./constants";

const AVATAR_SIZE = 40;
const useStyles = makeStyles((theme) => ({
  container: {
    height: 70,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: `0px ${theme.spacing(2)}px`,
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ECEDF2",
    },
    "&:hover": {
      backgroundColor: "#EAEEF2", // todo(me): what color?
    },
    width: "100%",
  },
  avatarContainer: {
    marginRight: theme.spacing(2),
  },
  textContainer: {
    width: `calc(100% - ${theme.spacing(4)}px - ${AVATAR_SIZE}px)`,
    display: "flex",
    flexDirection: "column",
    "& > div": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 15,
    color: "#48465B",
    fontWeight: 400,
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {},
}));

function SearchResultContainer({
  avatar,
  title,
  subtitle,
  onClick = () => {},
}) {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.avatarContainer}>{avatar}</div>
      <div className={classes.textContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
}

function UserSearchResult({ data, selectOption }) {
  const id = data.value;

  const user = useSelector((state) => state.users.items[id]);

  const {
    firstName, lastName, jobTitle, companyName
  } = user.attributes;

  const handleClick = () => {
    selectOption(data);
  };

  return (
    <SearchResultContainer
      onClick={handleClick}
      avatar={<ConnectedAvatarIcon member={user} height={AVATAR_SIZE} />}
      title={buildFullName(firstName, lastName)}
      subtitle={compact([jobTitle, companyName]).join(" - ")}
    />
  );
}

function ConversationSearchResult({ data }) {
  const id = data.value;

  const conversation = useSelector((state) => state.conversations.items[id]);

  // cannot useConversationContext hook here, because modal exists outside of context
  const context = useContext(ConversationsContext);

  const handleClick = () => {
    context?.setSelectedConversationId(id);
    context?.setIsCreating(false);
  };

  const { conversationParticipantsCount } = conversation.attributes;

  return (
    <SearchResultContainer
      onClick={handleClick}
      avatar={
        <ConversationAvatar conversation={conversation} size={AVATAR_SIZE} />
      }
      title={<ConversationLabel {...{ conversation }} />}
      subtitle={`Group Message - ${conversationParticipantsCount} participants`}
    />
  );
}

function MaxSelectionsExceededResult({ data }) {
  return (
    <div
      style={{
        height: 70,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data.label}
    </div>
  );
}
function SearchResult({
  innerRef, innerProps, data, selectOption
}) {
  const TypedSearchResult = {
    [USER]: UserSearchResult,
    [CONVERSATION]: ConversationSearchResult,
    [MAX_OPTION]: MaxSelectionsExceededResult,
  }[data.type];

  return TypedSearchResult ? (
    React.createElement(TypedSearchResult, { data, selectOption })
  ) : (
    <></>
  );
}

export default SearchResult;
