import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import useSharedStyles from "../useSharedStyles";

function CheckboxRow({
  id,
  onChange,
  checked,
  title,
  rowStyle = "borderedRow",
}) {
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses[rowStyle]} style={{ paddingRight: 5 }}>
      <div className="col-lg-10">{title}</div>
      <div className="col-lg-2" style={{ textAlign: "end" }}>
        <Checkbox {...{ id, onChange, checked }} />
      </div>
    </div>
  );
}

export default CheckboxRow;
