import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { destroyResponse } from "../../../../../actions/pollActions";

import FooterButton from "../FooterButton";

const UndoButton = ({ poll }) => {
  const dispatch = useDispatch();
  const { vendorUser } = useSelector((state) => state.settings.attributes);

  const { id, closesAt } = poll.attributes;

  if (vendorUser || moment(closesAt).isBefore(moment())) {
    return null;
  }

  const handleClick = () => dispatch(destroyResponse(id));

  return <FooterButton onClick={handleClick} buttonText="Undo" />;
};

export default UndoButton;
