import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { Formik } from "formik";

import {
  initialValues,
  buildValidationSchema,
  initialErrors,
} from "./fieldHelpers";
import { map, merge } from "lodash";
import { submitResponse } from "../../../../../actions/pollActions";

const SubmitResponseForm = ({ poll, children }) => {
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => buildValidationSchema(poll), [poll]);

  const { id } = poll.attributes;

  const handleSubmit = (values, context) => {
    return dispatch(submitResponse(merge({}, values, { pollId: id }))).catch(
      () => {
        context.setSubmitting(false);
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      initialErrors={initialErrors}
      onSubmit={handleSubmit}
    >
      {() => children}
    </Formik>
  );
};

export default SubmitResponseForm;
