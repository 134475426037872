import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import map from "lodash/map";
import difference from "lodash/difference";
import take from "lodash/take";

import { makeGetFilteredUsers } from "../../../selectors/userSelectors";
import AvatarIcon from "../../General/AvatarIcon";
import { makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { usePrevious } from "../../../util/customHooks";
import { debounce } from "lodash-es";
import classnames from "classnames";
import { openModal } from "../../../actions/uiActions";
import ConversationDetailsModal from "./modals/ConversationDetailsModal/ConversationDetailsModal";

const AVATAR_SIZE = 60;
const AVATAR_MARGIN = 32;

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 80,
    borderBottom: "1px solid #ebedf2",
    position: "sticky",
    backgroundColor: "white",
    top: 0,
    zIndex: 1,
  },
  avatarItem: {
    marginRight: AVATAR_MARGIN / 2,
    marginLeft: AVATAR_MARGIN / 2,
  },
  overflowAvatar: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    alignItems: "center",
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    minWidth: AVATAR_SIZE,
    opacity: "75%",
    fontWeight: 400,
    fontSize: AVATAR_SIZE / 3,
    border: "1px solid",
    cursor: "pointer",
  },
}));

const OverflowAvatar = ({ numOverflow, conversationId }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openModal(<ConversationDetailsModal conversationId={conversationId} />)
    );
  };

  const classes = useStyles();

  return (
    <div
      className={classnames(classes.overflowAvatar, classes.avatarItem)}
      onClick={handleClick}
    >
      +{numOverflow}
    </div>
  );
};
// taking up too much space, will be redesigned in future story
const ParticipantsBanner = ({ conversation, isScrolling }) => {
  const classes = useStyles();

  const [isDisplayed, setIsDisplayed] = useState(true);

  const toggleDisplayed = useCallback(
    debounce((isScrolling) => {
      setIsDisplayed(!isScrolling);
    }, 500),
    [setIsDisplayed]
  );

  useEffect(() => {
    toggleDisplayed(isScrolling);
  }, [isScrolling, toggleDisplayed]);

  const [maxAvatars, setMaxAvatars] = useState(10);

  const containerRef = useRef();

  const { conversationParticipants } = conversation.relationships;

  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const participantIds = map(conversationParticipants.data, ({ id }) => id);

  const getFilteredUsers = useMemo(makeGetFilteredUsers, []);

  const users = useSelector((state) =>
    getFilteredUsers(state, {
      ids: difference(participantIds, [currentUserId]), // todo(me): excluding current user?
    })
  );

  const adjustMaxAvatars = useCallback(() => {
    setMaxAvatars(
      Math.max(
        1,
        Math.floor(
          containerRef.current.offsetWidth / (AVATAR_SIZE + AVATAR_MARGIN)
        )
      )
    );
  }, [containerRef.current, setMaxAvatars]);

  useEffect(() => {
    adjustMaxAvatars();

    window.addEventListener("resize", adjustMaxAvatars);

    return () => {
      window.removeEventListener("resize", adjustMaxAvatars);
    };
  }, [adjustMaxAvatars]);

  const overflowCount = Math.max(0, users.length - maxAvatars);
  const overflowReplacement = Boolean(overflowCount);

  const displayedUsers = take(users, maxAvatars - overflowReplacement);

  return (
    <Fade in={isDisplayed} timeout={{ enter: 500, exit: 200, appear: 500 }}>
      <div className={classes.container} ref={containerRef}>
        {map(displayedUsers, (user, idx) => (
          <div key={user.id + idx} className={classes.avatarItem}>
            <AvatarIcon avatarId={user.id} height={AVATAR_SIZE} />
          </div>
        ))}
        {overflowReplacement && (
          <OverflowAvatar
            numOverflow={overflowCount + overflowReplacement}
            conversationId={conversation.id}
          />
        )}
      </div>
    </Fade>
  );
};

export default ParticipantsBanner;
