import map from "lodash/map";
import React from "react";
import { createUseStyles } from "react-jss";

import { useDirectoryContext } from "./DirectoryContext";
import Item from "./Item";
import NoResults from "./items/NoResults";
import Skeletons from "./items/Skeletons";

const useStyles = createUseStyles({
  unorderedList: {
    margin: "1rem 0 1rem 0",
    padding: 0,
    listStyleType: "none",
    "& li+li": {
      borderTop: "1px solid #ECEDF2",
    },
  },
});

export default function Items({ profiles }) {
  const classes = useStyles();
  const { loading } = useDirectoryContext();

  if (loading) return <Skeletons />;

  if ((profiles || []).length === 0) return <NoResults />;

  return (
    <ul className={classes.unorderedList}>
      {map(profiles, ({ id }) => (
        <Item {...{ key: id, id }} />
      ))}
    </ul>
  );
}
