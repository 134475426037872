import { createUseStyles } from "react-jss";

export const PLACEHOLDER_URL =
  "https://via.placeholder.com/160x240.png?text=Event%20Placeholder%20Image";

export const useStyles = createUseStyles({
  image: {
    paddingTop: "56.25%",
    width: "100%",
    background: ({ url }) => `url('${url}')`,
    backgroundSize: () => "cover",
    backgroundPosition: () => "center",
  },
  titleContainer: {
    color: "#FF6669",
    fontFamily: "Poppins",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "30px",
    marginTop: -5,
    marginBottom: 6,
    display: "flex",
  },
  titleLink: {
    flexGrow: 1
  },
  subtitle: {
    color: "#455A64",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "16px",
    marginBottom: "14px",
    height: "54px",
  },
  eventTime: {
    height: 18,
    color: "#263238",
    fontFamily: "Poppins",
    fontSize: 14,
    letterSpacing: "0.05px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  eventLocation: {
    height: 20,
    color: "#263238",
    fontFamily: "Poppins",
    fontSize: 14,
    letterSpacing: "0.05px",
    lineHeight: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const DESKTOP = ["lg", "xl", "xxl"];
const MOBILE = ["xs"];
const NOT_TABLET = DESKTOP.concat(MOBILE);

export function checkDesktop(screenClass) {
  return DESKTOP.includes(screenClass);
}

export function checkMobile(screenClass) {
  return MOBILE.includes(screenClass);
}

export function checkTablet(screenClass) {
  return !NOT_TABLET.includes(screenClass);
}
