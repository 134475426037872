import React from "react";
import { Row } from "react-grid-system";

import Truncate from "react-truncate";

import { makeStyles } from "@material-ui/core/styles";

import AvatarIcon from "../../../General/AvatarIcon";

const useStyles = makeStyles(() => ({
  name: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  infoContainer: {
    width: "85%",
    paddingLeft: 8,
  },
}));

const PersonalInformation = ({ user }) => {
  const {
    id,
    attributes: { firstName, lastName, jobTitle, companyName },
  } = user;

  const classes = useStyles();

  return (
    <Row nogutter>
      <AvatarIcon avatarId={id} height={62} />
      <div className={classes.infoContainer}>
        <div className={classes.name}>
          <Truncate lines={1}>
            {firstName} {lastName}
          </Truncate>
        </div>
        <div>
          <Truncate lines={1}>{jobTitle}</Truncate>
        </div>
        <div>
          <Truncate lines={1}>{companyName}</Truncate>
        </div>
      </div>
    </Row>
  );
};

export default PersonalInformation;
