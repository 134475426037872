import React from "react";

const FormHeader = ({ title }) => (
  <div className="kt-portlet__head">
    <div className="kt-portlet__head-label">
      <h3
        className="kt-portlet__head-title"
        style={{
          color: "#595d6e",
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 500,
          letterSpacing: "0.07px",
          lineHeight: "24px",
        }}
      >
        {title}
      </h3>
    </div>
  </div>
);

export default FormHeader;
