import React, { memo } from "react";

import { ConnectedDropdown } from "../../../General/FormInputs/ConnectedInputs";

import { CHAPTER } from "../fieldHelpers";

const MemberDropdown = ({ announcementEntity }) => {
  const memberTypes = [
    { value: "all", label: "All members" },
    { value: "board_members", label: "Board members only" },
    { value: "admins", label: "Admins only" },
  ];

  announcementEntity === CHAPTER &&
    memberTypes.push({
      value: "regular_members",
      label: "Regular members only",
    });

  return (
    <ConnectedDropdown
      id="memberType"
      options={memberTypes}
      label="Member type"
    />
  );
};

export default memo(MemberDropdown);
