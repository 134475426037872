import map from "lodash/map";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import lowerCase from "lodash/lowerCase";
import normalize from "json-api-normalizer";
import { GET } from "../../../../util/apiHelpers";

import { uploadFile } from "../../../../util/uploadHelpers";

const acceptedFileTypes = {
  file: "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf",
  image: "image/*",
};

export function filePickerCallback(cb, _value, meta) {
  const input = document.createElement("input");
  input.setAttribute("type", "file");

  input.setAttribute("accept", acceptedFileTypes[meta.filetype]);

  const { uploadFolder } = this.props;

  input.onchange = function () {
    const file = this.files[0];

    const reader = new FileReader();
    reader.onload = function () {
      // Note: Now we need to register the blob in TinyMCEs image blob
      // registry. In the next release this part hopefully won't be
      // necessary, as we are looking to handle it internally.
      const id = "blobid" + new Date().getTime();
      const blobCache = tinymce.activeEditor.editorUpload.blobCache;
      const base64 = reader.result.split(",")[1];
      const blobInfo = blobCache.create(id, file, base64);
      blobCache.add(blobInfo);

      // call the callback and populate the Title field with the file name

      uploadFile({
        file,
        uploadFolder,
        success: (location) =>
          cb(location, { title: file.name, text: file.name }),
      });
    };

    reader.readAsDataURL(file);
  };

  input.click();
}

export function imagesUploadHandler(blobInfo, success, failure, progress) {
  uploadFile({
    file: blobInfo.blob(),
    uploadFolder: this.props.uploadFolder,
    success,
    failure,
    progress,
  });
}

export function addAttachFileButton(editor) {
  editor.ui.registry.addButton("attach-file", {
    icon: "new-document",
    tooltip: "Attach file",
    onAction: () => {
      this.filePickerCallback(
        (location, { title }) => {
          editor.execCommand(
            "mceInsertRawHTML",
            false,
            `<a href='${location}'>${title || location}</a>`
          );
        },
        null,
        { filetype: "file" }
      );
    },
  });
}

const fetchHashtags = (query) => GET(`/api/v1/hashtags?query=${query}`);

export function addHashtagButton(editor) {
  editor.ui.registry.addButton("insert-hashtag", {
    text: "Add Hashtag",
    onAction: () => {
      editor.insertContent("#");
    },
  });
}

export function addHashtagAutocompleter(editor) {
  editor.ui.registry.addAutocompleter("hashtag-autocomplete", {
    fetch: (pattern, _maxResults, _fetchOptions) => {
      return fetchHashtags(pattern).then((res) => {
        const matchedHashtags = map(
          normalize(res.data).hashtag,
          (hashtag) => hashtag.attributes
        );

        const exactMatchItem = find(
          matchedHashtags,
          ({ label }) => lowerCase(label) === lowerCase(pattern)
        );

        if (exactMatchItem) {
          exactMatchItem.isExactMatch = true;
          exactMatchItem.isNew = false;
        } else if (pattern.length >= 3) {
          matchedHashtags.push({
            isExactMatch: true,
            isNew: true,
            totalRecentUses: 0,
            label: pattern,
          });
        }

        const sortedItems = orderBy(
          matchedHashtags,
          [
            ({ isExactMatch }) => (isExactMatch ? -1 : 1),
            ({ totalRecentUses }) => totalRecentUses,
            ({ label }) => label.length,
          ],
          ["asc", "desc", "asc"]
        );

        return map(sortedItems, ({ label, isNew }) => ({
          type: "autocompleteitem",
          text: isNew ? `+ #${label}` : `#${label}`,
          value: label,
        }));
      });
    },
    minChars: 0,
    ch: "#",
    onAction: function (autocompleteApi, range, value) {
      editor.selection.setRng(range);

      editor.insertContent(
        `<a contenteditable='false' class="hashtag_link" style="font-weight:700;font-style:italic">#${value}</a>`
      );

      autocompleteApi.hide();
    },
    matches: (_range, value) => /#[\w\_]{0,30}$/.test(value),
  });
}
