import React, { useEffect, useState } from "react";
import { Col, useScreenClass } from "react-grid-system";
import { useSelector, shallowEqual } from "react-redux";

import {
  RSVP_EVENT_INDEX,
  LINKEDIN_EVENT_INDEX,
  URL_EVENT_INDEX
} from "../../../../util/fathomEventCodes";

import AcceptedEventInProgressActions from "./actions/AcceptedEventInProgressActions";
import BoardMemberActions from "./actions/BoardMemberActions";
import InvitationActions from "./actions/InvitationActions";
import OtherEventAction from "./actions/OtherEventAction";
import ShareEventActions from "./actions/ShareEventActions";
import StatusText from "./actions/StatusText";
import { checkDesktop, checkMobile, checkTablet } from "./utils";

const STYLES = {
  desktop: {
    padding: 0,
    minHeight: 160,
    maxWidth: 364,
    width: 364,
    flex: "0 0 364px",
    borderLeft: "1px solid rgba(34,34,34,0.2)",
  },
  tablet: {
    padding: 0,
    minHeight: 160,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 50%",
    borderBottom: "1px solid #ECEDF2",
  },
  mobile: {
    padding: 0,
    minHeight: 160,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 100%",
    borderTop: "1px solid #ECEDF2",
  },
};

export default function Actions({ id }) {
  const screenClass = useScreenClass();
  const [styles, setStyles] = useState({});

  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const settings = useSelector((state) => state.settings);

  const { groupId } = event.attributes;
  const { boardMemberGroups, admin } = settings.attributes;

  useEffect(() => {
    if (checkDesktop(screenClass)) {
      setStyles(STYLES.desktop);
    }
    else if (checkTablet(screenClass)) {
      setStyles(STYLES.tablet);
    }
    else if (checkMobile(screenClass)) {
      setStyles(STYLES.mobile);
    }
  }, [screenClass]);

  const isRegularMember = !admin && !boardMemberGroups.includes(groupId);

  return (
    <Col xs={12} sm={6} lg={3} style={styles}>
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: "12px 24px",
          paddingTop: "9px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StatusText {...{ id }} />
        <InvitationActions {...{ id, trackGoal: RSVP_EVENT_INDEX }} />
        <OtherEventAction {...{ id }} />
        {isRegularMember &&
          <ShareEventActions
            {...{
              id,
              trackUrlGoal: URL_EVENT_INDEX,
              trackLinkedinGoal: LINKEDIN_EVENT_INDEX
            }}
          />
        }
        {isRegularMember && <AcceptedEventInProgressActions {...{ id }} />}
        <BoardMemberActions {...{ id }} />
      </div>
    </Col>
  );
}
