

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";


import { createConversation } from "../../../actions/conversationActions";
import { fetchUser } from "../../../actions/userActions";

import { INTIALIZED, LOADING, READY, ERROR } from "../../../util/constants";

import ChatContents from "./ProfileHoverContents/ChatContents";
import ChatExpansion from "./ProfileHoverContents/ChatExpansion";
import Classifications from "./ProfileHoverContents/Classifications";
import PersonalInformation from "./ProfileHoverContents/PersonalInformation";

const useStyles = makeStyles(() => ({
  paper: {
    width: 440,
    minHeight: 180,
    backgroundColor: "#FFF",
    border: "1px solid #357BA6",
    borderRadius: 10,
    padding: 12,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ProfileHoverContents = ({ userId }) => {
  const [status, setStatus] = useState(INTIALIZED);
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState("");
  const [conversationId, setConversationId] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.items[userId]);

  useEffect(() => {
    if (user) {
      setStatus(READY);
    } else {
      setStatus(LOADING);
      dispatch(fetchUser(userId))
        .then(() => {
          setStatus(READY);
        })
        .catch((_err) => {
          setStatus(ERROR);
        });
    }
  }, [status, setStatus, user, dispatch, userId]);

  const createMessage = () =>
    dispatch(
      createConversation({ body: text, participant_ids: [user.id] })
    ).then((res) => {
      const createdConversationId = Object.keys(res)[0];
      setConversationId(createdConversationId);
    });

  if (user.type !== "user") {
    return null;
  }

  switch (status) {
    case READY:
      return (
        <div className={classes.paper}>
          <PersonalInformation {...{ user }} />
          <Classifications {...{ user }} />
          <ChatExpansion {...{ user, expanded, setExpanded }} />
          <ChatContents
            {...{ expanded, text, setText, createMessage, conversationId }}
          />
        </div>
      );
    case ERROR:
      return <div className={classes.paper}>Internal Server Error</div>;
    case INTIALIZED:
    case LOADING:
      return (
        <div className={classnames(classes.paper, classes.loadingContainer)}>
          <CircularProgress color="primary" />
        </div>
      );
    default:
      return null;
  }
};

export default ProfileHoverContents;
