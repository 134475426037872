import React, { useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import PageSection from "../../General/PageSection";
import Search from "../Search";

import map from "lodash/map";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import { getSubscribedGroups } from "../../../selectors/groupSelectors";
import { ALL_OPTION } from "./constants";
import Order from "../../General/Order";

const Filters = ({
  setSelectedGroupId,
  selectedGroupId,
  query,
  setQuery,
  loading,
  setGroupName,
}) => {
  const subscribedGroups = useSelector(getSubscribedGroups);

  useEffect(() => {
    let defaultGroupId = null;

    if (subscribedGroups.length > 1) {
      if (!find(subscribedGroups, { id: selectedGroupId })) {
        defaultGroupId = ALL_OPTION.value;
      } else {
        defaultGroupId = selectedGroupId;
      }
    } else if (subscribedGroups[0]) {
      defaultGroupId = subscribedGroups[0].id;
    }

    setSelectedGroupId(defaultGroupId);
  }, []);

  const options = useMemo(
    () =>
      (subscribedGroups.length > 1 ? [ALL_OPTION] : []).concat(
        orderBy(
          map(subscribedGroups, ({ attributes: { name, id } }) => ({
            value: id,
            label: `${name} Only`,
          })),
          "label",
          "asc"
        )
      ),
    [subscribedGroups]
  );

  useEffect(() => {
    let groupName = find(options, { value: selectedGroupId });
    setGroupName(groupName ? groupName.label.split(" ")[0] : null);
  }, [selectedGroupId]);

  return (
    <PageSection>
      <div
        className="kt-widget__head"
        style={{ padding: 12, justifyContent: "start" }}
      >
        <React.Fragment>
          <Search query={query} setQuery={setQuery} loading={loading} />
          <Order
            setOrder={setSelectedGroupId}
            value={find(options, { value: selectedGroupId }) || ALL_OPTION}
            options={options}
          />
        </React.Fragment>
      </div>
    </PageSection>
  );
};

export default Filters;
