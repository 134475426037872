import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../../actions/uiActions";
import merge from "lodash/merge";

const _defaultState = {
  open: false,
  content: null,
};

const snackbarReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case OPEN_SNACKBAR:
      return merge({}, state, { open: true, content: action.payload });
    case CLOSE_SNACKBAR:
      return merge({}, state, { open: false });
    default:
      return state;
  }
};

export default snackbarReducer;
