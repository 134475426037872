import { object, string } from "../../../util/yup";

export const initialValues = {
  name: "",
  categories: [],
  url: "",
  email: "",
};

export const validationSchema = object().shape({
  categories: string().required("This field is required."),
  name: string()
    .required("This field is required.")
    .nullable(),
  url: string()
    .required("This field is required.")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Must be a valid URL."
    )
    .nullable(),
  email: string().email().nullable(),
});

export const sectionFields = [
  [
    "categories",
    "name",
    "url",
    "email"
  ],
  [],
  []
];

export const createHandleSubmit = ({
  handleSubmit,
  handleSuccess = () => {},
  handleErrors = () => {},
}) => {
  return (values, context) => {
    return handleSubmit(values).then(handleSuccess).catch(handleErrors);
  };
};
