import React from "react";
import { ALL_OPTION } from "./constants";

const EmptyState = ({ setQuery, setSelectedGroupId }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        minHeight: 144,
        flexDirection: "column",
      }}
    >
      <span
        style={{
          fontSize: "1.28571rem",
          lineHeight: "2.28571rem",
          fontWeight: 500,
        }}
      >
        We cannot find anything for that search.
      </span>
      <a
        style={{ color: "#f25b5e", cursor: "pointer" }}
        onClick={() => {
          setQuery("");
          setSelectedGroupId(ALL_OPTION.value);
        }}
      >
        Show all
      </a>
    </div>
  );
};

export default EmptyState;
