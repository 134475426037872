import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 12,
    float: "right",
    marginTop: 6,
    "&> a": {
      textDecoration: "underline",
      color: "rgb(53, 123, 166)",
    }
  }
}))

export default function AcceptedInvitationsText({ showCount, id }) {
  const classes = useStyles();
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const { link, acceptedInvitationsCount } = event.attributes;

  if (!showCount || acceptedInvitationsCount < 10) return null;

  return (
    <span className={classes.link}>
      <a href={link + "/attendees"}>{`see ${acceptedInvitationsCount} attending`}</a>
    </span>
  );
}
