import { makeStyles } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { getConversation } from "../../../selectors/conversationSelectors";
import { useConversationContext } from "../ConversationsContext";
import AttachmentsList from "../form/AttachmentsList";
import FormContainer from "../form/FormContainer";
import FormFooter from "../form/FormFooter";
import MessageBodyInput from "../form/MessageBodyInput";
import ConversationHeader from "../partials/ConversationHeader";
import ConversationLabel from "../partials/ConversationLabel";

import ActionMenu from "./ActionMenu";
import ConversationEventsIndex from "./ConversationEvents/ConversationEventsIndex";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  scrollableContent: {
    overflowY: "scroll",
  },
  notFoundContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textAlign: "center",
  },
  notFoundText: {
    fontSize: 18,
    color: "#48465B",
    fontWeight: 400,
  },
}));

function NotFoundContent() {
  const classes = useStyles();

  return (
    <>
      <ConversationHeader title="" />
      <div className={classes.notFoundContainer}>
        <p className={classes.notFoundText}>
          You do not have access to this chat.
        </p>
        <p className={classes.notFoundText}>
          If you think this is a mistake, please reach out to
          support@talktalent.com.
        </p>
      </div>
    </>
  );
}

function ConversationContent({ conversation }) {
  // todo(me): kill this autofocus stuff?
  const [shouldFocusInput, setShouldFocusInput] = useState(false);

  const handleScrollBottom = useCallback(() => {
    setShouldFocusInput(true);
  }, [setShouldFocusInput]);

  useEffect(() => {
    setShouldFocusInput(false);
  }, [conversation.id]);

  const classes = useStyles();

  return (
    <>
      <ConversationHeader
        title={<ConversationLabel {...{ conversation }} />}
        actionMenu={<ActionMenu conversationId={conversation.id} />}
      />
      <div className={classes.scrollableContent}>
        <ConversationEventsIndex
          conversationId={conversation.id}
          onScrollBottom={handleScrollBottom}
        />
      </div>
      <FormContainer>
        <AttachmentsList />
        <MessageBodyInput />
        <FormFooter />
      </FormContainer>
    </>
  );
}
function Conversation() {
  const classes = useStyles();

  const { selectedConversationId: id } = useConversationContext();

  const conversation = useSelector((state) => getConversation(state, { id }));

  return (
    <div className={classes.container}>
      {Boolean(conversation) ? (
        <ConversationContent conversation={conversation} />
      ) : (
        <NotFoundContent />
      )}
    </div>
  );
}

export default Conversation;
