import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-grid-system";

import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 24,
  },
}));

const ActionModalContent = ({ children, style = {} }) => {
  const classes = useStyles();

  return (
    <DialogContent classes={{ root: classes.root }} style={style}>
      <Row nogutter style={{ flexGrow: 1 }}>
        <Col className="modal-content-area">{children}</Col>
      </Row>
    </DialogContent>
  );
};

export default ActionModalContent;
