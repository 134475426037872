import React from "react";
import { Icon } from "@material-ui/core";

export default function CityIcon(props) {
  return (
    <Icon {...props}>
      <img alt="CityIcon" src="/assets/media/logos/city-icon.svg" />
    </Icon>
  );
}
