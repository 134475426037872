import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import map from "lodash/map";
import startCase from "lodash/startCase";
import capitalize from "lodash/capitalize";
import sortBy from "lodash/sortBy";
import find from "lodash/find";

import PageSection from "../../General/PageSection";
import Order from "../../General/Order";

import {
  ALL,
  NEW,
  COMPLETED,
  POSTED,
  CLOSING,
  ALL_GROUPS_OPTION,
  ALL_POLLS_OPTION,
  MY_POLLS
} from "./constants";
import { getSubscribedGroups } from "../../../selectors/groupSelectors";
import Search from "../../General/Search/Search";

const statusOptions = map([ALL, NEW, COMPLETED, MY_POLLS], (value) => ({
  value,
  label: value === ALL ? "All Polls" : startCase(value.toLowerCase())
}));

const sortValues = map([POSTED, CLOSING], (value) => ({
  value,
  label: startCase(value.toLowerCase()),
}));

const Filters = ({
  currentFilters,
  handleFilterSelection,
  search,
  setSearch,
  loading,
}) => {
  const subscribedGroups = useSelector(getSubscribedGroups);

  useEffect(() => {
    if (Object.keys(subscribedGroups).length && !currentFilters.groupId) {
      handleFilterSelection("groupId", ALL_GROUPS_OPTION.value);
    }
  }, [handleFilterSelection, currentFilters, subscribedGroups]);

  const groupOptions = useMemo(() => {
    const sortedGroups = sortBy(
      map(subscribedGroups, ({ attributes: { name, isNational, isInternational, id } }) => ({
        value: id,
        label: (isNational || isInternational) ? name : `${name} Only`,
      })),
      (group) => group.label
    );

    return (subscribedGroups.length > 1 ? [ALL_GROUPS_OPTION] : []).concat(
      sortedGroups
    );
  }, [subscribedGroups]);

  if (!currentFilters.groupId) {
    return null;
  }

  const { groupId, status, sort } = currentFilters;

  return (
    <PageSection>
      <div
        className="kt-widget__head"
        style={{ justifyContent: "inherit", padding: 12 }}
      >
        <Search
          {...{ search, setSearch, loading }}
          placeholder="Search polls"
          width="40%"
        />
        <Order
          options={groupOptions}
          value={find(groupOptions, { value: groupId }) || ALL_GROUPS_OPTION}
          setOrder={(val) => handleFilterSelection("groupId", val)}
        />
        <Order
          options={statusOptions}
          value={find(statusOptions, { value: status }) || ALL_POLLS_OPTION}
          setOrder={(val) => handleFilterSelection("status", val)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 36,
            minWidth: 216,
            justifyContent: "space-between"
          }}>
          <span>Sort By: </span>
          <Order
            options={sortValues}
            value={
              find(sortValues, { value: sort }) || {
                value: POSTED,
                label: startCase(POSTED.toLowerCase()),
              }
            }
            setOrder={(val) => handleFilterSelection("sort", val)}
          />
        </div>
      </div>
    </PageSection>
  );
};

export default Filters;
