import { makeStyles } from "@material-ui/core";

export const AVATAR_SIZE = 50;
export const ITEM_HEIGHT = 100;

const useSharedStyles = makeStyles((theme) => ({
  container: ({ isSelected, disableHover }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: ITEM_HEIGHT,
    backgroundColor: isSelected ? "#EAEEF2" : "inherit",
    borderLeft: isSelected ? `5px solid ${theme.palette.primary.main}` : "none",
    paddingLeft: isSelected ? 11 : 16,
    paddingRight: 8,
    cursor: disableHover ? "inherit" : "pointer",
    borderBottom: "1px solid #ECEDF2",
    "&:hover": {
      backgroundColor: disableHover ? "inherit" : "#EAEEF2", // todo(me): what color?
    },
  }),
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: theme.spacing(2),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: `calc(100% - ${theme.spacing(2)}px - ${AVATAR_SIZE}px)`,
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 50,
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "#48465B",
    fontWeight: 400,
    maxWidth: "calc(100% - 68px)",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  bodyContent: {
    width: "calc(100% - 48px)",
    fontSize: 14,
    color: "#5d5b6f",
  },
  timestampContainer: {
    width: 60,
    position: "absolute",
    right: 0,
  },
  unreadIcon: {
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    height: 10,
    width: 10,
  },
}));

export default useSharedStyles;
