import React from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";

import { createUseStyles } from "react-jss";
import { Row, Col } from "react-grid-system";
import includes from "lodash/includes";

import AvatarIcon from "../../General/AvatarIcon";
import StarRatingDisplay from "./StarRatingDisplay";
import OtherVendorLink from "./OtherVendorLink";
import ProfileHover from "../../Shared/ProfileHover/ProfileHover";

const useStyles = createUseStyles({
  starIcon: {
    color: "#FFAE28",
    lineHeight: "25px",
    fontSize: "25px",
    verticalAlign: "middle",
    marginTop: -5,
  },
  name: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "400",
    color: "#48465B",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  jobtitle: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: "400",
    color: "#48465B",
    whiteSpace: "wrap",
  },
  ratingItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 450,
    width: "100%",
    fontSize: 18,
    color: "#292E33",
    fontWeight: 500,
    marginBottom: 12,
    paddingRight: 72,
  },
});

const ProductReview = ({ review, reviewIndexUrl }) => {
  const classes = useStyles();
  const userVisible = review.attributes.userVisible;
  let user, firstName, lastName, jobTitle, companyName;

  if (userVisible) {
    user = useSelector(
      (state) => state.users.items[review.relationships.user.data.id]
    );

    firstName = user.attributes.firstName;
    lastName = user.attributes.lastName;
    jobTitle = user.attributes.jobTitle;
    companyName = user.attributes.companyName;
  } else {
    user = {
      attributes: {
        firstName: "Anonymous",
        lastName: `Reviewer${review.id}`,
      },
    };

    firstName = "Anonymous";
    lastName = "Reviewer";
  }

  const otherVendors = review.relationships.otherVendorProducts;
  const otherVendorLinks = map(
    otherVendors.data || [],
    (relationship, index) => (
      <OtherVendorLink
        key={`${index}otherlink`}
        relationship={relationship}
        index={index}
        length={otherVendors.data.length}
      />
    )
  );

  return (
    <Row style={{ padding: 24, borderTop: "1px solid #979797", width: "100%" }}>
      <Col xs={12} md={6}>
        <ProfileHover userId={user.id}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AvatarIcon height={75} member={user} />
            <div style={{ marginLeft: 12 }}>
              <span className={classes.name}>{`${firstName} ${lastName}`}</span>
              <div className={classes.jobtitle}>
                {jobTitle ? (
                  <React.Fragment>
                    <span className="avoidwrap">{jobTitle}</span>{" "}
                    <span className="avoidwrap">- {companyName}</span>
                  </React.Fragment>
                ) : (
                  companyName
                )}
              </div>
            </div>
          </div>
        </ProfileHover>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            className={classes.ratingItem}
            style={{ fontWeight: 500, width: "100%" }}
          >
            <div>Overall</div>
            <StarRatingDisplay rating={review.attributes.overallRating} />
          </div>
          {review.attributes.easeOfUseRating > 0 && (
            <div className={classes.ratingItem} style={{ width: "100%" }}>
              <div style={{ paddingLeft: 12, fontWeight: 300 }}>
                Ease of Use
              </div>
              <StarRatingDisplay rating={review.attributes.easeOfUseRating} />
            </div>
          )}
          {review.attributes.customerSupportRating > 0 && (
            <div
              className={classes.ratingItem}
              style={{ width: "100%", fontWeight: 300 }}
            >
              <div style={{ paddingLeft: 12 }}>Customer Support</div>
              <StarRatingDisplay
                rating={review.attributes.customerSupportRating}
              />
            </div>
          )}
          {review.attributes.productValueRating > 0 && (
            <div
              className={classes.ratingItem}
              style={{ width: "100%", fontWeight: 300 }}
            >
              <div style={{ paddingLeft: 12 }}>Value for Money</div>
              <StarRatingDisplay
                rating={review.attributes.productValueRating}
              />
            </div>
          )}
        </div>
        <div
          className={classes.ratingItem}
          style={{ fontWeight: 500, marginTop: 20, flexDirection: "column" }}
        >
          <div style={{ marginBottom: 12 }}>About The Reviewer</div>
          {otherVendors.data.length > 0 && (
            <div
              style={{
                fontWeight: 400,
                color: "#48465B",
                marginBottom: 12,
                marginLeft: 12,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              Has used similiar vendor{otherVendors.data.length > 1 ? "s" : ""}:{" "}
              {otherVendorLinks}
            </div>
          )}
          {/* User for X Years */}
          {(includes(review.attributes.reviewerRoles, "User") ||
            includes(review.attributes.reviewerRoles, "Administrator")) && (
            <div
              style={{
                fontWeight: 400,
                color: "#48465B",
                marginBottom: 12,
                marginLeft: 12,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              User and/or Administrator for {review.attributes.useDuration}
            </div>
          )}
          {/* User for X Years */}
          {(includes(review.attributes.reviewerRoles, "Set up") ||
            includes(review.attributes.reviewerRoles, "Selected")) && (
            <div
              style={{
                fontWeight: 400,
                color: "#48465B",
                marginBottom: 12,
                marginLeft: 12,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              Set up and/or Selected
            </div>
          )}
          <div
            style={{
              fontWeight: 400,
              color: "#48465B",
              marginBottom: 12,
              marginLeft: 12,
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            {includes(review.attributes.lastUse, "Current User") ? (
              <span>Current User at time of Review</span>
            ) : (
              <span>Last used {review.attributes.lastUse} before Review</span>
            )}
          </div>
          <div
            style={{
              fontWeight: 400,
              color: "#48465B",
              marginLeft: 12,
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            Reviewed on {review.attributes.formattedTime}.
          </div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <div
          style={{
            fontWeight: 500,
            fontSize: 22,
            color: "#263238",
            marginBottom: 20,
          }}
        >
          {review.attributes.title}
        </div>
        {review.attributes.commentText && (
          <div style={{ width: "100%", marginBottom: 20 }}>
            <div style={{ fontWeight: 500, fontSize: 16, color: "#292E33" }}>
              Overall
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
                color: "#292E33",
                paddingLeft: 12,
                whiteSpace: "pre-wrap",
              }}
            >
              {review.attributes.commentText}
            </div>
          </div>
        )}
        <div style={{ width: "100%", marginBottom: 20 }}>
          <div style={{ fontWeight: 500, fontSize: 16, color: "#292E33" }}>
            Pros
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
              color: "#292E33",
              paddingLeft: 12,
              whiteSpace: "pre-wrap",
            }}
          >
            {review.attributes.positiveText}
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: 20 }}>
          <div style={{ fontWeight: 500, fontSize: 16, color: "#292E33" }}>
            Cons
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
              color: "#292E33",
              paddingLeft: 12,
              whiteSpace: "pre-wrap",
            }}
          >
            {review.attributes.negativeText}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProductReview;
