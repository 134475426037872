import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { buildFullName } from "../../../../util/formatHelpers";
import AvatarIcon from "../../../General/AvatarIcon";

const useStyles = makeStyles((theme) => ({
  container: ({ fromSelf }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
    alignSelf: fromSelf ? "flex-end" : "flex-start",
    marginTop: theme.spacing(2),
  }),
  name: {
    marginLeft: theme.spacing(1),
    fontWeight: 400,
    fontSize: 14,
    color: "#48465B",
  },
  timestamp: {
    marginLeft: theme.spacing(1),
    fontSize: 12,
    color: "#5d5b6f",
  },
}));

const EventGroupHeader = ({ event }) => {
  const { actorId, createdAt: timestamp } = event.attributes;

  const user = useSelector((state) => state.users.items[actorId]);
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const { firstName, lastName } = user.attributes;

  const fromSelf = currentUserId === user.id;

  const formattedTimestamp = moment(timestamp).isAfter(moment().startOf("day"))
    ? moment(timestamp).format("h:mm A")
    : moment(timestamp).format("MMM Do");

  const classes = useStyles({ fromSelf });

  return (
    <div className={classes.container}>
      <AvatarIcon avatarId={user.id} height={30} />
      <div className={classes.name}>{buildFullName(firstName, lastName)}</div>
      <div className={classes.timestamp}>{formattedTimestamp}</div>
    </div>
  );
};

export default EventGroupHeader;
