import { connect } from "formik";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import React from "react";

import OtherVendorLink from '../../general/OtherVendorLink';
import StarRatingDisplay from "../../general/StarRatingDisplay";

const determineVisibilityText = (userVisible, vendorVisible) => {
  let text = "";

  if (userVisible) {
    text = "Your name, title, and company will be visible to anyone.";
  } else {
    text = "Your name, title, and company will not be visible.";
  }

  if (vendorVisible) {
    text = text + "\nYour review can be seen by anyone (including the vendor).";
  } else {
    text = text + "\nYour review can only be seen by other TALK Members.";
  }
  
  return text;
};

const Preview = connect(
  ({
    formik: {
      values: {
        lastUse,
        useDuration,
        reviewerRoles,
        vendorProductReviewsProducts,
        title,
        commentText,
        positiveText,
        negativeText,
        overallRating,
        easeOfUseRating,
        customerSupportRating,
        productValueRating,
        userVisible,
        vendorVisible,
      },
    },
    otherProductsInCategory,
  }) => {
    const otherVendorLinks = map(
      vendorProductReviewsProducts || [],
      (productId, index) => {
        const product = find(otherProductsInCategory, { value: productId })

        return (
          <OtherVendorLink
            key={`${product.slug}-otherlink`}
            newTab={true}
            pageUrl={`../${product.slug}`}
            name={product.label}
            index={index}
            length={vendorProductReviewsProducts.length}
          />
        );}
    );

    return (
      <div style={{ padding: 24, whiteSpace: "pre-wrap" }}>
        <div
          style={{
            fontWeight: 400,
            color: "#48465B",
            marginBottom: 12,
            fontSize: "1.2857142857142858rem",
            lineHeight: "2.2857142857142856rem",
          }}
        >
          Please make sure your review is accurate.
        </div>
        <div style={{ padding: 12, border: "1px solid #979797" }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#292E33",
              marginBottom: 12,
            }}
          >
            {title}
          </div>
          {commentText && (
            <div style={{ fontSize: 18, fontWeight: 500, color: "#292E33" }}>
              Overall
            </div>
          )}
          {commentText && (
            <div
              style={{
                fontWeight: 400,
                color: "#455A64",
                padding: "12px 24px",
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              {commentText}
            </div>
          )}
          <div style={{ fontSize: 18, fontWeight: 500, color: "#292E33" }}>
            Pros
          </div>
          <div
            style={{
              fontWeight: 400,
              color: "#455A64",
              padding: "12px 24px",
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            {positiveText}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, color: "#292E33" }}>
            Cons
          </div>
          <div
            style={{
              fontWeight: 400,
              color: "#455A64",
              padding: "12px 24px",
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            {negativeText}
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                fontWeight: 500,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                maxWidth: 450,
                fontSize: 18,
                color: "#292E33",
                marginBottom: 12,
                paddingRight: 72
              }}
            >
              <div>Overall</div>
              <StarRatingDisplay rating={overallRating} />
            </div>
            {easeOfUseRating > 0 && (
              <div
                style={{
                  width: "100%",
                  fontWeight: 400,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  maxWidth: 450,
                  fontSize: 18,
                  color: "#292E33",
                  marginBottom: 12,
                  paddingRight: 72
                }}
              >
                <div style={{ paddingLeft: 12, fontWeight: 300 }}>
                  Ease of Use
                </div>
                <StarRatingDisplay rating={easeOfUseRating} />
              </div>
            )}
            {customerSupportRating > 0 && (
              <div
                style={{
                  width: "100%",
                  fontWeight: 400,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  maxWidth: 450,
                  fontSize: 18,
                  color: "#292E33",
                  marginBottom: 12,
                  paddingRight: 72
                }}
              >
                <div style={{ paddingLeft: 12, fontWeight: 300  }}>Customer Support</div>
                <StarRatingDisplay rating={customerSupportRating} />
              </div>
            )}
            {productValueRating > 0 && (
              <div
                style={{
                  width: "100%",
                  fontWeight: 400,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  maxWidth: 450,
                  fontSize: 18,
                  color: "#292E33",
                  marginBottom: 12,
                  paddingRight: 72
                }}
              >
                <div style={{ paddingLeft: 12, fontWeight: 300  }}>Value for Money</div>
                <StarRatingDisplay rating={productValueRating} />
              </div>
            )}
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: 500,
              marginBottom: 12,
              marginTop: 20,
              color: "#292E33",
            }}
          >
            About the Reviewer
          </div>
          {vendorProductReviewsProducts.length > 0 && (
            <div
              style={{
                fontWeight: 400,
                color: "#48465B",
                marginBottom: 12,
                marginLeft: 12,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              Has used similiar vendor
              {vendorProductReviewsProducts.length > 1 ? "s" : ""}:{" "}
              {otherVendorLinks}
            </div>
          )}
          <div
            style={{
              fontWeight: 400,
              color: "#455A64",
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            {(includes(reviewerRoles, "User") ||
              includes(reviewerRoles, "Administrator")) && (
              <div
                style={{
                  fontWeight: 400,
                  color: "#48465B",
                  marginBottom: 12,
                  marginLeft: 12,
                  fontSize: "1.2857142857142858rem",
                  lineHeight: "2.2857142857142856rem",
                }}
              >
                User and/or Administrator for {useDuration}
              </div>
            )}
            {/* User for X Years */}
            {(includes(reviewerRoles, "Set up") ||
              includes(reviewerRoles, "Selected")) && (
              <div
                style={{
                  fontWeight: 400,
                  color: "#48465B",
                  marginBottom: 12,
                  marginLeft: 12,
                  fontSize: "1.2857142857142858rem",
                  lineHeight: "2.2857142857142856rem",
                }}
              >
                Set up and/or Selected
              </div>
            )}
            <div
              style={{
                fontWeight: 400,
                color: "#48465B",
                marginBottom: 12,
                marginLeft: 12,
                fontSize: "1.2857142857142858rem",
                lineHeight: "2.2857142857142856rem",
              }}
            >
              {includes(lastUse, "Current User") ? (
                <span>Current User at time of Review</span>
              ) : (
                <span>Last used {lastUse} before Review</span>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            fontWeight: 400,
            color: "#48465B",
            marginTop: 12,
            fontSize: "1.2857142857142858rem",
            lineHeight: "2.2857142857142856rem",
            whiteSpace: "pre-wrap",
          }}
        >
          {determineVisibilityText(userVisible, vendorVisible)}
        </div>
      </div>
    );
  }
);

export default Preview;
