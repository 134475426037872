import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import includes from "lodash/includes";
import uniq from "lodash/uniq";
import compact from "lodash/compact";

import { ConnectedDropdown } from "../../General/FormInputs/ConnectedInputs";

import { fetchBoardMembersAndAdmins } from "../../../actions/userActions";

const CreatorDropdown = () => {
  const { values, setFieldValue } = useFormikContext();
  const { chapter: selectedChapterId, creator, hosts } = values;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedChapterId) {
      dispatch(fetchBoardMembersAndAdmins(selectedChapterId));
    }
  }, [selectedChapterId]);


  useEffect(() => {
    const newHosts = compact(uniq(hosts.concat(creator)));
    setFieldValue("hosts", newHosts);
  }, [creator]);

  const selectBoardMembersAndAdmins = createSelector(
    (state) => state.users.items,
    (state) => state.groups.items,
    (users, chapters) => {
      const selectedChapter = chapters[selectedChapterId];

      return filter(
        users,
        (user) =>
          user.attributes.admin ||
          (user.attributes.boardMember &&
            selectedChapter &&
            includes(user.attributes.chapters, selectedChapter.attributes.name))
      );
    }
  );
  const boardMembersAndAdmins = useSelector(selectBoardMembersAndAdmins);

  const options = orderBy(
    map(boardMembersAndAdmins, (user) => ({
      value: user.id,
      label: `${user.attributes.firstName} ${user.attributes.lastName}`,
    })),
    "label",
    "asc"
  );

  return <ConnectedDropdown id="creator" options={options} label="Creator" />;
};

export default CreatorDropdown;
