import React, { useEffect, useState } from "react";

import PageSection from "../../General/PageSection";
import Topic from "./Topic";
import PaginationLinks from "../../General/PaginationLinks";
import PaginatedFetch from "../../General/PaginatedFetch";

import { fetchTopics } from "../../../actions/topicActions";

import map from "lodash/map";
import orderBy from "lodash/orderBy";
import EmptyState from "./EmptyState";
import SkeletonTopics from "./SkeletonTopics";
import queryString from "query-string";
import pickBy from "lodash/pickBy";

const changeQueryString = (searchString) => {
  let urlSplit = window.location.href.split("?");
  let obj = {
    Title: document.title,
    Url:
      urlSplit[0] +
        (searchString && searchString.length > 0 && "?" + searchString) || "",
  };
  history.pushState(obj, obj.Title, obj.Url);
};

const PaginationInner = ({
  handlePageSelection,
  items,
  currentPage,
  totalPages,
  totalItems,
  shownItems,
  offsetValue,
  loading,
  query,
  setSelectedGroupId,
  selectedGroupId,
  setQuery,
}) => {
  useEffect(() => {
    if (items) {
      const params = {};

      params.page = currentPage === 1 ? null : currentPage;
      params.search = query && query !== "" ? query : null;
      params.group =
        selectedGroupId && selectedGroupId !== "ALL" ? selectedGroupId : null;

      const paramsStr = queryString.stringify(pickBy(params));

      changeQueryString(paramsStr);
    }
  }, [currentPage, query, selectedGroupId, items]);

  return (
    <React.Fragment>
      <PageSection>
        <div
          className="kt-widget__head"
          style={{
            height: 48,
            paddingTop: 12,
            paddingBottom: 12,
            fontWeight: 500,
            color: "#666666",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {!loading && (
            <div>
              Displaying{" "}
              <strong>
                {offsetValue + Boolean(shownItems)} - {offsetValue + shownItems}
              </strong>{" "}
              of <strong>{totalItems}</strong> in total
            </div>
          )}
        </div>
        <div className="kt-widget__body" style={{ padding: 0 }}>
          <div className="kt-notification-v2">
            {loading ? (
              <SkeletonTopics />
            ) : items === undefined ? (
              <EmptyState {...{ setQuery, setSelectedGroupId }} />
            ) : (
              map(
                orderBy(
                  items,
                  ({ attributes }) => attributes.lastActivityAt,
                  "desc"
                ),
                ({ id }) => <Topic id={id} key={id} />
              )
            )}
          </div>
        </div>
        <div className="kt-widget__footer"></div>
      </PageSection>
      {!loading && totalPages > 1 && (
        <PageSection>
          <div className="kt-widget__footer" style={{ padding: 24 }}>
            <PaginationLinks
              currPage={currentPage}
              numPages={totalPages}
              handleSelection={handlePageSelection}
            />
          </div>
        </PageSection>
      )}
    </React.Fragment>
  );
};

const TopicsList = ({
  setSelectedGroupId,
  selectedGroupId,
  query,
  setQuery,
  loading,
  setLoading,
}) => {
  const [customParams, setCustomParams] = useState({});
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    setCustomParams({ query, groupId: selectedGroupId });
  }, [query, selectedGroupId]);

  return (
    <PaginatedFetch
      fetchFunction={fetchTopics}
      loading={loading}
      setLoading={setLoading}
      customParams={customParams}
      fetchDebounce={500}
      initialPage={(queryParams.page && parseInt(queryParams.page)) || 1}
    >
      {(paginationProps) => (
        <PaginationInner
          {...paginationProps}
          {...{
            setSelectedGroupId,
            selectedGroupId,
            setQuery,
            query,
          }}
        />
      )}
    </PaginatedFetch>
  );
};

export default TopicsList;
