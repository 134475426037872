import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import JoinConversationButton from "./JoinConversationButton";
import WatchRecordingButton from "./WatchRecordingButton";
import SubmitEventButton from "./SubmitEventButton";

export default function OtherEventAction({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const { status } = event.attributes;
  
  if (
    status !== "past" &&
    status !== "draft"
  ) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "12px",
        alignItems: "space-between",
      }}
    >
      <JoinConversationButton {...{ id }} />
      <WatchRecordingButton {...{ id }} />
      <SubmitEventButton {...{ id }} />
    </div>
  );
}
