import React from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function EmailAttendeesButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { status, announcementPath } = event.attributes;

  // if (!["past"].includes(status)) return null;

  return (
    <a
      href={announcementPath}
      style={{ maxWidth: "150px", width: "calc(50% - 6px)" }}
    >
      <button
        style={{ width: "100%" }}
        className="btn btn-sm btn-brand-secondary"
      >
        Email Attendees
      </button>
    </a>
  );
}
