import normalize from "json-api-normalizer";
import queryString from "query-string";

import { GET, PATCH, POST, DELETE } from "../util/apiHelpers";
import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";

export const RECEIVE_EVENT = "RECEIVE_EVENT";
export const RECEIVE_EVENTS = "RECEIVE_EVENTS";

export const receiveEvents = (events) => ({
  type: RECEIVE_EVENTS,
  payload: events,
});

export const receiveEvent = (event) => ({
  type: RECEIVE_EVENT,
  payload: event,
});

export const fetchEvent = (id) => (dispatch) => {
  return GET(`/api/v1/events/${id}`).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};

export const fetchEvents = (params) => (dispatch) => {
  const paramsStr = queryString.stringify(mapKeysToSnakeCase(params));

  return GET("/api/v1/events?" + paramsStr).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receiveEvents(normalized));

    return {
      items: normalized.event || {},
      ...mapKeysToCamelCase(res.data.meta || {}),
    };
  });
};

export const createEvent = (data) => (dispatch) => {
  return POST("/api/v1/events", data).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};

export const updateEvent = (data) => (dispatch) => {
  return PATCH(`/api/v1/events/${data.id}`, data).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};

export const submitEvent = (id) => (dispatch) => {
  return PATCH("/api/v1/events/" + id + "/submit").then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};

export const startEvent = (id) => (dispatch) => {
  return PATCH("/api/v1/events/" + id + "/start").then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};

export const deleteEvent = (id) => (dispatch) => {
  return DELETE("/api/v1/events/" + id).then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveEvent(normalized));
  });
};
