import React from "react";
import merge from "lodash/merge"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const defaultStyles = {
  label: {
    fontSize: 14,
    fontWeight: 400,
    height: 22,
    color: "#292E33",
    fontFamily: "Poppins",
    letterSpacing: "0.06px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    fontSize: "1.2857142857142858rem",
    "&:focus": {
      borderColor: "#357BA6 !important",
      boxShadow: "0 0 0 1px #357BA6 !important",
    },
  },
  helperText: {
    marginTop: -4,
  }
};

const CustomCheckbox = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  showError,
  helperText,
  styles = {},
}) => {
  const mergedStyles = merge({}, defaultStyles, styles);

  return (
    <div style={mergedStyles.container}>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            color="primary"
          />
        }
        label={
          <div
            style={Object.assign(
              mergedStyles.label,
              showError ? { color: "#f45b5e" } : {}
            )}
          >
            {label}
          </div>
        }
        style={{ marginBottom: 0 }}
      />
      <div style={mergedStyles.helperText}>{helperText}</div>
    </div>
  );
};

export default CustomCheckbox;
