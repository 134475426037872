import { Formik } from "formik";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";

import { createReview } from "../../../../actions/reviewActions";
import FormHeader from "../../../General/FormPage/FormHeader";
import FormPageLayout from "../../../General/FormPage/FormPageLayout";

import BackgroundInfo from "./BackgroundInfo";
import {
  initialValues,
  validationSchema,
  createHandleSubmit,
} from "./fieldHelpers";
import Footer from "./Footer";
import Preview from "./Preview";
import Review from "./Review";

const useStyles = createUseStyles({});

const Form = ({ vendorProductId, otherProductsInCategory }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentSection, setCurrentSection] = useState(0);

  const handleSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      values.vendorProductId = vendorProductId;
      return dispatch(createReview(values))
    },
    handleSuccess: (createdReview) => {
      window.location.href = window.location + "s"
    },
    handleErrors: () => {
    },
  });

  let content;

  switch (currentSection) {
    case 0:
      content = (
        <BackgroundInfo otherProductsInCategory={otherProductsInCategory} />
      );
      break;
    case 1:
      content = <Review otherProductsInCategory={otherProductsInCategory} />;
      break;
    case 2:
      content = <Preview otherProductsInCategory={otherProductsInCategory} />;
      break;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <FormPageLayout>
          <FormHeader title={"New Review"} />
          {content}
          <Footer
            currentSection={currentSection}
            sectionCount={3}
            setCurrentSection={setCurrentSection}
          />
        </FormPageLayout>
      )}
    </Formik>
  );
};

export default Form;
