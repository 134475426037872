import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import map from "lodash/map";

import Item from "./popularCategories/Item";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    "@media (minWidth: 950px)": {
      overflowY: "scroll",
      height: "calc(100vh - 60px)",
    },
  },
  unorderedList: {
    padding: "0px 20px 4px 44px",
    margin: 0,
    listStyleType: "none",
    "& li+li": {
      margin: "4px 0",
    },
  },
});

const PopularCategories = () => {
  const classes = useStyles();

  const { popular, loaded } = useSelector(
    (state) => state.productCategories
  );

  return loaded ? (
    <React.Fragment>
      <div
        style={{
          color: "#595d6e",
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 500,
          letterSpacing: "0.07px",
          lineHeight: "24px",
          padding: "20px",
        }}
      >
        Popular Categories
      </div>
      <ul className={classes.unorderedList}>
        {map(popular, (categoryId) => (
          <Item key={categoryId} categoryId={categoryId} />
        ))}
      </ul>
    </React.Fragment>
  ) : null;
};

export default PopularCategories;
