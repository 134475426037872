import { object, string } from "../../../util/yup.js";
import {
  COMPANY_SIZE_OPTIONS,
  COMPANY_INDUSTRY_OPTIONS,
  TA_LEVEL_OPTIONS,
} from "../shared/constants";

export const buildInitialValues = (
  attributes
) => ({
  companySize: attributes.companySize || "",
  companyIndustry: attributes.companyIndustry || "",
  taLevel: attributes.taLevel || "",
});

export const validationSchema = object().shape({
  companySize: string()
    .nullable()
    .required("This field is required")
    .oneOf(COMPANY_SIZE_OPTIONS, "Must be one of the available options"),
  companyIndustry: string()
    .nullable()
    .required("This field is required")
    .oneOf(COMPANY_INDUSTRY_OPTIONS, "Must be one of the available options"),
  taLevel: string()
    .nullable()
    .required("This field is required")
    .oneOf(TA_LEVEL_OPTIONS, "Must be one of the available options"),
});
