import React, { useState, useEffect, useCallback, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MuiFormControl from "@material-ui/core/FormControl";

import map from "lodash/map";
import debounce from "lodash/debounce";

import Fuse from "fuse.js";
import OptionSearch from "./form_control/OptionSearch";
import UnselectAll from "./form_control/UnselectAll";
import FormGroup from "./form_control/FormGroup";

import { FilterContext } from "../Filter";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: "0",
    width: "100%",
  },
}));

export default function FormControl({ id, options, searchable, selectedCount }) {
  const { context } = useContext(FilterContext);
  const { handleChangeState, state } = useContext(context);

  const classes = useStyles();

  // handle searching options
  const [searchedOptions, setSearchedOptions] = useState(options);

  // handle search input
  const [searchValue, setSearchValue] = useState(null);
  useEffect(() => {
    if (searchValue === "") {
      setSearchedOptions(options);
    } else if (!(searchValue === null)) {
      performSearch(searchValue);
    }
  }, [searchValue]);

  const performSearch = useCallback(
    debounce((search) => {
      const fuse = new Fuse(options, { keys: ["label"] });
      const results = fuse.search(search);
      setSearchedOptions(map(results, (result) => result["item"]));
    }, 2000),
    []
  );

  const resetSelections = () => {
    options.forEach(({ value, label, color }) => {
      if ((state[value] && state[value]["value"]) || (state[value + "|" + color] && state[value + "|" + color]["value"])) handleChangeState(false, value, label, false, color);
    });
  };

  return (
    <MuiFormControl component="fieldset" className={classes.formControl}>
      <OptionSearch {...{ searchable, searchValue, setSearchValue }} />
      <UnselectAll {...{ selectedCount, resetSelections }} />
      <FormGroup {...{ id, searchable, searchedOptions }} />
    </MuiFormControl>
  );
}
