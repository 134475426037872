import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { RECEIVE_COMMENTS, REMOVE_COMMENT } from "../actions/commentActions";

const defaultState = {
  items: {},
};

const commentsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  let newState;
  switch (action.type) {
    case RECEIVE_COMMENTS:
      return merge({}, state, { items: action.payload } );
    case REMOVE_COMMENT:
      newState = cloneDeep(state);
      delete newState.items[action.payload];

      return newState;
    default:
      return state;
  }
};

export default commentsReducer;
