import consumer from "./consumer";

window.Sockets.createConversationsSubscription = (callbacks = {}) => {
  return consumer.subscriptions.create(
    { channel: "ConversationsChannel" },
    {
      received: () => {},
      ...callbacks,
    }
  );
};
