import React from "react";

const EmptyState = ({
  resetFilters,
  message,
  ctaText = "Show All",
  loading,
}) => {
  if (loading) return null;
  return (
    <div
      className="kt-portlet"
      style={{
        justifyContent: "space-evenly",
        alignItems: "center",
        minHeight: 144,
      }}
    >
      <span
        style={{
          fontSize: "1.28571rem",
          lineHeight: "2.28571rem",
          fontWeight: 500,
        }}
      >
        {message}
      </span>
      <a style={{ color: "#f25b5e", cursor: "pointer" }} onClick={resetFilters}>
        {ctaText}
      </a>
    </div>
  );
};

export default EmptyState;
