import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import { map } from "lodash";
import React from "react";
import { useScreenClass } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";

import { clearModal } from "../../../../../actions/uiActions";
import { useConversationUsers } from "../../../partials/hooks";

import AddParticipant from './AddParticipant';
import ConversationName from "./ConversationName/ConversationName";
import ParticipantItem from "./ParticipantItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 575,
    maxWidth: 575,
  },
  title: {
    borderBottom: "1px solid #EBEDF3",
    padding: "1.5rem 1.75rem",
    fontWeight: 500,
    fontSize: "1.75rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: 12,
  },
  contentContainer: ({ fullScreen }) => ({
    maxHeight: fullScreen ? "inherit" : 350,
  }),
  contentHeader: {
    fontWeight: 400,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    borderBottom: "1px solid #ECEDF2",
  },
}));

function ConversationDetailsModal({ conversationId }) {
  const dispatch = useDispatch();

  const conversation = useSelector(
    (state) => state.conversations.items[conversationId]
  );

  const open = useSelector((state) => state.ui.modal.visible);

  const handleClose = () => {
    dispatch(clearModal());
  };

  const users = useConversationUsers(conversation, { includeCurrent: true });

  const screenClass = useScreenClass();

  const fullScreen = screenClass === "xs";

  const classes = useStyles({ fullScreen });

  const isBoardGroup = Boolean(conversation.attributes.groupId);
  const isGroup = Boolean(conversation.relationships.conversationParticipants.data.length > 2);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle classes={{ root: classes.title }}>
        Chat Details
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentContainer }}>
        {isGroup && !isBoardGroup && (
          <ConversationName conversation={conversation} />
        )}
        <div className={classes.contentHeader}>
          {(isGroup || isBoardGroup)
            ? `${users.length} people are in this group`
            : 'Participants in this chat'}
          {(isGroup && !isBoardGroup) && (
            <AddParticipant {...{ conversation }} />
          )}
        </div>
        {map(users, (user) => (
          <ParticipantItem
            key={user.id}
            {...{
              user, conversation, isGroup, isBoardGroup
            }}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default ConversationDetailsModal;
