import { connect } from "formik";
import React from "react";
import Checkbox from "../Checkbox";
import { StyledErrorMessage } from "../ConnectedInputs";
import get from "lodash/get";

const ConnectedCheckbox = connect(
  ({
    formik: { values, handleChange, handleBlur, errors, touched },
    id,
    ...props
  }) => {
    const value = get(values, id);
    const showError = Boolean(get(touched, id) && get(errors, id));

    return (
      <Checkbox
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        helperText={
          <StyledErrorMessage
            name={id}
            style={{ marginBottom: 0, marginLeft: 24 }}
          />
        }
        {...props}
      />
    );
  }
);

export default ConnectedCheckbox;
