import classnames from "classnames";
import merge from "lodash/merge";
import React, { useMemo, useState } from "react";

const buildDefaultStyles = ({ fontRem, numRows }) => ({
  container: {},
  label: {
    fontSize: `${fontRem}rem`,
    lineHeight: `${fontRem + 1}rem`,
    fontWeight: 400,
  },
  input: {
    fontSize: `${fontRem}rem`,
    lineHeight: `${fontRem + 1}rem`,
    whiteSpace: "preWrap",
    display: "inline-block",
    resize: "none",
    minHeight: `calc(${fontRem + 1}rem * ${numRows})`,
    height: "100%",
    "&:focus": {
      borderColor: "#357BA6 !important",
      boxShadow: "0 0 0 1px #357BA6 !important",
    },
  },
});

function Textarea({
  id,
  label,
  value,
  setValue,
  onBlur,
  showError,
  helperText,
  maxCharacters,
  fontRem = 1.2857142857142858,
  numRows = 4,
  maxRows = 0,
  placeholder = "",
  autoFocus = false,
  customStyles = {},
  overloadedClassName = "",
}) {
  const [rows, setRows] = useState(0);

  const styles = useMemo(() => merge(
    {},
    buildDefaultStyles(
      { fontRem, numRows: (rows > numRows) ? rows : numRows }
    ),
    customStyles
  ), [fontRem, numRows, customStyles, rows]);

  const handleChange = (event) => {
    setValue(event.target.value);

    // resize textarea if scroll
    if (maxRows > 0) {
      const rows = (event.target.value.split(/\r*\n/).length);
      setRows((rows > maxRows ? maxRows : rows));
    }
  };

  return (
    <div className="form-group" style={styles.container}>
      {label && (
        <>
          <label
            htmlFor={id}
            style={{
              ...styles.label,
              ...(showError ? { color: "#f45b5e" } : {})
            }}
          >
            {label}
          </label>
          <br />
        </>
      )}
      <textarea
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={classnames("form-control", overloadedClassName)}
        placeholder={placeholder}
        autoFocus={autoFocus}
        style={{
          ...styles.input,
          ...(showError ? { borderColor: "#f45b5e" } : {}),
        }}
      />
      {maxCharacters && (
        <span style={{ float: "right", marginTop: 4 }}>
          {(value?.length ?? 0)}
          {' '}
          /
          {maxCharacters}
        </span>
      )}
      {helperText}
    </div>
  );
}

export default Textarea;
