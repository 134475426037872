import moment from "moment";
import React from "react";
import { createUseStyles } from "react-jss";

import {
  RSVP_DASHBOARD,
  URL_EVENT_NEWSFEED,
  LINKEDIN_EVENT_NEWSFEED
} from "../../util/fathomEventCodes";

import { formatAddress } from "../../util/formatHelpers";
import InvitationActions from "../Events/index/item/actions/InvitationActions";
import ShareEventActions from "../Events/index/item/actions/ShareEventActions";

const useStyles = createUseStyles({
  container: {
    width: "100%",
    padding: "12px 24px",
    borderTop: "1px solid rgba(34,34,34,0.2)",
    height: "100%",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  header: {
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "500",
    letterSpacing: "0.06px",
    marginBottom: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  time: {
    color: "#263238",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    height: "20px",
    letterSpacing: "0.05px",
    lineHeight: "16px",
  },
  groupName: {
    color: "#263238",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "light",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",
  },
  eventImage: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100%",
  },
  calendarLinks: {
    color: "#263238",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    height: "20px",
    letterSpacing: "0.05px",
    lineHeight: "16px",
    margin: "6px 0",
  },
  shareActions: {
    justifyContent: "flex-start",
  }
});

const EventItem = ({ event, showInviteActions = false }) => {
  const { groupName, scheduledAt, title, webinar, link } =
    event.attributes;
  const classes = useStyles();


  const timeZone = new Date()
    .toLocaleTimeString(undefined, { timeZoneName: "short" })
    .split(" ")[2];

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex"
        }}
      >
        <div className={`event-item ${classes.container}`}>
          <a href={link} style={{ width: "100%" }}>
            <div className={classes.header}>{title}</div>
            <div className={classes.time}>
              {moment(scheduledAt).format("llll")} {timeZone}
            </div>
            <div className={classes.groupName}>
              {webinar
                ? groupName + " Webinar"
                : `${groupName}: ${formatAddress(event.attributes)}`}
            </div>
          </a>
          {showInviteActions ? (
            <InvitationActions id={event.id} trackGoal={RSVP_DASHBOARD} />
          ) : (
            <div className={classes.calendarLinks}>
              <span>Add to Calendar:</span>{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={event.attributes.calendarUrl}
              >
                Outlook/iCal
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={event.attributes.googleCalendarUrl}
              >
                Google
              </a>
            </div>
          )}
          <ShareEventActions
            {...{
              id: event.id,
              customClass: classes.shareActions,
              trackLinkedinGoal: LINKEDIN_EVENT_NEWSFEED,
              trackUrlGoal: URL_EVENT_NEWSFEED
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventItem;
