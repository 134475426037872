import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const Modal = ({ children, open, handleClose, title = null, actions = null, ...props }) => {
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      { title }
      <DialogContent>
        {children}
      </DialogContent>
      { actions }
    </Dialog>
  )
}
export default Modal;
