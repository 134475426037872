import React from "react";
import Header from "./partials/Header";
import Question from "../../shared/PollContent/Question";
import Footer from "./partials/Footer";
import Choices from "../../shared/PollContent/SubmitResponseForm/Choices";
import SubmitButton from "../../shared/PollContent/SubmitResponseForm/SubmitButton";
import SubmitResponseForm from "../../shared/PollContent/SubmitResponseForm/SubmitResponseForm";
import { POLL_ANSWER_SHOW } from "../../../../util/fathomEventCodes";

const PollResponseForm = ({ poll }) => {
  const { multiSelect } = poll.attributes;

  return (
    <SubmitResponseForm poll={poll}>
      <Header poll={poll} />
      <div style={{ paddingLeft: 24 }}>
        <Question poll={poll} />
        <Choices poll={poll} fathomCode={POLL_ANSWER_SHOW} />
        <Footer
          poll={poll}
          action={multiSelect && <SubmitButton fathomCode={POLL_ANSWER_SHOW} />}
        />
      </div>
    </SubmitResponseForm>
  );
};

export default PollResponseForm;
