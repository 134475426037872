import { ThemeProvider } from '@material-ui/core/styles';
import React from "react";
import { useSelector } from "react-redux";

import muiTheme from '../muiTheme';

function ConnectedModal() {
  const { visible, content } = useSelector((state) => state.ui.modal);

  return visible ? (
    <ThemeProvider theme={muiTheme}>
      {content}
    </ThemeProvider>
  )
    : <></>;
}

export default ConnectedModal;
