import React from "react";
import { createUseStyles } from "react-jss";

import {
  TALevelField,
  CompanySizeField,
  CompanyIndustryField,
} from "../shared/FormFields";

const useStyles = createUseStyles({
  container: {
    padding: "10px 0px",
  },
  description: {
    fontSize: 16,
    color: "#292E33",
    marginBottom: 20
  },
});

const AddClassificationsFields = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        Complete your profile to see the most relevant content for you. You can
        update this information at any time in your settings.
      </div>
      <TALevelField />
      <CompanySizeField
        selectProps={{
          menuPlacement: "top",
          customStyles: { menuList: { maxHeight: 200, overflowY: "scroll" } },
        }}
      />
      <CompanyIndustryField
        selectProps={{
          menuPlacement: "top",
          customStyles: { menuList: { maxHeight: 200, overflowY: "scroll" } },
        }}
      />
    </div>
  );
};

export default AddClassificationsFields;
