import React, { useEffect, useState } from "react";
import { Col, useScreenClass } from "react-grid-system";
import Skeleton from "react-loading-skeleton";

import { checkDesktop, checkMobile, checkTablet } from "../item/utils";

const STYLES = {
  desktop: {
    padding: 0,
    minHeight: 160,
    maxWidth: 364,
    width: 364,
    flex: "0 0 364px",
    borderLeft: "1px solid rgba(34,34,34,0.2)",
  },
  tablet: {
    padding: 0,
    minHeight: 160,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 50%",
    borderBottom: "1px solid #ECEDF2",
  },
  mobile: {
    padding: 0,
    minHeight: 160,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 100%",
    borderTop: "1px solid #ECEDF2",
  },
};

export default function Actions({ id }) {
  const screenClass = useScreenClass();

  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (checkDesktop(screenClass)) {
      setStyles(STYLES.desktop);
    } else if (checkTablet(screenClass)) {
      setStyles(STYLES.tablet);
    } else if (checkMobile(screenClass)) {
      setStyles(STYLES.mobile);
    }
  }, [screenClass]);

  return (
    <Col xs={12} sm={6} lg={3} style={styles}>
      <div
        style={{
          height: "100%",
          width: "100%",
          padding: "12px 24px",
          paddingTop: "9px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            color: "#357BA6",
            fontSize: 18,
            maxHeight: 25,
            overflow: "hidden",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <span style={{ width: "100%", maxWidth: 315 }}>
            <Skeleton style={{ width: 120 }} />
            <span
              style={{
                fontSize: 12,
                color: "#8c979a",
                float: "right",
                marginTop: 6,
              }}
            >
              <Skeleton style={{ width: 90 }} />
            </span>
          </span>
        </div>
        <div
          style={{
            marginBottom: "12px",
          }}
        >
          <div
            style={{
              maxWidth: 312,
              margin: "0 auto",
            }}
          >
            <Skeleton
              style={{
                maxWidth: 150,
                width: "calc(50% - 6px)",
                height: 34,
                marginRight: 6,
              }}
            />
            <Skeleton
              style={{
                maxWidth: 150,
                width: "calc(50% - 6px)",
                height: 34,
                marginLeft: 6,
              }}
            />
          </div>
        </div>
      </div>
    </Col>
  );
}
