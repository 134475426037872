import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import map from "lodash/map";
import find from "lodash/find";

const useStyles = createUseStyles({
  dropdownMenu: { overflowY: "scroll", maxHeight: 500 },
  clickable: {
    cursor: "pointer",
  },
  label: {
    color: "#F35B5E",
    "&:hover": {
      color: "#CB3337",
    },
  },
});

const DropdownNav = ({
  value,
  options,
  handleSelection,
  placeholder = "Select",
}) => {
  const classes = useStyles();

  const renderOptions = () =>
    map(options, ({ key, label = key }) => (
      <div
        className={`dropdown-item ${classes.clickable}`}
        onClick={() => handleSelection(key, label)}
        key={key}
      >
        {label}
      </div>
    ));

  const currSelection = useMemo(
    () => find(options, ({ key }) => key === value),
    [value, options]
  );

  return (
    <div className="nav-item dropdown">
      <div
        className={`nav-link dropdown-toggle ${classes.clickable} ${classes.label}`}
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {(currSelection && currSelection.label) || placeholder}
      </div>
      <div className={`dropdown-menu ${classes.dropdownMenu}`}>
        {renderOptions()}
      </div>
    </div>
  );
};

export default DropdownNav;
