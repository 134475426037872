import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  searchContainer: {
    position: "relative",
    display: "inline-block",
    margin: 0,
    width: 600,
  },
  inputIcon: {
    position: "absolute",
    top: 12,
    left: 8,
  },
  inputClose: {
    position: "absolute",
    top: 10,
    right: 8,
    cursor: "pointer",
  },
});

const Search = ({ query, setQuery, loading }) => {
  const classes = styles({});

  return (
    <div className={`form-group ${classes.searchContainer}`}>
      <React.Fragment>
        <i className={`fas fa-search ${classes.inputIcon}`} />
        <input
          value={query}
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          className="form-control"
          style={{ paddingLeft: 36 }}
        />
        {query &&
          query.length > 0 &&
          (!loading ? (
            <i
              onClick={() => setQuery("")}
              className={`fas fa-times ${classes.inputClose}`}
            />
          ) : (
            <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
          ))}
      </React.Fragment>
    </div>
  );
};

export default Search;
