import React from "react";
import { Row, Col, Visible, useScreenClass } from "react-grid-system";
import Skeleton from "react-loading-skeleton";

import { styles } from "./utils";

export default function SkeletonItem() {
  const screenClass = useScreenClass();
  const isDesktop = ["lg", "xl", "xxl"].includes(screenClass);
  const classes = styles({});

  return (
    <li className={classes.itemContainer}>
      <React.Fragment>
        <Col
          xs={4}
          lg={2}
          className={classes.avatarColumn}
          style={{ maxWidth: 120 }}
        >
          <Skeleton circle={true} height={90} width={90} />
        </Col>
        <Visible xs sm md>
          <Col xs={8} className={classes.mobileName}>
            <Skeleton />
          </Col>
        </Visible>
      </React.Fragment>
      <Col
        xs={12}
        lg={8}
        className={classes.infoColumn}
        style={{
          width: isDesktop ? "calc(100% - 260px)" : "100%",
          maxWidth: isDesktop ? "calc(100% - 260px)" : "100%",
          flex: isDesktop ? "0 0 calc(100% - 260px)" : "0 0 100%",
          marginTop: isDesktop ? 0 : 20,
          marginBottom: isDesktop ? 0 : 20,
        }}
      >
        <Row>
          <Visible lg xl xxl>
            <Col xs={12}>
              <Skeleton />
            </Col>
          </Visible>
          <Col xs={12} lg={6}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Col>
          <Col xs={12} lg={6}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Col>
        </Row>
      </Col>
      <Col
        xs={12}
        className={classes.ctaColumn}
        style={{
          maxWidth: isDesktop ? "140px" : "100%",
          width: isDesktop ? "140px" : "100%",
          flex: isDesktop ? "0 0 140px" : "0 0 100%",
          justifyContent: isDesktop ? "flex-end" : "center",
        }}
      >
        <Skeleton height={34} width={61} />
        <Skeleton height={34} width={34} style={{marginLeft: 12}} />
      </Col>
    </li>
  );
}
