import { RadioGroup } from "@headlessui/react";

import assign from "lodash/assign";
import React, { useState, useEffect } from "react";

const settings = [
  {
    internal: true,
    name: "Internal TA/HR Professional",
    description:
      "I have a corporate email address and a role in TA/HR, and would like full site access. Agencies and vendors cannot contact me.",
  },
  {
    internal: false,
    name: "Agency Recruiter",
    description:
      "I work at an agency and would like to be listed in the Agency Directory.",
  },
  {
    internal: true,
    name: "Non-TA Professional",
    description:
      "I am at a hiring company, but work in a division outside of TA or HR.",
  },
];

function flattenStyles(...styles) {
  return assign.apply(Object, styles.filter(Boolean));
}

export default function Join({ omniauthPath, recruitifiSignInPath }) {
  const [selected, setSelected] = useState();
  const [targetURL, setTargetURL] = useState("");

  useEffect(() => {
    if (selected) {
      if (selected.internal) {
        setTargetURL(omniauthPath);
      } else {
        setTargetURL(recruitifiSignInPath);
      }
    }
  }, [selected]);

  return (
    <div className="kt-login__body" style={{ margin: "0 auto", width: 410 }}>
      <div className="kt-login__logo">
        <a href="/">
          <img src="/assets/media/logos/talk.svg" />
        </a>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          color: "#F35B5E",
          fontWeight: "500",
          marginTop: "-40px",
        }}
      >
        Sign In
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "1.23rem",
          color: "#49455D",
          fontWeight: "500",
          marginTop: "20px",
        }}
      >
        <p>Welcome to TALK!</p>

        <p>Please select the option that best describes you.</p>
      </div>
      <div style={{ margin: "24px 0" }}>
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0, 0, 0, 0)",
              whiteSpace: "nowrap",
              borderWidth: "0",
            }}
          >
            Privacy setting
          </RadioGroup.Label>
          <div
            style={{
              marginTop: "-1px",
              backgroundColor: "#ffffff",
              borderRadius: "0.375rem",
            }}
          >
            {settings.map((setting, settingIdx) => (
              <RadioGroup.Option
                key={setting.name}
                value={setting}
                style={flattenStyles(
                  settingIdx > 0 ? { marginTop: "1rem" } : "",
                  setting === selected
                    ? {
                      zIndex: "10",
                      backgroundColor: "#f0f9ff",
                      border: "1px solid #70ACD1",
                    }
                    : { border: "1px solid #E5E7EB" },
                  {
                    display: "flex",
                    position: "relative",
                    maxWidth: 410,
                    padding: "1rem",
                    borderWidth: "1px",
                    cursor: "pointer",
                    borderRadius: "0.5rem",
                    boxShadow:
                      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                  }
                )}
              >
                {({ active, checked }) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={flattenStyles(
                          checked
                            ? {
                              backgroundColor: "#357BA6",
                              borderColor: "transparent",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              border: "1px solid #D0E4F0",
                            },
                          active
                            ? {
                              boxShadow:
                                  "0 0 0 var(--ring-offset-width) var(--ring-offset-color), var(--ring-shadow)",
                              "-ringColor": "#357BA6",
                              "-ringOffsetWidth": "2px",
                            }
                            : "",
                          {
                            display: "flex",
                            marginTop: "0.125rem",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "1.3rem",
                            height: "1.3rem",
                            minWidth: "1.3rem",
                            borderRadius: "9999px",
                            borderWidth: "1px",
                            cursor: "pointer",
                          }
                        )}
                        aria-hidden="true"
                      >
                        <span
                          style={{
                            backgroundColor: "#ffffff",
                            width: "0.375rem",
                            height: "0.375rem",
                            borderRadius: "9999px",
                          }}
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <RadioGroup.Label
                        as="span"
                        style={flattenStyles(
                          checked ? { color: "#233239" } : { color: "#111827" },
                          {
                            display: "block",
                            fontSize: "1.23rem",
                            lineHeight: "1.5rem",
                            fontWeight: "500",
                            marginBottom: 5,
                          }
                        )}
                      >
                        {setting.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        style={flattenStyles(
                          checked ? { color: "#405B65" } : { color: "#6B7280" },
                          {
                            display: "block",
                            fontSize: "1.23rem",
                            lineHeight: "1.45rem",
                          }
                        )}
                      >
                        {setting.description}
                      </RadioGroup.Description>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      <div className="kt-login__signin">
        {selected && selected.internal ? (
          <a
            href={targetURL}
            target="_self"
            rel="noopener noreferrer"
            className={"btn btn-brand-secondary"}
            style={{
              width: "100%",
              fontSize: "1.5rem",
              padding: 11.8,
              textAlign: "left",
              display: "flex",
              color: targetURL ? "#fff" : "silver",
            }}
          >
            <i style={{ width: 26.06 }} className="socicon-linkedin"></i>
            <div
              style={{
                width: "calc(100% - 51.1px)",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Sign in with LinkedIn
            </div>
          </a>
        ) : (
          <a
            href={
              window.location.protocol +
              "//" +
              targetURL.replace(/(^\w+:|^)\/\//, "")
            }
            target="_self"
            rel="noopener noreferrer"
            className={`btn btn-brand-secondary ${targetURL ? "" : "disabled"}`}
            style={{
              width: "100%",
              fontSize: "1.5rem",
              padding: 11.8,
              textAlign: "left",
              display: "flex",
              color: targetURL ? "#fff" : "silver",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "none",
              }}
            >
              Sign in
            </div>
          </a>
        )}
        <br />
        <div className="kt-login__account">
          <span className="kt-login__account-msg">
            Don't have an account yet?
          </span>{" "}
          <a href="/sign-up" style={{ fontWeight: 500 }}>
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
}
