import { Button, IconButton, makeStyles } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import React, {
  useState, useEffect, forwardRef, createRef, useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addParticipants } from '../../../../../actions/conversationActions';
import { openSnackbar } from '../../../../../actions/uiActions';
import useFadeAnimation from '../../../../../hooks/useFadeAnimation';
import { getUser } from '../../../../../selectors/userSelectors';

import ParticipantsSearch from '../../../new/ParticipantsSearch/ParticipantsSearch';
import { useConversationUsers } from '../../../partials/hooks';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
  },
  inputContainer: {
    position: "relative",
    left: theme.spacing(1),
    width: "calc(100% - 150px)",
  },
  addedNotification: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "#f35b5e"
  },
  actionsContainer: {
    display: "flex",
  },
  button: {
    borderRadius: theme.spacing(2),
    marginLeft: theme.spacing(3),
    boxShadow: "none"
  },
  rightButton: {
    marginLeft: theme.spacing(2)
  }
}));

function ParticipantForm({
  conversation, onCancel, setAdded
}) {
  const dispatch = useDispatch();

  const [addingIds, setAddingIds] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const isDisabled = isSubmitting || addingIds.length === 0;

  const classes = useStyles();

  const users = useConversationUsers(conversation, { includeCurrent: true });

  const handleSubmit = () => {
    if (isDisabled) return;

    const submissionData = {
      conversation_id: conversation.id,
      participant_ids: addingIds
    };

    setSubmitting(true);
    dispatch(addParticipants(submissionData))
    .then(() => {
      setSubmitting(false);
      setAdded(addingIds);
      onCancel();
    })
    .catch(() => {
      dispatch(openSnackbar("Server Error"));
      setSubmitting(false);
    });
  };

  // Combine existing and users to be added for the sake of max persons
  const participantIds = [
    ...users.map(({ id }) => id),
    ...addingIds
  ];

  const excludedIds = users.map(({ attributes: { profileId } }) => profileId);

  return (
    <div className={classes.formContainer}>
      <div className={classes.inputContainer}>
        <ParticipantsSearch
          {...{
            participantIds,
            setParticipantIds: setAddingIds,
            excludedIds,
          }}
        />
      </div>
      <div className={classes.actionsContainer}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classnames(classes.button, classes.rightButton)}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

const AddedNotification = forwardRef(({ added }, ref) => {
  const classes = useStyles();

  const users = useSelector((state) => (
    added.map((id) => getUser(state, { id }))
  ));

  let names = users[0].attributes.firstName;
  if (users.length > 1) {
    const namesArr = map(users, user => user.attributes.name);
    names = [[namesArr.slice(0,-1).join(", ")]].concat([namesArr.slice(-1)]).join(", and ");
  }

  return (
    <div ref={ref}>
      <div className={classnames(classes.addedNotification)}>
        {users.length > 1 ? `${names} were added` : `${names} was added`}
      </div>
    </div>
  );
});

AddedNotification.displayName = "AddedNotification";

function AddParticipant({ conversation }) {
  const ref = createRef();

  const [adding, setAdding] = useState(false);
  const [added, setAdded] = useState([]);
  const prevAdded = useRef(added);

  const animate = useFadeAnimation(ref);

  const toggleAdding = () => setAdding((curr) => {
    if (!curr) setAdded([]); // if adding again, reset the added
    return !curr;
  });

  useEffect(() => {
    if (!isEqual(added, prevAdded.current)) {
      prevAdded.current = added;
      if (added.length > 0) {
        animate(3000).then(() => {
          setAdded([]);
        });
      }
    }
  }, [added, animate]);

  return (
    <>
      <IconButton onClick={toggleAdding}>
        <GroupAddIcon />
      </IconButton>
      {adding && (
        <ParticipantForm
          {...{ conversation, setAdded, onCancel: toggleAdding }}
        />
      )}
      {added.length > 0 && (
        <AddedNotification ref={ref} {...{ added }} />
      )}
    </>
  );
}

export default AddParticipant;
