import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSettings } from "../../actions/settingActions";

export default function Settings({ children }) {
  const dispatch = useDispatch();
  const { loaded } = useSelector((state) => state.settings);

  useEffect(() => {
    !loaded && dispatch(fetchSettings());
  }, [dispatch]);

  return loaded ? children : null
}
