import merge from "lodash/merge";
import { RECEIVE_GROUPS } from "../actions/groupActions";

const defaultState = {
  items: {},
};

const groupsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_GROUPS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default groupsReducer;
