import React from "react";
import { connect } from "formik";
import makeStyles from "@material-ui/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    bottom: "100%",
    backgroundColor: "white",
  },
  itemContainer: {
    width: "100%",
    position: "relative",
    borderTop: `1px solid ${theme.palette.primary.main}`,
    height: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nameContainer: {
    width: "calc(100% - 100px)",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  attachmentIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
}));

const AttachmentItem = ({ name, handleDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemContainer}>
      <i
        className={classnames("fas", "fa-paperclip", classes.attachmentIcon)}
      ></i>
      <div className={classes.nameContainer}>{name}</div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleDelete}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const AttachmentsList = ({
  formik: {
    values: { attachments },
    setFieldValue,
    isSubmitting,
  },
}) => {
  const classes = useStyles();

  const handleDelete = (attachmentUrl) => () => {
    if (isSubmitting) return;

    setFieldValue(
      "attachments",
      attachments.filter((attachment) => attachment.url !== attachmentUrl)
    );
  };

  return (
    <div className={classes.listContainer}>
      {attachments.map(({ name, url }) => (
        <AttachmentItem
          key={url}
          name={name}
          handleDelete={handleDelete(url)}
        />
      ))}
    </div>
  );
};

export default connect(AttachmentsList);
