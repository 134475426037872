import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Visible } from "react-grid-system";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { createSelector } from "reselect";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import pick from "lodash/pick";
import concat from "lodash/concat";
import merge from "lodash/merge";

import FormPageLayout from "../General/FormPage/FormPageLayout";
import withGlobalProviders from "../General/withGlobalProviders";

import Form from "./create/Form";
import Tips from "./create/Tips";
import Footer from "./create/Footer";
import TipsModal from "./create/TipsModal";

import {
  buildInitialValues,
  buildValidationSchema,
  createHandleSubmit,
  LOCATION_KEYS,
  WEBINAR_KEYS,
  EVENT_KEYS,
  ADMIN_KEYS,
} from "./create/fieldHelpers";

import {
  createEvent,
  fetchEvent,
  updateEvent,
} from "../../actions/eventActions";
import {
  fetchBoardMembers,
  fetchBoardMembersAndAdmins,
} from "../../actions/userActions";
import {
  addHttp,
  mapKeysToSnakeCase,
  removeSpaces,
} from "../../util/formatHelpers";

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "32px 20px 32px 20px",
  },
  tipsContainer: {
    marginLeft: 24,
    maxWidth: 320,
  },
}));

const Create = ({ chapterId, stateOptions, eventId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const selectBoardMembersOrAdmins = createSelector(
    (state) => state.users.items,
    (users) =>
      filter(
        users,
        (user) => user.attributes.admin || user.attributes.boardMember
      )
  );

  // Custom loading required to ensure handleSubmit not called while site redirect
  // Remove code once app is fully react and use Formik's isSubmitting
  const [loading, setLoading] = useState(false);

  const boardMembersOrAdmins = useSelector(selectBoardMembersOrAdmins);
  const { admin } = useSelector((state) => state.settings.attributes);

  const event = useSelector((state) => state.events.items[eventId]);
  const [webinar, setWebinar] = useState(null);

  useEffect(() => {
    isEmpty(boardMembersOrAdmins) &&
      (admin
        ? dispatch(fetchBoardMembersAndAdmins(chapterId))
        : dispatch(fetchBoardMembers(chapterId)));
  }, [boardMembersOrAdmins]);

  useEffect(() => {
    if (eventId && !event) {
      dispatch(fetchEvent(eventId)).then((data) => {
        setWebinar(data.payload.event[eventId].attributes.webinar);
      });
    }
  }, [event, eventId, dispatch]);

  const submitFunction = (data) =>
    eventId ? dispatch(updateEvent(data)) : dispatch(createEvent(data));

  const handleSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      const keys = concat(
        EVENT_KEYS,
        admin ? ADMIN_KEYS : [],
        webinar ? WEBINAR_KEYS : LOCATION_KEYS
      );

      const data = merge({}, pick(values, keys), {
        id: eventId,
        g2w_webinar_key: removeSpaces(values.g_2_w_webinar_key),
        zoom_webinar_id: removeSpaces(values.zoom_webinar_id),
        webinar,
        webinar_url: webinar ? addHttp(values.webinar_url) : null,
        is_submit: true,
      });

      setLoading(true);
      return submitFunction(data);
    },
    handleSuccess: () => {
      window.location.href = "/events?tab=submitted";
    },
    handleErrors: (context) => {
      setLoading(false);
      context.setSubmitting(false);
    },
  });

  const handleBack = () => {
    setLoading(true);
    window.location.href = "/events";
  };

  const adminValues = admin && { chapter: "", creator: "" };

  const initialValues = buildInitialValues(
    merge(
      {},
      adminValues,
      mapKeysToSnakeCase((event && event.attributes) || {})
    )
  );

  if (isEmpty(boardMembersOrAdmins)) return null;
  return (eventId && event) || !eventId ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Row style={{ width: "100%" }}>
        <Col lg={8.5} style={{ paddingRight: 0 }}>
          <FormPageLayout>
            <Formik
              initialValues={initialValues}
              webinar={webinar}
              validationSchema={buildValidationSchema(webinar, admin)}
              onSubmit={handleSubmit}
            >
              {({ setFieldTouched }) => (
                <div className={classes.formContainer}>
                  <TipsModal />
                  <Form
                    event={event}
                    admin={admin}
                    webinar={webinar}
                    setWebinar={setWebinar}
                    stateOptions={stateOptions}
                  />
                  <Footer
                    submitFunction={submitFunction}
                    event={event}
                    loading={loading}
                    handleBack={handleBack}
                    setLoading={setLoading}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              )}
            </Formik>
          </FormPageLayout>
        </Col>
        <Visible lg xl xxl>
          <Col md={12} lg={3.5} style={{ paddingLeft: 0 }}>
            <div className={classes.tipsContainer}>
              <Tips />
            </div>
          </Col>
        </Visible>
      </Row>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default withGlobalProviders(Create);
