import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import sortBy from "lodash/sortBy";

import withGlobalProviders from "../General/withGlobalProviders";
import EmailSettings from "./EmailSettings/EmailSettings";
import TalkSettings from "./TalkSettings/TalkSettings";
import { fetchSubscriptions } from "../../actions/subscriptionActions";
import { getSubscriptionsByChapter } from "../../selectors/subscriptionSelectors";

function Edit() {
  const dispatch = useDispatch();

  const { subscriptions, loaded } = useSelector(getSubscriptionsByChapter);

  const sortedSubscriptions = useMemo(
    () => sortBy(subscriptions, ({ groupName }) => groupName),
    [subscriptions]
  );

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchSubscriptions());
    }
  }, [dispatch, loaded]);

  return loaded ? (
    <div className="row row-full-height">
      <EmailSettings subscriptions={sortedSubscriptions} />
      <TalkSettings subscriptions={sortedSubscriptions} />
    </div>
  ) : (
    <React.Fragment />
  );
}

export default withGlobalProviders(Edit);
