import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { Row } from "react-grid-system";

import { PROFILE_HOVER_CHAT } from "../../../../util/fathomEventCodes";
import Textarea from "../../../General/FormInputs/Textarea";

const useStyles = makeStyles(() => ({
  sendChatContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  conversationLink: {
    color: "#357BA6",
    textDecoration: "underline",
  },
  conversationLinkContainer: {
    "&$standAlone": {
      marginTop: 24,
      textAlign: "center",
      fontSize: 16,
    },
  },
  standAlone: {},
}));
function ChatContents({
  expanded,
  standAlone,
  text,
  setText,
  createMessage,
  conversationId,
}) {
  const classes = useStyles();

  if (expanded && !conversationId) {
    return (
      <>
        <Row
          nogutter
          style={{
            justifyContent: "center",
            borderTop: "1px solid #ECEDF2",
            paddingTop: 12,
          }}
        >
          <div className={classes.sendChatContainer}>
            <Textarea
              placeholder="Type here..."
              value={text}
              setValue={setText}
              maxRows={6}
              styles={{
                input: {
                  fontSize: 13,
                },
              }}
            />
          </div>
        </Row>
        <Row nogutter style={{ justifyContent: "flex-end" }}>
          <button
            type="submit"
            disabled={!text}
            onClick={() => {
              createMessage();
              if (window.fathom) window.fathom.trackGoal(PROFILE_HOVER_CHAT, 0);
            }}
            className={classnames("btn", "btn-sm", "btn-secondary")}
          >
            Send
          </button>
        </Row>
      </>
    );
  }
  if (conversationId) {
    return (
      <div
        className={classnames(classes.conversationLinkContainer, {
          [classes.standAlone]: standAlone,
        })}
      >
        <span>
          Chat sent!
          {" "}
          <a
            className={classes.conversationLink}
            target="_blank"
            rel="noopener noreferrer"
            href={`/chat?open_conversation_id=${conversationId}`}
          >
            View the Conversation
          </a>
        </span>
      </div>
    );
  }
  return null;
}

export default ChatContents;
