import { object, string, array } from "../../../../../util/yup";

export const initialValues = {
  selectedChoices: []
};

export const initialErrors = {
  selectedChoices: "Must select at least one option",
};

export const buildValidationSchema = (poll) =>
  poll.attributes.multiSelect
    ? object().shape({
        selectedChoices: array()
          .of(string())
          .min(1, "Must select at least one option"),
      })
    : object().shape({
        selectedChoices: array()
          .of(string())
          .min(1, "Must select at least one option")
          .max(1, "Cannot select more than one option"),
      });
