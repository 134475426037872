import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    marginBottom: -1,
    minHeight: 56,
    padding: "0 20px",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "16px",
    color: "#292E33",
  },
  subtitle: {
    marginTop: 2,
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: "Poppins",
    lineHeight: "16px",
    color: "#48465B",
  },
}));

export default function Summary({ id, title, subtitle }) {
  const classes = useStyles();

  return (
    <AccordionSummary
      aria-controls={id + "-content"}
      id={id + "-header"}
      expandIcon={<ExpandMoreIcon />}
    >
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </AccordionSummary>
  );
}
