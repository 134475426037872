import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import { useConversationLabel } from "../../../../partials/hooks";

import EditNameForm from "./EditNameForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  labelContainer: {
    fontSize: 15,
    fontWeight: 400,
    color: "#292E33",
    maxWidth: "calc(100% - 40px)",
    textOverflow: "ellipsis",
    whiteSpace: "noWrap",
    overflow: "hidden",
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  hideButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "auto",
  },
}));

function ConversationName({ conversation }) {
  const [editing, setEditing] = useState(false);

  const classes = useStyles();

  const toggleEditing = () => setEditing((currEditing) => !currEditing);

  const currentName = useConversationLabel(conversation);

  return (
    <div>
      <div className={classes.headerContainer}>
        <div className={classes.labelContainer}>{currentName}</div>
        <IconButton onClick={toggleEditing}>
          <EditIcon />
        </IconButton>
      </div>
      {editing && (
        <EditNameForm
          conversationId={conversation.id}
          initialValue={currentName}
          unsetEditing={toggleEditing}
        />
      )}
    </div>
  );
}

export default ConversationName;
