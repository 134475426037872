import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";
import { useSelector } from "react-redux";

import { getConversationParticipant } from "../../../selectors/conversationSelectors";
import { getUser } from "../../../selectors/userSelectors";
import { buildFullName } from "../../../util/formatHelpers";

const useStyles = makeStyles(() => ({
  userName: {
    fontWeight: 400,
  },
}));

function RemovedUserText({ event }) {
  const classes = useStyles();

  const { actorId, subjectId: participantId } = event.attributes;

  const currentUserId = useSelector((state) => state.settings.attributes.id);
  const participant = useSelector((state) => getConversationParticipant(state, participantId));
  const removedUser = useSelector((state) => getUser(state, { id: participant.attributes.userId }));

  const actor = useSelector((state) => getUser(state, { id: actorId }));

  if (actor.id === removedUser.id) {
    return (
      <>
        <span className={classes.userName}>
          {buildFullName(
            removedUser.attributes.firstName,
            removedUser.attributes.lastName
          )}
        </span>
        {" has left the chat"}
      </>
    );
  }

  const senderName = (actor.id === currentUserId)
    ? "You"
    : buildFullName(actor.attributes.firstName, actor.attributes.lastName);

  return (
    <>
      <span className={classes.userName}>
        {senderName}
      </span>
      {" removed "}
      <span className={classes.userName}>
        {buildFullName(
          removedUser.attributes.firstName,
          removedUser.attributes.lastName
        )}
      </span>
      {" from the chat"}
    </>
  );
}

export default RemovedUserText;
