import React, { useState } from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";

export function ReadMore({ children, lines, more, less, open, close }) {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(true);

  function toggleLines() {
    setExpanded(!expanded);
  }

  function handleTruncate(incomingTruncated) {
    if (truncated !== incomingTruncated) {
      setTruncated(incomingTruncated);
    }
  }

  return (
    <>
      <Truncate
        lines={!expanded && lines}
        trimWhitespace={true}
        ellipsis={
          <span>
            ...{close + " "}
            <span
              style={{
                color: "#f25b5e",
                cursor: "pointer",
                paddingLeft: 4,
              }}
              onClick={toggleLines}
            >
              {more}
            </span>
          </span>
        }
        onTruncate={handleTruncate}
      >
        <span style={{ width: "100% " }}>
          {open}
          {children}
          {(expanded || !truncated) && close}
        </span>
      </Truncate>
      {!truncated && expanded && (
        <span>
          {" "}
          <span
            style={{
              color: "#f25b5e",
              cursor: "pointer",
              paddingLeft: 4,
            }}
            onClick={toggleLines}
          >
            {less}
          </span>
        </span>
      )}
    </>
  );
}

ReadMore.defaultProps = {
  lines: 2,
  more: "See More",
  less: "Show Less",
  open: '"',
  close: '"'
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  open: PropTypes.string,
  close: PropTypes.string,
};

export default ReadMore;
