import { makeStyles } from "@material-ui/core";
import { forEach, includes, map, orderBy } from "lodash-es";
import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConversationEvents } from "../../../../actions/conversationActions";
import { makeGetFilteredConversationEvents } from "../../../../selectors/conversationSelectors";

import {
  MESSAGE,
  ATTACHMENT,
  ADD_USER,
  REMOVE_USER,
} from "../../../../selectors/conversationSelectors";

import { IDLE, LOADING, LOADED, ERROR } from "../../../../util/apiHelpers";

const ConversationEventsLoader = ({ conversationId, children }) => {
  const dispatch = useDispatch();
  const [loadStatus, setLoadStatus] = useState(IDLE);

  const getEvents = useMemo(() => makeGetFilteredConversationEvents(), []);

  const events = useSelector((state) =>
    getEvents(state, { conversationId: conversationId })
  );

  const sortedEvents = useMemo(
    () => orderBy(events, [(event) => event.attributes.createdAt], ["asc"]),
    [events]
  );

  useEffect(() => {
    setLoadStatus(LOADING);

    dispatch(fetchConversationEvents(conversationId)).then(() => {
      setLoadStatus(LOADED);
    });
  }, [conversationId]);

  const groupedEvents = useMemo(() => {
    const grouped = [];

    if (!sortedEvents.length) return grouped;

    let currGroup = { primaryEvent: sortedEvents[0], events: [] };

    forEach(sortedEvents, (event) => {
      if (
        includes([ATTACHMENT, MESSAGE], event.attributes.eventType) &&
        currGroup.primaryEvent.attributes.actorId ===
          event.attributes.actorId &&
        moment(currGroup.primaryEvent.attributes.createdAt).day() ===
          moment(event.attributes.createdAt).day()
      ) {
        currGroup.events.push(event);
      } else {
        grouped.push(currGroup);
        currGroup = { primaryEvent: event, events: [event] };
      }
    });

    grouped.push(currGroup);

    return grouped;
  }, [sortedEvents]);

  return children({ items: groupedEvents, loadStatus });
};

export default ConversationEventsLoader;
