import React, { useMemo } from "react";
import { map } from "lodash";

const PageLink = ({
  pageNum,
  handleSelection,
  itemClassName,
  style,
  customContent,
}) => {
  const handleClick = () => handleSelection(pageNum);

  return (
    <li className={itemClassName} onClick={handleClick} style={style}>
      <a style={{ cursor: "pointer" }}>{customContent || pageNum}</a>
    </li>
  );
};

const PrevPageLinks = ({ currPage, handleSelection }) => {
  const style = currPage <= 1 ? { visibility: "hidden" } : {};

  return (
    <React.Fragment>
      <PageLink
        pageNum={1}
        handleSelection={handleSelection}
        itemClassName="kt-pagination__link--first"
        style={style}
        customContent={
          <i className="fa fa-angle-double-left kt-font-brand"></i>
        }
      />
      <PageLink
        pageNum={currPage - 1}
        handleSelection={handleSelection}
        itemClassName="kt-pagination__link--prev"
        style={style}
        customContent={<i className="fa fa-angle-left kt-font-brand'"></i>}
      />
    </React.Fragment>
  );
};

const NextPageLinks = ({ currPage, numPages, handleSelection }) => {
  const style = currPage >= numPages ? { visibility: "hidden" } : {};

  return (
    <React.Fragment>
      <PageLink
        pageNum={currPage + 1}
        handleSelection={handleSelection}
        itemClassName="kt-pagination__link--next"
        style={style}
        customContent={<i className="fa fa-angle-right kt-font-brand"></i>}
      />
      <PageLink
        pageNum={numPages}
        handleSelection={handleSelection}
        itemClassName="kt-pagination__link--last"
        style={style}
        customContent={
          <i className="fa fa-angle-double-right kt-font-brand"></i>
        }
      />
    </React.Fragment>
  );
};

const InnerPageLinks = ({
  currPage,
  numPages,
  handleSelection,
  maxVisible,
}) => {
  const items = [];

  const [leftBoundary, rightBoundary] = useMemo(() => {
    let left = currPage;
    let right = currPage;

    const hasRemainingPages = () => maxVisible > right - left + 1;
    while (hasRemainingPages() && !(left <= 1 && right >= numPages)) {
      if (left > 1) {
        left--;
      }
      if (right < numPages && hasRemainingPages()) {
        right++;
      }
    }

    return [left, right];
  }, [currPage, numPages, maxVisible]);

  if (leftBoundary > 1) {
    items.push(
      <PageLink
        key={`boundary-${leftBoundary - 1}`}
        pageNum={leftBoundary - 1}
        handleSelection={handleSelection}
        customContent="..."
      />
    );
  }

  let idx;
  for (idx = leftBoundary; idx <= rightBoundary; idx++) {
    items.push(
      <PageLink
        key={idx}
        pageNum={idx}
        handleSelection={handleSelection}
        itemClassName={currPage === idx ? "kt-pagination__link--active" : ""}
      />
    );
  }

  if (rightBoundary < numPages) {
    items.push(
      <PageLink
        key={`boundary-${rightBoundary + 1}`}
        pageNum={rightBoundary + 1}
        handleSelection={handleSelection}
        customContent="..."
      />
    );
  }

  return items;
};

const PaginationLinks = ({
  currPage,
  numPages,
  handleSelection,
  maxVisible = 5,
}) => (
  <div
    className="kt-pagination kt-pagination--brand"
    style={{ display: "flex", justifyContent: "center" }}
  >
    <ul className="kt-pagination__links">
      {map(
        [PrevPageLinks, InnerPageLinks, NextPageLinks],
        (sectionClass, idx) =>
          React.createElement(sectionClass, {
            key: idx,
            currPage,
            numPages,
            handleSelection,
            maxVisible,
          })
      )}
    </ul>
  </div>
);

export default PaginationLinks;
