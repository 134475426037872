// RSVP - Dashboard
export const RSVP_DASHBOARD = "SNAXOCH6";
// Modal - View Event Conversation
export const MODAL_EVENT_CONVERSATION = "ROGOUMRG";
// topic comment (newsfeed)
export const TOPIC_COMMENT = "A340XSOA";
// RSVP - Event Index
export const RSVP_EVENT_INDEX = "TWLGGKZ1";
// Click "See Full"
export const CLICK_SEE_FULL = "HVITWFQR";
// React to comment
export const REACT_TO_COMMENT = "YLQDQZQR";
// Directory - Chat
export const DIRECTORY_CHAT = "ZCYEIZYB";
// Directory - LinkedIn
export const DIRECTORY_LINKEDIN = "NLXYJ34P";
// poll_answer (index)
export const POLL_ANSWER_INDEX = "4VW0WAAC";
// poll_answer (show)
export const POLL_ANSWER_SHOW = "67QW56UA";
// poll_answer (newsfeed)
export const POLL_ANSWER_NEWSFEED = "WWJSRLBS";
// Profile Hover Chat
export const PROFILE_HOVER_CHAT = "XQKT30XS";
// Share Event LinkedIn - Events Index
export const LINKEDIN_EVENT_INDEX = "CBB900QJ"
// Share Event LinkedIn - Events Page
export const LINKEDIN_EVENT_SHOW = "9CR0PGSS"
// Share Event LinkedIn - Newsfeed
export const LINKEDIN_EVENT_NEWSFEED = "N8JCSKUX"
// Share Event Copy URL - Events Index
export const URL_EVENT_INDEX = "TDUA1ZSL"
// Share Event Copy URL - Events Page
export const URL_EVENT_SHOW = "6FCXZXEH"
// Share Event Copy URL - Newsfeed
export const URL_EVENT_NEWSFEED = "VPFQ6T17"
