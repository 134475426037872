import { createUseStyles } from "react-jss";

export const styles = createUseStyles({
  searchContainer: {
    position: "relative",
    display: "inline-block",
    margin: 0,
  },
  inputIcon: {
    position: "absolute",
    top: "calc(50% - 7px)",
    left: 8,
  },
  inputClose: {
    position: "absolute",
    top: "calc(50% - 7px)",
    right: 8,
    cursor: "pointer",
  },
});
