import merge from "lodash/merge";

import { RECEIVE_EVENTS, RECEIVE_EVENT } from "../actions/eventActions";

const defaultState = {
  items: {},
  loaded: false,
};

const eventReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_EVENTS:
      return merge({}, state, { items: action.payload.event, loaded: true });
    case RECEIVE_EVENT:
      return merge({}, state, { items: action.payload.event, loaded: true });
    default:
      return state;
  }
};

export default eventReducer;
