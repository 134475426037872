import { makeStyles } from "@material-ui/core";
import { connect } from "formik";
import React from "react";

import Textarea from "../../General/FormInputs/Textarea";

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "#ECEDF2",
    "&:focus": {
      borderTopColor: theme.palette.primary.main,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: "#ECEDF2",
    },
  },
}));

// todo(me): handle autofocus?
function MessageBodyInput({
  formik: { values, setFieldValue },
  id = "body",
  placeholder = "Write a message...",
  ...props
}) {
  const classes = useStyles();

  return (
    <Textarea
      id={id}
      value={values.body}
      placeholder={placeholder}
      customStyles={{
        container: { marginBottom: "1rem" },
        input: { borderRadius: 0 },
      }}
      overloadedClassName={classes.container}
      showError={false}
      setValue={(newValue) => setFieldValue(id, newValue)}
      fontRem={1.15}
      numRows={3}
      maxRows={6}
      {...props}
    />
  );
}

export default connect(MessageBodyInput);
