import React, { useRef, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { CLICK_SEE_FULL } from "../../../util/fathomEventCodes";

const useStyles = createUseStyles({
  container: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 15,
    "& img": {
      maxWidth: "100%",
    },
  },
  truncatedContainer: {
    cursor: "pointer",
    position: "relative",
  },
  truncatedGradient: {
    position: "absolute",
    bottom: 29,
    left: "0",
    width: "100%",
    textAlign: "center",
    margin: "0",
    padding: "40px 0",
  },
  buttonContainer: {
    color: "#f25b5e",
    textAlign: "center",
  },
});

const CollapsableText = (props) => {
  const classes = useStyles();
  const divRef = useRef();
  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const maxHeight = props.category === "comment" ? 158 : 800;

  useEffect(() => {
    if (
      divRef &&
      divRef.current.scrollHeight &&
      divRef.current.scrollHeight > maxHeight + 8
    ) {
      setTruncated(true);
    }
  }, [divRef]);

  const manualToggle = () => {
    if (!!window.fathom) {
      window.fathom.trackGoal(CLICK_SEE_FULL, 0);
    }
    setExpanded(true);
  };

  return (
    <div
      className={`${classes.container} trix-content`}
      style={{
        maxHeight: expanded ? "none" : maxHeight,
        wordBreak: "break-word",
        color: "#212121",
        fontSize: "1.2857142857142858rem",
        lineHeight: "2.2857142857142856rem",
        maxWidth: "700px",
        paddingRight: 0,
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
        ref={(el) => {
          divRef.current = el;
        }}
        style={{
          overflowY: truncated ? "hidden" : "auto",
          maxHeight: expanded ? "none" : maxHeight,
          marginTop: 20,
        }}
      ></div>
      {truncated && !expanded && (
        <div className={classes.truncatedContainer} onClick={manualToggle}>
          <div
            className={classes.truncatedGradient}
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${props.gradientColor}`,
            }}
          ></div>
          <div
            className={classes.buttonContainer}
            style={{ backgroundColor: props.gradientColor, marginTop: -22 }}
          >
            See Full
          </div>
        </div>
      )}
    </div>
  );
};

export default CollapsableText;
