import React, { useEffect, useState } from "react";
import { Col, useScreenClass } from "react-grid-system";
import { useSelector, shallowEqual } from "react-redux";

import {
  PLACEHOLDER_URL,
  useStyles,
  checkDesktop,
  checkMobile,
  checkTablet,
} from "./utils";

const STYLES = {
  desktop: {
    padding: 0,
    maxWidth: 285,
    width: 285,
    flex: "0 0 285px",
  },
  tablet: {
    padding: 0,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 50%",
  },
  mobile: {
    padding: 0,
    maxWidth: "100%",
    width: "100%",
    flex: "0 0 100%",
  },
};

export default function Image({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);

  const { status, conversationPath, link, photoUrl } = event.attributes;

  const classes = useStyles({ url: photoUrl || PLACEHOLDER_URL });

  const screenClass = useScreenClass();

  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (checkDesktop(screenClass)) {
      setStyles(STYLES.desktop);
    }
    else if (checkTablet(screenClass)) {
      setStyles(STYLES.tablet);
    }
    else if (checkMobile(screenClass)) {
      setStyles(STYLES.mobile);
    }
  }, [screenClass]);

  return (
    <Col xs={12} sm={6} lg={3} style={styles}>
      <a href={status === "past" ? conversationPath : link}>
        <div className={classes.image} />
      </a>
    </Col>
  );
}
