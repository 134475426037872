import { RECEIVE_ACTIVITIES } from "../actions/activityActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  vendorActivityItems: {},
  loaded: false,
  allActivitiesLoaded: false,
};

const activitesReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ACTIVITIES:
      return merge({}, state, {
        items: action.payload.activity,
        vendorActivityItems: action.payload.vendorActivity,
        allActivitiesLoaded: action.payload.allActivitiesLoaded,
        loaded: true,
      });
    default:
      return state;
  }
};

export default activitesReducer;
