import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { handleConversationResponse } from "../../../actions/conversationActions";

const useConversationsSubscription = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = window.Sockets.createConversationsSubscription({
      received: (data) => {
        handleConversationResponse(data, dispatch);
      },
    });

    return () => window.Sockets.removeSubscription(subscription);
  }, [dispatch]);
};

export default useConversationsSubscription;
