import { RECEIVE_VENDOR_PRODUCTS } from "../actions/vendorProductActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  popular: [],
  loaded: false
};

const vendorProductsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_VENDOR_PRODUCTS:
      return merge({}, state, {
        items: action.payload,
        popular: action.popular,
        loaded: true,
      });
    default:
      return state;
  }
};

export default vendorProductsReducer;
