import React, { useState, useEffect, createContext } from "react";
import queryString from "query-string";

export const EventsContext = createContext({ items: {} });

import { fetchEvents as fetchFunction } from "../../../actions/eventActions";

import withGlobalProviders from "../../General/withGlobalProviders";
import PaginatedFetch from "../../General/PaginatedFetch";
import PaginationLinks from "../../General/PaginationLinks";

function Wrapper({ children }) {
  const queryParams = queryString.parse(location.search)

  const [search, setSearch] = useState(queryParams.search || "");
  const [status, setStatus] = useState(queryParams.status || "all");
  const [chapters, setChapters] = useState(queryParams.chapters || "");
  const [loading, setLoading] = useState(true);
  const [customParams, setCustomParams] = useState({});

  useEffect(() => {
    setCustomParams({ search, status, chapters });
  }, [search, status, chapters]);

  return (
    <PaginatedFetch
      {...{
        fetchFunction,
        loading,
        setLoading,
        customParams,
        fetchDebounce: 500,
        initialPage: (queryParams.page && parseInt(queryParams.page)) || 1,
      }}
    >
      {({
        handlePageSelection,
        items,
        currentPage,
        totalPages,
        loading,
        inProgress,
        fetchAction,
      }) => (
        <EventsContext.Provider
          value={{
            handlePageSelection,
            items,
            currentPage,
            totalPages,
            loading,
            search,
            setSearch,
            status,
            setStatus,
            chapters,
            setChapters,
            inProgress,
            fetchAction,
          }}
        >
          {children}
          <div
            className="kt-portlet kt-portlet--height-fluid"
            style={{ height: "auto" }}
          >
            <div
              className="kt-portlet__body kt-portlet__body--fit"
              style={{ padding: 20 }}
            >
              <PaginationLinks
                currPage={currentPage}
                numPages={totalPages}
                handleSelection={handlePageSelection}
              />
            </div>
          </div>
        </EventsContext.Provider>
      )}
    </PaginatedFetch>
  );
}

export default withGlobalProviders(Wrapper);
