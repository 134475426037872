import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import useSharedStyles from "../useSharedStyles";
import Tooltip from "@material-ui/core/Tooltip";

function CheckboxRow({
  id,
  onChange,
  checked,
  title,
  rowStyle = "borderedRow",
}) {
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses[rowStyle]} style={{ paddingRight: 15 }}>
      <div className="col-lg-8">{title}</div>
      <div className="col-lg-2" style={{ textAlign: "center" }}>
        <Checkbox {...{ id, onChange, checked }} />
      </div>
      <div className="col-lg-2" style={{ textAlign: "end" }}>
        <Tooltip title="This cannot be unchecked">
          <div style={{ display: "inline" }}>
            <Checkbox checked={true} disabled={true} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default CheckboxRow;
