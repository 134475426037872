import React, { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";

import { fetchVendorActivities } from "../../actions/activityActions";

import Topic from './Activities/Topic';

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(34,34,34,0.2)",
    backgroundColor: "white",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(34,34,34,0.2)",
    height: "50px",
    fontSize: "18px",
    letterSpacing: "0.07;px",
    lineHeight: "24px",
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
  },
  expandButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  activityContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const Activity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const { items, loaded } = useSelector((state) => state.topics);
  const allLoaded = useSelector((state) => state.activities.allActivitiesLoaded);

  const handleMoreActivities = useCallback(() => {
    if (!fetching) {
      setFetching(true);
      setPage(page + 1);
      dispatch(fetchVendorActivities({ page })).then(() => {
        setFetching(false);
      });
    }
  }, [dispatch, fetching, page]);

  return (
    <>
      <div
        className={`kt-portlet kt-portlet__body ${classes.header}`}
        style={{ borderBottom: 0 }}
      >
        Your Activity
      </div>
      <div className={classes.activityContainer}>
        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleMoreActivities}
            hasMore={!allLoaded && !fetching}
            useWindow={true}
          >
            {Object.values(items).map((topic) => (
              <div className="kt-portlet kt-portlet__body" key={topic.id}>
                <Topic {...topic} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
        {(loaded && Object.keys(items).length === 0) && (
          <div className={classes.footer}>No recent posts found</div>
        )}
        <div
          className="kt-portlet kt-portlet__body"
        >
          {!allLoaded && (
            <div className={classes.expandButton} style={{ borderRadius: 5 }}>
              {"Loading..."}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Activity;
