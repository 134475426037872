import React from "react";
import { connect } from "formik";
import get from "lodash/get";
import TextField from "../TextField";

const ConnectedMultipleTextField = connect(
  ({
    formik: { values, handleChange, handleBlur, errors, touched },
    id,
    idx,
    listType,
    ...props
  }) => {
    const value = get(values, id);
    const didTouch = touched[listType] && touched[listType][idx];
    const displayError = get(errors, id);
    const showError = Boolean(didTouch && displayError);

    return (
      <TextField
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        showError={showError}
        {...props}
        helperText={
          showError ? (
            <div
              style={Object.assign(
                {},
                { color: "#f45b5e", marginBottom: "-18px" }
              )}
            >
              {displayError}
            </div>
          ) : null
        }
      />
    );
  }
);

export default ConnectedMultipleTextField;
