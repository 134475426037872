import assign from "lodash/assign";

import { object, string } from "yup";

export const initialValues = {
  subject: "",
  body: "",
  senderId: null,
  chapterId: null,
  memberType: "all",
};

// Announcement Entities
export const CHAPTER = "chapter";
export const BOARD_MEMBER = "board_member";
export const PAST_EVENT = "past_event";

export const buildValidationSchema = (admin, announcementEntity) => {
  let validations = {
    subject: string().required("Cannot be blank"),
    body: string().required("Cannot be blank"),
  };

  admin &&
    assign(validations, {
      senderId: string().required("Cannot be blank").nullable(),
    });

  announcementEntity === "chapter" &&
    assign(validations, {
      chapterId: string().required("Cannot be blank").nullable(),
    });

  return object().shape(validations);
};

export const createHandleSubmit = ({
  handleSubmit,
  handleSuccess = () => {},
  handleErrors = () => {},
}) => {
  return (values, context) => {
    return handleSubmit(values)
      .then(handleSuccess)
      .catch(() => handleErrors(context));
  };
};
