import React from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { getPrimaryGroup } from "../../selectors/groupSelectors";

const useStyles = createUseStyles({
  container: {
    borderTop: "1px solid rgba(34,34,34,0.2)",
    padding: "20px",
  },
  errorMessage: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontColor: "#595d6e",
    textAlign: "center",
    color: "#212121",
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
  },
  actionContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
});

const btnStyle = {
  width: "160px",
  margin: "10px",
};

function CTA({ boardMember, group }) {
  const { slug: groupSlug } = group.attributes;

  const newEventLink = groupSlug && `/chapter/${groupSlug}/events/new`;
  const newWebinarLink =
    groupSlug && `/chapter/${groupSlug}/events/new?webinar=true`;
  const directoryLink = groupSlug && `/chapter/${groupSlug}/directory`;

  if (boardMember) {
    <React.Fragment>
      <a href={newWebinarLink}>
        <button
          style={btnStyle}
          className="btn btn-sm btn-outline-brand-secondary"
        >
          Create Webinar
        </button>
      </a>
      <a href={newEventLink}>
        <button
          style={btnStyle}
          className="btn btn-sm btn-outline-brand-secondary"
        >
          Create Event
        </button>
      </a>
    </React.Fragment>;
  } else {
    <a href={directoryLink}>
      <button
        style={btnStyle}
        className="btn btn-sm btn-outline-brand-secondary"
      >
        Find Board Member
      </button>
    </a>;
  }
}

function EventEmptyState() {
  const classes = useStyles();
  const { boardMember } = useSelector((state) => state.settings.attributes);
  const group = useSelector(getPrimaryGroup) || { attributes: {} };
  const eventsLoaded = useSelector((state) => state.events.loaded);

  return (
    <div className={classes.container}>
      <div className={classes.errorMessage}>
        You are not currently going to any events. Have an idea for a great
        event or webinar?
      </div>
      <div className={classes.actionContainer}>
        {!eventsLoaded && <CTA {...{ boardMember, group }} />}
      </div>
    </div>
  );
}

export default EventEmptyState;
