import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    height: 50,
    borderBottom: "1px solid #ECEDF2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 24,
    "&:hover": {
      borderRadius: "5px 5px 0 0",
      backgroundColor: "#EAEEF3",
    },
  },
  title: {
    color: "#F35B5E",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    paddingRight: 24,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sideMessage: {
    whiteSpace: "nowrap",
    textAlign: "right",
    color: "#595d6e",
  },
});

const Header = ({ title, sideText, }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.sideMessage}>
        {sideText}
      </div>
    </div>
  )
}

export default Header;
