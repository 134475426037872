import React, { useCallback } from "react";
import includes from "lodash/includes";

import {
  COMMENT_LIKE,
  EVENT_COMMENT_CREATE,
  FORUM_COMMENT_CREATE,
  GROUPLESS,
  REPLY_COMMENT_CREATE,
} from "./constants";
import EmailCheckboxRow from "./EmailSettings/CheckboxRow";
import TalkCheckboxRow from "./TalkSettings/CheckboxRow";

function GrouplessSection({ settings = {}, updateSettings, type = "Email" }) {
  const handleChange = useCallback((e) => {
    updateSettings(e.target.id);
  });

  const CheckboxComponent =
    type === "Email" ? EmailCheckboxRow : TalkCheckboxRow;

  return (
    <React.Fragment>
      <CheckboxComponent
        id={`${GROUPLESS}_${FORUM_COMMENT_CREATE}`}
        onChange={handleChange}
        checked={includes(settings.toUpdate, FORUM_COMMENT_CREATE)}
        title="Someone posted on your topic"
      />
      <CheckboxComponent
        id={`${GROUPLESS}_${REPLY_COMMENT_CREATE}`}
        onChange={handleChange}
        checked={includes(settings.toUpdate, REPLY_COMMENT_CREATE)}
        title="Someone replied to you"
      />
      <CheckboxComponent
        id={`${GROUPLESS}_${COMMENT_LIKE}`}
        onChange={handleChange}
        checked={includes(settings.toUpdate, COMMENT_LIKE)}
        title="Someone liked your post"
      />
      <CheckboxComponent
        id={`${GROUPLESS}_${EVENT_COMMENT_CREATE}`}
        onChange={handleChange}
        checked={includes(settings.toUpdate, EVENT_COMMENT_CREATE)}
        title="Someone posted in an event you are attending"
      />
    </React.Fragment>
  );
}

export default GrouplessSection;
