import { Formik } from "formik";
import React, { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "../../../../actions/settingActions";
import FieldGroupHeader from "../../../General/FormCard/FieldGroupHeader";
import FormFooter from "../../../General/FormCard/FormFooter";

import { buildInitialValues, taValidationSchema, validationSchema } from "./fieldHelpers";
import Fields from "./Fields";
import { openSnackbar } from "../../../../actions/uiActions";
import FormCard from "../../../General/FormCard/FormCard";

const UpdateProfileForm = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const initialValues = useMemo(
    () => buildInitialValues(settings.attributes),
    [settings]
  );

  const handleSubmit = useCallback(
    (values, context) =>
      dispatch(updateSettings(values))
        .then(() => {
          context.setSubmitting(false);
          dispatch(openSnackbar("Settings successfully updated"));
          context.resetForm();
        })
        .catch(() => {
          context.setSubmitting(false);
          dispatch(openSnackbar("An error occurred during submission"));
        }),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={settings.attributes.hasMembershipsAccess ? taValidationSchema : validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <FormCard>
          <FieldGroupHeader
            title="Professional Details"
            iconClass="flaticon-suitcase"
          />
          <Fields />
          <FormFooter />
        </FormCard>
      )}
    </Formik>
  );
};

export default UpdateProfileForm;
