import React, { forwardRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles";

import RemovedUserText from "../../partials/RemovedUserText";

const useStyles = makeStyles((theme) => ({
  container: {
    alignSelf: "center",
    fontSize: 16,
    padding: theme.spacing(1),
  },
}));

const RemovedUserEvent = ({ event }, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.container}>
      <RemovedUserText event={event} />{" "}
    </div>
  );
};

export default forwardRef(RemovedUserEvent);
