import React, { useState } from "react";
import BoardMemberInterestModal from "./BoardMemberInterestModal";

export default function BoardMemberInterestButton() {
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  return (
    <React.Fragment>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "300",
          fontFamily: "Poppins",
          lineHeight: "36px",
          color: "#F35B5E",
          cursor: "pointer",
          marginLeft: 12,
        }}
        onClick={toggleModal}
      >
        Interested in Leadership Positions?
      </div>
      <BoardMemberInterestModal open={open} handleClose={toggleModal} />
    </React.Fragment>
  );
}
