import { createSelector } from "reselect";
import { getSubscribedGroups } from "./groupSelectors";

import map from "lodash/map";
import filter from "lodash/filter";
import { GROUPLESS } from "../components/NotificationSettings/constants";

export const getSubscriptionsByChapter = createSelector(
  [getSubscribedGroups, (state) => state.subscriptions],
  (groups, subscriptions) => {
    if (!subscriptions.loaded) {
      return [];
    }

    const grouplessSubscriptions = filter(
      subscriptions.items,
      ({ attributes: { groupId } }) => groupId === GROUPLESS
    );

    const subscriptionsByGroup = {};

    map(groups, (group) => {
      subscriptionsByGroup[group.id] = filter(
        subscriptions.items,
        ({ attributes: { groupId } }) => group.id === groupId
      );
    });

    const formattedSubscriptions = {};

    map(groups, (group) => {
      formattedSubscriptions[group.id] = {
        groupId: group.id,
        groupName: group.attributes.name,
        immediateEmails: map(
          filter(
            subscriptionsByGroup[group.id],
            ({ attributes: { subscribingToEmail } }) => subscribingToEmail
          ),
          ({ attributes: { key } }) => key
        ),
        immediateNotifications: map(
          filter(
            subscriptionsByGroup[group.id],
            ({ attributes: { subscribing } }) => subscribing
          ),
          ({ attributes: { key } }) => key
        ),
      };
    });

    formattedSubscriptions[GROUPLESS] = {
      groupId: GROUPLESS,
      immediateEmails: map(
        filter(
          grouplessSubscriptions,
          ({ attributes: { subscribingToEmail } }) => subscribingToEmail
        ),
        ({ attributes: { key } }) => key
      ),
      immediateNotifications: map(
        filter(
          grouplessSubscriptions,
          ({ attributes: { subscribing } }) => subscribing
        ),
        ({ attributes: { key } }) => key
      ),
    };

    return {
      subscriptions: formattedSubscriptions,
      loaded: true,
    };
  }
);
