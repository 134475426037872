import React from "react";

import SharedHeader from "../../../../Polls/shared/PollContent/Header";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    padding: "0px 6px 6px 6px",
  },
});

const Header = (props) => (
  <SharedHeader {...props} overloadedClasses={useStyles()} />
);

export default Header;
