import React from "react";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";
import { connect } from "formik";

import orderBy from "lodash/orderBy";
import map from "lodash/map";
import get from "lodash/get";
import merge from "lodash/merge";

import {
  ConnectedTextField,
  ConnectedDropdown,
  ConnectedWysiwyg,
  ConnectedTextarea,
  ConnectedDatePicker,
  ConnectedTimePicker,
} from "../../General/FormInputs/ConnectedInputs";

import CreatorDropdown from "../create/CreatorDropdown";
import CoHostsMultiToggle from "../create/CoHostsMultiToggle";
import ConnectedImageEditorButton from "../../General/FormInputs/ImageEditor/ConnectedImageEditorButton";

import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { US_STATES, CA_PROVINCES, SCHEDULED_AT, END_AT } from "./constants";

const statesAndProvinces = () => merge({}, US_STATES, CA_PROVINCES);

// shared fields
const Title = () => <ConnectedTextField id="title" label="Event Title" />;

const ScheduledDate = () => (
  <ConnectedDatePicker
    id="date"
    label="Date"
    minDate={moment().add(1, "days").format("YYYY-MM-DD")}
    start_id={SCHEDULED_AT}
    end_id={END_AT}
  />
);

const StartTime = () => (
  <ConnectedTimePicker id={SCHEDULED_AT} label="Start Time" />
);

const EndTime = () => <ConnectedTimePicker id={END_AT} label="End Time" />;

const LocalTimeZone = () => {
  const date = new Date().toString().match(/\(([^()]*)\)/);

  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <div>
        {date[1]}{" "}
        <Tooltip title={"This is your local chapter time"}>
          <InfoOutlinedIcon
            style={{ color: "#90A4AE", marginLeft: 5, marginBottom: 2 }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

const DateAndTime = () => (
  <Row>
    <Col md={12} lg={6} xl={3}>
      <ScheduledDate />
    </Col>
    <Col md={12} lg={6} xl={3}>
      <StartTime />
    </Col>
    <Col md={12} lg={6} xl={3}>
      <EndTime />
    </Col>
    <Col md={12} lg={6} xl={3}>
      <LocalTimeZone />
    </Col>
  </Row>
);

const PhotoUpload = connect(
  ({ formik: { values, errors, touched, setFieldValue } }) => {
    const showError = Boolean(
      get(touched, "photo_url") && get(errors, "photo_url")
    );

    const onSuccess = (imageUrl) => setFieldValue("photo_url", imageUrl);
    const errorMessage = "There was an error on image upload.";

    return (
      <div
        style={{ marginBottom: 26, color: showError ? "#f45b5e" : "#212121" }}
      >
        <label
          style={{
            fontSize: "1.28571rem",
            lineHeight: "2.28571rem",
            fontWeight: 400,
            marginBottom: 0,
          }}
        >
          Event Photo
        </label>
        <br />
        <div style={{ fontSize: 13, lineHeight: "2.28571rem" }}>
          Images will be publically visible so should not include any specific
          event information (16:9 aspect ratio preferred).
        </div>
        <div
          style={{
            borderStyle: "dashed",
            border: `1px dashed ${showError ? "#f45b5e" : "#495057"}`,
            height: "169px",
            width: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 14,
            marginBottom: 14,
          }}
        >
          {values.photo_url ? (
            <img
              src={values.photo_url}
              alt="Photo URL"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            "Preview"
          )}
        </div>
        <div style={{ width: 300, display: "flex", justifyContent: "center" }}>
          <ConnectedImageEditorButton
            uploadFolder="events"
            onSuccess={onSuccess}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    );
  }
);

const Description = () => (
  <ConnectedWysiwyg
    id="description"
    showErrorOnBlur={true}
    hashtaggable
    label={
      <div>
        <div
          style={{
            fontSize: "1.28571rem",
            lineHeight: "2.28571rem",
            fontWeight: 400,
          }}
        >
          Full Event Description
        </div>
        <div
          style={{
            fontSize: "13px",
            fontFamily: "Poppins",
            fontWeight: 300,
          }}
        >
          This appears on the Event Description page to provide members a full
          understanding of your event.
        </div>
      </div>
    }
  />
);

const EventSummary = () => (
  <ConnectedTextField
    id="subtitle"
    label={
      <div>
        <div
          style={{
            fontSize: "1.28571rem",
            lineHeight: "2.28571rem",
            fontWeight: 400,
          }}
        >
          Summary of Event
        </div>
        <div
          style={{
            fontSize: "13px",
            fontWeight: "300",
            fontFamily: "Poppins",
          }}
        >
          This is a 1-2 sentence summary used for promoting your event within
          TALK.
        </div>
      </div>
    }
  />
);

// event specific fields
const LocationName = () => {
  return (
    <ConnectedTextField
      id="location_name"
      label={
        <div>
          <div
            style={{
              fontSize: "1.28571rem",
              lineHeight: "2.28571rem",
              fontWeight: 400,
            }}
          >
            Location Name (optional)
          </div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "300",
              fontFamily: "Poppins",
              fontStyle: "italic",
            }}
          >
            For example: hotel or restaurant name.
          </div>
        </div>
      }
    />
  );
};

const EventType = connect(
  ({ formik: { setFieldValue }, webinar, setWebinar, event }) => {
    const webinarOptions = [
      { value: true, label: "Webinar" },
      { value: false, label: "In-person" },
    ];
    const approvedAt = Boolean(event && event.attributes.approvedAt);

    return (
      <ConnectedDropdown
        id="webinar"
        options={webinarOptions}
        label="Event Type"
        value={webinar}
        onChange={({ value }) => {
          setWebinar(value);
          setFieldValue("webinar", value);
        }}
        selectProps={{ isDisabled: approvedAt }}
      />
    );
  }
);

const Address = () => (
  <ConnectedTextField id="address" label="Street Address" />
);

const City = () => <ConnectedTextField id="city" label="City" />;

const State = connect(({ formik: { setFieldValue } }) => {
  const options = orderBy(
    map(statesAndProvinces(), (val, key) => ({
      value: key,
      label: val,
    })),
    "label",
    "asc"
  );

  return (
    <ConnectedDropdown
      id="state"
      label="State/Province"
      options={options}
      onChange={(e) => {
        const stateCode = e.value;
        if (US_STATES[stateCode]) {
          setFieldValue("country_code", "US");
        } else if (CA_PROVINCES[stateCode]) {
          setFieldValue("country_code", "CA");
        }
      }}
    />
  );
});

const PostalCode = () => (
  <ConnectedTextField id="postal_code" label="Postal Code" />
);

const ParkingInformation = () => (
  <ConnectedTextField
    id="how_to_find_us"
    label="Parking and entry instructions (optional)"
  />
);

const Location = ({ stateOptions }) => {
  return (
    <React.Fragment>
      <LocationName />
      <Address />
      <City />
      <State stateOptions={stateOptions} />
      <PostalCode />
      <ParkingInformation />
    </React.Fragment>
  );
};

// webinar specific fields
const AttendingInstructions = () => (
  <ConnectedTextarea
    id="webinar_blurb"
    label={
      <div>
        <div>Instructions to Join</div>
        <div
          style={{ fontSize: 13, fontWeight: 300, lineHeight: "2.28571rem" }}
        >
          Provide information how members should join your event (dial in,
          passcode, etc). This will only be visible after members RSVP.
        </div>
      </div>
    }
  />
);

const GoToWebinarKey = () => (
  <ConnectedTextField id="g_2_w_webinar_key" label="GoToWebinar Webinar Key" />
);

const ZoomWebinarId = () => (
  <ConnectedTextField id="zoom_webinar_id" label="Zoom Webinar ID" />
);

const VirtualEventLink = () => (
  <ConnectedTextField
    id="webinar_url"
    label={
      <div>
        <div>Link to Virtual Event (optional)</div>
        <div
          style={{ fontSize: 13, fontWeight: 300, lineHeight: "2.28571rem" }}
        >
          This should only be the link to the event.{" "}
          <span style={{ fontStyle: "italic" }}>
            Example: https://zoom.us/…
          </span>
        </div>
      </div>
    }
  />
);

const InvitedGroup = connect(({ event }) => {
  const approvedAt = Boolean(event && event.attributes.approvedAt);
  const chapters = useSelector((state) => state.groups.items);
  const chapterOptions = map(
    chapters,
    ({ id, attributes: { name, isNational, isInternational } }) => ({
      value: id,
      label: isNational || isInternational ? name : `${name} Only`,
    })
  );

  const options = orderBy(chapterOptions, "label", "asc");

  return (
    <ConnectedDropdown
      id="chapter"
      label="Who is invited?"
      options={options}
      selectProps={{ isDisabled: approvedAt }}
    />
  );
});

const WebinarFields = ({ event, admin }) => {
  const eventLinkId =
    event && event.attributes.zoomWebinarId
      ? "zoom_webinar_id"
      : "g_2_w_webinar_key";

  return (
    <React.Fragment>
      <AttendingInstructions />
      {admin && event && event.attributes.g2WWebinarKey ? (
        <GoToWebinarKey />
      ) : admin ? (
        <ZoomWebinarId />
      ) : (
        <React.Fragment />
      )}
      <VirtualEventLink id={eventLinkId} />
    </React.Fragment>
  );
};

const Form = (props) => {
  const { event, admin, stateOptions, webinar, setWebinar } = props;

  return (
    <div>
      <EventType webinar={webinar} setWebinar={setWebinar} event={event} />
      {(admin || webinar) && <InvitedGroup admin={admin} event={event} />}
      {admin && <CreatorDropdown />}
      <Title />
      <DateAndTime />
      <PhotoUpload />
      <Description />
      <EventSummary />
      {webinar === true ? (
        <WebinarFields event={event} admin={admin} />
      ) : webinar === false ? (
        <Location stateOptions={stateOptions} />
      ) : (
        <React.Fragment />
      )}
      <CoHostsMultiToggle />
      <br />
    </div>
  );
};

export default Form;
