import React, { useState, useEffect, useContext, createContext } from "react";
import map from "lodash/map";
import merge from "lodash/merge";

import AccordionFormControlCheckboxes from "./AccordionFormControlCheckboxes";
import Header from "./Header";

export const FilterContext = createContext({});

function Filter({ boxShadow = true, context }) {
  const [filterTags, setFilterTags] = useState([]);

  const { filterSettings, state } = useContext(context);

  useEffect(() => {
    const newFilterTags = [];
    Object.keys(state).forEach((key) => {
      if (state[key]["value"]) {
        newFilterTags.push(merge({}, state[key], { key }));
      }
    });
    setFilterTags(newFilterTags);
  }, [state]);

  return (
    <FilterContext.Provider
      value={{
        context,
      }}
    >
      <div
        className="kt-portlet"
        style={{
          height: "auto",
          boxShadow: boxShadow
            ? "0px 0px 13px 0px rgb(82 82 82 / 20%)"
            : "none",
        }}
      >
        <div>
          <div
            className="kt-portlet__body kt-portlet__body--fit"
            style={{ paddingTop: 20, paddingBottom: 12 }}
          >
            <Header {...{ filterTags }} />
            {map(filterSettings, (filterSetting) => (
              <AccordionFormControlCheckboxes
                key={filterSetting.id}
                {...filterSetting}
              />
            ))}
          </div>
        </div>
      </div>
    </FilterContext.Provider>
  );
}

export default Filter;
