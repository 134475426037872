import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import Header from "./Header";
import CommentContent from "./CommentContent";
import Footer from "./Footer";

import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";

import { createUseStyles } from "react-jss";
import { Collapse } from "@material-ui/core";
import { DisplayRepliesButton, EditButton, DeleteButton } from "./FooterActions";

const useStyles = createUseStyles({
  childrenContainer: {
    paddingLeft: 24,
    borderLeft: "1px dashed #C0C0C0",
  },
});

const Comment = ({ id }) => {
  const comment = useSelector((state) => state.comments.items[id]);
  const [isEditing, setEditing] = useState(false);
  const [repliesShown, setRepliesShown] = useState(true);

  if (!comment) {
    return null;
  }
  const classes = useStyles();

  const belongsToCurrentUser = useSelector(
    (state) => state.settings.attributes.id === comment.attributes.userId
  );

  const replies = useSelector((state) =>
    filter(state.comments.items, ({ attributes }) => attributes.ancestry === id)
  );

  const orderedReplies = useMemo(
    () => orderBy(replies, ({ attributes }) => attributes.createdAt, "asc"),
    [replies]
  );

  return (
    <React.Fragment>
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <Header comment={comment} />
          <CommentContent
            comment={comment}
            isEditing={isEditing}
            setEditing={setEditing}
          />
          <Footer
            comment={comment}
            leftActions={
              Boolean(replies.length) && (
                <DisplayRepliesButton
                  numReplies={replies.length}
                  repliesShown={repliesShown}
                  setRepliesShown={setRepliesShown}
                />
              )
            }
            rightActions={
              belongsToCurrentUser && (
                <React.Fragment>
                  <EditButton isEditing={isEditing} setEditing={setEditing} />
                  <DeleteButton commentId={comment.id} />
                </React.Fragment>
              )
            }
          />
        </div>
      </div>
      <Collapse in={repliesShown}>
        <div className={classes.childrenContainer}>
          {map(orderedReplies, ({ id }) => (
            <Comment id={id} key={id} />
          ))}
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export default Comment;
