import React, { forwardRef } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import { getAttachment } from "../../../../selectors/conversationSelectors";

import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  container: ({ fromSelf }) => ({
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    maxWidth: "66%",
    alignSelf: fromSelf ? "flex-end" : "flex-start",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    whiteSpace: "pre-line",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAEEF2",
      "& > div": {
        textDecoration: "underline",
      },
    },
  }),
  attachmentIcon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  textContainer: {
    color: theme.palette.primary.main,
    maxWidth: "calc(100% - 22px)",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 16,
    fontWeight: 350,
  },
}));

const AttachmentContent = ({ id }, ref) => {
  const attachment = useSelector((state) => getAttachment(state, { id }));
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const fromSelf = currentUserId === attachment.attributes.userId;

  const classes = useStyles({ fromSelf });

  return (
    <a
      className={classes.container}
      title={attachment.attributes.createdAt}
      ref={ref}
      href={attachment.attributes.fileUrl}
      alt={attachment.attributes.fileName}
    >
      <i
        className={classnames("fas", "fa-paperclip", classes.attachmentIcon)}
      ></i>
      <div className={classes.textContainer}>
        {attachment.attributes.fileName}
      </div>
    </a>
  );
};

export default forwardRef(AttachmentContent);
