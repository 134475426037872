import { RECEIVE_SETTINGS } from "../actions/settingActions";
import values from "lodash/values";
import merge from "lodash/merge";

const defaultState = {
  attributes: {},
  loaded: false,
};

const settingsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_SETTINGS:
      return merge({}, state, values(action.payload)[0], {
        loaded: true,
      });
    default:
      return state;
  }
};

export default settingsReducer;
