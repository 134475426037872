import React from "react";

import { connect, ErrorMessage } from "formik";

const ConnectedInput = ({
  formik: { values, handleChange, handleBlur, errors, touched },
  InputField,
  id,
  ...inputFieldProps
}) => {
  const value = values[id];
  const showError = Boolean(touched[id] && errors[id]);

  return (
    <InputField
      id={id}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      showError={showError}
      helperText={<ErrorMessage name={id} />}
      {...inputFieldProps}
    />
  );
};

export default connect(ConnectedInput);
