import React from "react";
import { Row, Col, Visible } from "react-grid-system";

import withGlobalProviders from "../General/withGlobalProviders";

import VendorPolls from "./VendorPolls";
import RecentActivity from "./VendorRecentActivity";

function Index() {
  return (
    <Row>
      <Visible lg xl xxl>
        <Col lg={7.5} style={{ paddingRight: 0 }}>
          <RecentActivity />
        </Col>
      </Visible>
      <Col md={12} lg={4.5} style={{ paddingLeft: 20 }}>
        <VendorPolls />
      </Col>
      <Visible xs sm md>
        <Col>
          <RecentActivity />
        </Col>
      </Visible>
    </Row>
  );
};

export default withGlobalProviders(Index);
