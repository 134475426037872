import React from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function RSVPListButton({ id }) {
  const event = useSelector((state) => state.events.items[id], shallowEqual);
  const { status, invitesPath } = event.attributes;

  if (!["upcoming", "past"].includes(status)) return null;

  return (
    <a
      href={invitesPath}
      style={{ maxWidth: "150px", width: `calc(${status === "upcoming" ? "33%" : "50%"} - 6px)`, marginRight: 12 }}
    >
      <button style={{ width: "100%" }} className="btn btn-sm btn-tertiary">
        RSVP List
      </button>
    </a>
  );
}
