import React from "react";
import { connect } from "formik";
import { Col, Row } from "react-grid-system";
import get from "lodash/get";
import map from "lodash/map";
import startCase from "lodash/startCase";
import ConnectedMultipleTextField from "../../General/FormInputs/connected/ConnectedMultipleTextField";
import { RemoveButton } from "./buttons";

const fieldTypes = ["email", "firstName", "lastName"];
const dimensions = {
  email: 5.5,
  firstName: 2.5,
  lastName: 2.5,
};

const InviteEntry = connect(({ formik, idx, listType, removeEntry }) => {
  const customError = get(formik.errors, `${listType}.${idx}.customError`);

  return (
    <React.Fragment>
      <Row style={{ position: "relative" }}>
        {map(fieldTypes, (fieldType) => (
          <Col lg={dimensions[fieldType]}>
            <ConnectedMultipleTextField
              id={`userInvitations.${idx}.${fieldType}`}
              idx={idx}
              listType={listType}
              label={startCase(fieldType)}
              styles={{ marginBottom: 0 }}
            />
          </Col>
        ))}
        <Col lg={0.5}>
          <div style={{ minWidth: 60 }}>
            {idx > 0 && <RemoveButton onClick={removeEntry} />}
          </div>
        </Col>
        {customError && (
          <div
            style={{
              fontSize: 13,
              lineHeight: "2.2857142857142856rem",
              fontWeight: 400,
              color: "#f45b5e",
              position: "absolute",
              bottom: 0,
              marginLeft: 20,
            }}
          >
            {customError}
          </div>
        )}
      </Row>
    </React.Fragment>
  );
});

export default InviteEntry;
