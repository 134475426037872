import React from "react";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";

import { destroyComment } from "../../../../actions/commentActions";
import { formatCount } from "../../../../util/formatHelpers";
import classnames from "classnames";

const useStyles = createUseStyles({
  button: {
    margin: "0px 3px",
    fontSize: "1rem",
    lineHeight: "1.2rem",
  },
  displayRepliesButton: {
    color: "#48465b",
    padding: "0.5rem 0.75rem",
  },
});

export const EditButton = ({ isEditing, setEditing }) => {
  const classes = useStyles();
  const handleClick = () => setEditing(!isEditing);

  return (
    <button
      className={classnames(
        "btn",
        "btn-label-instagram",
        "btn-sm",
        classes.button
      )}
      onClick={handleClick}
    >
      <i className={classnames("flaticon-edit-1", classes.buttonIcon)} />
      Edit
    </button>
  );
};

export const DeleteButton = ({ commentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (window.confirm("Are you sure? This cannot be undone.")) {
      dispatch(destroyComment(commentId));
    }
  };

  return (
    <button
      className={classnames(
        "btn",
        "btn-label-google",
        "btn-sm",
        classes.button
      )}
      onClick={handleClick}
    >
      <i className={classnames("flaticon-delete", classes.buttonIcon)} />
      Delete
    </button>
  );
};

export const ReplyButton = ({ setReplying, isReplying }) => {
  const classes = useStyles();
  const handleClick = () => setReplying(!isReplying);

  return (
    <button
      className={classnames(
        "btn",
        "btn-label-facebook",
        "btn-sm",
        classes.button
      )}
      onClick={handleClick}
    >
      <i className={classnames("flaticon-reply", classes.buttonIcon)} />
      Reply
    </button>
  );
};

export const DisplayRepliesButton = ({
  numReplies,
  repliesShown,
  setRepliesShown,
}) => {
  if (!numReplies) {
    return null;
  }

  const classes = useStyles();
  const handleClick = () => {
    setRepliesShown(!repliesShown);
  };

  return (
    <button
      className={classnames(
        "btn",
        "btn-outline",
        "btn-sm",
        classes.displayRepliesButton,
        classes.button
      )}
      onClick={handleClick}
    >
      <i
        className={classnames("fa", {
          "fa-eye-slash": repliesShown,
          "fa-eye": !repliesShown,
        })}
      />
      {repliesShown ? "Hide" : "Show"} {formatCount("Reply", numReplies)}
    </button>
  );
};

