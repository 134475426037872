import React from "react";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import { DateTimePicker as MuiDateTimePicker } from "@material-ui/pickers";

import merge from "lodash/merge";

const defaultStyles = {
  label: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    fontWeight: 400,
  },
  input: {
    fontSize: "1.2857142857142858rem",
    lineHeight: "2.2857142857142856rem",
    "&:focus": {
      borderColor: "#357BA6 !important",
      boxShadow: "0 0 0 1px #357BA6 !important",
    },
  },
};

const DatePickerTextField = ({
  id,
  label,
  value,
  showError,
  helperText,
  onClick,
  onKeyDown,
  inputRef,
  styles: propStyles,
}) => {
  const styles = merge({}, defaultStyles, propStyles, {
    container: {},
    label: showError ? { color: "#f45b5e" } : {},
    input: showError ? { borderColor: "#f45b5e" } : {},
  });

  return (
    <div className="form-group" style={styles.container}>
      <label htmlFor={id} style={styles.label}>
        {label}
      </label>
      <br />
      <div className="input-group date">
        <input
          type="text"
          className="form-control"
          value={value}
          onClick={onClick}
          onKeyDown={onKeyDown}
          readOnly={true}
          style={showError ? { borderColor: "#f45b5e" } : {}}
          ref={inputRef}
          style={styles.input}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <i className="la la-calendar-check-o" />
          </span>
        </div>
      </div>
      <div style={{ fontSize: 13, display: "flex" }}>{helperText}</div>
    </div>
  );
};

const DatePicker = ({ dateTime = false, ...props }) => {
  return React.createElement(dateTime ? MuiDateTimePicker : MuiDatePicker, {
    variant: "dialog",
    format: (dateTime ? "MMM. DD YYYY hh:mm a" : "MMM. DD YYYY"),
    TextFieldComponent: DatePickerTextField,
    ...props,
  });
};

export default DatePicker;
