/* eslint-disable no-unused-vars */
import React, { cloneElement, createContext, useContext } from 'react';

const ConversationsContext = createContext(undefined);

/**
 * @returns { {
 * selectedConversationId: string | null,
 * setSelectedConversationId: (id: string | null) => void
 * isCreating: boolean
 * setIsCreating: (isCreating: boolean) => void
 * handleDrawerOpen: (shouldOpen: boolean | null, shouldToggle?: boolean) => void
 * isMobile: boolean
 * } }
 */
export function useConversationContext() {
  const context = useContext(ConversationsContext);
  if (context === undefined) {
    throw new Error("useConversationContext must be used within ConversationsContext");
  }
  return context;
}

export default ConversationsContext;
