import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import TextField from "@material-ui/core/TextField";

import { createComment } from "../../actions/commentActions";
import AvatarIcon from "./AvatarIcon";
import { TOPIC_COMMENT } from "../../util/fathomEventCodes";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    marginTop: 12,
  },
  form: {
    marginLeft: 12,
    width: "100%",
  },
  multiline: {
    padding: "10px !important",
  },
});

const CommentField = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const settings = useSelector((state) => state.settings);

  const handleSubmit = (e) => {
    if (!loading) {
      e.preventDefault();
      setLoading(true);
      if (!!window.fathom) {
        window.fathom.trackGoal(TOPIC_COMMENT, 0);
      }
      return dispatch(
        createComment({
          body: text,
          subject_id: props.topicId,
          subject_type: "Topic",
          ancestor_id: props.parentCommentId,
        })
      ).then(() => {
        setText("");
        setLoading(false);
        props.setShowReply(false);
      });
    }
  };

  return (
    <div className={classes.container}>
      <AvatarIcon height={40} member={settings} />
      <div className={classes.form}>
        <TextField
          id="comment-input"
          placeholder="Write a reply..."
          value={text}
          onChange={(e) => {
            setText(e.currentTarget.value);
          }}
          variant="outlined"
          multiline={true}
          fullWidth={true}
          InputProps={{
            classes: {
              multiline: classes.multiline,
            },
          }}
          inputProps={{
            style: {
              fontSize: 14,
              fontFamily: "Poppins",
              lineHeight: 1.4,
            },
          }}
        />
        {text && (
          <div
            style={{
              width: 120,
              marginRight: 20,
              marginTop: 6,
              opacity: loading ? 0.3 : 1,
              cursor: loading ? "auto" : "pointer",
            }}
            className="btn btn-sm btn-brand-secondary"
            onClick={handleSubmit}
          >
            Comment
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentField;
