import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import axios from "axios";

const useStyles = createUseStyles({
  listItem: {
    fontSize: 18,
    lineHeight: "20px",
    color: "#367BA6",
    cursor: "pointer",
  },
  listIcon: {
    marginTop: 2,
    fontSize: 22,
    color: "#000000",
    marginRight: 8,
    width: 22,
    textAlign: "center",
  },
});

const FetchDataLink = ({ icon, text, link }) => {
  const classes = useStyles();
  const [info, setInfo] = useState();

  const fetchData = () =>
    axios.get(link).then(({ data: { url } }) => setInfo(url));

  return (
    <li className={classes.listItem}>
      <i className={`fa fa-${icon} ${classes.listIcon}`} />
      {info ? (
        <span style={{ cursor: "auto" }}>{info}</span>
      ) : (
        <span onClick={fetchData}>Click for {text}</span>
      )}
    </li>
  );
};

export default FetchDataLink;
