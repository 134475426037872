import React from "react";
import { createUseStyles } from "react-jss";

import SharedFooter from "../../../shared/PollContent/Footer";

const useStyles = createUseStyles({
  commentsCount: {
    display: "none",
  },
});

const Footer = (props) => (
  <SharedFooter {...props} overloadedClasses={useStyles()} />
);

export default Footer;
