import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { ConnectedWysiwyg } from "../../../General/FormInputs/ConnectedInputs";
import Signature from "./EmailContent/Signature";
import Header from "./EmailContent/Header";
import Footer from "./EmailContent/Footer";

const useStyles = makeStyles(() => ({
  emailContainer: {
    border: "1px solid #A9A9B0",
    width: 602,
    margin: "12px 0px",
  },
  table: {
    width: 602,
    tableLayout: "fixed",
  },
  tableRowHorizontalSpacing: {
    height: 24,
  },
  tableRowContent: {
    padding: "0 30px",
    color: "#37474f",
    fontFamily: "proxima-nova, helvetica",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    lineHeight: "22px",
    margin: 0,
  },
  tableDataGreeting: {
    width: 450,
    wordBreak: "keep-all",
    padding: "0 75px 12px 75px",
  },
  tableDataWysiwyg: {
    width: "450px",
    wordBreak: "keep-all",
    padding: "0 56px 12px 56px",
  },
  label: {
    fontSize: "1.28571rem",
    lineHeight: "2.28571rem",
    fontWeight: "400",
  },
}));

const EmailContent = ({ user }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <label id="emailContent" class={classes.label}>
        Email Content
      </label>
      <div className={classes.emailContainer}>
        <table cellPadding="0" cellSpacing="0" className={classes.table}>
          <tbody>
            <Header />
            <tr className={classes.tableRowHorizontalSpacing}>
              <td></td>
            </tr>
            <tr className={classes.tableRowContent}>
              <td className={classes.tableDataGreeting}>Hi [First Name],</td>
            </tr>
            <tr className={classes.tableRowHorizontalSpacing}>
              <td></td>
            </tr>
            <tr className={classes.tableRowContent}>
              <td className={classes.tableDataWysiwyg}>
                <ConnectedWysiwyg id="body" />
                <Signature user={user} />
              </td>
            </tr>
            <Footer />
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default EmailContent;
