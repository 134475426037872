import {
  RECEIVE_CONVERSATION_EVENTS,
  RECEIVE_UNREAD_EVENTS_COUNT,
} from "../../actions/conversationActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  totalUnreadCount: 0,
};

const eventsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CONVERSATION_EVENTS:
      return merge({}, state, { items: action.payload });
    case RECEIVE_UNREAD_EVENTS_COUNT:
      return merge({}, state, { totalUnreadCount: action.payload });
    default:
      return state;
  }
};

export default eventsReducer;
