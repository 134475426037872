import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { connect } from "formik";
import classnames from "classnames";

import { openModal } from "../../../../actions/uiActions";
import ImageUploaderModal from "./ImageUploaderModal";

const useStyles = makeStyles(() => ({
  container: { 
    overflow: "hidden", 
    position: "relative" 
  },
  fileInput: {
    cursor: "pointer",
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: "99",
    fontSize: "50px",
    opacity: "0",
    "-moz-opacity": "0",
  },
}));

const ConnectedImageEditorButton = connect(({ disabled, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const file = e.target.files[0];
    openImageUploaderModal(file);
  };

  const openImageUploaderModal = (image) =>
    dispatch(openModal(<ImageUploaderModal {...props} editingImage={image} />));

  return (
    <div
      className={classnames(
        "btn btn-sm btn-outline-brand-secondary",
        classes.container
      )}
    >
      Choose Image
      <input
        type="file"
        className={classes.fileInput}
        onChange={handleChange}
        onClick={(e) => (e.target.value = null)}
        accept="image/png, image/jpeg"
      />
    </div>
  );
});

export default ConnectedImageEditorButton;
