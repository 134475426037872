import { Formik } from "formik";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { object, string, array } from "yup";

import {
  createConversation,
  submitConversationContent,
} from "../../../actions/conversationActions";
import { mapKeysToSnakeCase } from "../../../util/formatHelpers";
import { useConversationContext } from "../ConversationsContext";

const buildValidationSchema = (selectedConversationId) => object().shape({
  body: string()
      .trim()
      .when("attachments", {
        is: (attachments) => attachments.length === 0,
        then: (schema) => schema.required("Cannot be blank"),
      }),
  participantIds: Boolean(selectedConversationId)
    ? array()
    : array().min(1, "Must have participants selected"),
  attachments: array(),
});

const initialValues = {
  body: "",
  participantIds: [],
  attachments: [],
};

function ConversationForm({ children }) {
  const dispatch = useDispatch();

  const {
    selectedConversationId, setSelectedConversationId, setIsCreating
  } = useConversationContext();

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const submissionData = merge(mapKeysToSnakeCase(values), {
        conversation_id: selectedConversationId,
      });

      if (submissionData.conversation_id) {
        dispatch(submitConversationContent(submissionData))
          .then(resetForm)
          .catch(() => {
            setSubmitting(false);
          });
      }
      else {
        dispatch(createConversation(submissionData))
          .then((createdConversation) => {
            resetForm();

            const createdConversationId = Object.keys(createdConversation)[0];
            setSelectedConversationId(createdConversationId);
            setIsCreating(false);
          })
          .catch(() => {
            setSubmitting(false);
          });
      }
    },
    [dispatch, setSelectedConversationId, selectedConversationId, setIsCreating]
  );

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={{ body: "Cannot be blank" }}
      validationSchema={buildValidationSchema(selectedConversationId)}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
}

ConversationForm.propTypes = {
  children: PropTypes.node.isRequired
};

export default ConversationForm;
