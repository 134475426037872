import React from "react";
import { makeStyles } from "@material-ui/styles";

import Search from "../../../General/Search/Search";

const useStyles = makeStyles(() => ({
  inputContainer: {
    borderRadius: 0,
    borderWidth: "0px 0px 1px 0px",
    "&:focus": {
      borderWidth: "1px",
    },
  },
}));

const SearchBar = ({ searchQuery, setSearchQuery, loading }) => {
  const classes = useStyles();

  return (
    <Search
      search={searchQuery}
      setSearch={setSearchQuery}
      loading={loading}
      placeholder="Search by members or groups"
      inputClass={classes.inputContainer}
    />
  );
};

export default SearchBar;
