import makeStyles from "@material-ui/styles/makeStyles";
import React, { forwardRef } from "react";

import AddedUserText from "../../partials/AddedUserText";

const useStyles = makeStyles((theme) => ({
  container: {
    alignSelf: "center",
    fontSize: 16,
    padding: theme.spacing(1),
  },
}));

function AddedUserEvent({ event }, ref) {
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.container}>
      <AddedUserText event={event} />
      {" "}
    </div>
  );
}

export default forwardRef(AddedUserEvent);
