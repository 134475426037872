import React from "react";

export default function Button({ children, selected, onClick }) {

  return (
    <button
      style={{ maxWidth: "150px", width: "calc(50% - 6px)" }}
      className={
        "btn btn-sm " + (selected
          ? "btn-brand-secondary"
          : "btn-outline-brand-secondary")
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};
