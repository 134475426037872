function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

const hexToRgb = (hex, alpha) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return result && `rgb(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return null;
  }
};

export const getAvatarColors = (firstName, lastName) => {
  const hexColor = "#" + intToRGB(hashCode(`${firstName} ${lastName}`));
  const lightColor = hexToRgb(hexColor, 0.1);
  return {
    initials: hexColor,
    backgroundColor: lightColor,
  };
};
