import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import Collapse from "@material-ui/core/Collapse";
import CreateCommentForm from "../CommentForm/CreateCommentForm";
import { ReplyButton } from "./FooterActions";

import classnames from "classnames";

const useStyles = createUseStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftActions: {
    marginRight: "auto",
  },
  rightActions: {
    marginLeft: "auto",
  },
});

const ReplyForm = ({ comment, isReplying, setReplying }) => {
  const { subjectId, subjectType, id } = comment.attributes;

  return (
    <Collapse in={isReplying}>
      <CreateCommentForm
        handleSuccess={(context) => {
          context.resetForm();
          setReplying(false);
        }}
        staticValues={{ subjectType, subjectId, ancestorId: id }}
        style={{ padding: 24 }}
      />
    </Collapse>
  );
};

const Footer = ({
  comment,
  leftActions,
  rightActions
}) => {
  const classes = useStyles();
  const [isReplying, setReplying] = useState(false);

  return (
    <div className={"kt-portlet__foot"} style={{ padding: "12px 0px 0px 0px" }}>
      <div className={classnames("kt-form__actions", classes.buttonContainer)}>
        <div className={classes.leftActions}>
          {leftActions}
        </div>
        <div className={classes.rightActions}>
          {rightActions}
          <ReplyButton isReplying={isReplying} setReplying={setReplying} />
        </div>
      </div>
      <ReplyForm
        comment={comment}
        isReplying={isReplying}
        setReplying={setReplying}
      />
    </div>
  );
};

export default Footer;
