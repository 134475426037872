import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import find from "lodash/find";

import { EventsContext } from "./Wrapper";

import Search from "./Search";
import Order from "../../General/Order";

import {
  ALL_OPTION,
  OPTIONS,
  IN_PROGRESS_OPTIONS,
  BOARD_MEMBER_OPTIONS,
} from "./toolbar/util";
import { getSubscribedGroups } from "../../../selectors/groupSelectors";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 60,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
  },
  secondPartHeader: { display: "flex" },
  "@media only screen and (max-width: 950px)": {
    header: {
      flexDirection: "column",
    },
    secondPartHeader: {
      display: "flex",
      flexDirection: "row",
      marginTop: 12,
    },
  },
});

const GroupFilter = ({ setOrder, chapters }) => {
  const subscribedGroups = useSelector(getSubscribedGroups);

  const defaultValue = {
    label: "My Chapters",
    value: "all",
  };

  const options = useMemo(() => {
    const chapterOptions = map(
      subscribedGroups,
      ({ id, attributes: { isNational, isInternational, name } }) => {
        const label = (isNational || isInternational) ? name : name + " Only";
        return {
          value: id,
          label,
        };
      }
    );

    return [defaultValue].concat(orderBy(chapterOptions, "label", "asc"));
  }, [subscribedGroups]);

  return (
    <Order
      {...{
        setOrder,
        options,
        value: find(options, { value: chapters }) || defaultValue,
      }}
    />
  );
};

export default function Toolbar() {
  const classes = useStyles();
  const { chapters, status, setStatus, inProgress, setChapters } =
    useContext(EventsContext);

  const { admin, boardMember } = useSelector(
    (state) => state.settings.attributes
  );

  let statusOptions = inProgress ? IN_PROGRESS_OPTIONS : OPTIONS;
  if (admin || boardMember)
    statusOptions = statusOptions.concat(BOARD_MEMBER_OPTIONS);

  return (
    <div className={`kt-portlet kt-portlet__body ${classes.header}`}>
      <Search />
      <div className={classes.secondPartHeader}>
        <Order
          setOrder={setStatus}
          defaultValue={find(statusOptions, { value: status }) || ALL_OPTION}
          options={statusOptions}
          isSearchable={false}
          value={find(statusOptions, { value: status }) || ALL_OPTION}
        />
        <GroupFilter setOrder={setChapters} chapters={chapters} />
      </div>
    </div>
  );
}
