import React from "react";

import map from "lodash/map";
import EmptyState from "./EmptyState";
import Option from "./Option";

export default function Options({ id, searchedOptions }) {
  if (!searchedOptions || searchedOptions.length === 0) return <EmptyState />;

  return map(searchedOptions, ({ label, value, color }) => (
    <Option
      {...{
        key: value,
        id,
        label,
        value,
        color,
      }}
    />
  ));
}
