import React, { useState, useEffect, useCallback, useContext } from "react";
import debounce from "lodash/debounce";

import { EventsContext } from './Wrapper';

import { styles } from "./search/utils";
import Input from "./search/Input";

export default function Search() {
  const classes = styles({});

  const { search, loading, setSearch } = useContext(EventsContext);

  const [inputValue, setRealInputValue] = useState(search);
  const [inputLoading, setInputLoading] = useState(false);

  useEffect(() => {
    if (!inputLoading && search !== inputValue && search === "") {
      setInputValue(search);
    }
  }, [search]);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      setInputLoading(true);
    }

    if (inputLoading) {
      performSearch(inputValue);
    }

    if (search === inputValue) {
      setInputLoading(false)
    }
  }, [inputValue]);

  const performSearch = useCallback(
    debounce((search) => {
      setInputLoading(false);
      setSearch(search);
    }, 500),
    []
  );

  const setInputValue = (value, forcePerformSearch = false) => {
    setRealInputValue(value)
    if (forcePerformSearch) performSearch(value);
  }

  return (
    <div
      className={`form-group ${classes.searchContainer}`}
      style={{ width: "100%" }}
    >
      <Input
        {...{ search, loading, inputValue, setInputValue, inputLoading }}
      />
    </div>
  );
};
