import React from "react";
import { connect } from "formik";

import FormFooter, {
  SectionMessage,
} from "../../../General/FormPage/FormFooter";

import { sectionFields } from "./fieldHelpers";

import some from "lodash/some";

const SubmitButton = connect(({ formik: { handleSubmit } }) => {
  return (
    <button
      className="btn btn-secondary"
      onClick={handleSubmit}
      type="button"
      style={{ marginLeft: 10 }}
    >
      Submit
    </button>
  );
});

const NextButton = connect(
  ({
    formik: { setFieldTouched, setFieldError, validateForm },
    handleNext,
    currentSection,
  }) => {
    const setSectionFieldsTouched = (section) => {
      sectionFields[section].forEach((field) => {
        setFieldTouched(field, true);
      });
    };

    const handleClick = () => {
      // setFieldsTouched
      setSectionFieldsTouched(currentSection);
      // validateForm
      validateForm().then((errors) => {
        const sectionHasErrors = some(
          sectionFields[currentSection],
          (fieldName) => errors[fieldName]
        );

        if (sectionHasErrors) {
          setFieldError(
            "formError",
            "Please review the field(s) highlighted in red."
          );
        } else {
          handleNext();
        }
      });
    };

    return (
      <button
        className="btn btn-brand-secondary"
        style={{marginLeft: 10}}
        onClick={handleClick}
        type="button"
      >
        Next
      </button>
    );
  }
);

const BackButton = connect(
  ({
    handleBack,
  }) => {
    return (
      <button
        className="btn btn-outline-brand-secondary"
        onClick={handleBack}
        type="button"
      >
        Back
      </button>
    );
  }
);

const CancelButton = () => {
  return (
    <a className="btn btn-outline-brand-secondary" href="./">
      Cancel
    </a>
  );
};

const Footer = connect(
  ({ formik: { errors }, currentSection, setCurrentSection, sectionCount }) => {
    const handleNext = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setCurrentSection(currentSection + 1);
    }

    const handleBack = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setCurrentSection(currentSection - 1);
    }

    let cta = (
      <NextButton currentSection={currentSection} handleNext={handleNext} />
    );

    if (currentSection + 1 === sectionCount) {
      cta = <SubmitButton />
    }

    return (
      <FormFooter
        errorMessage={errors.formError}
        message={
          <SectionMessage
            currentSection={currentSection + 1}
            sectionCount={sectionCount}
          />
        }
        handleNext={handleNext}
        actions={
          <React.Fragment>
            {currentSection === 0 ? (
              <CancelButton />
            ) : (
              <BackButton
                currentSection={currentSection}
                handleBack={handleBack}
              />
            )}
            {cta}
          </React.Fragment>
        }
      />
    );
  }
);

export default Footer;
