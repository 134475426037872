import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { Formik, connect } from "formik";
import merge from "lodash/merge";

import FormPageLayout from "../General/FormPage/FormPageLayout";
import AlertBanner from "./create/AlertBanner";
import EmailSubject from "./create/fields/EmailSubject";
import EmailContent from "./create/fields/EmailContent";
import FromDropdown from "./create/fields/FromDropdown";
import ChapterDropdown from "./create/fields/ChapterDropdown";
import MemberDropdown from "./create/fields/MemberDropdown";

import {
  initialValues,
  buildValidationSchema,
  createHandleSubmit,
  CHAPTER,
  BOARD_MEMBER,
  PAST_EVENT,
} from "./create/fieldHelpers";
import withGlobalProviders from "../General/withGlobalProviders";
import { fetchBoardMembersAndAdmins } from "../../actions/userActions";
import { fetchAdminAllGroups } from "../../actions/groupActions";
import {
  createChapterAnnouncement,
  createPastEventAnnouncement,
} from "../../actions/announcementActions";

const SubmitButton = ({ handleSubmit, disabled }) => {
  return (
    <button
      className="btn btn-brand btn-md btn-upper"
      style={{ width: "120px" }}
      onClick={handleSubmit}
      type="button"
      disabled={disabled}
    >
      Send
    </button>
  );
};

const CreateAnnouncement = ({
  memberCount,
  announcementEntity,
  eventSlug = "",
  chapterId = "",
}) => {
  const [signatureUser, setSignatureUser] = useState(null);
  const [count, setCount] = useState(memberCount);

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  let initValues;

  switch (announcementEntity) {
    case CHAPTER:
      initValues = merge({}, initialValues, { chapterId });
      break;
    case BOARD_MEMBER:
      initValues = merge({}, initialValues, { memberType: "board_members" });
      break;
    case PAST_EVENT:
      initValues = merge({}, initialValues, { eventSlug });
      break;
    default:
      initValues = initialValues;
  }

  useEffect(() => {
    settings.attributes.admin &&
      signatureUser &&
      batch(() => {
        dispatch(fetchBoardMembersAndAdmins(chapterId));
        dispatch(fetchAdminAllGroups());
      });
  }, [signatureUser]);

  useEffect(() => {
    if (!signatureUser) {
      setSignatureUser(settings);
    }
  }, [signatureUser]);

  const handleSubmit = createHandleSubmit({
    handleSubmit: (values) => {
      return announcementEntity === PAST_EVENT
        ? createPastEventAnnouncement(values)
        : createChapterAnnouncement(values);
    },
    handleSuccess: (res) => {
      window.location.href = res.url_path;
    },
    handleErrors: (context) => {
      context.setSubmitting(false);
    },
  });

  if (!signatureUser) return null;
  return (
    <FormPageLayout>
      <Formik
        initialValues={initValues}
        validationSchema={buildValidationSchema(
          settings.attributes.admin,
          announcementEntity
        )}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            <div style={{ maxWidth: 602, width: "100%" }}>
              <AlertBanner
                count={count}
                setCount={setCount}
                totalMemberCount={memberCount}
                announcementEntity={announcementEntity}
                admin={settings.attributes.admin}
              />
              <div style={{ paddingBottom: "15px", fontSize: "14px" }}>
                Do not use this email to send event reminders, those are
                automated through TALK. This email goes out to your entire
                chapter, and should not be used to discuss anything other than
                TALK.
              </div>
              {settings.attributes.admin && <FromDropdown />}
              {announcementEntity === "chapter" &&
                settings.attributes.admin && <ChapterDropdown />}
              {settings.attributes.admin && (
                <MemberDropdown announcementEntity={announcementEntity} />
              )}
              <EmailSubject />
              <EmailContent user={signatureUser} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <SubmitButton handleSubmit={handleSubmit} disabled={!count} />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </FormPageLayout>
  );
};

export default withGlobalProviders(CreateAnnouncement);
