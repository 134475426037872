import React from "react";
import CommentForm from "./CommentForm";
import { useDispatch } from "react-redux";

import { createComment } from "../../../../actions/commentActions";

const CreateCommentForm = ({ staticValues, handleSuccess, ...props }) => {
  const dispatch = useDispatch();

  const submitFunction = ({ body }, context) =>
    dispatch(createComment({ body: body, ...staticValues })).then(() =>
      handleSuccess(context)
    );

  return <CommentForm submitFunction={submitFunction} {...props} />;
};

export default CreateCommentForm;
