import { GET } from "../util/apiHelpers";

export const RECEIVE_FEATURE_FLAGS = "RECEIVE_FEATURE_FLAGS";

export const receiveFeatureFlags = (featureFlags) => ({
  type: RECEIVE_FEATURE_FLAGS,
  payload: featureFlags,
});

export const fetchFeatureFlags = () => (dispatch) => {
  return GET("/authentications/feature_flags").then((res) => {
    dispatch(receiveFeatureFlags(res.data));
  });
}
