import React, { useMemo } from "react";
import MUISnackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../actions/uiActions";

const Snackbar = () => {
  const dispatch = useDispatch();
  const { open, content } = useSelector((state) => state.ui.snackbar);

  const handleClose = useMemo(() => () => dispatch(closeSnackbar()), [
    dispatch,
  ]);

  return (
    <MUISnackbar
      open={open}
      message={content}
      onClose={handleClose}
      autoHideDuration={3000}
    />
  );
};

export default Snackbar;
