import { combineReducers } from "redux";

import activitiesReducer from "./activitiesReducer";
import commentsReducer from "./commentsReducer";
import conversationsReducer from "./conversations/conversationsReducer";
import eventReducer from "./eventReducer";
import featureFlagReducer from "./featureFlagReducer";
import groupsReducer from "./groupsReducer";
import organizationsReducer from "./organizationsReducer";
import pollsReducer from "./pollsReducer";
import productCategoriesReducer from "./productCategoriesReducer";
import profilesReducer from "./profilesReducer";
import settingsReducer from "./settingsReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import topicsReducer from "./topicsReducer";
import uiReducer from "./ui/uiReducer";
import usersReducer from "./usersReducer";
import vendorProductsReducer from "./vendorProductsReducer";

export default combineReducers({
  activities: activitiesReducer,
  comments: commentsReducer,
  events: eventReducer,
  featureFlags: featureFlagReducer,
  groups: groupsReducer,
  polls: pollsReducer,
  productCategories: productCategoriesReducer,
  profiles: profilesReducer,
  organizations: organizationsReducer,
  settings: settingsReducer,
  subscriptions: subscriptionsReducer,
  topics: topicsReducer,
  users: usersReducer,
  vendorProducts: vendorProductsReducer,
  conversations: conversationsReducer,
  ui: uiReducer,
});
