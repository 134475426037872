import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";

import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Card } from "@material-ui/core";

const styles = () => ({
  popper: {
    zIndex: 9002,
  },
  card: {
    boxShadow: "0px 0px 13px 0px rgba(82, 82, 82, 0.2)",
  },
});

const Link = ({ renderLink, renderPopoverContent, cardStyle, classes }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLinkClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const handleClickaway = (e) => {
    // ignore the clickaway listener if the link (anchorEl) is clicked,
    // so the handling can be exclusively deferred to handleLinkClick
    if (!(anchorEl && anchorEl.contains(e.target))) {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      {renderLink(handleLinkClick, open)}
      <Popper open={open} anchorEl={anchorEl} className={classes.popper}>
        {open && (
          <ClickAwayListener onClickAway={handleClickaway}>
            <Card className={classes.card} style={cardStyle}>
              {renderPopoverContent()}
            </Card>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default withStyles(styles)(Link);
