import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { forwardRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { openModal } from "../../../actions/uiActions";
import { useConversationUsers } from "../partials/hooks";

import ConversationDetailsModal from "./modals/ConversationDetailsModal/ConversationDetailsModal";
import LeaveConversationModal from "./modals/LeaveConversationModal";

const SeeMoreItem = forwardRef(({ conversationId, handleClose }, ref) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openModal(<ConversationDetailsModal conversationId={conversationId} />)
    );
    handleClose();
  };

  return (
    <MenuItem onClick={handleClick} ref={ref}>
      Chat Details
    </MenuItem>
  );
});

SeeMoreItem.displayName = "SeeMore";

const LeaveItem = forwardRef(({ conversationId, handleClose }, ref) => {
  const dispatch = useDispatch();

  const conversation = useSelector(
    (state) => state.conversations.items[conversationId]
  );

  const otherActiveParticipants = useConversationUsers(conversation);

  if (conversation.attributes.groupId || otherActiveParticipants.length <= 1) return null;

  const handleClick = () => {
    dispatch(
      openModal(<LeaveConversationModal conversationId={conversationId} />)
    );
    handleClose();
  };

  return (
    <MenuItem onClick={handleClick} ref={ref}>
      Leave
    </MenuItem>
  );
});

LeaveItem.displayName = "LeaveItem";

function ActionMenu({ conversationId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={undefined}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SeeMoreItem {...{ conversationId, handleClose }} />
        <LeaveItem {...{ conversationId, handleClose }} />
      </Menu>
    </div>
  );
}

export default ActionMenu;
