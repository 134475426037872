import React from "react";
import { createUseStyles } from "react-jss";

import SharedHeader from "../../../shared/PollContent/Header";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#48465B",
    margin: "4px 0px 0px 8px",
  },
  userName: {
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  userBackground: {
    fontWeight: 400,
    fontSize: "1rem",
  },
});

const Header = (props) => (
  <SharedHeader {...props} overloadedClasses={useStyles()} avatarSize={50} />
);

export default Header;
