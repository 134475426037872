import normalize from "json-api-normalizer";

import { PATCH } from "../util/apiHelpers";
import { receiveEvents } from "./eventActions";

export const acceptInvitation = (invitationId) => (dispatch) => {
  return PATCH("/api/v1/event_invitations/" + invitationId + "/accept").then(
    (res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveEvents(normalized));
    }
  );
};

export const declineInvitation = (invitationId) => (dispatch) => {
  return PATCH("/api/v1/event_invitations/" + invitationId + "/decline").then(
    (res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveEvents(normalized));
    }
  );
};
