import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { getPrimaryGroup } from "../../selectors/groupSelectors";
import InviteTeammateModal from "./InviteTeammateModal/InviteTeammateModal";

import { fetchProfiles as fetchFunction } from "../../actions/profileActions";

import PaginatedFetch from "../General/PaginatedFetch";
import withActionHeader from "../General/withActionHeader";
import withGlobalProviders from "../General/withGlobalProviders";

import IndexComponent from "./index/IndexComponent";
import { DEFAULT } from "./index/order/orderHelpers";

const EmailChapterButton = () => {
  const settings = useSelector((state) => state.settings.attributes);
  const primaryGroup = useSelector(getPrimaryGroup);
  const groupName = settings.admin ? "national" : ((primaryGroup && primaryGroup.attributes) ? primaryGroup.attributes.name : "national");
  const buttonText = settings.boardMember
    ? "Email My Chapter"
    : "Email My Board";

  return (
    <button
      className={classnames("btn", "btn-secondary")}
      style={{
        height: "100%",
        borderRadius: 4,
        padding: "0px 2rem",
        marginLeft: 24,
      }}
      type="button"
    >
      <a
        href={`/chapter/${groupName}/announcement/new`}
        style={{ color: "white" }}
      >
        {buttonText}
      </a>
    </button>
  );
};

const InviteMembersButton = () => {
  let { search } = useLocation();

  const queryParams = queryString.parse(search);

  const [open, setOpen] = useState(queryParams?.invite === "true");

  return (
    <React.Fragment>
      <button
        className={classnames("btn", "btn-secondary")}
        style={{
          height: "100%",
          borderRadius: 4,
          padding: "0px 2rem",
          marginLeft: 24,
        }}
        onClick={() => setOpen(true)}
        type="button"
      >
        Invite New Members
      </button>
      <InviteTeammateModal open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

const Index = ({ chapterOptions }) => {
  let location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [search, setSearch] = useState(queryParams?.search || "");
  const [order, setOrder] = useState(queryParams?.order || DEFAULT);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [customParams, setCustomParams] = useState({});

  useEffect(() => {
    setCustomParams({ order, search, ...filters });
  }, [order, search, filters]);

  return (
    <PaginatedFetch
      {...{
        fetchFunction,
        loading,
        setLoading,
        customParams,
        initialPage: (queryParams.page && parseInt(queryParams.page)) || 1,
      }}
    >
      {({ fetchAction, handlePageSelection, currentPage, totalPages, items, loading }) => (
        <IndexComponent
          {...{
            fetchAction,
            handlePageSelection,
            currentPage,
            totalPages,
            search,
            setSearch,
            order,
            setOrder,
            loading,
            setLoading,
            setFilters,
            items,
            chapterOptions
          }}
        />
      )}
    </PaginatedFetch>
  );
};

export default withGlobalProviders(
  withActionHeader(Index, [InviteMembersButton, EmailChapterButton])
);
