import React from "react";

import Wrapper from "./index/Wrapper";
import Toolbar from "./index/Toolbar";
import Items from "./index/Items";

export default function Index() {
  return (
    <Wrapper>
      <Toolbar />
      <Items />
    </Wrapper>
  );
}
