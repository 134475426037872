import React from "react";
import FormPageLayout from "../../General/FormPage/FormPageLayout";

const Tips = () => {
  return (
    <FormPageLayout>
      <div style={{ padding: "24px 16px 24px 16px" }}>
        <div
          className="kt-section__title"
          style={{
            fontWeight: 500,
            fontSize: "1.3rem",
            marginBottom: "1.75em",
          }}
        >
          Tips for a great event
        </div>
        <div className="kt-section__desc" style={{ fontWeight: 500 }}>
          Be descriptive
        </div>
        <div className="kt-section__content" style={{ marginBottom: "1.75em" }}>
          A good title immediately gives people an idea of what the event is
          about.
        </div>
        <div className="kt-section__desc" style={{ fontWeight: 500 }}>
          Get organized
        </div>
        <div className="kt-section__content" style={{ marginBottom: "1.75em" }}>
          Describe things in a clear order so it's easy to digest. Start with an
          overall description of the event and include a basic agenda, before
          you move into really specific details.
        </div>
        <div className="kt-section__desc" style={{ fontWeight: 500 }}>
          Add an image
        </div>
        <div className="kt-section__content" style={{ marginBottom: "1.75em" }}>
          Use the preview to edit the image to your liking.
        </div>
      </div>
    </FormPageLayout>
  );
};

export default Tips;
