import React from "react";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { formatCount } from "../../../../util/formatHelpers";
import merge from "lodash/merge";
import classnames from "classnames";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: "auto",
    height: 30,
  },
  pollStatus: {
    fontSize: 14,
    color: "#595D6E",
    fontStyle: "italic",
  },
  commentsCount: {
    color: "#357BA6",
    fontSize: 14,
    fontWeight: 600,
    marginLeft: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  commentIcon: {
    height: 15,
    width: 15,
    marginRight: 3,
  },
});

const Footer = ({ poll, action, overloadedClasses = {} }) => {
  const classes = merge({}, useStyles(), overloadedClasses);

  const {
    closesAt,
    responseCount,
    urlPath,
    commentsCount,
  } = poll.attributes;

  const isClosed = moment(closesAt).isBefore(moment());
  const closeStatusText = isClosed
    ? "Poll Closed"
    : `${moment(closesAt).fromNow(moment())} remaining`;

  return (
    <div className={classes.container}>
      <div className={classes.pollStatus}>
        {formatCount("Vote", responseCount)} - {closeStatusText}
      </div>
      <div>{action}</div>
      <div
        className={classes.commentsCount}
        onClick={() => (window.location.href = urlPath)}
      >
        <i className={classnames("la", "la-comments-o", classes.commentIcon)} />
        {formatCount("Comment", commentsCount)}
      </div>
    </div>
  );
};

export default Footer;
