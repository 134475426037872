import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import {
  ConnectedTextField,
  ConnectedDropdown,
} from "../../General/FormInputs/ConnectedInputs";

import map from "lodash/map";

import {
  TA_LEVEL_OPTIONS,
  COMPANY_INDUSTRY_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  PERSONAL,
  PROFESSIONAL,
} from "./constants";

export const FirstNameField = () => (
  <ConnectedTextField id="firstName" label="First name" />
);

export const LastNameField = () => (
  <ConnectedTextField id="lastName" label="Last name" />
);

export const PersonalEmailField = () => (
  <ConnectedTextField id="personalEmail" label="Personal email address" />
);

export const LinkedinUrlField = () => {
  return (
    <ConnectedTextField
      id="linkedinUrl"
      label={
        <span>
          LinkedIn Profile URL{" "}
          <Tooltip
            title={
              <span>
                1. After logging in to LinkedIn, click your profile picture in
                the upper left.<br></br>
                2. Copy the URL that appears in the address bar of your browser.{" "}
                <br></br>
                3. Paste that URL here.
              </span>
            }
          >
            <InfoOutlinedIcon
              style={{ fontSize: 16, color: "#90A4AE", marginBottom: 2 }}
            />
          </Tooltip>{" "}
        </span>
      }
    />
  );
};

export const TALevelField = () => {
  const options = useMemo(
    () => map(TA_LEVEL_OPTIONS, (value) => ({ value, label: value })),
    []
  );

  return (
    <ConnectedDropdown
      id="taLevel"
      label="What is your level within TA?"
      options={options}
    />
  );
};

export const PrimaryEmailAddressField = () => {
  const options = [
    { value: PERSONAL, label: "Personal" },
    { value: PROFESSIONAL, label: "Work" },
  ];

  return (
    <ConnectedDropdown
      id="preferredContact"
      options={options}
      label={
        <span>
          Primary email address{" "}
          <Tooltip
            title={
              "Preferred email for receiving event invitations, reminders, and notifications."
            }
          >
            <InfoOutlinedIcon
              style={{ fontSize: 16, color: "#90A4AE", marginBottom: 2 }}
            />
          </Tooltip>{" "}
        </span>
      }
    />
  );
};

export const CompanyNameField = () => (
  <ConnectedTextField id="companyName" label="Company name" />
);

export const JobTitleField = () => (
  <ConnectedTextField id="jobTitle" label="Job title" />
);

export const WorkEmailField = () => (
  <ConnectedTextField id="workEmail" label="Work email address" />
);

export const CompanySizeField = (props) => {
  const options = useMemo(
    () => map(COMPANY_SIZE_OPTIONS, (value) => ({ value, label: value })),
    []
  );

  return (
    <ConnectedDropdown
      id="companySize"
      label="What is your company's size?"
      options={options}
      {...props}
    />
  );
};

export const CompanyIndustryField = (props) => {
  const options = useMemo(
    () => map(COMPANY_INDUSTRY_OPTIONS, (value) => ({ value, label: value })),
    []
  );

  return (
    <ConnectedDropdown
      id="companyIndustry"
      label="What is your company's industry?"
      options={options}
      {...props}
    />
  );
};
