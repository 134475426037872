import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import uniq from "lodash/uniq";
import xor from "lodash/xor";
import { useEffect, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { makeGetConversationsWithParticipants } from "../../../../selectors/conversationSelectors";

import ConversationsContext from "../../ConversationsContext";

import { USER } from "./constants";

const useConversationAutoSelector = (selectedValue) => {
  // cannot useConversationContext hook here, because modal exists outside of context
  const context = useContext(ConversationsContext);

  const currentUserId = useSelector((state) => state.settings.attributes.id);

  const getConversationsWithParticipants = useMemo(
    makeGetConversationsWithParticipants,
    []
  );

  const conversationsWithActiveParticipants = useSelector((state) => getConversationsWithParticipants(state, { isActive: true }));

  const selectedUserIds = useMemo(
    () => uniq(
      map(
        filter(selectedValue, ({ type }) => type === USER),
        ({ value }) => value
      ).concat([currentUserId])
    ),
    [selectedValue, currentUserId]
  );

  useEffect(() => {
    if (context === undefined) return;

    if (selectedUserIds.length <= 1) {
      if (context.selectedConversationId !== null) {
        context.setSelectedConversationId(null);
      }
      return;
    }

    const conversationId = conversationsWithActiveParticipants.find(
      (convoParticipants) => {
        const convoUserIds = map(
          convoParticipants.participants,
          (participant) => participant.attributes.userId
        );
        return isEmpty(xor(selectedUserIds, convoUserIds));
      }
    )?.conversation?.id ?? null;

    if (context.selectedConversationId !== conversationId) {
      context.setSelectedConversationId(conversationId);
    }
  }, [context, conversationsWithActiveParticipants, currentUserId, selectedUserIds]);
};

export default useConversationAutoSelector;
