import { RECEIVE_PROFILES } from "../actions/profileActions";
import merge from "lodash/merge";

const defaultState = {
  items: {},
  loaded: false,
};

const profilesReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PROFILES:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default profilesReducer;
