import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebedf2",
    height: 60,
    minHeight: 60,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  createIcon: {
    fontSize: 20,
    cursor: "pointer",
  },
}));

const ConversationsListHeader = ({ openNewConversation }) => {
  const classes = useStyles();

  const unreadEventsCount = useSelector(
    (state) => state.conversations.events.totalUnreadCount
  );

  return (
    <div className={classes.container}>
      <div>
        Chats
        {Boolean(unreadEventsCount) && (
          <span> - {unreadEventsCount} unread</span>
        )}
      </div>
      <div>
        <Tooltip title="New chat">
          <i
            className={classnames("la", "la-edit", classes.createIcon)}
            onClick={openNewConversation}
          ></i>
        </Tooltip>
      </div>
    </div>
  );
};

export default ConversationsListHeader;
