import merge from "lodash/merge";

import { RECEIVE_CONVERSATION_ATTACHMENTS } from "../../actions/conversationActions";

const defaultState = {
  items: {},
};

const attachmentsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CONVERSATION_ATTACHMENTS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default attachmentsReducer;
