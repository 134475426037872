import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Col, Visible } from "react-grid-system";
import AvatarIcon from "../../../General/AvatarIcon";

import { styles, Name } from "./utils";

export default function AvatarColumn({ id }) {
  const profile = useSelector(
    (state) => state.profiles.items[id],
    shallowEqual
  );

  const classes = styles();

  const { firstName, lastName } = profile.attributes;
  return (
    <React.Fragment>
      <Col
        xs={4}
        lg={2}
        className={classes.avatarColumn}
        style={{ maxWidth: 120 }}
      >
        <AvatarIcon member={profile} height={90} />
      </Col>
      <Visible xs sm md>
        <Col xs={8} className={classes.mobileName}>
          <Name {...{ firstName, lastName }} />
        </Col>
      </Visible>
    </React.Fragment>
  );
}
