import moment from "moment";
import React from "react";

import useSharedStyles from "./useSharedStyles";

const ConversationItemTimestamp = ({ conversation, recentEvent }) => {
  const classes = useSharedStyles();

  const timestamp = (recentEvent || conversation).attributes.createdAt;

  const formattedTimestamp = moment(timestamp).isAfter(moment().startOf("day"))
    ? moment(timestamp).format("h:mm A")
    : moment(timestamp).format("MMM Do");

  return <div className={classes.timestampContainer}>{formattedTimestamp}</div>;
};

export default ConversationItemTimestamp;
