import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchEvent } from '../../../../actions/eventActions';

import {
  RSVP_EVENT_INDEX,
  URL_EVENT_SHOW,
  LINKEDIN_EVENT_SHOW
} from '../../../../util/fathomEventCodes';
import withGlobalProviders from '../../../General/withGlobalProviders';
import InvitationActions from '../../index/item/actions/InvitationActions';
import ShareEventActions from '../../index/item/actions/ShareEventActions';
import StatusText from '../../index/item/actions/StatusText';

function Actions ({ id }) {
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.events.loaded);

  useEffect(() => {
    dispatch(fetchEvent(id))
  }, [dispatch, id]);

  if (!loaded) return null;

  return (<div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <StatusText id={id} />
    <InvitationActions {...{ id, trackGoal: RSVP_EVENT_INDEX }} />
    <ShareEventActions
      {...{
        id,
        trackUrlGoal: URL_EVENT_SHOW,
        trackLinkedinGoal: LINKEDIN_EVENT_SHOW
      }}
    />
  </div>);
}

export default withGlobalProviders(Actions);
