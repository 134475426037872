import React from "react";
import { connect } from "formik";

import FormFooter from "../../General/FormPage/FormFooter";

const SubmitButton = connect(({ formik: { handleSubmit } }) => {
  return (
    <button className="btn btn-secondary" onClick={handleSubmit}>
      Create Topic
    </button>
  );
});

const CancelButton = () => {
  const handleClick = () => {
    const chapterMatch = window.location.pathname.match(
      /\/chapter\/(.+)\/topics\/new/i
    );
    const chapterName = chapterMatch && chapterMatch[1];

    chapterName &&
      (window.location.href = `/chapter/${chapterName}/forums`);
  };

  return (
    <button
      className="btn btn-outline-brand-secondary"
      onClick={handleClick}
    >
      Cancel
    </button>
  );
};

const Footer = () => (
  <FormFooter
    actions={[
      <CancelButton key={"footer-cancel"} />,
      <SubmitButton key={"footer-submit"} />,
    ]}
  />
);

export default Footer;
