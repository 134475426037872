import React from "react";
import { makeStyles } from "@material-ui/core";

import { useStyles as useEventsListStyles } from "../ConversationEvents/ConversationEventsIndex";
import Skeleton from "react-loading-skeleton";
import classnames from "classnames";

const AVATAR_SIZE = 36;

const useMessageContentStyles = makeStyles((theme) => ({
  container: ({ fromSelf }) => ({
    marginBottom: theme.spacing(1),
    maxWidth: "66%",
    width: "66%",
    alignSelf: fromSelf ? "flex-end" : "flex-start",
    borderRadius: 8,
  }),
  skeletonWrapper: {
    "& > span": {
      width: "100%",
    },
  },
}));

const useMessageHeaderStyles = makeStyles((theme) => ({
  container: ({ fromSelf }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    alignSelf: fromSelf ? "flex-end" : "flex-start",
  }),
  textContent: {
    marginLeft: theme.spacing(2),
  },
}));

const MessageHeaderSkeleton = ({ fromSelf }) => {
  const messageHeaderClasses = useMessageHeaderStyles({ fromSelf });

  return (
    <div className={classnames(messageHeaderClasses.container)}>
      <Skeleton circle={true} height={AVATAR_SIZE} width={AVATAR_SIZE} />
      <div className={messageHeaderClasses.textContent}>
        <Skeleton height={20} width={150} />
      </div>
    </div>
  );
};

const MessageContentSkeleton = ({ fromSelf }) => {
  const messageContentClasses = useMessageContentStyles({ fromSelf });

  return (
    <div
      className={classnames(
        messageContentClasses.container,
        messageContentClasses.skeletonWrapper
      )}
    >
      <Skeleton height={48} width={"100%"} />
    </div>
  );
};

const MessagesIndexSkeleton = () => {
  const eventListClasses = useEventsListStyles();

  return (
    <div className={eventListClasses.listContainer}>
      <MessageHeaderSkeleton fromSelf={false} />
      <MessageContentSkeleton fromSelf={false} />
      <MessageHeaderSkeleton fromSelf={true} />
      <MessageContentSkeleton fromSelf={true} />
    </div>
  );
};

export default MessagesIndexSkeleton;
