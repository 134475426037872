import React from "react";
import { useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";

import { updateComment } from "../../../actions/commentActions";
import { updateTopic } from "../../../actions/topicActions";
import { Formik } from "formik";
import { ConnectedWysiwyg } from "../../General/FormInputs/ConnectedInputs";
import { object, string } from "yup";

const validationSchema = object().shape({
  body: string().required("Cannot be blank"),
});
const uploadFolderNames = { comment: "comments", topic: "posts" };

const useStyles = createUseStyles({
  container: {
    display: "flex",
    marginTop: 24,
    marginRight: 24,
    position: "relative",
  },
  buttonContainer: {
    position: "absolute",
    top: "-32px",
    right: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  form: {
    marginLeft: 12,
    width: "100%",
  },
});

const UpdatePostForm = ({
  id,
  initialValues = { body: "" },
  setEditing,
  editorHeight = 150,
  category,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const updatePost = { topic: updateTopic, comment: updateComment }[category];

    return dispatch(updatePost({ body: values.body, id })).then(() => {
      setEditing(false);
    });
  };
  const handleCancel = () => setEditing(false);

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleSubmit }) => (
            <div>
              <ConnectedWysiwyg
                id="body"
                uploadFolder={uploadFolderNames[category]}
                customInitProps={{ height: editorHeight }}
                style={{ container: { marginBottom: "1rem" } }}
              />
              <div className={classes.buttonContainer}>
                <div
                  onClick={handleCancel}
                  className={classes.button}
                  style={
                    isSubmitting ? { pointerEvents: "none", opacity: 0.7 } : {}
                  }
                >
                  cancel
                </div>
                <div
                  onClick={handleSubmit}
                  className={classes.button}
                  style={
                    isSubmitting ? { pointerEvents: "none", opacity: 0.7 } : {}
                  }
                >
                  save
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdatePostForm;
