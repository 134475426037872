import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

import { styles } from "./utils";
import Input from "./Input";

const Search = ({
  search,
  loading,
  setSearch,
  width = "100%",
  placeholder,
  inputClass,
}) => {
  const classes = styles({});

  const [inputValue, setInputValue] = useState("");
  const [inputLoading, setInputLoading] = useState(false);

  useEffect(() => {
    if (!inputLoading && search !== inputValue && search === "") {
      setInputValue(search);
    }
  }, [search]);

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      setInputLoading(true);
    }
    performSearch(inputValue);
  }, [inputValue]);

  const performSearch = useCallback(
    debounce((search) => {
      setInputLoading(false);
      setSearch(search);
    }, 500),
    []
  );

  return (
    <div className={`form-group ${classes.searchContainer}`} style={{ width }}>
      <Input
        {...{
          search,
          loading,
          inputValue,
          setInputValue,
          inputLoading,
          placeholder,
          inputClass,
        }}
      />
    </div>
  );
};

export default Search;
