import classnames from "classnames";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { createUseStyles } from "react-jss";
import { shallowEqual, useSelector } from "react-redux";

import { AvatarIcon } from "../../General/AvatarIcon";
import ProfileHover from "../../Shared/ProfileHover/ProfileHover";


const useStyles = createUseStyles({
  container: {
    borderTop: "1px solid #ECEDF2",
  },
  descriptionIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  statusItemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  statusItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
  },
  statusIcon: {
    height: 15,
    width: 15,
    marginRight: 3,
  },
  "@media screen and (max-width: 600px)": {
    descriptionIcons: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});

const UserCard = ({ topic, user, group }) => {
  const classes = useStyles();
  const { subject, userId } = topic.attributes;
  const { firstName, lastName, companyName } = user.attributes;
  const { name: groupName } = group.attributes;

  return (
    <>
      <ProfileHover {...{ userId }}>
        <AvatarIcon user={user} height={50} />
      </ProfileHover>
      <div className="kt-notification-v2__itek-wrapper">
        <div
            className="kt-notification-v2__item-title"
            style={{ color: "#F35B5E", fontSize: 16 }}
          >
          {subject}
        </div>
        <div
            className={`kt-notification-v2__item-desc ${classes.descriptionIcons}`}
            style={{
              color: "#212121",
              fontSize: 14,
            }}
          >
          <div style={{ marginRight: 15 }}>
            <i className="la la-book" /> {groupName}
          </div>
          <ProfileHover {...{ userId }}>
            <div>
              <i className="la la-user"></i> {firstName} {lastName} -{" "}
              {companyName}
            </div>
          </ProfileHover>
        </div>
      </div>
    </>
  )
}

const VendorCard = ({ topic, group }) => {
  const classes = useStyles();
  const { name: groupName } = group.attributes;
  const { vendorProduct, subject } = topic.attributes;
  
  return (
    <>
      <AvatarIcon height={50}
        user={{ attributes: { avatarUrl: vendorProduct.logoUrl, firstName: vendorProduct.name, lastName: "" } }} />
      <div className="kt-notification-v2__itek-wrapper">
        <div
            className="kt-notification-v2__item-title"
            style={{ color: "#F35B5E", fontSize: 16 }}
          >
          {subject}
        </div>
        <div
            className={`kt-notification-v2__item-desc ${classes.descriptionIcons}`}
            style={{
              color: "#212121",
              fontSize: 14,
            }}
          >
          <div style={{ marginRight: 15 }}>
            <i className="la la-book" /> {groupName}
          </div>
          <div>
            <i className="la la-building"></i> {vendorProduct.name} - Vendor Post
          </div>
        </div>
      </div>
    </>
  );
}

const Topic = ({ id }) => {
  const classes = useStyles();
  const topic = useSelector((state) => state.topics.items[id], shallowEqual);

  const group = useSelector(
    (state) => topic && state.groups.items[topic.attributes.groupId]
  );

  const user = useSelector(
    (state) => topic && state.users.items[topic.attributes.userId],
    shallowEqual
  );

  if (!(topic && group && user)) {
    return null;
  }

  const { urlPath, lastActivityAt, commentsCount } =
    topic.attributes;

  return (
    <div className={classes.container}>
      <a
        href={urlPath}
        className="kt-notification-v2__item"
        style={{ padding: "2rem" }}
      >
        {topic.attributes.vendorProduct ? (
          <VendorCard {...{ topic, group }} />
        ) : (
          <UserCard {...{ topic, user, group }} />
        )}
        <div className={classes.statusItemsContainer}>
          <div
            className={classes.statusItem}
            style={{
              color: "#212121",
            }}
          >
            <i className={clsx("la la-clock-o", classes.statusIcon)}></i>
            {moment(lastActivityAt).fromNow()}
          </div>
          <div
            className={classes.statusItem}
            style={{
              color: "#357BA6",
              fontWeight: 600,
            }}
          >
            <i
              className={classnames("la", "la-comments-o", classes.statusIcon)}
            />
            {commentsCount} Comment{commentsCount !== 1 && "s"}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Topic;
