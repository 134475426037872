import React, { memo, useMemo, useState } from "react";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { getAvatarColors } from "../../util/colorHelpers";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    alignItems: "center",
  },
});

const convertNameToInitials = (firstName, lastName) =>
  firstName[0] + lastName[0];

// brychael-lezul: weird prop names? avatarId => userId, member => user
export const AvatarIcon = ({
  height,
  user,
  onImageLoadError = () => {},
  customStyles = {},
}) => {
  const classes = useStyles();
  const [imageLoadError, setImageLoadError] = useState(false);

  const { avatarUrl, firstName, lastName } = user.attributes;

  const avatarColors = useMemo(
    () => getAvatarColors(firstName, lastName),
    [firstName, lastName]
  );

  const handleImageLoadError = () => {
    setImageLoadError(true);
    onImageLoadError();
  };

  useEffect(() => {
    setImageLoadError(false);
  }, [avatarUrl]);

  return (
    <div>
      {avatarUrl && !imageLoadError ? (
        <img
          className={`kt-widget__img ${classes.container}`}
          style={Object.assign({ height, width: height }, customStyles)}
          src={avatarUrl}
          alt={`${firstName} ${lastName}`}
          onError={handleImageLoadError}
        />
      ) : (
        <div
          className={`kt-widget__pic kt-font-boldest ${classes.container}`}
          style={Object.assign(
            {
              height: height,
              width: height,
              minWidth: height,
              opacity: "75%",
              backgroundColor: avatarColors.backgroundColor,
              color: avatarColors.initials,
              fontSize: height / 3,
            },
            customStyles
          )}
        >
          {convertNameToInitials(firstName, lastName)}
        </div>
      )}
    </div>
  );
};

const ConnectedAvatarIcon = ({ member, avatarId, ...props }) => {
  const userById = useSelector((state) =>
    avatarId ? state.users.items[avatarId] : null
  );

  const user = member || userById;

  return <AvatarIcon {...props} user={user} />;
};

export default memo(ConnectedAvatarIcon);
