import { object, string, ref } from "../../../../util/yup.js";
import {
  PREFERRED_CONTACT_OPTIONS,
} from "../../shared/constants.js";

export const buildInitialValues = (attributes) => ({
  firstName: attributes.firstName || "",
  lastName: attributes.lastName || "",
  personalEmail: attributes.personalEmail || "",
  workEmail: attributes.workEmail || "",
  linkedinUrl: attributes.linkedinUrl || "",
  preferredContact: attributes.preferredContact || "",
});

// brychael-lezul: once we rewrite the application form, we should add backend validations
// for email and linkedin_url fields
export const validationSchema = object().shape({
  firstName: string().required("This field is required"),
  lastName: string().required("This field is required"),
  workEmail: string()
    .required("This field is required")
    .email("Must be a valid email address"),
  personalEmail: string()
    .required("This field is required")
    .email("Must be a valid email address")
    .notOneOf(
      [
        ref('workEmail'),
      ],
      "Cannot be same as work email"
    ),
  linkedinUrl: string().required("This field is required").validLinkedinUrl(),
  preferredContact: string()
    .required("This field is required")
    .oneOf(PREFERRED_CONTACT_OPTIONS, "Must be one of the available options"),
});
